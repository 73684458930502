/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeavePolicy } from './LeavePolicy';
import {
    LeavePolicyFromJSON,
    LeavePolicyFromJSONTyped,
    LeavePolicyToJSON,
} from './LeavePolicy';

/**
 * 
 * @export
 * @interface ShowLeavePolicyResponse
 */
export interface ShowLeavePolicyResponse {
    /**
     * 
     * @type {LeavePolicy}
     * @memberof ShowLeavePolicyResponse
     */
    data: LeavePolicy;
}

/**
 * Check if a given object implements the ShowLeavePolicyResponse interface.
 */
export function instanceOfShowLeavePolicyResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowLeavePolicyResponseFromJSON(json: any): ShowLeavePolicyResponse {
    return ShowLeavePolicyResponseFromJSONTyped(json, false);
}

export function ShowLeavePolicyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowLeavePolicyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeavePolicyFromJSON(json['data']),
    };
}

export function ShowLeavePolicyResponseToJSON(value?: ShowLeavePolicyResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeavePolicyToJSON(value['data']),
    };
}

