/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfirmUploadResponse,
  CreateUploadData,
  CreateUploadResponse,
  ListUploadsResponse,
  ShowUploadResponse,
} from '../models/index';
import {
    ConfirmUploadResponseFromJSON,
    ConfirmUploadResponseToJSON,
    CreateUploadDataFromJSON,
    CreateUploadDataToJSON,
    CreateUploadResponseFromJSON,
    CreateUploadResponseToJSON,
    ListUploadsResponseFromJSON,
    ListUploadsResponseToJSON,
    ShowUploadResponseFromJSON,
    ShowUploadResponseToJSON,
} from '../models/index';

export interface ConfirmUploadRequest {
    id: string;
}

export interface CreateUploadRequest {
    createUploadData?: CreateUploadData;
}

export interface ListUploadsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowUploadRequest {
    id: number;
}

/**
 * 
 */
export class UploadApi extends runtime.BaseAPI {

    /**
     * 
     * Confirm Upload
     */
    async confirmUploadRaw(requestParameters: ConfirmUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfirmUploadResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling confirmUpload().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.upload.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/uploads/{id}/confirm`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmUploadResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Confirm Upload
     */
    async confirmUpload(requestParameters: ConfirmUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfirmUploadResponse> {
        const response = await this.confirmUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new pending upload for a new file. The file should be uploaded using the returned URL.
     * Create Upload
     */
    async createUploadRaw(requestParameters: CreateUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUploadResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.upload.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/uploads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUploadDataToJSON(requestParameters['createUploadData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUploadResponseFromJSON(jsonValue));
    }

    /**
     * Create a new pending upload for a new file. The file should be uploaded using the returned URL.
     * Create Upload
     */
    async createUpload(requestParameters: CreateUploadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUploadResponse> {
        const response = await this.createUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View all files that have been uploaded for a company
     * List Uploads
     */
    async listUploadsRaw(requestParameters: ListUploadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListUploadsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.upload.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/uploads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListUploadsResponseFromJSON(jsonValue));
    }

    /**
     * View all files that have been uploaded for a company
     * List Uploads
     */
    async listUploads(requestParameters: ListUploadsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListUploadsResponse> {
        const response = await this.listUploadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details of a specific upload
     * Show Upload
     */
    async showUploadRaw(requestParameters: ShowUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowUploadResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showUpload().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.upload.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/uploads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowUploadResponseFromJSON(jsonValue));
    }

    /**
     * Show details of a specific upload
     * Show Upload
     */
    async showUpload(requestParameters: ShowUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowUploadResponse> {
        const response = await this.showUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
