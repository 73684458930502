<template>
  <SidebarLayout disable-sidebar-margin-right>
    <template #menu>
      <ReportsMenu />
    </template>
    <template #body>
      <slot />
    </template>
  </SidebarLayout>
</template>

<script>
import ReportsMenu from '@/components/menu/ReportsMenu.vue';
import SidebarLayout from '@/layouts/SidebarLayout';

export default {
  name: 'ReportsLayout',

  components: {
    ReportsMenu,
    SidebarLayout,
  },
};
</script>
