/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyNotificationChannel } from './CompanyNotificationChannel';
import {
    CompanyNotificationChannelFromJSON,
    CompanyNotificationChannelFromJSONTyped,
    CompanyNotificationChannelToJSON,
} from './CompanyNotificationChannel';

/**
 * 
 * @export
 * @interface UpdateCompanyNotificationChannelResponse
 */
export interface UpdateCompanyNotificationChannelResponse {
    /**
     * 
     * @type {CompanyNotificationChannel}
     * @memberof UpdateCompanyNotificationChannelResponse
     */
    data: CompanyNotificationChannel;
}

/**
 * Check if a given object implements the UpdateCompanyNotificationChannelResponse interface.
 */
export function instanceOfUpdateCompanyNotificationChannelResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateCompanyNotificationChannelResponseFromJSON(json: any): UpdateCompanyNotificationChannelResponse {
    return UpdateCompanyNotificationChannelResponseFromJSONTyped(json, false);
}

export function UpdateCompanyNotificationChannelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyNotificationChannelResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanyNotificationChannelFromJSON(json['data']),
    };
}

export function UpdateCompanyNotificationChannelResponseToJSON(value?: UpdateCompanyNotificationChannelResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': CompanyNotificationChannelToJSON(value['data']),
    };
}

