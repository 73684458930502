/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListAvailableEmployeesResponse
 */
export interface ListAvailableEmployeesResponse {
    /**
     * 
     * @type {Array<Employee>}
     * @memberof ListAvailableEmployeesResponse
     */
    data: Array<Employee>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListAvailableEmployeesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListAvailableEmployeesResponse interface.
 */
export function instanceOfListAvailableEmployeesResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListAvailableEmployeesResponseFromJSON(json: any): ListAvailableEmployeesResponse {
    return ListAvailableEmployeesResponseFromJSONTyped(json, false);
}

export function ListAvailableEmployeesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAvailableEmployeesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EmployeeFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListAvailableEmployeesResponseToJSON(value?: ListAvailableEmployeesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(EmployeeToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

