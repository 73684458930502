/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A tag is essentially a label to be added to models
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    status: TagStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    visibleToEmployees: boolean;
}

/**
* @export
* @enum {string}
*/
export enum TagStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the Tag interface.
 */
export function instanceOfTag(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('status' in value)) return false;
    if (!('visibleToEmployees' in value)) return false;
    return true;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'status': json['status'],
        'visibleToEmployees': json['visibleToEmployees'],
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'status': value['status'],
        'visibleToEmployees': value['visibleToEmployees'],
    };
}

