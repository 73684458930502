<template>
  <div>
    <div
      v-for="(n, index) in iterate"
      :key="`Placeholder ${n}`"
      :style="{ 'animation-delay': `${index * 0.1}s` }"
      class="placeholder"
    >
      <i :class="Icon.User" />
    </div>
  </div>
</template>

<script>
import { Icon } from '@/lib/enum/Icon';

/**
 * `AvatarPlaceholder` is used as a loading state for avatars or logos. All of the animation is handled within
 * the component.
 *
 * `iterate` property is used to specify how many iterations of the placeholder you need.
 */
export default {
  name: 'AvatarPlaceholder',

  props: {
    iterate: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      Icon,
    };
  },
};
</script>

<style scoped lang="scss">
.placeholder {
  animation-name: backgroundFade;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  margin-right: 1rem;
  border-radius: 50%;
  display: inline-block;
  padding: 1rem 1.2rem;
  width: 60px;
  height: 60px;

  i {
    color: #ffffff;
    font-size: 1.5rem;
  }
}

@keyframes backgroundFade {
  from {
    background: $gray-400;
  }

  to {
    background: #ffffff;
  }
}
</style>
