/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveType } from './LeaveType';
import {
    LeaveTypeFromJSON,
    LeaveTypeFromJSONTyped,
    LeaveTypeToJSON,
} from './LeaveType';

/**
 * 
 * @export
 * @interface UpdateLeaveTypeResponse
 */
export interface UpdateLeaveTypeResponse {
    /**
     * 
     * @type {LeaveType}
     * @memberof UpdateLeaveTypeResponse
     */
    data: LeaveType;
}

/**
 * Check if a given object implements the UpdateLeaveTypeResponse interface.
 */
export function instanceOfUpdateLeaveTypeResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateLeaveTypeResponseFromJSON(json: any): UpdateLeaveTypeResponse {
    return UpdateLeaveTypeResponseFromJSONTyped(json, false);
}

export function UpdateLeaveTypeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeaveTypeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveTypeFromJSON(json['data']),
    };
}

export function UpdateLeaveTypeResponseToJSON(value?: UpdateLeaveTypeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeaveTypeToJSON(value['data']),
    };
}

