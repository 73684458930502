/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateEmployeeMessageData,
  CreateEmployeeMessageResponse,
  ListEmployeeMessageRecipientsResponse,
  ListEmployeeMessagesResponse,
  ShowEmployeeMessageStatsResponse,
} from '../models/index';
import {
    CreateEmployeeMessageDataFromJSON,
    CreateEmployeeMessageDataToJSON,
    CreateEmployeeMessageResponseFromJSON,
    CreateEmployeeMessageResponseToJSON,
    ListEmployeeMessageRecipientsResponseFromJSON,
    ListEmployeeMessageRecipientsResponseToJSON,
    ListEmployeeMessagesResponseFromJSON,
    ListEmployeeMessagesResponseToJSON,
    ShowEmployeeMessageStatsResponseFromJSON,
    ShowEmployeeMessageStatsResponseToJSON,
} from '../models/index';

export interface CreateEmployeeMessageRequest {
    createEmployeeMessageData?: CreateEmployeeMessageData;
}

export interface ListEmployeeMessageRecipientsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListEmployeeMessagesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

/**
 * 
 */
export class EmployeeMessageApi extends runtime.BaseAPI {

    /**
     * 
     * Create Employee Message
     */
    async createEmployeeMessageRaw(requestParameters: CreateEmployeeMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEmployeeMessageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-message.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmployeeMessageDataToJSON(requestParameters['createEmployeeMessageData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEmployeeMessageResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Create Employee Message
     */
    async createEmployeeMessage(requestParameters: CreateEmployeeMessageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEmployeeMessageResponse> {
        const response = await this.createEmployeeMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Employee Message Recipients
     */
    async listEmployeeMessageRecipientsRaw(requestParameters: ListEmployeeMessageRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeMessageRecipientsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-message.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-messages/recipients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeMessageRecipientsResponseFromJSON(jsonValue));
    }

    /**
     * List Employee Message Recipients
     */
    async listEmployeeMessageRecipients(requestParameters: ListEmployeeMessageRecipientsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeMessageRecipientsResponse> {
        const response = await this.listEmployeeMessageRecipientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * List Employee Messages
     */
    async listEmployeeMessagesRaw(requestParameters: ListEmployeeMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeMessagesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-message.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeMessagesResponseFromJSON(jsonValue));
    }

    /**
     * 
     * List Employee Messages
     */
    async listEmployeeMessages(requestParameters: ListEmployeeMessagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeMessagesResponse> {
        const response = await this.listEmployeeMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Employee Message Stats
     */
    async showEmployeeMessageStatsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeeMessageStatsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-message-stats.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-messages/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeeMessageStatsResponseFromJSON(jsonValue));
    }

    /**
     * Show Employee Message Stats
     */
    async showEmployeeMessageStats(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeeMessageStatsResponse> {
        const response = await this.showEmployeeMessageStatsRaw(initOverrides);
        return await response.value();
    }

}
