/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Upload } from './Upload';
import {
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
} from './Upload';

/**
 * 
 * @export
 * @interface ShowUploadResponse
 */
export interface ShowUploadResponse {
    /**
     * 
     * @type {Upload}
     * @memberof ShowUploadResponse
     */
    data?: Upload;
}

/**
 * Check if a given object implements the ShowUploadResponse interface.
 */
export function instanceOfShowUploadResponse(value: object): boolean {
    return true;
}

export function ShowUploadResponseFromJSON(json: any): ShowUploadResponse {
    return ShowUploadResponseFromJSONTyped(json, false);
}

export function ShowUploadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowUploadResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : UploadFromJSON(json['data']),
    };
}

export function ShowUploadResponseToJSON(value?: ShowUploadResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': UploadToJSON(value['data']),
    };
}

