/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSubscriptionQuoteData
 */
export interface CreateSubscriptionQuoteData {
    /**
     * The billing plan for the new subscription
     * @type {number}
     * @memberof CreateSubscriptionQuoteData
     */
    billingPlanId: number;
}

/**
 * Check if a given object implements the CreateSubscriptionQuoteData interface.
 */
export function instanceOfCreateSubscriptionQuoteData(value: object): boolean {
    if (!('billingPlanId' in value)) return false;
    return true;
}

export function CreateSubscriptionQuoteDataFromJSON(json: any): CreateSubscriptionQuoteData {
    return CreateSubscriptionQuoteDataFromJSONTyped(json, false);
}

export function CreateSubscriptionQuoteDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSubscriptionQuoteData {
    if (json == null) {
        return json;
    }
    return {
        
        'billingPlanId': json['billingPlanId'],
    };
}

export function CreateSubscriptionQuoteDataToJSON(value?: CreateSubscriptionQuoteData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'billingPlanId': value['billingPlanId'],
    };
}

