/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLeaveAdjustmentData
 */
export interface CreateLeaveAdjustmentData {
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveAdjustmentData
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveAdjustmentData
     */
    leavePeriodId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveAdjustmentData
     */
    leaveTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveAdjustmentData
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveAdjustmentData
     */
    reason: string;
}

/**
 * Check if a given object implements the CreateLeaveAdjustmentData interface.
 */
export function instanceOfCreateLeaveAdjustmentData(value: object): boolean {
    if (!('employeeId' in value)) return false;
    if (!('leavePeriodId' in value)) return false;
    if (!('leaveTypeId' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('reason' in value)) return false;
    return true;
}

export function CreateLeaveAdjustmentDataFromJSON(json: any): CreateLeaveAdjustmentData {
    return CreateLeaveAdjustmentDataFromJSONTyped(json, false);
}

export function CreateLeaveAdjustmentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveAdjustmentData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'leavePeriodId': json['leavePeriodId'],
        'leaveTypeId': json['leaveTypeId'],
        'amount': json['amount'],
        'reason': json['reason'],
    };
}

export function CreateLeaveAdjustmentDataToJSON(value?: CreateLeaveAdjustmentData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employeeId': value['employeeId'],
        'leavePeriodId': value['leavePeriodId'],
        'leaveTypeId': value['leaveTypeId'],
        'amount': value['amount'],
        'reason': value['reason'],
    };
}

