
import { Icon } from '../../lib/enum/Icon';

export default {
  name: 'Pill',
  props: {
    // Colour class name, e.g. 'purple-50'
    colour: {
      type: String,
      default: 'purple-900',
    },
    text: {
      type: String,
      default: '',
    },
    // Colour class name, e.g. 'purple-50'
    textColour: {
      type: String,
      default: 'white',
    },
    borderColour: {
      type: String,
      default: '',
    },
    icon: {
      type: String as () => Icon,
      default: '',
    },
    prependIcon: {
      type: String as () => Icon,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'right',
      validator: (p) => ['left', 'right'].includes(p),
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    hoverEffect: {
      type: String as () => 'darken' | 'lighten',
      default: 'darken',
      validator: (p) => ['darken', 'lighten'].includes(p),
    },
  },
};
