var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.viewport.md)?_c('SwitchBetweenInput',{staticClass:"w-1/2 mx-auto mb-4",attrs:{"value":_vm.subscriptionInterval,"active-color":"white","show-active-icon":"","options":[
      {
        label: _vm.$t(`label.${_vm.BillingPlanIntervalEnum.Monthly}`),
        value: _vm.BillingPlanIntervalEnum.Monthly,
      },
      {
        label: _vm.$t(`label.${_vm.BillingPlanIntervalEnum.Annual}`),
        value: _vm.BillingPlanIntervalEnum.Annual,
      },
    ]},on:{"toggled":function($event){return _vm.$emit('toggleInterval', $event)}}}):_vm._e(),_c('div',{staticClass:"all:grid md:grid-cols-2 all:gap-4 md:gap-8"},_vm._l((_vm.filteredPlans),function(plan){return _c('PackageCardV2',{key:plan.id,staticClass:"all:p-3 all:bg-white",attrs:{"can-trial-plan":_vm.isTrialVersion && !_vm.paymentMethod && !_vm.isCurrentPlan(plan),"can-choose-plan":!_vm.isCurrentPlan(plan) || !_vm.billingDetails || !_vm.paymentMethod,"show-choose-plan":!_vm.isCurrentPlan(plan) || (_vm.isTrialVersion && !_vm.paymentMethod),"most-popular":plan.name === _vm.PlanNameEnum.VeryShiftie,"package":plan,"is-current-package":_vm.isCurrentPlan(plan),"collapse-features":!_vm.viewport.md},on:{"trialPlan":function($event){return _vm.$emit('trialPlan', $event)},"choosePlan":function($event){return _vm.$emit('choosePlan', $event)}}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }