<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 274.496 60"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop
          offset="0"
          stop-color="#ec3f8c"
        />
        <stop
          offset="1"
          stop-color="#515ea6"
        />
        <animate
          id="icon"
          attributeName="x1"
          values="0.5; -1.5; -1; 0.5"
          dur="1.5s"
          begin="0.1s;icon.end+0.5s"
        />
        <animate
          id="icon2"
          attributeName="y2"
          values="1; 0.5; 1.5; 1"
          dur="1.5s"
          begin="0.1s;icon2.end+0.5s"
        />
      </linearGradient>
      <clipPath id="clip-path">
        <path
          id="SVGID"
          transform="translate(-613.359 -376.614)"
          fill="url(#linear-gradient)"
          :d="longPath"
        />
      </clipPath>
    </defs>
    <g
      id="Group_2146"
      data-name="Group 2146"
      transform="translate(-134 -112)"
    >
      <g
        id="Group_1587"
        data-name="Group 1587"
        transform="translate(134 112)"
      >
        <g
          id="Group_1586"
          data-name="Group 1586"
          clip-path="url(#clip-path)"
        >
          <rect
            id="Rectangle_928"
            data-name="Rectangle 928"
            width="42.574"
            height="60"
            transform="translate(-0.49)"
            fill="url(#linear-gradient)"
          >
            <animate
              id="icon3"
              attributeType="CSS"
              attributeName="opacity"
              values="0; 1; 0;"
              dur="1.5s"
              begin="0s;icon3.end + 0.1s"
            />
          </rect>
        </g>
      </g>
      <g
        id="Group_1585"
        data-name="Group 1585"
        transform="translate(204.126 115)"
      >
        <path
          id="Path_927"
          class="text-path"
          data-name="Path 927"
          transform="translate(-546.045 -445.628)"
          :fill="startColour"
          d="M562.205,500.273q-7.245,0-11.686-3.778a12.556,12.556,0,0,1-4.475-9.978h10.136a4.548,
            4.548,0,0,0,1.793,3.8,7.539,7.539,0,0,0,4.65,1.323,7.083,7.083,0,0,0,3.884-1.01,3.2,3.2,
            0,0,0,1.584-2.856,2.6,2.6,0,0,0-.539-1.637,4,4,0,0,0-1.69-1.167,15.027,15.027,0,0,
            0-2.263-.732q-1.115-.261-2.926-.574-1.638-.279-2.751-.505t-2.7-.679a15.344,15.344,0,0,
            1-2.664-.993,14.75,14.75,0,0,1-2.282-1.445,7.926,7.926,0,0,1-1.916-2,10.223,10.223,0,0,
            1-1.184-2.664,11.971,11.971,0,0,1-.47-3.448,10.873,10.873,0,0,1,4.285-8.9q4.282-3.429,
            11.214-3.431,6.966,0,11.18,3.535a11.671,11.671,0,0,1,4.249,9.317h-9.891a4.1,4.1,0,0,
            0-1.55-3.413,6.929,6.929,0,0,0-4.266-1.149,6.266,6.266,0,0,0-3.78,1.01,3.167,3.167,0,0,
            0-1.376,2.682,2.734,2.734,0,0,0,.226,1.132,2.032,2.032,0,0,0,.8.871c.384.244.744.453,
            1.081.626a7.482,7.482,0,0,0,1.533.524q1.025.261,1.671.383t1.967.365a39.77,39.77,0,0,1,
            8.081,2.091q6.232,2.786,6.234,9.751a11.447,11.447,0,0,1-4.424,9.421q-4.422,3.536-11.736,3.535"
        >
          <animate
            id="stext"
            attributeName="fill"
            :values="`${startColour}; ${endColour}; ${startColour};`"
            dur="1s"
            begin="0.1s;stext.end+0.5s"
          />
        </path>
        <path
          id="Path_928"
          data-name="Path 928"
          transform="translate(-539.939 -447.844)"
          :fill="startColour"
          d="M597.3,461.984a13.08,13.08,0,0,1,10.327,4.318q3.814,4.32,3.813,
            11.7v23.4H601.023V479.746q0-4.387-1.62-6.512a6.005,6.005,0,0,
            0-5.1-2.124,7.918,7.918,0,0,0-6.286,2.7,10.833,10.833,0,0,0-2.352,
            7.367V501.41H575.251V453.562a4.568,4.568,0,0,1,4.569-4.569h5.843v17.484a16.187,16.187,0,0,1,11.634-4.493"
        >
          <animate
            id="htext"
            attributeName="fill"
            :values="`${startColour}; ${endColour}; ${startColour};`"
            dur="1s"
            begin="0.2s;htext.end+0.5s"
          />
        </path>
        <path
          id="Path_929"
          data-name="Path 929"
          transform="translate(-532.888 -448.001)"
          :fill="startColour"
          d="M608.975,457.926v-5.113a4.568,4.568,0,0,1,4.569-4.569h5.81v9.682Zm0,5.12h10.412v38.52H608.975Z"
        >
          <animate
            id="itext"
            attributeName="fill"
            :values="`${startColour}; ${endColour}; ${startColour};`"
            dur="1s"
            begin="0.3s;itext.end+0.5s"
          />
        </path>
        <path
          id="Path_930"
          data-name="Path 930"
          transform="translate(-530.536 -448.043)"
          :fill="startColour"
          d="M647.009,463.611v8.567h-9.683V501.61H626.912V472.178h-6.686v-4a4.568,4.568,0,
            0,1,4.569-4.569h2.152v-3.483q0-5.64,3.135-8.864t9.091-3.221h7.766v8.185h-5.678q-4.213,0-4.214,4.143v3.239Z"
        >
          <animate
            id="ftext"
            attributeName="fill"
            :values="`${startColour}; ${endColour}; ${startColour};`"
            dur="1s"
            begin="0.4s;ftext.end+0.5s"
          />
        </path>
        <path
          id="Path_931"
          data-name="Path 931"
          transform="translate(-525.658 -447.182)"
          :fill="startColour"
          d="M666.858,491.483H671.6v9.264h-7.383q-6.342,
            0-10.015-3.674t-3.674-10.084v-16.4h-6.965v-2.23l15.22-16.194h1.985v10.066h10.623v8.358H660.938V485.6a5.344,
            5.344,0,0,0,5.92,5.886"
        >
          <animate
            id="ttext"
            attributeName="fill"
            :values="`${startColour}; ${endColour}; ${startColour};`"
            dur="1s"
            begin="0.4s;ttext.end+0.5s"
          />
        </path>
        <path
          id="Path_932"
          data-name="Path 932"
          transform="translate(-520.112 -448.001)"
          :fill="startColour"
          d="M670.087,457.926v-5.113a4.569,4.569,0,0,1,4.569-4.569h5.81v9.682Zm0,5.12H680.5v38.52H670.087Z"
        >
          <animate
            id="itext2"
            attributeName="fill"
            :values="`${startColour}; ${endColour}; ${startColour};`"
            dur="1s"
            begin="0.5s;itext2.end+0.5s"
          />
        </path>
        <path
          id="Path_933"
          data-name="Path 933"
          transform="translate(-517.608 -445.628)"
          :fill="startColour"
          d="M721.978,479.794a22.19,22.19,0,0,1-.209,3.065H692.616a9.693,9.693,0,0,0,3.153,
            6.043,9.389,9.389,0,0,0,6.286,2.107,9.78,9.78,0,0,0,4.823-1.185,8.4,8.4,0,0,0,
            3.326-3.274h10.937a19.658,19.658,0,0,1-19.086,13.723,19.9,19.9,0,0,1-10.188-2.664,
            19.148,19.148,0,0,1-7.192-7.332,21.919,21.919,0,0,1-.018-20.723,18.9,18.9,0,0,1,
            7.175-7.314,21.059,21.059,0,0,1,20.6.087,18.734,18.734,0,0,1,7.071,7.331,21.066,
            21.066,0,0,1,2.474,10.136m-19.923-11.319a9.1,9.1,0,0,0-5.852,1.915,9.8,9.8,0,0,
            0-3.308,5.365h18.668a10.4,10.4,0,0,0-3.535-5.4,9.534,9.534,0,0,0-5.973-1.881"
        >
          <animate
            id="etext"
            attributeName="fill"
            :values="`${startColour}; ${endColour}; ${startColour};`"
            dur="1s"
            begin="0.6s;etext.end+0.5s"
          />
        </path>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ShiftieLogoAnimated',

  data() {
    return {
      startColour: '#F4F4F4',
      endColour: '#2C1841',
      longPath:
        'M622.815,376.614a9.464,9.464,0,0,0-8.75,13.052c4.923,12.075,17.72,19.727,\n' +
        '18.262,20.046l.063.036c.927.568,11.36,7.125,15.2,16.528a2.279,2.279,0,0,1-.216,\n' +
        '2.147,2.24,2.24,0,0,1-1.872,1H623a2.184,2.184,0,0,1-1.873-1.007,2.3,2.3,0,0,\n' +
        '1-.186-2.2,33.563,33.563,0,0,1,4.991-8,3.6,3.6,0,1,0-5.539-4.591,40.833,\n' +
        '40.833,0,0,0-6.06,9.749A9.467,9.467,0,0,0,623,436.614h22.5a9.466,9.466,0,0,0,\n' +
        '8.75-13.053c-4.926-12.076-17.721-19.726-18.263-20.046-.022-.012-.042-.025-.063-.035-.927-.568-11.36-7.125-15.194-16.529a2.269,\n' +
        '2.269,0,0,1,.216-2.146,2.234,2.234,0,0,1,1.872-1h22.5a2.282,2.282,0,0,1,\n' +
        '1.918,1.036,2.091,2.091,0,0,1,.2,2.018,32.787,32.787,0,0,1-4.032,6.865,\n' +
        '3.6,3.6,0,0,0,5.737,4.338,40.026,40.026,0,0,0,4.923-8.4,9.317,9.317,0,0,\n' +
        '0-.832-8.794,9.48,9.48,0,0,0-7.915-4.256Z',
    };
  },
};
</script>

<style scoped lang="scss">
#Rectangle_928 {
  opacity: 0;
}
</style>
