import { required } from '@/plugins/vuelidate';
import { BillingDetails } from '../../../api/v1';

export const formSpec = (billingDetails: BillingDetails) => ({
  name: {
    validations: {
      required: {},
    },
    default: billingDetails?.name || '',
  },
  countryCode: {
    validations: {
      required: {},
    },
    default: billingDetails?.countryCode || '',
  },
  city: {
    validations: {
      required: {},
    },
    default: billingDetails?.city || '',
  },
  address1: {
    validations: {
      required: {},
    },
    default: billingDetails?.address1 || '',
  },
  address2: {
    default: billingDetails?.address2 || '',
  },
  postalCode: {
    validations: {
      required: {},
    },
    default: billingDetails?.postalCode || '',
  },
});

export type BillingDetailsFormType = {
  name: string;
  countryCode: string;
  city: string;
  address1: string;
  address2: string;
  postalCode: string;
};

export const buildBillingForm = (
  billingDetails: BillingDetails | null,
): BillingDetailsFormType => ({
  name: billingDetails?.name || '',
  countryCode: billingDetails?.countryCode || '',
  city: billingDetails?.city || '',
  address1: billingDetails?.address1 || '',
  address2: billingDetails?.address2 || '',
  postalCode: billingDetails?.postalCode || '',
});

export const billingFormValidations = {
  name: { required },
  countryCode: { required },
  city: { required },
  address1: { required },
  address2: {},
  postalCode: { required },
};
