/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Unavailability } from './Unavailability';
import {
    UnavailabilityFromJSON,
    UnavailabilityFromJSONTyped,
    UnavailabilityToJSON,
} from './Unavailability';

/**
 * 
 * @export
 * @interface UpdateUnavailabilityResponse
 */
export interface UpdateUnavailabilityResponse {
    /**
     * 
     * @type {Unavailability}
     * @memberof UpdateUnavailabilityResponse
     */
    data?: Unavailability;
}

/**
 * Check if a given object implements the UpdateUnavailabilityResponse interface.
 */
export function instanceOfUpdateUnavailabilityResponse(value: object): boolean {
    return true;
}

export function UpdateUnavailabilityResponseFromJSON(json: any): UpdateUnavailabilityResponse {
    return UpdateUnavailabilityResponseFromJSONTyped(json, false);
}

export function UpdateUnavailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUnavailabilityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : UnavailabilityFromJSON(json['data']),
    };
}

export function UpdateUnavailabilityResponseToJSON(value?: UpdateUnavailabilityResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': UnavailabilityToJSON(value['data']),
    };
}

