
import { authUsersApi } from '@/api';
import Button from '@/components/buttons/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import { links } from '@/lang/urlLinks';
import { Icon } from '@/lib/enum/Icon';
import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import { User } from '../../../api/v1';

enum ChoiceEnum {
  Accept = 'Accept',
  Reject = 'Reject',
}

export default {
  name: 'CookiePrompt',

  components: {
    Dialog,
    Button,
    BubbleIcon,
  },

  data() {
    return {
      Icon,
      links,
      ChoiceEnum,
      submitting: null as ChoiceEnum | null,
    };
  },

  computed: {
    user: (): User => store.getters.user,
  },

  methods: {
    goToAccountSettings() {
      redirect(routes.updateAccountDetails.route());
    },

    async updateCookieChoice(choice: ChoiceEnum) {
      this.submitting = choice;

      const handleUpdate = async () => {
        await authUsersApi.updateAuthenticatedUser({
          updateAuthenticatedUserData: {
            cookiesAccepted: choice === ChoiceEnum.Accept,
          },
        });

        await this.$store.dispatch('fetchAuthenticatedUser');
      };

      handleUpdate().finally(() => {
        this.submitting = null;
      });
    },
  },
};
