
/**
 * Base component for `TextArea`.
 *
 * `value` is used to prefill component
 *
 * `manualError` is used to set a custom error message
 *
 * `readOnly` a boolean and can be used with the `value` component to prefill data
 *
 * `type` can be used to specify certain types of text input, such as password, email, number, etc.
 */

import { hideFixedElement, showFixedElement } from '@/util/displayFunctions';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

export default {
  name: 'TextArea',

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number],
      default: '',
    },

    labelText: {
      type: [String, TranslateResult],
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    rows: {
      type: Number,
      default: 2,
    },

    append: {
      type: String,
      default: null,
    },

    manualError: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Array,
      default: null,
    },
  },

  data() {
    return { timerId: null };
  },

  computed: {
    getListeners() {
      const { ...listeners } = this.$listeners;
      delete listeners.input;
      return listeners;
    },

    inputValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    hideFixedElement,

    handleInput(event) {
      clearTimeout(this.timerId);

      // delay new call
      this.timerId = setTimeout(() => {
        showFixedElement('#fixed-button');
      }, 1000);

      this.$emit('input', event);
    },

    blur() {
      this.$emit('blur');
      showFixedElement('#fixed-button');
    },
  },
};
