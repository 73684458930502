/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateRecurrenceRuleData } from './CreateRecurrenceRuleData';
import {
    CreateRecurrenceRuleDataFromJSON,
    CreateRecurrenceRuleDataFromJSONTyped,
    CreateRecurrenceRuleDataToJSON,
} from './CreateRecurrenceRuleData';

/**
 * Define a period of time where an employee has made a specific statement regarding their working preference.
 * @export
 * @interface CreateUnavailabilityData
 */
export interface CreateUnavailabilityData {
    /**
     * 
     * @type {string}
     * @memberof CreateUnavailabilityData
     */
    notes?: string;
    /**
     * 
     * @type {CreateRecurrenceRuleData}
     * @memberof CreateUnavailabilityData
     */
    recurrenceRule?: CreateRecurrenceRuleData;
    /**
     * 
     * @type {Date}
     * @memberof CreateUnavailabilityData
     */
    startsAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateUnavailabilityData
     */
    endsAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CreateUnavailabilityData
     */
    employeeId: number;
}

/**
 * Check if a given object implements the CreateUnavailabilityData interface.
 */
export function instanceOfCreateUnavailabilityData(value: object): boolean {
    if (!('startsAt' in value)) return false;
    if (!('endsAt' in value)) return false;
    if (!('employeeId' in value)) return false;
    return true;
}

export function CreateUnavailabilityDataFromJSON(json: any): CreateUnavailabilityData {
    return CreateUnavailabilityDataFromJSONTyped(json, false);
}

export function CreateUnavailabilityDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUnavailabilityData {
    if (json == null) {
        return json;
    }
    return {
        
        'notes': json['notes'] == null ? undefined : json['notes'],
        'recurrenceRule': json['recurrenceRule'] == null ? undefined : CreateRecurrenceRuleDataFromJSON(json['recurrenceRule']),
        'startsAt': (new Date(json['startsAt'])),
        'endsAt': (new Date(json['endsAt'])),
        'employeeId': json['employeeId'],
    };
}

export function CreateUnavailabilityDataToJSON(value?: CreateUnavailabilityData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notes': value['notes'],
        'recurrenceRule': CreateRecurrenceRuleDataToJSON(value['recurrenceRule']),
        'startsAt': ((value['startsAt']).toISOString()),
        'endsAt': ((value['endsAt']).toISOString()),
        'employeeId': value['employeeId'],
    };
}

