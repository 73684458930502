<template>
  <header
    id="mobile-header"
    class="w-full"
  >
    <slot name="mobileHeader">
      <div class="top-row w-100 text-white">
        <div class="button-container left">
          <slot name="buttonLeft" />
        </div>
        <div class="title uppercase text-center align-center">
          <slot name="headerTitle" />
        </div>
        <div class="button-container right">
          <slot name="buttonRight" />
        </div>
      </div>
      <!-- Any second row components - search, tabs, etc -->
      <div
        v-if="$slots.subComponent"
        class="px-2 pb-2 w-full"
      >
        <slot name="subComponent" />
      </div>
    </slot>
  </header>
</template>

<script>
import { viewport } from '@/util/windowFunctions';

export default {
  name: 'MobileHeaderLayout',

  data() {
    return {
      viewport,
    };
  },
};
</script>
<style scoped lang="scss">
#mobile-header {
  background-color: $purple-900;
  padding-left: var(--deviceSafeAreaLeft);
  padding-right: var(--deviceSafeAreaRight);
  position: sticky;
  top: 0;
  z-index: 100; // Should probably match whatever we have set in Topbar.vue which is the desktop equivalent
  transition: all 0.2s ease, width 0ms;

  .top-row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: 'left title right';

    .title {
      grid-area: title;
      display: inline-flex;
      min-height: 48px;
      overflow: hidden;

      h1 {
        color: #fff;
        font-size: $text-16 !important;
      }
    }

    .button-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &.left {
        .timer {
          margin-left: 0.5rem;
          font-size: $text-12;
          font-weight: bold;
        }
      }

      &.right {
        grid-area: right;
      }
    }
  }
}
</style>
