
export default {
  name: 'Tag',

  props: {
    // Not required as component can be used for styling with slot
    tagName: {
      type: String,
      default: '',
    },
  },
};
