/*
  FinalizationWeakMap is a Map-like object that holds only weak references to its keys.
  As soon as a value is no longer reachable anywhere else, its key will automatically be removed from the map
  (using FinalizationRegistry).
  Since the state of this object may change without direct access to it, it also extends CustomEventEmitter
 */
import { CustomEventEmitter } from '@/lib/helpers/CustomEventEmitter';

export enum FinalizationWeakMapEventType {
  FINALIZE = 'FINALIZE',
  CLEAR = 'CLEAR',
  SET = 'SET',
  DELETE = 'DELETE',
}

export class FinalizationWeakMap<
  K,
  V extends object,
> extends CustomEventEmitter<{
  [FinalizationWeakMapEventType.CLEAR]: {};
  [FinalizationWeakMapEventType.FINALIZE]: { key: K };
  [FinalizationWeakMapEventType.SET]: { key: K; value: V };
  [FinalizationWeakMapEventType.DELETE]: { key: K; value: V };
}> {
  readonly #map: Map<K, WeakRef<V>>;

  readonly #registry: FinalizationRegistry<K>;

  constructor(values?: readonly (readonly [K, V])[] | null) {
    super();

    this.#registry = new FinalizationRegistry<K>((key) => {
      if (this.#map.delete(key)) {
        this._dispatchEvent(FinalizationWeakMapEventType.FINALIZE, { key });
      }
    });
    this.#map = new Map(
      values
        ? values.map(([k, v]): [K, WeakRef<V>] => {
            this.#registry.register(v, k, v);
            return [k, new WeakRef(v)];
          })
        : null,
    );
  }

  set(key: K, value: V): this {
    this.#registry.register(value, key);
    this.#map.set(key, new WeakRef(value));
    this._dispatchEvent(FinalizationWeakMapEventType.SET, { key, value });
    return this;
  }

  delete(key: K): boolean {
    if (!this.#map.has(key)) return false;
    const value = this.#map.get(key).deref();
    if (value === undefined) return false;
    this.#map.delete(key);
    this.#registry.unregister(value);
    this._dispatchEvent(FinalizationWeakMapEventType.DELETE, { key, value });
    return true;
  }

  has(key: K): boolean {
    return this.#map.has(key);
  }

  forEach(
    callbackFn: (value: V, key: K, set: FinalizationWeakMap<K, V>) => void,
    thisArg?: any,
  ): void {
    this.#map.forEach((v, k) => {
      const value = v.deref();
      if (value === undefined) return;
      callbackFn(value, k, this);
    }, thisArg);
  }

  get(key: K): V | undefined {
    const value = this.#map.get(key)?.deref();
    if (value === undefined) this.#map.delete(key);
    return value;
  }

  get size(): number {
    return this.#map.size;
  }

  *entries(): IterableIterator<[K, V]> {
    let entry: IteratorResult<[K, WeakRef<V>]>;
    const iterator = this.#map.entries();
    // eslint-disable-next-line no-cond-assign
    while (!(entry = iterator.next()).done) {
      const [k, v] = entry.value;
      const value = v.deref();
      if (value !== undefined) yield [k, value];
    }
  }

  *keys(): IterableIterator<K> {
    let entry: IteratorResult<[K, WeakRef<V>]>;
    const iterator = this.#map.entries();
    // eslint-disable-next-line no-cond-assign
    while (!(entry = iterator.next()).done) {
      const [k, v] = entry.value;
      if (v.deref() !== undefined) yield k;
    }
  }

  *values(): IterableIterator<V> {
    let entry: IteratorResult<[K, WeakRef<V>]>;
    const iterator = this.#map.entries();
    // eslint-disable-next-line no-cond-assign
    while (!(entry = iterator.next()).done) {
      const value = entry.value[1].deref();
      if (value !== undefined) yield value;
    }
  }

  *[Symbol.iterator](): IterableIterator<[K, V]> {
    yield* this.entries();
  }

  clear(): void {
    this.#map.forEach((v) => {
      const value = v.deref();
      if (value === undefined) return;
      this.#registry.unregister(v.deref());
    });
    this.#map.clear();
    this._dispatchEvent(FinalizationWeakMapEventType.CLEAR, {});
  }
}
