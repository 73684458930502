export const IconSizes = {
  Xsmall: 'xsmall',
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  Xlarge: 'xlarge',
  Xxlarge: 'xxlarge',
} as const;

export type IconSizesType = typeof IconSizes[keyof typeof IconSizes];
