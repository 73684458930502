/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Upload } from './Upload';
import {
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
} from './Upload';

/**
 * 
 * @export
 * @interface ConfirmUploadResponse
 */
export interface ConfirmUploadResponse {
    /**
     * 
     * @type {Upload}
     * @memberof ConfirmUploadResponse
     */
    data: Upload;
}

/**
 * Check if a given object implements the ConfirmUploadResponse interface.
 */
export function instanceOfConfirmUploadResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ConfirmUploadResponseFromJSON(json: any): ConfirmUploadResponse {
    return ConfirmUploadResponseFromJSONTyped(json, false);
}

export function ConfirmUploadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmUploadResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': UploadFromJSON(json['data']),
    };
}

export function ConfirmUploadResponseToJSON(value?: ConfirmUploadResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': UploadToJSON(value['data']),
    };
}

