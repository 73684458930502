/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { PayCycle } from './PayCycle';
import {
    PayCycleFromJSON,
    PayCycleFromJSONTyped,
    PayCycleToJSON,
} from './PayCycle';

/**
 * 
 * @export
 * @interface ListPayCyclesResponse
 */
export interface ListPayCyclesResponse {
    /**
     * 
     * @type {Array<PayCycle>}
     * @memberof ListPayCyclesResponse
     */
    data: Array<PayCycle>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListPayCyclesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListPayCyclesResponse interface.
 */
export function instanceOfListPayCyclesResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListPayCyclesResponseFromJSON(json: any): ListPayCyclesResponse {
    return ListPayCyclesResponseFromJSONTyped(json, false);
}

export function ListPayCyclesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPayCyclesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(PayCycleFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListPayCyclesResponseToJSON(value?: ListPayCyclesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(PayCycleToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

