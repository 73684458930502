var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.forceReload,staticClass:"font-body",attrs:{"id":"app"}},[_c('v-app',[_c('v-main',{attrs:{"id":"main"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isLoading)?_c(_vm.layout,_vm._b({tag:"component",class:{
            'pointer-events-none':
              _vm.showCompanyStatusModal ||
              (_vm.packageDialog.show && _vm.packageDialog.isRestore),
          }},'component',_vm.layoutProps,false),[_c('router-view')],1):_c('ShiftieLoading')],1),(_vm.showCompanyStatusModal)?_c('Dialog',{attrs:{"fullscreen":false,"max-width":450,"persistent":_vm.showCompanyStatusModal,"value":_vm.showCompanyStatusModal}},[_c('span',{attrs:{"slot":"header"},slot:"header"}),_c('CompanyStatus')],1):_vm._e(),(!_vm.globalLoading)?[(!!_vm.updatedRoles && !_vm.showCompanyStatusModal)?_c('ActionDialog',{attrs:{"persistent":"","value":!!_vm.updatedRoles,"bubble-icon":{
            icon: _vm.Icon.User,
            colour: 'pink',
          },"header":_vm.$tc('modal.heading.permissionsUpdated'),"primary-button":{
            label: _vm.$tc('button.okay'),
            action: _vm.reload,
            colour: 'pink',
          }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('i18n',{staticClass:"text-gray-600 text-16",attrs:{"tag":"p","path":"modal.body.permissionsUpdated"},scopedSlots:_vm._u([{key:"from",fn:function(){return [_c('strong',[_vm._v(" "+_vm._s(_vm.updatedRoles.old)+" ")])]},proxy:true},{key:"to",fn:function(){return [_c('strong',[_vm._v(" "+_vm._s(_vm.updatedRoles.new)+" ")])]},proxy:true}],null,false,789064191)})]},proxy:true}],null,false,3409744523)}):_vm._e(),(_vm.loggedInEmployee && !_vm.isImpersonationSession)?_c('CookiePrompt'):_vm._e(),(_vm.packageDialog.show)?_c('BillingPackageDialog',{attrs:{"value":_vm.packageDialog.show,"billing-only":_vm.packageDialog.billingOnly,"restore-account":_vm.packageDialog.isRestore},on:{"close":_vm.togglePackageDialog}}):_vm._e(),(_vm.showPlanUpdateDialog)?_c('SuccessDialog',{attrs:{"value":_vm.showPlanUpdateDialog,"bubble-icon":{
            icon: _vm.Icon.Gear,
            colour: 'default',
          },"header":_vm.$tc('modal.heading.planUpdate'),"body-text":_vm.$tc('modal.body.planUpdate')},on:{"click":_vm.reload}}):_vm._e()]:_vm._e(),_c('AddToHome')],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }