<template>
  <div
    class="placeholder"
    :class="{ animation: loading }"
  >
    <i :class="Icon.LocationPin" />
  </div>
</template>

<script>
import { Icon } from '@/lib/enum/Icon';

export default {
  name: 'MapPlaceholder',

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      Icon,
    };
  },
};
</script>

<style scoped lang="scss">
.placeholder {
  color: $gray-600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;

  i {
    font-size: $text-30;
    color: $gray-600;
  }
}

.animation {
  animation-name: backgroundFade;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes backgroundFade {
  from {
    background: $gray-200;
  }

  to {
    background: #ffffff;
  }
}
</style>
