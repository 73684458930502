/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { ReportCostsByEmploymentType } from './ReportCostsByEmploymentType';
import {
    ReportCostsByEmploymentTypeFromJSON,
    ReportCostsByEmploymentTypeFromJSONTyped,
    ReportCostsByEmploymentTypeToJSON,
} from './ReportCostsByEmploymentType';
import type { ReportCostsByEmploymentTypeRollup } from './ReportCostsByEmploymentTypeRollup';
import {
    ReportCostsByEmploymentTypeRollupFromJSON,
    ReportCostsByEmploymentTypeRollupFromJSONTyped,
    ReportCostsByEmploymentTypeRollupToJSON,
} from './ReportCostsByEmploymentTypeRollup';

/**
 * 
 * @export
 * @interface ListShiftCostReportsByEmploymentTypeResponse
 */
export interface ListShiftCostReportsByEmploymentTypeResponse {
    /**
     * 
     * @type {Array<ReportCostsByEmploymentType>}
     * @memberof ListShiftCostReportsByEmploymentTypeResponse
     */
    data: Array<ReportCostsByEmploymentType>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftCostReportsByEmploymentTypeResponse
     */
    pagination: Pagination;
    /**
     * 
     * @type {ReportCostsByEmploymentTypeRollup}
     * @memberof ListShiftCostReportsByEmploymentTypeResponse
     */
    rollup: ReportCostsByEmploymentTypeRollup;
}

/**
 * Check if a given object implements the ListShiftCostReportsByEmploymentTypeResponse interface.
 */
export function instanceOfListShiftCostReportsByEmploymentTypeResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    if (!('rollup' in value)) return false;
    return true;
}

export function ListShiftCostReportsByEmploymentTypeResponseFromJSON(json: any): ListShiftCostReportsByEmploymentTypeResponse {
    return ListShiftCostReportsByEmploymentTypeResponseFromJSONTyped(json, false);
}

export function ListShiftCostReportsByEmploymentTypeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftCostReportsByEmploymentTypeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ReportCostsByEmploymentTypeFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
        'rollup': ReportCostsByEmploymentTypeRollupFromJSON(json['rollup']),
    };
}

export function ListShiftCostReportsByEmploymentTypeResponseToJSON(value?: ListShiftCostReportsByEmploymentTypeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(ReportCostsByEmploymentTypeToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
        'rollup': ReportCostsByEmploymentTypeRollupToJSON(value['rollup']),
    };
}

