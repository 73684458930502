/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateUnavailabilityData,
  DeleteUnavailabilityData,
  ListUnavailabilityResponse,
  ShowUnavailabilityResponse,
  UpdateUnavailabilityData,
  UpdateUnavailabilityResponse,
} from '../models/index';
import {
    CreateUnavailabilityDataFromJSON,
    CreateUnavailabilityDataToJSON,
    DeleteUnavailabilityDataFromJSON,
    DeleteUnavailabilityDataToJSON,
    ListUnavailabilityResponseFromJSON,
    ListUnavailabilityResponseToJSON,
    ShowUnavailabilityResponseFromJSON,
    ShowUnavailabilityResponseToJSON,
    UpdateUnavailabilityDataFromJSON,
    UpdateUnavailabilityDataToJSON,
    UpdateUnavailabilityResponseFromJSON,
    UpdateUnavailabilityResponseToJSON,
} from '../models/index';

export interface CreateUnavailabilityRequest {
    createUnavailabilityData?: CreateUnavailabilityData;
}

export interface DeleteUnavailabilityRequest {
    id: number;
    deleteUnavailabilityData?: DeleteUnavailabilityData;
}

export interface ListUnavailabilityRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowUnavailabilityRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateUnavailabilityRequest {
    id: number;
    _with?: Array<string>;
    updateUnavailabilityData?: UpdateUnavailabilityData;
}

/**
 * 
 */
export class UnavailabilityApi extends runtime.BaseAPI {

    /**
     * Create a new unavailability period for an employee. By passing a recurrenceRule, you can generate multiple unavailability records in one go. Because this could result in an infinite number of records, this request doesn\'t return anything in the response. If you want to retrieve a subset of the created records, call the corresponding \'ListUnavailability\' endpoint with an appropriate date range filter.
     * Create Unavailability
     */
    async createUnavailabilityRaw(requestParameters: CreateUnavailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.unavailability.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/unavailability`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUnavailabilityDataToJSON(requestParameters['createUnavailabilityData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new unavailability period for an employee. By passing a recurrenceRule, you can generate multiple unavailability records in one go. Because this could result in an infinite number of records, this request doesn\'t return anything in the response. If you want to retrieve a subset of the created records, call the corresponding \'ListUnavailability\' endpoint with an appropriate date range filter.
     * Create Unavailability
     */
    async createUnavailability(requestParameters: CreateUnavailabilityRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createUnavailabilityRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an unavailability period
     * Delete Unavailability
     */
    async deleteUnavailabilityRaw(requestParameters: DeleteUnavailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteUnavailability().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.unavailability.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/unavailability/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteUnavailabilityDataToJSON(requestParameters['deleteUnavailabilityData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an unavailability period
     * Delete Unavailability
     */
    async deleteUnavailability(requestParameters: DeleteUnavailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUnavailabilityRaw(requestParameters, initOverrides);
    }

    /**
     * List or filter all unavailabilities
     * List Unavailability
     */
    async listUnavailabilityRaw(requestParameters: ListUnavailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListUnavailabilityResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.unavailability.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/unavailability`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListUnavailabilityResponseFromJSON(jsonValue));
    }

    /**
     * List or filter all unavailabilities
     * List Unavailability
     */
    async listUnavailability(requestParameters: ListUnavailabilityRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListUnavailabilityResponse> {
        const response = await this.listUnavailabilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single unavailability record
     * Show Unavailability
     */
    async showUnavailabilityRaw(requestParameters: ShowUnavailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowUnavailabilityResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showUnavailability().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.unavailability.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/unavailability/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowUnavailabilityResponseFromJSON(jsonValue));
    }

    /**
     * Show a single unavailability record
     * Show Unavailability
     */
    async showUnavailability(requestParameters: ShowUnavailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowUnavailabilityResponse> {
        const response = await this.showUnavailabilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing unavailability record. If the record is currently linked to a Recurrence Rule, changing the start or end time will cause it to be unlinked and treated like an independent unavailability record.
     * Update Unavailability
     */
    async updateUnavailabilityRaw(requestParameters: UpdateUnavailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUnavailabilityResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateUnavailability().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.unavailability.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/unavailability/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUnavailabilityDataToJSON(requestParameters['updateUnavailabilityData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUnavailabilityResponseFromJSON(jsonValue));
    }

    /**
     * Update an existing unavailability record. If the record is currently linked to a Recurrence Rule, changing the start or end time will cause it to be unlinked and treated like an independent unavailability record.
     * Update Unavailability
     */
    async updateUnavailability(requestParameters: UpdateUnavailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUnavailabilityResponse> {
        const response = await this.updateUnavailabilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
