var render = function render(){var _vm=this,_c=_vm._self._c;return _c('i',{staticClass:"h-6 w-6 cursor-pointer rounded-full all:flex align-center justify-center",class:[
    _vm.Icon.Xmark,
    {
      'text-white hover:bg-purple-50 hover:text-purple-900': _vm.lightMode,
      'text-gray-600 hover:bg-gray-100': !_vm.lightMode,
    },
  ],on:{"click":function($event){return _vm.$emit('click')}}})
}
var staticRenderFns = []

export { render, staticRenderFns }