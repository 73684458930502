import { render, staticRenderFns } from "./Dot.vue?vue&type=template&id=fd1f7452&scoped=true&"
import script from "./Dot.vue?vue&type=script&lang=ts&"
export * from "./Dot.vue?vue&type=script&lang=ts&"
import style0 from "./Dot.vue?vue&type=style&index=0&id=fd1f7452&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd1f7452",
  null
  
)

export default component.exports