<template>
  <button
    v-if="showLauncher"
    class="freshdesk-launcher"
    @click="openFreshdeskWidget(loggedInEmployee, currentCompany)()"
  >
    {{ $t('button.needHelp') }}
  </button>
</template>

<script>
import { EventsEnum } from '@/lib/enum/events';
import { openFreshdeskWidget } from '@/plugins/freshdesk';
import store from '@/store';

export default {
  name: 'FreshdeskLauncher',

  data() {
    return {
      showLauncher: false,
    };
  },

  computed: {
    currentCompany: () => store.getters.currentCompany,
    loggedInEmployee: () => store.getters.loggedInEmployee,
  },

  mounted() {
    this.showLauncher = true;

    this.$root.$on(
      EventsEnum.ToggleFreshdeskLauncher,
      this.toggleFreshdeskLauncher,
    );
  },

  beforeDestroy() {
    this.$root.$off(
      EventsEnum.ToggleFreshdeskLauncher,
      this.toggleFreshdeskLauncher,
    );
  },

  methods: {
    openFreshdeskWidget,
    toggleFreshdeskLauncher() {
      this.showLauncher = !this.showLauncher;
    },
  },
};
</script>

<style scoped lang="scss">
.freshdesk-launcher {
  position: fixed;
  right: 0;
  top: 33%;
  z-index: 5000;

  background: $purple-900;
  color: $white;
  padding: 0.5rem;
  font-size: $text-12;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  transform: rotate(-90deg) translateY(60%);

  &:hover {
    background: lighten($purple-900, 5%);
  }
}
</style>
