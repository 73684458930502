/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftSwap } from './ShiftSwap';
import {
    ShiftSwapFromJSON,
    ShiftSwapFromJSONTyped,
    ShiftSwapToJSON,
} from './ShiftSwap';

/**
 * 
 * @export
 * @interface RejectShiftSwapResponse
 */
export interface RejectShiftSwapResponse {
    /**
     * 
     * @type {ShiftSwap}
     * @memberof RejectShiftSwapResponse
     */
    data?: ShiftSwap;
}

/**
 * Check if a given object implements the RejectShiftSwapResponse interface.
 */
export function instanceOfRejectShiftSwapResponse(value: object): boolean {
    return true;
}

export function RejectShiftSwapResponseFromJSON(json: any): RejectShiftSwapResponse {
    return RejectShiftSwapResponseFromJSONTyped(json, false);
}

export function RejectShiftSwapResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RejectShiftSwapResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftSwapFromJSON(json['data']),
    };
}

export function RejectShiftSwapResponseToJSON(value?: RejectShiftSwapResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftSwapToJSON(value['data']),
    };
}

