/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimesheetEntry } from './TimesheetEntry';
import {
    TimesheetEntryFromJSON,
    TimesheetEntryFromJSONTyped,
    TimesheetEntryToJSON,
} from './TimesheetEntry';

/**
 * 
 * @export
 * @interface UpdateTimesheetEntryResponse
 */
export interface UpdateTimesheetEntryResponse {
    /**
     * 
     * @type {TimesheetEntry}
     * @memberof UpdateTimesheetEntryResponse
     */
    data?: TimesheetEntry;
}

/**
 * Check if a given object implements the UpdateTimesheetEntryResponse interface.
 */
export function instanceOfUpdateTimesheetEntryResponse(value: object): boolean {
    return true;
}

export function UpdateTimesheetEntryResponseFromJSON(json: any): UpdateTimesheetEntryResponse {
    return UpdateTimesheetEntryResponseFromJSONTyped(json, false);
}

export function UpdateTimesheetEntryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTimesheetEntryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : TimesheetEntryFromJSON(json['data']),
    };
}

export function UpdateTimesheetEntryResponseToJSON(value?: UpdateTimesheetEntryResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': TimesheetEntryToJSON(value['data']),
    };
}

