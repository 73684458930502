/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingSubscriptionCancellation } from './BillingSubscriptionCancellation';
import {
    BillingSubscriptionCancellationFromJSON,
    BillingSubscriptionCancellationFromJSONTyped,
    BillingSubscriptionCancellationToJSON,
} from './BillingSubscriptionCancellation';

/**
 * 
 * @export
 * @interface ShowSubscriptionCancellationResponse
 */
export interface ShowSubscriptionCancellationResponse {
    /**
     * 
     * @type {BillingSubscriptionCancellation}
     * @memberof ShowSubscriptionCancellationResponse
     */
    data?: BillingSubscriptionCancellation;
}

/**
 * Check if a given object implements the ShowSubscriptionCancellationResponse interface.
 */
export function instanceOfShowSubscriptionCancellationResponse(value: object): boolean {
    return true;
}

export function ShowSubscriptionCancellationResponseFromJSON(json: any): ShowSubscriptionCancellationResponse {
    return ShowSubscriptionCancellationResponseFromJSONTyped(json, false);
}

export function ShowSubscriptionCancellationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowSubscriptionCancellationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : BillingSubscriptionCancellationFromJSON(json['data']),
    };
}

export function ShowSubscriptionCancellationResponseToJSON(value?: ShowSubscriptionCancellationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': BillingSubscriptionCancellationToJSON(value['data']),
    };
}

