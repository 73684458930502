/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateIcalFeedData
 */
export interface CreateIcalFeedData {
    /**
     * 
     * @type {string}
     * @memberof CreateIcalFeedData
     */
    type?: CreateIcalFeedDataTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateIcalFeedDataTypeEnum {
    MyShifts = 'My Shifts'
}


/**
 * Check if a given object implements the CreateIcalFeedData interface.
 */
export function instanceOfCreateIcalFeedData(value: object): boolean {
    return true;
}

export function CreateIcalFeedDataFromJSON(json: any): CreateIcalFeedData {
    return CreateIcalFeedDataFromJSONTyped(json, false);
}

export function CreateIcalFeedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIcalFeedData {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function CreateIcalFeedDataToJSON(value?: CreateIcalFeedData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
    };
}

