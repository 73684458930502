/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Absence } from './Absence';
import {
    AbsenceFromJSON,
    AbsenceFromJSONTyped,
    AbsenceToJSON,
} from './Absence';

/**
 * 
 * @export
 * @interface UpdateAbsenceResponse
 */
export interface UpdateAbsenceResponse {
    /**
     * 
     * @type {Absence}
     * @memberof UpdateAbsenceResponse
     */
    data?: Absence;
}

/**
 * Check if a given object implements the UpdateAbsenceResponse interface.
 */
export function instanceOfUpdateAbsenceResponse(value: object): boolean {
    return true;
}

export function UpdateAbsenceResponseFromJSON(json: any): UpdateAbsenceResponse {
    return UpdateAbsenceResponseFromJSONTyped(json, false);
}

export function UpdateAbsenceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAbsenceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : AbsenceFromJSON(json['data']),
    };
}

export function UpdateAbsenceResponseToJSON(value?: UpdateAbsenceResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': AbsenceToJSON(value['data']),
    };
}

