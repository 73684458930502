import {
  loadStripe,
  Stripe as BaseStripe,
  StripeElements,
} from '@stripe/stripe-js';
import { BillingDetails } from '../../api/v1';

const stripePromise = async () => loadStripe(process.env.VUE_APP_STRIPE_PK);

export class Stripe {
  stripe: BaseStripe;

  stripeElements: StripeElements;

  constructor() {
    this.initStripe().then();
  }

  async initStripe() {
    await stripePromise().then((data) => {
      this.stripe = data;
      this.stripeElements = data?.elements();
    });
  }

  createElements() {
    this.stripeElements = this.stripe.elements();
  }

  async confirmCardSetup(
    secret: string,
    cardNumber,
    billingDetails: BillingDetails,
  ) {
    return this.stripe.confirmCardSetup(secret, {
      payment_method: {
        billing_details: {
          address: {
            city: billingDetails.city,
            country: billingDetails.countryCode,
            line1: billingDetails.address1,
            line2: billingDetails.address2,
            postal_code: billingDetails.postalCode,
          },
          name: billingDetails.name,
        },
        card: cardNumber,
      },
    });
  }
}
