import { render, staticRenderFns } from "./ViewShiftV2.vue?vue&type=template&id=d8c97e48&"
import script from "./ViewShiftV2.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ViewShiftV2.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports