<template>
  <div
    v-if="showAddToHome && !isExcludedRoute"
    class="add-to-home"
    @click="addToHome"
  >
    <div class="add-to-home_shiftie-logo relative">
      <ShiftieCutOutSquareSvg />
    </div>
    <div>
      <p class="mb-0">
        <strong>Add Shiftie to Home Screen</strong>
      </p>
      <p class="mb-0 text-gray-500">shiftie.app</p>
    </div>
    <i
      :class="Icon.Xmark"
      @click.stop="dismiss"
    />
  </div>
</template>

<script>
import ShiftieCutOutSquareSvg from '@/assets/ShiftieCutOutSquareSvg.vue';
import { Icon } from '@/lib/enum/Icon';
import { StorageEnum } from '@/lib/enum/StorageEnum';
import { routes } from '@/router/routes';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/util/storageFunctions';

export default {
  name: 'AddToHome',

  components: { ShiftieCutOutSquareSvg },

  data() {
    return {
      Icon,
      showAddToHome: true,
      deferredPrompt: null,
    };
  },

  computed: {
    isExcludedRoute() {
      const exclusionRoutes = [
        routes.logout.name,
        routes.signUp.name,
        routes.createPasswordReset.name,
        routes.pageNotFound.name,
        routes.token.name,
      ];

      const currentRoute = this.$route.name;

      return exclusionRoutes.includes(currentRoute);
    },
  },

  mounted() {
    const addToHome = getLocalStorageItem(StorageEnum.AddShiftieToHome);
    if (addToHome && addToHome === 'false') {
      this.showAddToHome = false;
      return;
    }

    const addBtn = document.querySelector('.add-to-home');

    if (addBtn) {
      this.showAddToHome = false;
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
        this.showAddToHome = true;
      });
    }
  },

  methods: {
    dismiss() {
      setLocalStorageItem(StorageEnum.AddShiftieToHome, 'false');
      this.showAddToHome = false;
    },

    addToHome() {
      this.showAddToHome = false;

      this.deferredPrompt.prompt();

      this.deferredPrompt.userChoice.then(() => {
        this.deferredPrompt = null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-to-home {
  @apply shadow-md;
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid $gray-200;
  height: 75px;
  cursor: pointer;
  // Above everything including help button
  z-index: 3000000000;

  position: fixed;
  bottom: 55.5px;
  right: 0;
  width: 100%;

  i {
    color: $gray-400;
    font-size: 1rem;
    margin-left: auto;
    padding: 1rem;
  }

  @media screen and (min-width: $md) {
    top: 2rem;
    right: 2rem;
    border-radius: 8px;
    width: auto;

    i {
      padding: 0.5rem;
      margin-left: 1rem;
    }
  }

  &_shiftie-logo {
    @include bgGradientLR;
    height: 3rem;
    width: 3rem;
    margin: 0.5rem 1rem 0.5rem 0.5rem;
    padding: 0.25rem;
  }
}
</style>
