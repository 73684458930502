/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingDetails } from './BillingDetails';
import {
    BillingDetailsFromJSON,
    BillingDetailsFromJSONTyped,
    BillingDetailsToJSON,
} from './BillingDetails';

/**
 * 
 * @export
 * @interface UpdateBillingDetailsResponse
 */
export interface UpdateBillingDetailsResponse {
    /**
     * 
     * @type {BillingDetails}
     * @memberof UpdateBillingDetailsResponse
     */
    data: BillingDetails;
}

/**
 * Check if a given object implements the UpdateBillingDetailsResponse interface.
 */
export function instanceOfUpdateBillingDetailsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateBillingDetailsResponseFromJSON(json: any): UpdateBillingDetailsResponse {
    return UpdateBillingDetailsResponseFromJSONTyped(json, false);
}

export function UpdateBillingDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBillingDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingDetailsFromJSON(json['data']),
    };
}

export function UpdateBillingDetailsResponseToJSON(value?: UpdateBillingDetailsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': BillingDetailsToJSON(value['data']),
    };
}

