/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeGroup
 */
export interface EmployeeGroup {
    /**
     * 
     * @type {number}
     * @memberof EmployeeGroup
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeGroup
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeGroup
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeGroup
     */
    position: number;
}

/**
 * Check if a given object implements the EmployeeGroup interface.
 */
export function instanceOfEmployeeGroup(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('position' in value)) return false;
    return true;
}

export function EmployeeGroupFromJSON(json: any): EmployeeGroup {
    return EmployeeGroupFromJSONTyped(json, false);
}

export function EmployeeGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'position': json['position'],
    };
}

export function EmployeeGroupToJSON(value?: EmployeeGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'position': value['position'],
    };
}

