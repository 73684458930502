<template>
  <div
    class="loading-icon-wrapper"
    :class="{ 'loading-icon-wrapper__full': !inline }"
  >
    <v-progress-circular
      v-bind="$attrs"
      :indeterminate="!progress"
      :value="progress"
      :color="colour"
    >
      <slot />
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'LoadingIcon',

  props: {
    colour: {
      type: String,
      default: '#ec3f8c',
    },

    progress: {
      required: false,
      type: Number,
      default: null,
    },

    inline: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.loading-icon-wrapper {
  display: inline-block;

  &__full {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
