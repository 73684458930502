/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Schedule } from './Schedule';
import {
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';

/**
 * 
 * @export
 * @interface UpdateScheduleResponse
 */
export interface UpdateScheduleResponse {
    /**
     * 
     * @type {Schedule}
     * @memberof UpdateScheduleResponse
     */
    data?: Schedule;
}

/**
 * Check if a given object implements the UpdateScheduleResponse interface.
 */
export function instanceOfUpdateScheduleResponse(value: object): boolean {
    return true;
}

export function UpdateScheduleResponseFromJSON(json: any): UpdateScheduleResponse {
    return UpdateScheduleResponseFromJSONTyped(json, false);
}

export function UpdateScheduleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateScheduleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ScheduleFromJSON(json['data']),
    };
}

export function UpdateScheduleResponseToJSON(value?: UpdateScheduleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ScheduleToJSON(value['data']),
    };
}

