/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeavePeriod } from './LeavePeriod';
import {
    LeavePeriodFromJSON,
    LeavePeriodFromJSONTyped,
    LeavePeriodToJSON,
} from './LeavePeriod';

/**
 * 
 * @export
 * @interface UpdateLeavePeriodResponse
 */
export interface UpdateLeavePeriodResponse {
    /**
     * 
     * @type {LeavePeriod}
     * @memberof UpdateLeavePeriodResponse
     */
    data: LeavePeriod;
}

/**
 * Check if a given object implements the UpdateLeavePeriodResponse interface.
 */
export function instanceOfUpdateLeavePeriodResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateLeavePeriodResponseFromJSON(json: any): UpdateLeavePeriodResponse {
    return UpdateLeavePeriodResponseFromJSONTyped(json, false);
}

export function UpdateLeavePeriodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeavePeriodResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeavePeriodFromJSON(json['data']),
    };
}

export function UpdateLeavePeriodResponseToJSON(value?: UpdateLeavePeriodResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeavePeriodToJSON(value['data']),
    };
}

