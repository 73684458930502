/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListHistoryResponse,
  SearchData,
  SearchHistoryResponse,
} from '../models/index';
import {
    ListHistoryResponseFromJSON,
    ListHistoryResponseToJSON,
    SearchDataFromJSON,
    SearchDataToJSON,
    SearchHistoryResponseFromJSON,
    SearchHistoryResponseToJSON,
} from '../models/index';

export interface ListHistoryRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface SearchHistoryRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    _with?: Array<string>;
    searchData?: SearchData;
}

/**
 * 
 */
export class HistoryApi extends runtime.BaseAPI {

    /**
     * You can find a series for a given model by adding the following parameters to your query string:<br> `where[foreignId][eq]={yourModelId}&where[tableName][eq]={theModelsTableName}` 
     * List History
     */
    async listHistoryRaw(requestParameters: ListHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListHistoryResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.history.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListHistoryResponseFromJSON(jsonValue));
    }

    /**
     * You can find a series for a given model by adding the following parameters to your query string:<br> `where[foreignId][eq]={yourModelId}&where[tableName][eq]={theModelsTableName}` 
     * List History
     */
    async listHistory(requestParameters: ListHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListHistoryResponse> {
        const response = await this.listHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List or filter history record. Filters are supplied in the body.
     * Search History
     */
    async searchHistoryRaw(requestParameters: SearchHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchHistoryResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.history.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/history/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchDataToJSON(requestParameters['searchData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchHistoryResponseFromJSON(jsonValue));
    }

    /**
     * List or filter history record. Filters are supplied in the body.
     * Search History
     */
    async searchHistory(requestParameters: SearchHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchHistoryResponse> {
        const response = await this.searchHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
