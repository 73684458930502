<template>
  <div class="attach">
    <v-tooltip
      v-model="show"
      :top="position === 'top'"
      :bottom="position === 'bottom'"
      :max-width="maxWidth"
      z-index="200000"
      :content-class="[arrowClass, contentClass].join(' ')"
      :attach="attach ? '.attach' : false"
      :disabled="disabled"
    >
      <template #activator="{ on }">
        <slot
          name="activator"
          :on="on"
        />
      </template>
      <slot name="message">
        <p class="text-center leading-tight mb-0 text-12">
          {{ message }}
        </p>
      </slot>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ToolTipWrapper',

  props: {
    position: {
      type: String,
      default: 'top',
      validator: (prop) => ['top', 'bottom'].includes(prop),
    },
    message: {
      type: String,
      default: '',
    },
    attach: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: [String, Number],
      default: 170,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    arrowClass() {
      return this.position === 'top' ? 'arrow-bottom' : 'arrow-top';
    },
  },
};
</script>
