/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftTemplate } from './ShiftTemplate';
import {
    ShiftTemplateFromJSON,
    ShiftTemplateFromJSONTyped,
    ShiftTemplateToJSON,
} from './ShiftTemplate';

/**
 * 
 * @export
 * @interface ShowShiftTemplateResponse
 */
export interface ShowShiftTemplateResponse {
    /**
     * 
     * @type {ShiftTemplate}
     * @memberof ShowShiftTemplateResponse
     */
    data?: ShiftTemplate;
}

/**
 * Check if a given object implements the ShowShiftTemplateResponse interface.
 */
export function instanceOfShowShiftTemplateResponse(value: object): boolean {
    return true;
}

export function ShowShiftTemplateResponseFromJSON(json: any): ShowShiftTemplateResponse {
    return ShowShiftTemplateResponseFromJSONTyped(json, false);
}

export function ShowShiftTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowShiftTemplateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftTemplateFromJSON(json['data']),
    };
}

export function ShowShiftTemplateResponseToJSON(value?: ShowShiftTemplateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftTemplateToJSON(value['data']),
    };
}

