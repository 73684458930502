
import EmployeeStatusDot from '@/components/employee/EmployeeStatusDot.vue';
import AvatarPlaceholder from '@/components/placeholders/AvatarPlaceholder.vue';
import { fullName } from '@/lib/employee/employeeFunctions';
import { AvatarSizes } from '@/lib/enum/AvatarSizes';
import { initials } from '@/util/stringFunctions';
import { PropType } from 'vue';
import { Employee } from '../../../api/v1';

/**
 * `EmployeeAvatar` handles displaying either the employees photo or if no photo has been set it will default to
 * their initials.
 */
export default {
  name: 'EmployeeAvatar',

  components: {
    EmployeeStatusDot,
    AvatarPlaceholder,
  },

  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },

    showStatus: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String as PropType<AvatarSizes>,
      default: AvatarSizes.Normal,
      validator: (prop) => Object.values(AvatarSizes).includes(prop),
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    highlightColour: {
      type: String,
      default: 'pink-800',
    },
    interaction: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      imageLoadFailed: false,
    };
  },

  computed: {
    name() {
      return fullName(this.employee);
    },

    photo() {
      return this.employee.photo?.url || '';
    },

    src() {
      return this.photo.concat('?d=128x128');
    },

    initials() {
      return initials(this.employee.firstName, this.employee.lastName);
    },
  },
};
