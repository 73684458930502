
import Chevron from '@/components/interface/Chevron.vue';
import { MenuItem, MenuSection } from '@/lib/menus/helpers';
import { routes } from '@/router/routes';
import { isRouteActive } from '@/util/routeFunctions';
import { PropType } from 'vue';

export default {
  name: 'SidebarNav',
  components: { Chevron },
  props: {
    menu: {
      type: Array as PropType<MenuSection[]>,
      required: true,
    },
  },
  data() {
    return {
      menuToggles: {} as Record<
        MenuSection['name'],
        ReturnType<MenuSection['open']>
      >,
    };
  },
  watch: {
    menu: {
      immediate: true,
      handler() {
        this.updateMenuToggles();
      },
    },
    '$route.name': {
      immediate: true,
      handler() {
        this.updateMenuToggles();
      },
    },
  },
  methods: {
    updateMenuToggles() {
      if (this.menu) {
        this.menuToggles = this.menu.reduce((obj, menuItem) => {
          const currentRouteName = this.$route.name;
          // we don't wanna open anything when SidebarNav being used as the main component on the page
          // e.g as a central menu on /reports and /settings pages
          const menuItemIsOpen = [
            routes.reportsMenu.name,
            routes.settingsMenu.name,
          ].includes(currentRouteName)
            ? false
            : menuItem.open(currentRouteName);
          return { ...obj, [menuItem.name]: menuItemIsOpen };
        }, {});
      }
    },

    selected(item: MenuItem) {
      return isRouteActive(this.$route.name, item.route.name);
    },

    toggleSection(key: string, value: boolean) {
      if (value) {
        Object.keys(this.menuToggles).forEach((sectionName) => {
          // open target section
          if (sectionName === key) {
            this.menuToggles[key] = value;
            return;
          }
          // close other sections
          if (this.menuToggles[sectionName]) {
            this.menuToggles[sectionName] = false;
          }
        });
        return;
      }
      // if we close target section then it means others already being closed
      this.menuToggles[key] = value;
    },
  },
};
