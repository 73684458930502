/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigiticketsEvent } from './DigiticketsEvent';
import {
    DigiticketsEventFromJSON,
    DigiticketsEventFromJSONTyped,
    DigiticketsEventToJSON,
} from './DigiticketsEvent';

/**
 * 
 * @export
 * @interface DigiticketsSession
 */
export interface DigiticketsSession {
    /**
     * 
     * @type {number}
     * @memberof DigiticketsSession
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof DigiticketsSession
     */
    startsAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DigiticketsSession
     */
    endsAt: Date;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsSession
     */
    spacesSold: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsSession
     */
    capacity: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsSession
     */
    orderCount: number;
    /**
     * 
     * @type {DigiticketsEvent}
     * @memberof DigiticketsSession
     */
    event?: DigiticketsEvent;
}

/**
 * Check if a given object implements the DigiticketsSession interface.
 */
export function instanceOfDigiticketsSession(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('startsAt' in value)) return false;
    if (!('endsAt' in value)) return false;
    if (!('spacesSold' in value)) return false;
    if (!('capacity' in value)) return false;
    if (!('orderCount' in value)) return false;
    return true;
}

export function DigiticketsSessionFromJSON(json: any): DigiticketsSession {
    return DigiticketsSessionFromJSONTyped(json, false);
}

export function DigiticketsSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigiticketsSession {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'startsAt': (new Date(json['startsAt'])),
        'endsAt': (new Date(json['endsAt'])),
        'spacesSold': json['spacesSold'],
        'capacity': json['capacity'],
        'orderCount': json['orderCount'],
        'event': json['event'] == null ? undefined : DigiticketsEventFromJSON(json['event']),
    };
}

export function DigiticketsSessionToJSON(value?: DigiticketsSession | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'startsAt': ((value['startsAt']).toISOString()),
        'endsAt': ((value['endsAt']).toISOString()),
        'spacesSold': value['spacesSold'],
        'capacity': value['capacity'],
        'orderCount': value['orderCount'],
        'event': DigiticketsEventToJSON(value['event']),
    };
}

