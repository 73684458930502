
import { viewport, ViewportSize } from '@/util/windowFunctions';
import { PropType } from 'vue';

export default {
  name: 'InputGroup',
  props: {
    shrink: {
      type: Boolean,
      default: false,
    },
    rowAt: {
      type: String as PropType<ViewportSize>,
      validator: (p) => Object.keys(viewport).includes(p) || p === null,
      default: null,
    },
    columnReverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'all:flex-col': this.rowAt !== 'all',
        'flex-column-reverse': this.columnReverse,
        [`${this.rowAt}:flex-row`]: this.rowAt,
        [this.shrink ? 'flex-grow-0' : 'flex-grow']: true,
      };
    },
  },
};
