<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 329 112"
  >
    <g transform="translate(-244 -1158)">
      <path
        d="M18,112a14,14,0,1,1,0-28H48.015a6,6,0,0,0,0-12H227.792a6,6,0,0,0,0,12v0H315a14,14,0,1,1,0,28ZM303,72a8,8,0,0,1,0-16h16a8,8,0,0,1,0,16ZM27,72a8,8,0,0,1,0-16H90.015a6,6,0,0,0-.005-12H239.792a6,6,0,0,0,0,12v0H279a8,8,0,1,1,0,16ZM48,44a8,8,0,1,1,0-16H95.015a6,6,0,0,0,0-12H265.792a6,6,0,0,0,0,12v0H307a8,8,0,0,1,0,16ZM8,44A8,8,0,1,1,8,28H25a8,8,0,1,1,0,16ZM42,16A8,8,0,0,1,42,0H281a8,8,0,0,1,0,16Z"
        transform="translate(244 1158)"
        fill="#f4f3f8"
      />
      <g transform="translate(366 1172)">
        <path
          d="M63.687,10H13.314A3.314,3.314,0,0,0,10,13.314v66.3a3.314,3.314,0,0,0,3.314,3.314H63.687A3.314,3.314,0,0,0,67,79.615v-66.3A3.314,3.314,0,0,0,63.687,10Z"
          transform="translate(-8.343 -8.343)"
          fill="#fff"
        />
        <path
          d="M75.992,306.375h35.129V313H75.992Z"
          transform="translate(-63.399 -255.603)"
          fill="#81bb5b"
          opacity="0.5"
        />
        <path
          d="M116.6,85.437a16.226,16.226,0,1,1-16.226-16.226A16.226,16.226,0,0,1,116.6,85.437Z"
          transform="translate(-70.206 -57.741)"
          fill="#1a90ff"
          opacity="0.2"
        />
        <path
          d="M161.306,128.684v1.286a5.145,5.145,0,1,1-10.29,0v-1.286a5.145,5.145,0,1,1,10.29,0Z"
          transform="translate(-125.99 -103.066)"
          fill="#ec3f8c"
          opacity="0.2"
        />
        <path
          d="M126.446,193.391a11.873,11.873,0,0,0-11.048,7.526,16.214,16.214,0,0,0,22.1,0A11.874,11.874,0,0,0,126.446,193.391Z"
          transform="translate(-96.274 -161.342)"
          fill="#515ea6"
          opacity="0.2"
        />
        <path
          d="M305.132,285.58a19.553,19.553,0,1,1-19.552-19.553A19.552,19.552,0,0,1,305.132,285.58Z"
          transform="translate(-221.941 -221.941)"
          fill="#1a90ff"
          opacity="0.2"
        />
        <path
          d="M1.657,223.349a1.658,1.658,0,1,0-1.172-.486A1.663,1.663,0,0,0,1.657,223.349Z"
          transform="translate(0 -183.571)"
          fill="#2c1841"
        />
        <path
          d="M150.377,384.181a1.657,1.657,0,1,0,0-3.314H138.805a1.657,1.657,0,1,0,0,3.314Z"
          transform="translate(-114.42 -317.75)"
          fill="#2c1841"
        />
        <path
          d="M79.772,90.1a1.664,1.664,0,0,0,.191.178,17.85,17.85,0,0,0,24.143,0,1.611,1.611,0,0,0,.191-.178,17.883,17.883,0,1,0-24.525,0Zm3.241-1.572a10.217,10.217,0,0,1,18.043,0,14.54,14.54,0,0,1-18.043,0ZM88.547,76.3V75.017a3.488,3.488,0,1,1,6.976,0V76.3a3.488,3.488,0,1,1-6.976,0Zm3.488-13.777A14.561,14.561,0,0,1,103.48,86.1a13.414,13.414,0,0,0-6.333-5.317,6.772,6.772,0,0,0,1.69-4.48V75.016a6.8,6.8,0,1,0-13.6,0V76.3a6.772,6.772,0,0,0,1.69,4.48A13.416,13.416,0,0,0,80.589,86.1,14.562,14.562,0,0,1,92.035,62.525Z"
          transform="translate(-61.864 -49.399)"
          fill="#2c1841"
        />
        <path
          d="M78.637,48.641a21.071,21.071,0,0,0-15-6.213,21.441,21.441,0,0,0-3.324.259V4.971A4.977,4.977,0,0,0,55.345,0H4.971A4.977,4.977,0,0,0,0,4.971V31.494a1.657,1.657,0,1,0,3.314,0V4.971A1.66,1.66,0,0,1,4.971,3.314H55.345A1.66,1.66,0,0,1,57,4.971V43.483a21.118,21.118,0,0,0-8.361,5.157c-.173.174-.341.35-.508.528a1.657,1.657,0,0,0-.411-.054H12.593a1.657,1.657,0,0,0-1.657,1.657V57.4a1.657,1.657,0,0,0,1.657,1.657H42.923a21.41,21.41,0,0,0-.494,4.581,21.124,21.124,0,0,0,2.133,9.291H4.971a1.659,1.659,0,0,1-1.657-1.657V44.75A1.657,1.657,0,1,0,0,44.75V71.272a4.977,4.977,0,0,0,4.971,4.971h41.6a21.562,21.562,0,0,0,2.066,2.393,21.21,21.21,0,0,0,30-30Zm-64.386,7.1V52.43H45.625a21,21,0,0,0-1.682,3.314Zm62.042,20.55a17.894,17.894,0,0,1-25.308,0,18.052,18.052,0,0,1-2.153-2.6c-.018-.029-.038-.056-.057-.083A17.915,17.915,0,0,1,58.807,46.4a1.635,1.635,0,0,0,.528-.139,18.033,18.033,0,0,1,4.3-.519A17.9,17.9,0,0,1,76.293,76.293Z"
          fill="#2c1841"
        />
        <path
          d="M375.575,449.969a1.657,1.657,0,0,0-1.657,1.657v1.194a1.657,1.657,0,0,0,3.314,0v-1.194A1.657,1.657,0,0,0,375.575,449.969Z"
          transform="translate(-311.952 -375.4)"
          fill="#2c1841"
        />
        <path
          d="M375.575,295.363a1.657,1.657,0,0,0,1.657-1.657v-1.194a1.657,1.657,0,0,0-3.314,0v1.194A1.657,1.657,0,0,0,375.575,295.363Z"
          transform="translate(-311.952 -242.655)"
          fill="#2c1841"
        />
        <path
          d="M293.706,373.918h-1.194a1.657,1.657,0,0,0,0,3.314h1.194a1.657,1.657,0,0,0,0-3.314Z"
          transform="translate(-242.655 -311.952)"
          fill="#2c1841"
        />
        <path
          d="M452.82,374.109h-1.194a1.657,1.657,0,0,0,0,3.314h1.194a1.657,1.657,0,0,0,0-3.314Z"
          transform="translate(-375.4 -312.112)"
          fill="#2c1841"
        />
        <path
          d="M361.968,321.312l-7.6,7.624-3.05-3.193a1.657,1.657,0,0,0-2.4,2.289l4.211,4.41a1.659,1.659,0,0,0,1.2.512h.024a1.658,1.658,0,0,0,1.173-.487l8.788-8.815a1.657,1.657,0,1,0-2.347-2.339Z"
          transform="translate(-290.716 -267.658)"
          fill="#2c1841"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ClockEmployeeCardSvg',
};
</script>
