import { Icon, IconType } from '@/lib/enum/Icon';
import { redirect } from '@/router/router';
import { reactive } from 'vue';
import { Location } from 'vue-router';

export interface IconSettings {
  action: () => void;
  icon: IconType;
  stat?: string;
}
export interface MobileHeaderTab {
  label: string;
  checked: boolean;
  value: string;
  action: Function;
}

type BackFunction = (fallbackRoute: Location) => void;

export type MobileHeaderStateType = {
  rightHeader: IconSettings[];
  title: string | null;
  leftIcon: IconSettings['icon'] | null;
  leftAction: IconSettings['action'] | null;
  pills: MobileHeaderTab[] | null;
  tabs: MobileHeaderTab[] | null;
  useAlternative: boolean;
  userMenuIsOpen: boolean;
};

const defaultState = (): MobileHeaderStateType => ({
  rightHeader: [],
  title: null,
  leftIcon: null,
  leftAction: null,
  pills: null,
  tabs: null,
  useAlternative: false,
  userMenuIsOpen: false,
});

class MobileHeaderState {
  state: MobileHeaderStateType;

  back: BackFunction;

  constructor() {
    this.state = reactive(defaultState());
  }

  setBack(back: BackFunction) {
    // We ask for a generic back function, rather than a concrete instance from router,
    // to avoid a dependency cycle.
    this.back = back;
  }

  resetState() {
    Object.assign(this.state, defaultState());
  }

  getState() {
    return this.state;
  }

  setTitle(title: string) {
    this.state.title = title;
  }

  setLeft(leftSettings: IconSettings) {
    this.state.leftAction = leftSettings.action;
    this.state.leftIcon = leftSettings.icon;
  }

  addBackButton(fallbackRoute?: Location) {
    this.setLeft({
      icon: Icon.ChevronLeft,
      action: () => this.back(fallbackRoute),
    });
  }

  setBackTo(route: Location) {
    this.setLeft({
      icon: Icon.ChevronLeft,
      action: () => redirect(route),
    });
  }

  setUserMenu() {
    this.setLeft({
      icon: Icon.User,
      action: () => {
        this.changeUserMenuIsOpen(true);
      },
    });
  }

  setRight(rightSettings: IconSettings[], clean: boolean = true) {
    if (clean) {
      this.resetRight();
    }
    this.state.rightHeader = [...this.state.rightHeader, ...rightSettings];
  }

  resetRight() {
    this.state.rightHeader = [];
  }

  setTabs(tabs: MobileHeaderTab[]) {
    this.state.tabs = [...tabs];
  }

  setPills(pills: MobileHeaderTab[]) {
    this.state.pills = [...pills];
  }

  useAlternative(value: boolean) {
    this.state.useAlternative = value;
  }

  changeUserMenuIsOpen(value: boolean) {
    this.state.userMenuIsOpen = value;
  }
}

const MobileHeader = new MobileHeaderState();

export default MobileHeader;
