/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleEvent } from './ScheduleEvent';
import {
    ScheduleEventFromJSON,
    ScheduleEventFromJSONTyped,
    ScheduleEventToJSON,
} from './ScheduleEvent';

/**
 * 
 * @export
 * @interface ShowScheduleEventResponse
 */
export interface ShowScheduleEventResponse {
    /**
     * 
     * @type {ScheduleEvent}
     * @memberof ShowScheduleEventResponse
     */
    data?: ScheduleEvent;
}

/**
 * Check if a given object implements the ShowScheduleEventResponse interface.
 */
export function instanceOfShowScheduleEventResponse(value: object): boolean {
    return true;
}

export function ShowScheduleEventResponseFromJSON(json: any): ShowScheduleEventResponse {
    return ShowScheduleEventResponseFromJSONTyped(json, false);
}

export function ShowScheduleEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowScheduleEventResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ScheduleEventFromJSON(json['data']),
    };
}

export function ShowScheduleEventResponseToJSON(value?: ShowScheduleEventResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ScheduleEventToJSON(value['data']),
    };
}

