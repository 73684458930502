<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 329 112"
  >
    <g transform="translate(-244 -1158)">
      <path
        d="M18,112a14,14,0,1,1,0-28H48.015a6,6,0,0,0,0-12H227.792a6,6,0,0,0,0,12v0H315a14,14,0,1,1,0,28ZM303,72a8,8,0,0,1,0-16h16a8,8,0,0,1,0,16ZM27,72a8,8,0,0,1,0-16H90.015a6,6,0,0,0-.005-12H239.792a6,6,0,0,0,0,12v0H279a8,8,0,1,1,0,16ZM48,44a8,8,0,1,1,0-16H95.015a6,6,0,0,0,0-12H265.792a6,6,0,0,0,0,12v0H307a8,8,0,0,1,0,16ZM8,44A8,8,0,1,1,8,28H25a8,8,0,1,1,0,16ZM42,16A8,8,0,0,1,42,0H281a8,8,0,0,1,0,16Z"
        transform="translate(244 1158)"
        fill="#f4f3f8"
      />
      <g transform="translate(364 1169.313)">
        <path
          d="M28.13,39.5H16.694A6.7,6.7,0,0,0,10,46.194v64.015a6.7,6.7,0,0,0,6.694,6.694H67.571a6.7,6.7,0,0,0,6.694-6.694V46.194A6.7,6.7,0,0,0,67.571,39.5Z"
          transform="translate(-8.326 -32.889)"
          fill="#f8dfb1"
        />
        <path
          d="M58,87.5h48.2v61.337H58Z"
          transform="translate(-48.293 -72.856)"
          fill="#fff"
        />
        <path
          d="M142,14.937A6.7,6.7,0,0,0,135.536,10h-.294a6.7,6.7,0,0,0-6.46,4.937h-3.895A2.887,2.887,0,0,0,122,17.824V22.97h26.777V17.824a2.887,2.887,0,0,0-2.887-2.887Z"
          transform="translate(-101.582 -8.326)"
          fill="#faf9fe"
        />
        <path
          d="M337.137,173.568A16.568,16.568,0,1,1,320.568,157,16.568,16.568,0,0,1,337.137,173.568Z"
          transform="translate(-253.123 -130.725)"
          fill="#e1f5ff"
        />
        <path
          d="M348.371,292.552a16.5,16.5,0,0,0,10.98-4.162,11.38,11.38,0,0,0-21.96,0A16.5,16.5,0,0,0,348.371,292.552Z"
          transform="translate(-280.926 -233.14)"
          fill="#ddf0cf"
        />
        <path
          d="M382.342,215.337v1.3a5.2,5.2,0,0,1-10.393,0v-1.3a5.2,5.2,0,0,1,10.393,0Z"
          transform="translate(-309.7 -174.972)"
          fill="#fce4ea"
        />
        <path
          d="M109.728,133.99l-4.363,4.363-1.008-1.007a1.674,1.674,0,0,0-2.367,2.367l2.191,2.191a1.675,1.675,0,0,0,2.367,0l5.547-5.547a1.674,1.674,0,0,0-2.367-2.366Z"
          transform="translate(-84.513 -111.157)"
          fill="#2c1841"
        />
        <path
          d="M193.674,136.847h15.983a1.674,1.674,0,0,0,0-3.347H193.674a1.674,1.674,0,0,0,0,3.347Z"
          transform="translate(-159.867 -111.158)"
          fill="#2c1841"
        />
        <path
          d="M193.674,176.847h7.573a1.674,1.674,0,0,0,0-3.347h-7.573a1.674,1.674,0,0,0,0,3.347Z"
          transform="translate(-159.867 -144.463)"
          fill="#2c1841"
        />
        <path
          d="M109.728,237.99l-4.363,4.363-1.008-1.008a1.674,1.674,0,1,0-2.367,2.367l2.191,2.191a1.675,1.675,0,0,0,2.367,0l5.547-5.547a1.674,1.674,0,0,0-2.367-2.366Z"
          transform="translate(-84.513 -197.752)"
          fill="#2c1841"
        />
        <path
          d="M193.674,280.847h7.573a1.674,1.674,0,0,0,0-3.347h-7.573a1.674,1.674,0,0,0,0,3.347Z"
          transform="translate(-159.867 -231.058)"
          fill="#2c1841"
        />
        <path
          d="M109.728,342.99l-4.363,4.363-1.008-1.008a1.674,1.674,0,1,0-2.367,2.367l2.191,2.191a1.675,1.675,0,0,0,2.367,0l5.547-5.547a1.674,1.674,0,0,0-2.367-2.366Z"
          transform="translate(-84.513 -285.18)"
          fill="#2c1841"
        />
        <path
          d="M193.674,345.847h15.983a1.674,1.674,0,1,0,0-3.347H193.674a1.674,1.674,0,0,0,0,3.347Z"
          transform="translate(-159.867 -285.18)"
          fill="#2c1841"
        />
        <path
          d="M193.674,385.847h7.573a1.674,1.674,0,0,0,0-3.347h-7.573a1.674,1.674,0,0,0,0,3.347Z"
          transform="translate(-159.867 -318.485)"
          fill="#2c1841"
        />
        <path
          d="M85.688,42.844A18.262,18.262,0,0,0,67.613,24.6V13.3a8.377,8.377,0,0,0-8.368-8.368H41.586A8.387,8.387,0,0,0,33.954,0h-.294a8.387,8.387,0,0,0-7.632,4.937H8.368A8.377,8.377,0,0,0,0,13.3V77.32a8.377,8.377,0,0,0,8.368,8.368H59.245a8.377,8.377,0,0,0,8.368-8.368V61.084a18.165,18.165,0,0,0,11.794-4.478,1.629,1.629,0,0,0,.253-.225A18.2,18.2,0,0,0,85.688,42.844Zm-3.347,0a14.827,14.827,0,0,1-3.318,9.362,13.106,13.106,0,0,0-6.456-5.967,6.841,6.841,0,0,0,1.75-4.574v-1.3a6.87,6.87,0,1,0-13.74,0v1.3a6.84,6.84,0,0,0,1.749,4.574,13.106,13.106,0,0,0-6.456,5.967A14.892,14.892,0,1,1,82.34,42.844ZM58.414,54.68a9.709,9.709,0,0,1,18.063,0,14.863,14.863,0,0,1-18.063,0Zm5.509-13.015v-1.3a3.523,3.523,0,1,1,7.045,0v1.3a3.523,3.523,0,0,1-7.045,0ZM64.266,13.3V24.88a18.1,18.1,0,0,0-4.686,1.507V14.644a1.674,1.674,0,0,0-1.674-1.674H48.869V9.5A4.535,4.535,0,0,0,48.7,8.284H59.245A5.026,5.026,0,0,1,64.266,13.3ZM27.2,8.284a1.674,1.674,0,0,0,1.616-1.236,5.028,5.028,0,0,1,4.844-3.7h.294a5.029,5.029,0,0,1,4.844,3.7,1.673,1.673,0,0,0,1.615,1.236h3.9A1.215,1.215,0,0,1,45.521,9.5V12.97H22.091V9.5A1.215,1.215,0,0,1,23.3,8.284ZM59.245,82.34H8.368A5.026,5.026,0,0,1,3.347,77.32V13.3A5.026,5.026,0,0,1,8.368,8.284H18.909A4.535,4.535,0,0,0,18.744,9.5V12.97H9.707a1.674,1.674,0,0,0-1.674,1.674V75.981a1.674,1.674,0,0,0,1.674,1.674H27.112a1.674,1.674,0,0,0,0-3.347H11.38V16.317H56.232V28.465a18.257,18.257,0,0,0-6.765,11.283H33.806a1.674,1.674,0,0,0,0,3.347h15.4a18.2,18.2,0,0,0,6.027,13.289,1.628,1.628,0,0,0,.249.22q.366.32.75.619V74.307H40.5a1.674,1.674,0,0,0,0,3.347H57.906a1.674,1.674,0,0,0,1.674-1.674V59.3a18.1,18.1,0,0,0,4.686,1.507V77.32A5.026,5.026,0,0,1,59.245,82.34Z"
          fill="#2c1841"
        />
        <path
          d="M193.674,42.1a1.674,1.674,0,1,0-1.183-.49A1.686,1.686,0,0,0,193.674,42.1Z"
          transform="translate(-159.867 -32.265)"
          fill="#2c1841"
        />
        <path
          d="M193.674,444a1.674,1.674,0,1,0,1.183.49A1.686,1.686,0,0,0,193.674,444Z"
          transform="translate(-159.867 -369.693)"
          fill="#2c1841"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ClipboardListCheckSvg',
};
</script>
