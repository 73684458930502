import Vue from 'vue';
import { BatchJobLogTypeEnum } from '../../api/v1';

export enum BatchJobState {
  Running,
  Complete,
  Failed,
}

export type BatchJobExtra = Record<string, string>;

export type BatchJob = {
  id: string;
  type: BatchJobLogTypeEnum;
  state: BatchJobState;
  jobCount: number;
  remainingJobs: number;
  lastEvent: Date;
  extra: BatchJobExtra;
};

export type BatchJobMessage = {
  id: string;
  type: string;
  event: string;
  jobCount: number;
  remainingJobs: number;
  unix: number;
  extra: BatchJobExtra;
};

const getStateFromNewMessage = (
  message: BatchJobMessage,
  current: BatchJobState | null,
): BatchJobState => {
  const stateMap = {
    Progress: BatchJobState.Running,
    Failed: BatchJobState.Failed,
    Finished: BatchJobState.Complete,
  };

  const stateTransitions = {
    [BatchJobState.Running]: [BatchJobState.Complete, BatchJobState.Failed],
    [BatchJobState.Complete]: [BatchJobState.Complete],
    [BatchJobState.Failed]: [BatchJobState.Failed],
  };

  if (!current) {
    return stateMap[message.event];
  }

  const allowedTransitions = stateTransitions[current];
  const newState = stateMap[message.event];

  return allowedTransitions.includes(newState) ? newState : current;
};

export default {
  namespaced: true,

  state: {
    jobs: {} as Record<string, BatchJob>,
  },

  mutations: {
    RESET(state) {
      Vue.set(state, 'jobs', {});
    },
    UPSERT_JOB(state, job: BatchJob) {
      Vue.set(state.jobs, job.id, job);
    },
    DELETE_JOB(state, id: string) {
      Vue.delete(state.jobs, id);
    },
  },

  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    handleUpdate({ state, commit }, message: BatchJobMessage) {
      const existingJob: BatchJob | null = state.jobs[message.id] ?? null;

      commit('UPSERT_JOB', {
        id: message.id,
        type: BatchJobLogTypeEnum[message.type],
        state: getStateFromNewMessage(message, existingJob?.state),
        jobCount: message.jobCount,
        remainingJobs: Math.min(
          existingJob?.remainingJobs ?? message.jobCount,
          message.remainingJobs,
        ),
        lastEvent: new Date(message.unix * 1000),
        extra: { ...(existingJob?.extra ?? {}), ...message.extra },
      });
    },
    delete({ commit }, id: string) {
      commit('DELETE_JOB', id);
    },
  },
};
