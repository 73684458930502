/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RedeemedOffer } from './RedeemedOffer';
import {
    RedeemedOfferFromJSON,
    RedeemedOfferFromJSONTyped,
    RedeemedOfferToJSON,
} from './RedeemedOffer';

/**
 * 
 * @export
 * @interface RedeemOfferResponse
 */
export interface RedeemOfferResponse {
    /**
     * 
     * @type {RedeemedOffer}
     * @memberof RedeemOfferResponse
     */
    data: RedeemedOffer;
}

/**
 * Check if a given object implements the RedeemOfferResponse interface.
 */
export function instanceOfRedeemOfferResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function RedeemOfferResponseFromJSON(json: any): RedeemOfferResponse {
    return RedeemOfferResponseFromJSONTyped(json, false);
}

export function RedeemOfferResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedeemOfferResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': RedeemedOfferFromJSON(json['data']),
    };
}

export function RedeemOfferResponseToJSON(value?: RedeemOfferResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': RedeemedOfferToJSON(value['data']),
    };
}

