import { isLocalEnv } from '@/lib/environment';
import { batchEntityConfig } from '@/lib/realtime/batchEntityConfig';
import { entityConfig } from '@/lib/realtime/entityConfig';
import {
  emptyCollectionResponse,
  Where,
} from '@/lib/realtime/realtimeFunctions';
import {
  EntityConfig,
  EntityInstance,
  EntityName,
  Filter,
  GetParameterType,
  SubscriptionResponse,
} from '@/lib/realtime/realtimeTypes';
import {
  SocketService,
  WebsocketMessageHandler,
  websocketService,
} from '@/plugins/socket-service/SocketService';
import { makeCancellable } from '@/util/promiseFunctions';
import Vue from 'vue';
import { Commit } from 'vuex';
import { ListEventEntitiesResponseDataEnum } from '../../../api/v1';

type CompositeKey = string;
type Collection<T extends EntityName> = {
  response: SubscriptionResponse<T>;
  filter: Filter;
  subscribedComponents: number[];
};
type Collections = Map<CompositeKey, Collection<EntityName>>;

// This relies on external code having set a reference to the store's commit function inside this module
// as Vuex doesn't provide any easy access to the commit function from outside a component or store.
// Yes, it's not ideal, but it seems to be the only way to get Vuex dev tools showing the correct values
// presumably because it watches for mutations making the changes to the state.
let commit: Commit = null;
export const setEntityRepositoryCommit = (c: Commit) => {
  commit = c;
};

const keySeparator = ' ';

const getEmptyCollection = <T extends EntityName>(
  entity: T,
): Collection<T> => ({
  /** @todo Remove this ignore once date-bound realtime stuff is merged */
  // @ts-ignore
  response: emptyCollectionResponse(entity),
  filter: () => true,
  subscribedComponents: [],
});

/**
 * This keeps track of which entities and conditions have already been loaded.
 * Once an entity/condition combo is in here, it should never be
 * fetched from the API again - only ever updated via websocket messages.
 */
class EntityRepository {
  private collections: Collections = new Map();

  private readonly entityConfig: EntityConfig;

  private readonly batchEntityConfig;

  constructor(entityConfig: EntityConfig, batchEntityConfig) {
    this.entityConfig = entityConfig;
    this.batchEntityConfig = batchEntityConfig;
    // Build our empty structures
    this.reset();
  }

  reset() {
    // Cancel all outstanding promises
    this.collections.forEach((c) => {
      c.response.promise?.cancel();
    });
    // Remove all collections
    this.collections.clear();

    this.updateVuex();
  }

  /**
   * Generate a human-readable composite key for our entity name + 'where' condition
   */
  private static makeKey<T extends EntityName>(entity: T, where: Where<T>) {
    return entity + keySeparator + JSON.stringify(where).replaceAll('"', '');
  }

  /**
   * So that we can keep track of which components have subscribed, we use Vue's internal _uid property.
   * This may change in the future. When a component isn't given to us, we just use zero as a generic ID.
   */
  private static getComponentId(component: Vue = null): number {
    // @ts-ignore-next-line
    return parseInt(component?._uid ?? 0, 10);
  }

  private getCollection<T extends EntityName>(entity: T, where: Where<T>) {
    const key = EntityRepository.makeKey(entity, where);
    const collection = this.collections.get(key) ?? getEmptyCollection(entity);
    this.collections.set(key, collection);
    return collection;
  }

  private removeCollection<T extends EntityName>(entity: T, where: Where<T>) {
    const key = EntityRepository.makeKey(entity, where);
    const collection = this.collections.get(key);
    collection.response.promise?.cancel();
    this.collections.delete(key);
  }

  private getCollectionsForEntity(entity: EntityName) {
    const matchingKeys = [...this.collections.keys()].filter((k) =>
      k.startsWith(entity + keySeparator),
    );
    return matchingKeys.map((key) => this.collections.get(key));
  }

  makeHandler(entityMaker: Function): WebsocketMessageHandler {
    return (
      socketService: SocketService,
      entityName: ListEventEntitiesResponseDataEnum,
      eventName: string,
      data: object | number | string,
    ) => {
      // After deleting an entity if the websocket message order isn't correct we get the entity id on an 'updated' event
      // E.g. deleting an employee
      if (
        typeof data === 'object' &&
        (eventName.endsWith('Created') || eventName.endsWith('Updated'))
      ) {
        const entity = entityMaker(data);
        this.upsertEntity(entityName as EntityName, entity);
      } else if (eventName.endsWith('Deleted')) {
        this.removeEntity(entityName as EntityName, data as number | string);
      }
    };
  }

  makeBatchHandler(entityFetcher: Function): WebsocketMessageHandler {
    return async (
      socketService: SocketService,
      entityName: ListEventEntitiesResponseDataEnum,
      eventName: string,
      data: number[],
    ) => {
      // After deleting an entity if the websocket message order isn't correct we get the entity id on an 'updated' event
      // E.g. deleting an employee
      if (eventName.endsWith('Created') || eventName.endsWith('Updated')) {
        const entities = await entityFetcher(data);
        this.batchUpsertEntities(entityName as EntityName, entities);
      } else if (eventName.endsWith('Deleted')) {
        this.batchRemoveEntities(entityName as EntityName, data as number[]);
      }
    };
  }

  registerWebsocketHandler(entity: EntityName) {
    // Register a websocket listener for any changes to this type of entity
    const handlerKey = `${entity}-realtime`;
    if (!websocketService.hasHandler(entity, handlerKey)) {
      const handler = this.makeHandler(this.entityConfig[entity].entityMaker);
      websocketService.registerHandler(entity, handlerKey, handler);
    }
    // Register a websocket listener for any batch changes to this type of entity
    if (
      !websocketService.hasBatchHandler(entity, handlerKey) &&
      this.batchEntityConfig[entity]
    ) {
      const handler = this.makeBatchHandler(this.batchEntityConfig[entity]);
      websocketService.registerBatchHandler(entity, handlerKey, handler);
    }
  }

  subscribe<T extends EntityName>(
    entityName: T,
    where: Where<T>,
    filter: Filter,
    component: Vue = null,
    params: GetParameterType<T> = {} as GetParameterType<T>,
  ): SubscriptionResponse<T> {
    this.registerWebsocketHandler(entityName);

    const collection = this.getCollection(entityName, where);
    collection.filter = filter;
    const { response, subscribedComponents } = collection;

    const componentId = EntityRepository.getComponentId(component);
    if (!subscribedComponents.includes(componentId)) {
      subscribedComponents.push(componentId);
    }

    // See if we already have a complete collection for this entity
    // If so, just use a filtered copy of it, rather than making another
    // request to the server
    const completeCollection = this.getCollection(entityName, {});
    if (completeCollection && completeCollection.response.promise) {
      const promise = completeCollection.response.promise.then((data) => {
        response.data = data.filter(filter);
        response.isLoading = false;
        return response.data;
      });
      response.promise = makeCancellable(promise);
      this.updateVuex();
    }

    // If response.promise is null then we need to make a request to the server for data.
    // This will either be because it's the first time we've requested this collection,
    // or the previous request failed (probably for some reason outside our control)
    // and therefore we treat it as if it's never been fetched before.
    if (response.promise === null) {
      const { fetcher } = this.entityConfig[entityName];
      // actually call the fetcher to make the api request
      const initialPromise = fetcher(params, where) as Promise<
        EntityInstance<T>[]
      >;
      // Intercept the promise to update the main store, plus our individual collections
      const promise = initialPromise.then(
        (entities: EntityInstance<T>[]) => {
          // This should only get run when the collection is already empty, so it should be safe to push these in
          // Some list endpoints don't have where params so ensure the data is filtered
          response.data.push(...entities.filter(filter));
          response.isLoading = false;
          this.updateVuex();
          return response.data;
        },
        (error) => {
          // If an API request errors, be sure to delete this promise so it can be retried. Otherwise we end
          // up with an empty collection that won't be re-fetched until the app is reloaded.
          response.promise = null;
          response.isLoading = false;
          // Probably best to throw an error so our monitoring can kick in
          throw error;
        },
      );

      response.isLoading = true;
      // We might have some overlapping data in the store at this point, but it may be more
      // confusing to return just a partial set of data.
      response.data = [];
      response.promise = makeCancellable(promise);
      response.unsubscribe = () =>
        this.unsubscribe(entityName, where, component);
    }

    return response as SubscriptionResponse<T>;
  }

  unsubscribe<T extends EntityName>(
    entityName: T,
    where: Where<T>,
    component: Vue = null,
  ): void {
    const collection = this.getCollection(entityName, where);

    const componentId = EntityRepository.getComponentId(component);
    const index = collection.subscribedComponents.findIndex(
      (id) => id === componentId,
    );
    collection.subscribedComponents.splice(index, 1);

    if (collection.subscribedComponents.length === 0) {
      this.removeCollection(entityName, where);
      this.updateVuex();
    }
  }

  updateVuex() {
    if (!isLocalEnv) {
      // Only do this logging in development
      return;
    }
    // Create a more readable version of the collections for debugging or sending to Vuex
    const readableStructure = {};
    this.collections.forEach(({ response }, key) => {
      readableStructure[key] = response.data.map((entity) => ({ ...entity }));
    });

    if (commit) {
      commit('entitySubscriptions/SET_SUBSCRIPTIONS', readableStructure);
    }
  }

  upsertEntity<T extends EntityName>(entityName: T, entity: EntityInstance<T>) {
    const collections = this.getCollectionsForEntity(entityName);
    collections.forEach(({ response, filter }) => {
      const { data } = response;
      // Because the values of an entity might have changed, we need to check the filters to see if
      // the entity should still exist in this collection.
      const oldIndex = data.findIndex((e) => e.id === entity.id);
      const previouslyMatched = oldIndex > -1;
      const nowMatches = [entity].filter(filter).length > 0;

      if (!previouslyMatched && nowMatches) {
        // Previously didn't match, but now does
        data.push(entity);
      } else if (previouslyMatched && nowMatches) {
        // Previously matched, still matches

        // To avoid issues where websocket messages arrive out-of-order,
        // check that the 'updatedAt' value of the old version of the entity
        // isn't newer than the incoming one.
        const oldEntity = data[oldIndex];
        if (
          'updatedAt' in oldEntity &&
          'updatedAt' in entity &&
          oldEntity.updatedAt >= entity.updatedAt
        ) {
          return;
        }
        Vue.set(data, oldIndex, entity);
      } else if (previouslyMatched && !nowMatches) {
        // Previously matched, now doesn't
        data.splice(oldIndex, 1);
      } else {
        // Previously didn't match, still doesn't - do nothing
      }
    });

    this.updateVuex();
  }

  batchUpsertEntities<T extends EntityName>(
    entityName: T,
    entities: EntityInstance<T>[],
  ) {
    const collections = this.getCollectionsForEntity(entityName);
    collections.forEach(({ response, filter }) => {
      // Existing collection
      const { data } = response;
      // Updated entities filtered to match the collection filters
      const filteredEntities = entities.filter(filter);

      const updatedData = data.reduce((array, oldEntity) => {
        const newEntity = filteredEntities.find((e) => e.id === oldEntity.id);
        // If the entity has been updated but no longer suited to the collection then return the array without adding it
        if (entities.find((e) => e.id === oldEntity.id) && !newEntity) {
          return array;
        }
        // If the entity hasn't been updated or the existing entity is more recent, then we can keep the existing entity
        if (
          !newEntity ||
          ('updatedAt' in oldEntity &&
            'updatedAt' in newEntity &&
            oldEntity.updatedAt >= newEntity.updatedAt)
        ) {
          return [...array, oldEntity];
        }
        // Otherwise add the updated entity
        return [...array, newEntity];
      }, []);

      // Add newly matching entities and updated entities together
      updatedData.push(
        ...filteredEntities.filter(({ id }) => !data.find((e) => e.id === id)),
      );
      response.data.splice(0, response.data.length, ...updatedData);
    });

    this.updateVuex();
  }

  removeEntity(entityName: EntityName, id: number | string) {
    const collections = this.getCollectionsForEntity(entityName);
    collections.forEach(({ response }) => {
      const { data } = response;
      const index = data.findIndex((e) => e.id === id);
      if (index !== -1) {
        data.splice(index, 1);
      }
    });

    this.updateVuex();
  }

  batchRemoveEntities(entityName: EntityName, ids: number[]) {
    const collections = this.getCollectionsForEntity(entityName);
    collections.forEach(({ response }) => {
      const { data } = response;
      response.data = data.filter(
        ({ id }) => typeof id === 'number' && !ids.includes(id),
      );
    });

    this.updateVuex();
  }
}

export const entityRepository = new EntityRepository(
  entityConfig,
  batchEntityConfig,
);
