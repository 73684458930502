import {
  EmployeeLeaveSummaryLeaveEntitlementUnitEnum,
  LeavePeriodTypeLeaveAllowanceUnitEnum,
  LeaveTypeLeaveAllowanceTypeEnum,
  LeaveTypeLeaveAllowanceUnitEnum,
} from '../../../api/v1';

export const LeaveEntitlementUnitEnum = {
  ...EmployeeLeaveSummaryLeaveEntitlementUnitEnum,
  ...LeaveTypeLeaveAllowanceUnitEnum,
  ...LeavePeriodTypeLeaveAllowanceUnitEnum,
} as const;
export type LeaveEntitlementUnitType =
  typeof LeaveEntitlementUnitEnum[keyof typeof LeaveEntitlementUnitEnum];

const LeaveTypeLeaveAllowanceType = LeaveTypeLeaveAllowanceTypeEnum;
export type LeaveTypeLeaveAllowanceTypeType =
  typeof LeaveTypeLeaveAllowanceType[keyof typeof LeaveTypeLeaveAllowanceType];

export const DefaultAccrualRate = 12.07;
export const DefaultAllowance = 25;
