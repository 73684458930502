/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCostsByEmployee
 */
export interface ReportCostsByEmployee {
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployee
     */
    employeeId: number;
    /**
     * 
     * @type {string}
     * @memberof ReportCostsByEmployee
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployee
     */
    shifts: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployee
     */
    hoursScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployee
     */
    hoursWorked: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployee
     */
    costsScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployee
     */
    costsWorked: number | null;
}

/**
 * Check if a given object implements the ReportCostsByEmployee interface.
 */
export function instanceOfReportCostsByEmployee(value: object): boolean {
    if (!('employeeId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('shifts' in value)) return false;
    if (!('hoursScheduled' in value)) return false;
    if (!('hoursWorked' in value)) return false;
    if (!('costsScheduled' in value)) return false;
    if (!('costsWorked' in value)) return false;
    return true;
}

export function ReportCostsByEmployeeFromJSON(json: any): ReportCostsByEmployee {
    return ReportCostsByEmployeeFromJSONTyped(json, false);
}

export function ReportCostsByEmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCostsByEmployee {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'name': json['name'],
        'shifts': json['shifts'],
        'hoursScheduled': json['hoursScheduled'],
        'hoursWorked': json['hoursWorked'],
        'costsScheduled': json['costsScheduled'],
        'costsWorked': json['costsWorked'],
    };
}

export function ReportCostsByEmployeeToJSON(value?: ReportCostsByEmployee | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employeeId': value['employeeId'],
        'name': value['name'],
        'shifts': value['shifts'],
        'hoursScheduled': value['hoursScheduled'],
        'hoursWorked': value['hoursWorked'],
        'costsScheduled': value['costsScheduled'],
        'costsWorked': value['costsWorked'],
    };
}

