/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateLeaveAdjustmentData,
  CreateLeaveAdjustmentResponse,
  ListLeaveAdjustmentsResponse,
  ShowLeaveAdjustmentResponse,
} from '../models/index';
import {
    CreateLeaveAdjustmentDataFromJSON,
    CreateLeaveAdjustmentDataToJSON,
    CreateLeaveAdjustmentResponseFromJSON,
    CreateLeaveAdjustmentResponseToJSON,
    ListLeaveAdjustmentsResponseFromJSON,
    ListLeaveAdjustmentsResponseToJSON,
    ShowLeaveAdjustmentResponseFromJSON,
    ShowLeaveAdjustmentResponseToJSON,
} from '../models/index';

export interface CreateLeaveAdjustmentRequest {
    _with?: Array<string>;
    createLeaveAdjustmentData?: CreateLeaveAdjustmentData;
}

export interface ListLeaveAdjustmentsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowLeaveAdjustmentRequest {
    id: number;
    _with?: Array<string>;
}

/**
 * 
 */
export class LeaveAdjustmentApi extends runtime.BaseAPI {

    /**
     * Create a new leave adjustment for an employee
     * Create Leave Adjustment
     */
    async createLeaveAdjustmentRaw(requestParameters: CreateLeaveAdjustmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLeaveAdjustmentResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-adjustment.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-adjustments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeaveAdjustmentDataToJSON(requestParameters['createLeaveAdjustmentData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLeaveAdjustmentResponseFromJSON(jsonValue));
    }

    /**
     * Create a new leave adjustment for an employee
     * Create Leave Adjustment
     */
    async createLeaveAdjustment(requestParameters: CreateLeaveAdjustmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLeaveAdjustmentResponse> {
        const response = await this.createLeaveAdjustmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a list of leave adjustment records
     * List Leave Adjustments
     */
    async listLeaveAdjustmentsRaw(requestParameters: ListLeaveAdjustmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeaveAdjustmentsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-adjustment.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-adjustments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeaveAdjustmentsResponseFromJSON(jsonValue));
    }

    /**
     * Show a list of leave adjustment records
     * List Leave Adjustments
     */
    async listLeaveAdjustments(requestParameters: ListLeaveAdjustmentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeaveAdjustmentsResponse> {
        const response = await this.listLeaveAdjustmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show an leave adjustment record
     * Show Leave Adjustment
     */
    async showLeaveAdjustmentRaw(requestParameters: ShowLeaveAdjustmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowLeaveAdjustmentResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showLeaveAdjustment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-adjustment.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-adjustments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowLeaveAdjustmentResponseFromJSON(jsonValue));
    }

    /**
     * Show an leave adjustment record
     * Show Leave Adjustment
     */
    async showLeaveAdjustment(requestParameters: ShowLeaveAdjustmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowLeaveAdjustmentResponse> {
        const response = await this.showLeaveAdjustmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
