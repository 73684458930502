/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';

/**
 * 
 * @export
 * @interface RegisterCompanyResponse
 */
export interface RegisterCompanyResponse {
    /**
     * 
     * @type {Company}
     * @memberof RegisterCompanyResponse
     */
    data: Company;
}

/**
 * Check if a given object implements the RegisterCompanyResponse interface.
 */
export function instanceOfRegisterCompanyResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function RegisterCompanyResponseFromJSON(json: any): RegisterCompanyResponse {
    return RegisterCompanyResponseFromJSONTyped(json, false);
}

export function RegisterCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterCompanyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanyFromJSON(json['data']),
    };
}

export function RegisterCompanyResponseToJSON(value?: RegisterCompanyResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': CompanyToJSON(value['data']),
    };
}

