/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A period of rest within a Shift
 * @export
 * @interface ShiftTemplateBreak
 */
export interface ShiftTemplateBreak {
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateBreak
     */
    startHour: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateBreak
     */
    startMinute: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateBreak
     */
    durationInMinutes: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShiftTemplateBreak
     */
    paid: boolean;
}

/**
 * Check if a given object implements the ShiftTemplateBreak interface.
 */
export function instanceOfShiftTemplateBreak(value: object): boolean {
    if (!('startHour' in value)) return false;
    if (!('startMinute' in value)) return false;
    if (!('durationInMinutes' in value)) return false;
    if (!('paid' in value)) return false;
    return true;
}

export function ShiftTemplateBreakFromJSON(json: any): ShiftTemplateBreak {
    return ShiftTemplateBreakFromJSONTyped(json, false);
}

export function ShiftTemplateBreakFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftTemplateBreak {
    if (json == null) {
        return json;
    }
    return {
        
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'durationInMinutes': json['durationInMinutes'],
        'paid': json['paid'],
    };
}

export function ShiftTemplateBreakToJSON(value?: ShiftTemplateBreak | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'durationInMinutes': value['durationInMinutes'],
        'paid': value['paid'],
    };
}

