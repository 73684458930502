
import { VisualTheme } from '@/lib/enum/Colour';
import { BrandIconType, IconType } from '@/lib/enum/Icon';
import { PropType } from 'vue';

const size = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
} as const;
type SizeType = typeof size[keyof typeof size];

export default {
  name: 'Dot',

  props: {
    colour: {
      type: String as PropType<VisualTheme>,
      default: VisualTheme.Warning,
    },

    // Boolean to invert the colours
    invert: {
      type: Boolean,
      default: false,
    },

    // Use icon enum
    icon: {
      type: String as PropType<IconType | BrandIconType>,
      default: null,
    },

    size: {
      type: String as PropType<SizeType>,
      default: size.lg,
    },

    position: {
      type: String as PropType<'absolute' | 'relative'>,
      default: 'absolute',
    },
  },
};
