
import ToolTipWrapper from '@/components/info/ToolTipWrapper.vue';
import { needsToConfirmAccount } from '@/lib/employee/employeeFunctions';
import { Icon } from '@/lib/enum/Icon';
import { userCan } from '@/lib/permission/userCan';
import store from '@/store';
import { hasMatchingId } from '@/util/entityFunctions';
import { PropType } from 'vue';
import { CompanySetting, Employee, EmployeeStatusEnum } from '../../../api/v1';

export default {
  name: 'EmployeeStatusDot',
  components: { ToolTipWrapper },

  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userCan,
      Icon,
    };
  },
  computed: {
    companySettings: (): CompanySetting => store.state.settings.companySettings,

    hasNoAccount(): boolean {
      return this.employee.userId === null;
    },
    showAsUnverified(): boolean {
      return needsToConfirmAccount(this.employee);
    },
    isAccountOwner(): boolean {
      return hasMatchingId(this.employee, this.companySettings.accountOwnerId);
    },
    showAsArchived(): boolean {
      return this.employee.status === EmployeeStatusEnum.Archived;
    },
  },
};
