import {
  AccessRole,
  AccessRolesPermissionPermissionEnum,
  CompanySetting,
  Employee,
} from '../../api/v1';
import store from '../store';

export const Permission = AccessRolesPermissionPermissionEnum;

/**
 * Check if the current user (employee) has a particular permission
 */
export const userHasPermission = (
  permission: AccessRolesPermissionPermissionEnum,
): boolean => store.state.permissions.includes(permission);

// USER UTILITIES
/**
 * Check current logged in user and update the language, ie 'My Profile' over 'Employees Profile'
 */
export const isCurrentEmployee = (employee: Employee) =>
  employee.id === store.getters.loggedInEmployee.id;

export const isProtectedEmployee = (
  { id }: Employee,
  { accountOwnerId, primaryContactId }: CompanySetting,
): boolean => [accountOwnerId, primaryContactId].includes(id);

/**
 * Extract a *unique* set of permissions from a given list of access roles.
 */
export const extractPermissionsFromAccessRoles = (
  accessRoles: AccessRole[],
): AccessRolesPermissionPermissionEnum[] => {
  return [
    ...new Set(
      accessRoles.reduce(
        (list: AccessRolesPermissionPermissionEnum[], accessRole) =>
          list.concat(
            accessRole.permissions.map(({ permission }) => permission),
          ),
        [],
      ),
    ),
  ];
};

export const employeeHasPermission = (
  employee: Employee,
  employeeAccessRoles: AccessRole[],
  permission: AccessRolesPermissionPermissionEnum,
): boolean =>
  extractPermissionsFromAccessRoles(employeeAccessRoles).includes(permission);
