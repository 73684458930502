/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Schedule } from './Schedule';
import {
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';

/**
 * 
 * @export
 * @interface CreateScheduleResponse
 */
export interface CreateScheduleResponse {
    /**
     * 
     * @type {Schedule}
     * @memberof CreateScheduleResponse
     */
    data?: Schedule;
}

/**
 * Check if a given object implements the CreateScheduleResponse interface.
 */
export function instanceOfCreateScheduleResponse(value: object): boolean {
    return true;
}

export function CreateScheduleResponseFromJSON(json: any): CreateScheduleResponse {
    return CreateScheduleResponseFromJSONTyped(json, false);
}

export function CreateScheduleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScheduleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ScheduleFromJSON(json['data']),
    };
}

export function CreateScheduleResponseToJSON(value?: CreateScheduleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ScheduleToJSON(value['data']),
    };
}

