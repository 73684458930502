/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OffersBehaviour
 */
export interface OffersBehaviour {
    /**
     * 
     * @type {number}
     * @memberof OffersBehaviour
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OffersBehaviour
     */
    offerId: number;
    /**
     * 
     * @type {string}
     * @memberof OffersBehaviour
     */
    offerTerm: OffersBehaviourOfferTermEnum;
    /**
     * 
     * @type {number}
     * @memberof OffersBehaviour
     */
    defaultQuota: number;
    /**
     * 
     * @type {number}
     * @memberof OffersBehaviour
     */
    rate: number;
}

/**
* @export
* @enum {string}
*/
export enum OffersBehaviourOfferTermEnum {
    Monthly = 'Monthly',
    Annual = 'Annual'
}


/**
 * Check if a given object implements the OffersBehaviour interface.
 */
export function instanceOfOffersBehaviour(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('offerId' in value)) return false;
    if (!('offerTerm' in value)) return false;
    if (!('defaultQuota' in value)) return false;
    if (!('rate' in value)) return false;
    return true;
}

export function OffersBehaviourFromJSON(json: any): OffersBehaviour {
    return OffersBehaviourFromJSONTyped(json, false);
}

export function OffersBehaviourFromJSONTyped(json: any, ignoreDiscriminator: boolean): OffersBehaviour {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'offerId': json['offerId'],
        'offerTerm': json['offerTerm'],
        'defaultQuota': json['defaultQuota'],
        'rate': json['rate'],
    };
}

export function OffersBehaviourToJSON(value?: OffersBehaviour | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'offerId': value['offerId'],
        'offerTerm': value['offerTerm'],
        'defaultQuota': value['defaultQuota'],
        'rate': value['rate'],
    };
}

