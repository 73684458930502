/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListPayPeriodsResponse,
  ListRemainingPayPeriodsResponse,
  ShiftiePlainDate,
  ShowPayPeriodResponse,
  UpdatePayPeriodData,
  UpdatePayPeriodResponse,
} from '../models/index';
import {
    ListPayPeriodsResponseFromJSON,
    ListPayPeriodsResponseToJSON,
    ListRemainingPayPeriodsResponseFromJSON,
    ListRemainingPayPeriodsResponseToJSON,
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateToJSON,
    ShowPayPeriodResponseFromJSON,
    ShowPayPeriodResponseToJSON,
    UpdatePayPeriodDataFromJSON,
    UpdatePayPeriodDataToJSON,
    UpdatePayPeriodResponseFromJSON,
    UpdatePayPeriodResponseToJSON,
} from '../models/index';

export interface ListPayPeriodsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

export interface ListRemainingPayPeriodsRequest {
    nextPayCycleStartDate: ShiftiePlainDate;
    page?: number;
    perPage?: number;
}

export interface ShowPayPeriodRequest {
    id: number;
}

export interface UpdatePayPeriodRequest {
    id: number;
    updatePayPeriodData?: UpdatePayPeriodData;
}

/**
 * 
 */
export class PayPeriodApi extends runtime.BaseAPI {

    /**
     * List Pay Periods
     */
    async listPayPeriodsRaw(requestParameters: ListPayPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPayPeriodsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.pay-period.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pay-periods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPayPeriodsResponseFromJSON(jsonValue));
    }

    /**
     * List Pay Periods
     */
    async listPayPeriods(requestParameters: ListPayPeriodsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPayPeriodsResponse> {
        const response = await this.listPayPeriodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the remaining pay periods for your current pay cycle based on a new pay cycle\'s start date.
     * List Remaining Pay Periods
     */
    async listRemainingPayPeriodsRaw(requestParameters: ListRemainingPayPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListRemainingPayPeriodsResponse>> {
        if (requestParameters['nextPayCycleStartDate'] == null) {
            throw new runtime.RequiredError(
                'nextPayCycleStartDate',
                'Required parameter "nextPayCycleStartDate" was null or undefined when calling listRemainingPayPeriods().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['nextPayCycleStartDate'] != null) {
            queryParameters['nextPayCycleStartDate'] = requestParameters['nextPayCycleStartDate'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.pay-period.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pay-periods/remaining`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListRemainingPayPeriodsResponseFromJSON(jsonValue));
    }

    /**
     * List the remaining pay periods for your current pay cycle based on a new pay cycle\'s start date.
     * List Remaining Pay Periods
     */
    async listRemainingPayPeriods(requestParameters: ListRemainingPayPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListRemainingPayPeriodsResponse> {
        const response = await this.listRemainingPayPeriodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Pay Period
     */
    async showPayPeriodRaw(requestParameters: ShowPayPeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowPayPeriodResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showPayPeriod().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.pay-period.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pay-periods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowPayPeriodResponseFromJSON(jsonValue));
    }

    /**
     * Show Pay Period
     */
    async showPayPeriod(requestParameters: ShowPayPeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowPayPeriodResponse> {
        const response = await this.showPayPeriodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Pay Period
     */
    async updatePayPeriodRaw(requestParameters: UpdatePayPeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatePayPeriodResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePayPeriod().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.pay-period.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pay-periods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePayPeriodDataToJSON(requestParameters['updatePayPeriodData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatePayPeriodResponseFromJSON(jsonValue));
    }

    /**
     * Update Pay Period
     */
    async updatePayPeriod(requestParameters: UpdatePayPeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatePayPeriodResponse> {
        const response = await this.updatePayPeriodRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
