/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeImport
 */
export interface EmployeeImport {
    /**
     * 
     * @type {string}
     * @memberof EmployeeImport
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeImport
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeImport
     */
    uploadId: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeImport
     */
    initiatedBy: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeImport
     */
    employeeCount: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeImport
     */
    locationId: number | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeImport
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeImport
     */
    sendInviteEmails: boolean;
}

/**
 * Check if a given object implements the EmployeeImport interface.
 */
export function instanceOfEmployeeImport(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('uploadId' in value)) return false;
    if (!('initiatedBy' in value)) return false;
    if (!('employeeCount' in value)) return false;
    if (!('locationId' in value)) return false;
    if (!('status' in value)) return false;
    if (!('sendInviteEmails' in value)) return false;
    return true;
}

export function EmployeeImportFromJSON(json: any): EmployeeImport {
    return EmployeeImportFromJSONTyped(json, false);
}

export function EmployeeImportFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeImport {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'uploadId': json['uploadId'],
        'initiatedBy': json['initiatedBy'],
        'employeeCount': json['employeeCount'],
        'locationId': json['locationId'],
        'status': json['status'],
        'sendInviteEmails': json['sendInviteEmails'],
    };
}

export function EmployeeImportToJSON(value?: EmployeeImport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'uploadId': value['uploadId'],
        'initiatedBy': value['initiatedBy'],
        'employeeCount': value['employeeCount'],
        'locationId': value['locationId'],
        'status': value['status'],
        'sendInviteEmails': value['sendInviteEmails'],
    };
}

