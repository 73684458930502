import i18n from '@/i18n';
import { belongsToEmployee } from '@/lib/filters/employeeFilters';
import { realtimeQuery } from '@/lib/realtime/weak/realtimeFunctions';
import { EntityInterfaceMap } from '@/lib/realtime/weak/realtimeTypes';
import { WeakEntityRepositoryResponse } from '@/lib/realtime/weak/WeakEntityRepository';
import { isLocked, isPublished } from '@/lib/schedule-v2/shiftV2Status';
import { Entity } from '@/lib/store/realtimeEntities';
import { Coordinates } from '@/plugins/mapbox';
import { addDays } from '@/util/dateArithmetic';
import spacetime from 'spacetime';
import {
  Location,
  ScheduleSettingsClockInUnscheduledShiftsEnum,
  Shift,
  TimesheetBreak,
  TimesheetEntry,
  TimesheetEntryStatusEnum,
} from '../../../api/v1';

const haversine = require('haversine');

export const isWithinClockInThreshold = (
  earlyClockInThresholdInMinutes: number,
  shift: Shift | null,
  now: number = Date.now(),
): boolean => {
  if (!shift) {
    return false;
  }
  const earliestClockInMs =
    shift.startsAt.getTime() - earlyClockInThresholdInMinutes * 60 * 1000;
  return now > earliestClockInMs;
};

export const userIsWithinClockingDistance = (
  shiftLocation: Location,
  userCoordinates: Coordinates,
): boolean => {
  const distanceBetween = haversine(
    { latitude: shiftLocation.latitude, longitude: shiftLocation.longitude },
    {
      latitude: userCoordinates[1],
      longitude: userCoordinates[0],
    },
    { unit: 'meter' },
  );
  return distanceBetween < shiftLocation.clockInGeoFenceInMeters;
};

export const employeeCanClockUnscheduledShift = (
  employeeId: number,
  shifts: Shift[],
  timezone: string,
  clockInUnscheduledShifts: ScheduleSettingsClockInUnscheduledShiftsEnum,
  timesheetInProgress: TimesheetEntry | null,
): boolean => {
  const clockableShifts = shifts
    .filter(isPublished)
    .filter((s) => !isLocked(s))
    .filter(belongsToEmployee(employeeId))
    .filter((shift) =>
      spacetime(new Date(), timezone).isSame(
        spacetime(shift.startsAt, timezone),
        'day',
      ),
    );

  return (
    !timesheetInProgress &&
    (clockInUnscheduledShifts ===
      ScheduleSettingsClockInUnscheduledShiftsEnum.Always ||
      (!clockableShifts.length &&
        clockInUnscheduledShifts ===
          ScheduleSettingsClockInUnscheduledShiftsEnum.IfNoShiftsToday))
  );
};

export const getEarliestClockInTimeString = (
  earlyClockInThresholdInMinutes: number,
  shift: Shift | null,
): string => {
  if (!shift) {
    return '';
  }

  const earliestClockInMs =
    shift.startsAt.getTime() - earlyClockInThresholdInMinutes * 60 * 1000;

  return i18n.d(new Date(earliestClockInMs), 'hourMinute');
};

export const getTotalBreakSeconds = (
  timesheetBreaks: TimesheetBreak[],
  timezone: string,
): number =>
  timesheetBreaks.reduce((total, timesheetBreak) => {
    const start = spacetime(timesheetBreak.startedAt, timezone);
    let end = spacetime.now(timezone);

    if (timesheetBreak.endedAt) {
      end = spacetime(timesheetBreak.endedAt, timezone);
    }
    return total + start.diff(end).seconds;
  }, 0);

/**
 * Returns a RealtimeQuery response { data, isLoading, promise } for the shifts an employee is able to clock into
 * Bounded by ending after now and starts in the future before next week
 * @param employeeId The employee's id for which the shifts should be assigned
 * @param timezone The company's timezone
 * */
export const shiftsForClocking = (
  employeeId: number,
  timezone: string,
): WeakEntityRepositoryResponse<EntityInterfaceMap, typeof Entity.Shift> =>
  realtimeQuery(Entity.Shift)
    .where('employeeId', 'eq', employeeId)
    .where('startsAt', 'lt', addDays(new Date(), 7, timezone))
    .where('endsAt', 'gt', new Date())
    .whereNull('lockedAt')
    .whereNotNull('publishedAt')
    .fetch();

/**
 * Returns a RealtimeQuery response { data, isLoading, promise } for the timesheets an employee has in progress
 * @param employeeId The employee's id for which the shifts should be assigned
 * */
export const timesheetsInProgress = (
  employeeId: number,
): WeakEntityRepositoryResponse<
  EntityInterfaceMap,
  typeof Entity.TimesheetEntry
> =>
  realtimeQuery(Entity.TimesheetEntry)
    .where('employeeId', 'eq', employeeId)
    .whereIn('status', [
      TimesheetEntryStatusEnum.Break,
      TimesheetEntryStatusEnum.Started,
    ])
    .fetch();

export const shiftsWithinNext12Hours = (
  shifts: Shift[],
  now: Date,
  timezone: string,
): Shift[] => {
  const spacetimeNow = spacetime(now, timezone);
  return shifts.filter(
    (shift) => spacetimeNow.diff(shift.startsAt, 'hour') < 12,
  );
};

export const shiftsForPersonalDeviceClocking = (
  shifts: Shift[],
  locations: Location[],
): Shift[] =>
  shifts.filter(
    (s) =>
      locations.find((l) => l.id === s.locationId)
        ?.allowClockInFromPersonalDevices,
  );

export const timesheetsForPersonalDeviceClocking = (
  timesheets: TimesheetEntry[],
  locations: Location[],
): TimesheetEntry[] =>
  timesheets.filter(
    (t) =>
      locations.find((l) => l.id === t.locationId)
        ?.allowClockInFromPersonalDevices,
  );
