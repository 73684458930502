export const ChipStatuses = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
  pink: 'pink',
  purple: 'purple',
  gray: 'gray',
} as const;

type ChipStyleClass = {
  tonal: string;
  flat: string;
  outline: string;
  [key: string]: string;
};

export type StyleClassType = {
  [key in keyof typeof ChipStatuses]: ChipStyleClass;
};

export type ChipVariantTypes = 'tonal' | 'outline' | 'flat';
export type ChipDisplayTypes = 'inline-block' | 'inline-flex';
