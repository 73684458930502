/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveType } from './LeaveType';
import {
    LeaveTypeFromJSON,
    LeaveTypeFromJSONTyped,
    LeaveTypeToJSON,
} from './LeaveType';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListLeaveTypesResponse
 */
export interface ListLeaveTypesResponse {
    /**
     * 
     * @type {Array<LeaveType>}
     * @memberof ListLeaveTypesResponse
     */
    data: Array<LeaveType>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListLeaveTypesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListLeaveTypesResponse interface.
 */
export function instanceOfListLeaveTypesResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListLeaveTypesResponseFromJSON(json: any): ListLeaveTypesResponse {
    return ListLeaveTypesResponseFromJSONTyped(json, false);
}

export function ListLeaveTypesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListLeaveTypesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(LeaveTypeFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListLeaveTypesResponseToJSON(value?: ListLeaveTypesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(LeaveTypeToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

