import { computed, ComputedGetter } from 'vue';

export type ModalEmit = {
  (e: 'input', value: boolean): void;
  (e: 'close'): void;
};

export const useOpen = (getter: ComputedGetter<boolean>, emit: ModalEmit) =>
  computed({
    get: getter,
    set: (newValue) => {
      emit('input', newValue);
      if (!newValue) {
        emit('close');
      }
    },
  });
