
import { SimpleBubbleIconType } from '@/types/propTypes';
import { Icon } from '../../lib/enum/Icon';
import Button from '../buttons/Button.vue';
import BubbleIcon from '../interface/BubbleIcon.vue';
import Dialog from './Dialog.vue';

export default {
  name: 'SuccessDialog',

  components: {
    Button,
    BubbleIcon,
    Dialog,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    inviteList: {
      type: Number,
      default: null,
    },
    bubbleIcon: {
      type: Object as () => SimpleBubbleIconType,
      default: () => ({
        icon: Icon.CircleCheck,
        colour: 'green',
      }),
    },
    header: {
      type: String,
      required: true,
    },
    bodyText: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 450,
    },
    buttonText: {
      type: [String, null],
      default: null,
    },
  },

  computed: {
    buttonTextToShow(): string {
      return this.buttonText ?? this.$tc('button.continue');
    },
  },
};
