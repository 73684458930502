/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { ReportCostsByDay } from './ReportCostsByDay';
import {
    ReportCostsByDayFromJSON,
    ReportCostsByDayFromJSONTyped,
    ReportCostsByDayToJSON,
} from './ReportCostsByDay';
import type { ReportCostsByDayRollup } from './ReportCostsByDayRollup';
import {
    ReportCostsByDayRollupFromJSON,
    ReportCostsByDayRollupFromJSONTyped,
    ReportCostsByDayRollupToJSON,
} from './ReportCostsByDayRollup';

/**
 * 
 * @export
 * @interface ListShiftCostReportsByDayResponse
 */
export interface ListShiftCostReportsByDayResponse {
    /**
     * 
     * @type {Array<ReportCostsByDay>}
     * @memberof ListShiftCostReportsByDayResponse
     */
    data: Array<ReportCostsByDay>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftCostReportsByDayResponse
     */
    pagination: Pagination;
    /**
     * 
     * @type {ReportCostsByDayRollup}
     * @memberof ListShiftCostReportsByDayResponse
     */
    rollup: ReportCostsByDayRollup;
}

/**
 * Check if a given object implements the ListShiftCostReportsByDayResponse interface.
 */
export function instanceOfListShiftCostReportsByDayResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    if (!('rollup' in value)) return false;
    return true;
}

export function ListShiftCostReportsByDayResponseFromJSON(json: any): ListShiftCostReportsByDayResponse {
    return ListShiftCostReportsByDayResponseFromJSONTyped(json, false);
}

export function ListShiftCostReportsByDayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftCostReportsByDayResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ReportCostsByDayFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
        'rollup': ReportCostsByDayRollupFromJSON(json['rollup']),
    };
}

export function ListShiftCostReportsByDayResponseToJSON(value?: ListShiftCostReportsByDayResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(ReportCostsByDayToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
        'rollup': ReportCostsByDayRollupToJSON(value['rollup']),
    };
}

