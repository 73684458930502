<template>
  <div
    class="tooltip-icon all:inline-flex"
    :class="classes"
    @click="disabled ? null : $emit('click')"
  >
    <v-tooltip
      v-model="show"
      :color="tooltipColour"
      :top="position === 'top'"
      :bottom="position === 'bottom'"
      z-index="20000"
    >
      <template #activator="{ on }">
        <i
          :class="[icon, { 'w-full': !text }]"
          :style="{ color: iconColour }"
          v-on="on"
        />
        <span
          v-if="text"
          class="ml-2"
          v-on="on"
        >
          {{ text }}
        </span>
      </template>
      <span>{{ message }}</span>
    </v-tooltip>
    <slot name="stat" />
  </div>
</template>

<script>
import { Icon } from '@/lib/enum/Icon';

export default {
  name: 'ToolTipIcon',

  props: {
    icon: {
      type: String,
      default: Icon.CircleQuestion,
    },

    color: {
      type: String,
      default: '#1a90ff',
      example: ['light-grey'],
    },

    iconColour: {
      type: String,
      default: 'gray-800',
    },

    tooltipColour: {
      type: String,
      default: '#303030',
    },

    message: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      default: '',
    },

    position: {
      type: String,
      default: 'top',
      validator: (prop) => ['top', 'bottom'].includes(prop),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    // Used to unfix the height of the wrapper
    fluid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    classes() {
      const classes = [];

      if (this.disabled) {
        classes.push('disabled');
      }

      if (this.color === 'light-grey') {
        classes.push('bg-gray');
      }

      if (this.text) {
        classes.push('pr-2');
      }

      if (this.fluid) {
        classes.push('fluid');
      }

      classes.push(`bg-${this.color}`);
      return classes;
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip-icon {
  cursor: pointer;
  min-width: 40px;
  width: auto;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;

  &.disabled {
    background: $gray-200 !important;
    border-radius: 5px;

    &:hover {
      background: $gray-200 !important;

      i {
        background: $gray-200 !important;
        cursor: not-allowed;
        color: $gray-400 !important;
      }
    }

    i {
      border-radius: 5px;
      min-height: 35px;
      color: $gray-400 !important;
    }
  }

  &.fluid {
    height: auto !important;
  }

  &.horizontal {
    height: 45px !important;
    float: left;
  }

  &--disabledHover {
    &:hover {
      i {
        background: none !important;
      }
    }
  }

  &:hover {
    border-radius: 5px;
    color: $blue !important;
    cursor: pointer;
  }

  &.bg-gray {
    background: $gray-200;
    border-radius: 5px;
    height: 40px;
    font-weight: bold;
    vertical-align: middle;

    i {
      color: $gray-900 !important;
    }

    &:hover {
      i {
        background: $transparent;
        color: $gray-400;
      }
    }

    i {
      font-size: 1.2rem;
    }
  }

  &.bg-white {
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid $gray-100;
    font-weight: bold;
    height: 40px;

    &.notify-button {
      i {
        min-width: 55px;
      }
    }

    &.disabled:hover {
      border: 0 !important;
    }

    &:hover {
      border: 1px solid $blue;
    }
  }

  &.red-trash {
    @extend .bg-white;
    border: 1px solid $gray-300;

    i {
      color: $red !important;
    }

    &:hover {
      border: 1px solid $red;
    }
  }

  &.bg-transparent {
    @extend .bg-white;
    background: none;

    i {
      color: $blue !important;
      border: 0;
    }

    &:hover {
      i {
        border: 0;
      }
    }

    &.text-green {
      i {
        color: $green !important;
      }
    }

    &.text-yellow {
      i {
        color: $yellow !important;
      }
    }

    &.text-purple {
      i {
        color: $purple !important;
      }
    }

    i {
      background: #ffffff;
    }

    &:first-child {
      margin-right: 10px;
    }
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.1rem;
    border-radius: 5px;
  }
}

.notify-button {
  i {
    font-size: 1.6rem !important;
  }
}
</style>
