
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import { Icon } from '@/lib/enum/Icon';
import { PropType } from 'vue';

interface OptionInterface {
  label: string;
  icon?: Icon;
  value: string;
  [key: string]: unknown;
}

const activeColours = {
  white: 'white',
  purple: 'purple',
} as const;
type ActiveColourValue = typeof activeColours[keyof typeof activeColours];

export default {
  name: 'SwitchBetweenInput',
  components: { BubbleIcon },
  props: {
    options: {
      type: Array as PropType<OptionInterface[]>,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showActiveIcon: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String as PropType<ActiveColourValue>,
      default: activeColours.purple,
      validator: (p) => Object.values(activeColours).includes(p),
    },
  },
  data() {
    return {
      Icon,
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.value !== val) {
          this.$emit('toggled', val);
        }
      },
    },
  },
};
