/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUnavailabilityData
 */
export interface UpdateUnavailabilityData {
    /**
     * If the record is linked to a Recurrence Rule, changing this value will cause it to be unlinked.
     * @type {Date}
     * @memberof UpdateUnavailabilityData
     */
    startsAt?: Date;
    /**
     * If the record is linked to a Recurrence Rule, changing this value will cause it to be unlinked.
     * @type {Date}
     * @memberof UpdateUnavailabilityData
     */
    endsAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateUnavailabilityData
     */
    notes?: string;
}

/**
 * Check if a given object implements the UpdateUnavailabilityData interface.
 */
export function instanceOfUpdateUnavailabilityData(value: object): boolean {
    return true;
}

export function UpdateUnavailabilityDataFromJSON(json: any): UpdateUnavailabilityData {
    return UpdateUnavailabilityDataFromJSONTyped(json, false);
}

export function UpdateUnavailabilityDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUnavailabilityData {
    if (json == null) {
        return json;
    }
    return {
        
        'startsAt': json['startsAt'] == null ? undefined : (new Date(json['startsAt'])),
        'endsAt': json['endsAt'] == null ? undefined : (new Date(json['endsAt'])),
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

export function UpdateUnavailabilityDataToJSON(value?: UpdateUnavailabilityData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'startsAt': value['startsAt'] == null ? undefined : ((value['startsAt']).toISOString()),
        'endsAt': value['endsAt'] == null ? undefined : ((value['endsAt']).toISOString()),
        'notes': value['notes'],
    };
}

