/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateNotificationSettingsData
 */
export interface UpdateNotificationSettingsData {
    /**
     * 
     * @type {number}
     * @memberof UpdateNotificationSettingsData
     */
    managerClockingWarnWindow?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateNotificationSettingsData
     */
    employeeClockingWarnWindow?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationSettingsData
     */
    warnManagerForEmployeeOvertime?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateNotificationSettingsData
     */
    warnManagerForEmployeeOvertimeWindow?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationSettingsData
     */
    enableOvertimeWindow?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateNotificationSettingsData
     */
    shiftStartingReminderMinutes?: number;
}

/**
 * Check if a given object implements the UpdateNotificationSettingsData interface.
 */
export function instanceOfUpdateNotificationSettingsData(value: object): boolean {
    return true;
}

export function UpdateNotificationSettingsDataFromJSON(json: any): UpdateNotificationSettingsData {
    return UpdateNotificationSettingsDataFromJSONTyped(json, false);
}

export function UpdateNotificationSettingsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNotificationSettingsData {
    if (json == null) {
        return json;
    }
    return {
        
        'managerClockingWarnWindow': json['managerClockingWarnWindow'] == null ? undefined : json['managerClockingWarnWindow'],
        'employeeClockingWarnWindow': json['employeeClockingWarnWindow'] == null ? undefined : json['employeeClockingWarnWindow'],
        'warnManagerForEmployeeOvertime': json['warnManagerForEmployeeOvertime'] == null ? undefined : json['warnManagerForEmployeeOvertime'],
        'warnManagerForEmployeeOvertimeWindow': json['warnManagerForEmployeeOvertimeWindow'] == null ? undefined : json['warnManagerForEmployeeOvertimeWindow'],
        'enableOvertimeWindow': json['enableOvertimeWindow'] == null ? undefined : json['enableOvertimeWindow'],
        'shiftStartingReminderMinutes': json['shiftStartingReminderMinutes'] == null ? undefined : json['shiftStartingReminderMinutes'],
    };
}

export function UpdateNotificationSettingsDataToJSON(value?: UpdateNotificationSettingsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'managerClockingWarnWindow': value['managerClockingWarnWindow'],
        'employeeClockingWarnWindow': value['employeeClockingWarnWindow'],
        'warnManagerForEmployeeOvertime': value['warnManagerForEmployeeOvertime'],
        'warnManagerForEmployeeOvertimeWindow': value['warnManagerForEmployeeOvertimeWindow'],
        'enableOvertimeWindow': value['enableOvertimeWindow'],
        'shiftStartingReminderMinutes': value['shiftStartingReminderMinutes'],
    };
}

