
import { Icon, IconType } from '@/lib/enum/Icon';
import { PropType } from 'vue';
import ActionDialog from './ActionDialog.vue';

export default {
  name: 'ConfirmActionDialog',

  components: {
    ActionDialog,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: '',
    },
    icon: {
      type: String as PropType<IconType>,
      default: Icon.TriangleExclamation,
    },
  },

  emits: ['confirm', 'cancel', 'input'],

  methods: {
    cancel() {
      this.$emit('cancel');
      this.$emit('input', false);
    },
    confirm() {
      this.$emit('confirm');
      this.$emit('input', false);
    },
  },
};
