/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDigiticketsConnectionData
 */
export interface UpdateDigiticketsConnectionData {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDigiticketsConnectionData
     */
    isDefault?: boolean;
}

/**
 * Check if a given object implements the UpdateDigiticketsConnectionData interface.
 */
export function instanceOfUpdateDigiticketsConnectionData(value: object): boolean {
    return true;
}

export function UpdateDigiticketsConnectionDataFromJSON(json: any): UpdateDigiticketsConnectionData {
    return UpdateDigiticketsConnectionDataFromJSONTyped(json, false);
}

export function UpdateDigiticketsConnectionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDigiticketsConnectionData {
    if (json == null) {
        return json;
    }
    return {
        
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
    };
}

export function UpdateDigiticketsConnectionDataToJSON(value?: UpdateDigiticketsConnectionData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDefault': value['isDefault'],
    };
}

