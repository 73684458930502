<template>
  <div>
    <div
      class="percentage-holder"
      :style="`--height: ${height}`"
    >
      <div
        class="percentage-fill"
        :class="[colour, { animated: animated }]"
        :style="`width: ${getFilled}%`"
      />
    </div>
    <div
      v-if="!hideNumbers"
      class="mt-2 flex justify-between"
    >
      <p
        v-for="percent in percentages"
        :key="percent"
      >
        {{ percent }}
      </p>
    </div>
  </div>
</template>

<script>
const fillColour = { PinkGradient: 'PinkGradient', Green: 'Green', Red: 'Red' };

export default {
  name: 'PercentageBar',

  props: {
    // Provides the minimum and maximum numbers of the bar
    range: {
      type: Object,
      default: () => ({ min: 0, max: 10 }),
    },
    // Provides the number that the bar is filled up to
    filled: {
      type: Number,
      default: 0,
    },

    // Fill colour
    colour: {
      type: String,
      default: fillColour.PinkGradient,
      validator: (p) => Object.values(fillColour).includes(p),
    },

    hideNumbers: {
      type: Boolean,
      default: false,
    },

    height: {
      type: String,
      default: '30px',
    },

    animated: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    percentages() {
      const percentages = [];
      for (let i = this.range.min; i <= this.range.max; i += this.increments)
        percentages.push(Math.round(i));
      if (percentages[percentages.length - 1] !== this.range.max)
        percentages.push(this.range.max);
      return percentages;
    },

    increments() {
      let increment = 10;
      if (this.range.max - this.range.min < 11) {
        increment = this.range.max - this.range.min;
      } else {
        for (let i = 0; i < 13; i += 1) {
          if (this.range.max / i === 0) {
            increment = i;
          }
        }
      }
      return (this.range.max - this.range.min) / increment;
    },

    getFilled() {
      if (this.filled >= this.range.max) return 100;
      return (100 * this.filled) / this.range.max;
    },
  },
};
</script>

<style scoped lang="scss">
.percentage-holder {
  height: var(--height);
  border-radius: 20px;
  background-color: $gray-300;
  overflow: hidden;

  .percentage-fill {
    height: 100%;
    border-radius: inherit;

    &.PinkGradient {
      @include bgGradientLR;
    }
    &.Green {
      background-color: $green;
    }
    &.Red {
      background-color: $red;
    }
  }
}

p {
  color: $gray-400;
}

.percentage-fill.animated {
  &.PinkGradient {
    @include bgGradientLR;
    background-size: 300% 300%;
    -webkit-animation: shiftie-grad 5s ease infinite;
    -moz-animation: shiftie-grad 5s ease infinite;
    animation: shiftie-grad 5s ease infinite;
  }
  &.Green {
    background: $green
      linear-gradient(
        90deg,
        $green,
        darken($green, 20),
        $green,
        darken($green, 20)
      );
    background-size: 300% 300%;
    -webkit-animation: shiftie-grad 5s ease infinite;
    -moz-animation: shiftie-grad 5s ease infinite;
    animation: shiftie-grad 5s ease infinite;
  }
  &.Red {
    background: $red
      linear-gradient(90deg, $red, darken($red, 5), $red, darken($red, 5));
    background-size: 300% 300%;
    -webkit-animation: shiftie-grad 5s ease infinite;
    -moz-animation: shiftie-grad 5s ease infinite;
    animation: shiftie-grad 5s ease infinite;
  }
}

@-webkit-keyframes shiftie-grad {
  0% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@-moz-keyframes shiftie-grad {
  0% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes shiftie-grad {
  0% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}
</style>
