/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigiticketsConnection } from './DigiticketsConnection';
import {
    DigiticketsConnectionFromJSON,
    DigiticketsConnectionFromJSONTyped,
    DigiticketsConnectionToJSON,
} from './DigiticketsConnection';

/**
 * 
 * @export
 * @interface CreateDigiticketsConnectionResponse
 */
export interface CreateDigiticketsConnectionResponse {
    /**
     * 
     * @type {DigiticketsConnection}
     * @memberof CreateDigiticketsConnectionResponse
     */
    data: DigiticketsConnection;
}

/**
 * Check if a given object implements the CreateDigiticketsConnectionResponse interface.
 */
export function instanceOfCreateDigiticketsConnectionResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateDigiticketsConnectionResponseFromJSON(json: any): CreateDigiticketsConnectionResponse {
    return CreateDigiticketsConnectionResponseFromJSONTyped(json, false);
}

export function CreateDigiticketsConnectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDigiticketsConnectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': DigiticketsConnectionFromJSON(json['data']),
    };
}

export function CreateDigiticketsConnectionResponseToJSON(value?: CreateDigiticketsConnectionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': DigiticketsConnectionToJSON(value['data']),
    };
}

