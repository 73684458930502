/**
 * This is basically just a place where we can keep an eye on what's happening with realtime entity subscriptions.
 * It doesn't actually do anything, but it gives us a live view of our realtime repository via the Vuex dev tools panel.
 */
export default {
  namespaced: true,

  state: {
    subscriptions: {},
  },

  mutations: {
    SET_SUBSCRIPTIONS(state, cache) {
      state.subscriptions = cache;
    },
  },
};
