<template>
  <div v-if="loading">
    <v-overlay
      absolute
      color="#ffffff"
      opacity="0.7"
      :z-index="zIndex"
    >
      <div>
        <LoadingIcon
          :color="colour"
          :progress="progress"
        />
      </div>
    </v-overlay>
  </div>
</template>

<script>
import LoadingIcon from '@/components/loaders/LoadingIcon';

export default {
  name: 'LoadingOverlay',

  components: {
    LoadingIcon,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    colour: {
      type: String,
      default: '#ec3f8c',
    },

    progress: {
      type: Number,
      default: null,
    },

    zIndex: {
      type: Number,
      default: 1000,
    },
  },
};
</script>
