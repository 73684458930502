/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateEmployeeGroupData,
  CreateEmployeeGroupResponse,
  ListEmployeeGroupsResponse,
  ShowEmployeeGroupResponse,
  UpdateEmployeeGroupData,
  UpdateEmployeeGroupResponse,
} from '../models/index';
import {
    CreateEmployeeGroupDataFromJSON,
    CreateEmployeeGroupDataToJSON,
    CreateEmployeeGroupResponseFromJSON,
    CreateEmployeeGroupResponseToJSON,
    ListEmployeeGroupsResponseFromJSON,
    ListEmployeeGroupsResponseToJSON,
    ShowEmployeeGroupResponseFromJSON,
    ShowEmployeeGroupResponseToJSON,
    UpdateEmployeeGroupDataFromJSON,
    UpdateEmployeeGroupDataToJSON,
    UpdateEmployeeGroupResponseFromJSON,
    UpdateEmployeeGroupResponseToJSON,
} from '../models/index';

export interface CreateEmployeeGroupRequest {
    createEmployeeGroupData?: CreateEmployeeGroupData;
}

export interface DeleteEmployeeGroupRequest {
    id: number;
}

export interface ListEmployeeGroupsRequest {
    orderBy?: Array<string>;
}

export interface ShowEmployeeGroupRequest {
    id: number;
}

export interface UpdateEmployeeGroupRequest {
    id: number;
    updateEmployeeGroupData?: UpdateEmployeeGroupData;
}

/**
 * 
 */
export class EmployeeGroupApi extends runtime.BaseAPI {

    /**
     * Create a new employee group
     * Create Employee Group
     */
    async createEmployeeGroupRaw(requestParameters: CreateEmployeeGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEmployeeGroupResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-group.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmployeeGroupDataToJSON(requestParameters['createEmployeeGroupData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEmployeeGroupResponseFromJSON(jsonValue));
    }

    /**
     * Create a new employee group
     * Create Employee Group
     */
    async createEmployeeGroup(requestParameters: CreateEmployeeGroupRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEmployeeGroupResponse> {
        const response = await this.createEmployeeGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a given employee group
     * Delete Employee Group
     */
    async deleteEmployeeGroupRaw(requestParameters: DeleteEmployeeGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEmployeeGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-group.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a given employee group
     * Delete Employee Group
     */
    async deleteEmployeeGroup(requestParameters: DeleteEmployeeGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmployeeGroupRaw(requestParameters, initOverrides);
    }

    /**
     * List employee groups
     * List Employee Groups
     */
    async listEmployeeGroupsRaw(requestParameters: ListEmployeeGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeGroupsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-group.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeGroupsResponseFromJSON(jsonValue));
    }

    /**
     * List employee groups
     * List Employee Groups
     */
    async listEmployeeGroups(requestParameters: ListEmployeeGroupsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeGroupsResponse> {
        const response = await this.listEmployeeGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a given employee group
     * Show Employee Group
     */
    async showEmployeeGroupRaw(requestParameters: ShowEmployeeGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeeGroupResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmployeeGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-group.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeeGroupResponseFromJSON(jsonValue));
    }

    /**
     * Show a given employee group
     * Show Employee Group
     */
    async showEmployeeGroup(requestParameters: ShowEmployeeGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeeGroupResponse> {
        const response = await this.showEmployeeGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a given employee group
     * Update Employee Group
     */
    async updateEmployeeGroupRaw(requestParameters: UpdateEmployeeGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEmployeeGroupResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEmployeeGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-group.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeeGroupDataToJSON(requestParameters['updateEmployeeGroupData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEmployeeGroupResponseFromJSON(jsonValue));
    }

    /**
     * Update a given employee group
     * Update Employee Group
     */
    async updateEmployeeGroup(requestParameters: UpdateEmployeeGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEmployeeGroupResponse> {
        const response = await this.updateEmployeeGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
