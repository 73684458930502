
import i18n from '@/i18n';
import { Icon } from '@/lib/enum/Icon';
import { viewport } from '@/util/windowFunctions';
import { PropType } from 'vue';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'TooltipIconClick',

  directives: {
    ClickOutside,
  },

  props: {
    // Icon from the icon enum
    icon: {
      type: String,
      default: Icon.CircleQuestion,
      validator: (p) => Object.values(Icon).includes(p),
    },

    // Hexadecimal
    iconColour: {
      type: String,
      default: '#BAB9BE',
    },

    // String from en.json - can be a slot
    message: {
      type: String,
      default: '',
    },

    // String from en.json
    title: {
      type: String,
      default: i18n.t('tooltip.whyAmISeeingThis'),
    },

    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium',
    },

    // v-menu api
    top: {
      type: Boolean,
      default: false,
    },

    bottom: {
      type: Boolean,
      default: false,
    },

    left: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },

    offsetX: {
      type: Boolean,
      default: false,
    },

    offsetY: {
      type: Boolean,
      default: false,
    },

    zIndex: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      Icon,
      viewport,
      show: false,
    };
  },

  methods: {
    close() {
      this.show = false;
    },
  },
};
