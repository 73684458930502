/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeOnboarding } from './EmployeeOnboarding';
import {
    EmployeeOnboardingFromJSON,
    EmployeeOnboardingFromJSONTyped,
    EmployeeOnboardingToJSON,
} from './EmployeeOnboarding';

/**
 * 
 * @export
 * @interface ShowEmployeeOnboardingResponse
 */
export interface ShowEmployeeOnboardingResponse {
    /**
     * 
     * @type {EmployeeOnboarding}
     * @memberof ShowEmployeeOnboardingResponse
     */
    data: EmployeeOnboarding;
}

/**
 * Check if a given object implements the ShowEmployeeOnboardingResponse interface.
 */
export function instanceOfShowEmployeeOnboardingResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowEmployeeOnboardingResponseFromJSON(json: any): ShowEmployeeOnboardingResponse {
    return ShowEmployeeOnboardingResponseFromJSONTyped(json, false);
}

export function ShowEmployeeOnboardingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeOnboardingResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeOnboardingFromJSON(json['data']),
    };
}

export function ShowEmployeeOnboardingResponseToJSON(value?: ShowEmployeeOnboardingResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeOnboardingToJSON(value['data']),
    };
}

