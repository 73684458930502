/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';

/**
 * 
 * @export
 * @interface UpdateCompanyResponse
 */
export interface UpdateCompanyResponse {
    /**
     * 
     * @type {Company}
     * @memberof UpdateCompanyResponse
     */
    data?: Company;
}

/**
 * Check if a given object implements the UpdateCompanyResponse interface.
 */
export function instanceOfUpdateCompanyResponse(value: object): boolean {
    return true;
}

export function UpdateCompanyResponseFromJSON(json: any): UpdateCompanyResponse {
    return UpdateCompanyResponseFromJSONTyped(json, false);
}

export function UpdateCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : CompanyFromJSON(json['data']),
    };
}

export function UpdateCompanyResponseToJSON(value?: UpdateCompanyResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': CompanyToJSON(value['data']),
    };
}

