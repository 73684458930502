/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';
import type { HistoryChangesInner } from './HistoryChangesInner';
import {
    HistoryChangesInnerFromJSON,
    HistoryChangesInnerFromJSONTyped,
    HistoryChangesInnerToJSON,
} from './HistoryChangesInner';

/**
 * Represents a single edit for a model
 * @export
 * @interface History
 */
export interface History {
    /**
     * 
     * @type {number}
     * @memberof History
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof History
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof History
     */
    ref: string;
    /**
     * 
     * @type {string}
     * @memberof History
     */
    tableName: string;
    /**
     * The id of the related model. Represented as a string to support both integer and UUID values.
     * @type {string}
     * @memberof History
     */
    foreignId: string;
    /**
     * The id of the related models parent, applicable if the related model has a closely related parent. Represented as a string to support both integer and UUID values.<br/><small>Example: For a scheduled break, this would be the id of the related shift.</small>
     * @type {string}
     * @memberof History
     */
    parentForeignId: string;
    /**
     * 
     * @type {number}
     * @memberof History
     */
    changedById: number | null;
    /**
     * 
     * @type {string}
     * @memberof History
     */
    action: HistoryActionEnum;
    /**
     * 
     * @type {Array<HistoryChangesInner>}
     * @memberof History
     */
    changes: Array<HistoryChangesInner>;
    /**
     * 
     * @type {Date}
     * @memberof History
     */
    changedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof History
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof History
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {Employee}
     * @memberof History
     */
    changedBy?: Employee;
}

/**
* @export
* @enum {string}
*/
export enum HistoryActionEnum {
    Created = 'Created',
    Deleted = 'Deleted',
    Updated = 'Updated'
}


/**
 * Check if a given object implements the History interface.
 */
export function instanceOfHistory(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('ref' in value)) return false;
    if (!('tableName' in value)) return false;
    if (!('foreignId' in value)) return false;
    if (!('parentForeignId' in value)) return false;
    if (!('changedById' in value)) return false;
    if (!('action' in value)) return false;
    if (!('changes' in value)) return false;
    if (!('changedAt' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function HistoryFromJSON(json: any): History {
    return HistoryFromJSONTyped(json, false);
}

export function HistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): History {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'ref': json['ref'],
        'tableName': json['tableName'],
        'foreignId': json['foreignId'],
        'parentForeignId': json['parentForeignId'],
        'changedById': json['changedById'],
        'action': json['action'],
        'changes': ((json['changes'] as Array<any>).map(HistoryChangesInnerFromJSON)),
        'changedAt': (new Date(json['changedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'changedBy': json['changedBy'] == null ? undefined : EmployeeFromJSON(json['changedBy']),
    };
}

export function HistoryToJSON(value?: History | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'ref': value['ref'],
        'tableName': value['tableName'],
        'foreignId': value['foreignId'],
        'parentForeignId': value['parentForeignId'],
        'changedById': value['changedById'],
        'action': value['action'],
        'changes': ((value['changes'] as Array<any>).map(HistoryChangesInnerToJSON)),
        'changedAt': ((value['changedAt']).toISOString()),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'changedBy': EmployeeToJSON(value['changedBy']),
    };
}

