import i18n from '@/i18n';
import { Icon } from '@/lib/enum/Icon';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
  type: 'show',
  containerClass: 'toast-container',
  iconPack: 'custom-class',
  duration: 6000,
  position: 'top-right',
  keepOnHover: true,
  action: {
    icon: Icon.Xmark,
    onClick: (e, toast) => toast.goAway(0),
  },
});

export enum ToastTypes {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

interface ToastMessage {
  message: string;
  type: string;
  titleOverride: VueI18n.TranslateResult;
}

// Custom layout with message

const messageLayout = (messageOptions: ToastMessage): string => {
  const { message, type, titleOverride } = messageOptions;
  const titleString = titleOverride || i18n.tc(`toastNotification.${type}`);

  return `
      <div class="flex flex-column">
        <h4>${titleString}</h4>
        <p>${message}</p>
      </div>
    `;
};

// Register our custom toast notifications

Vue.toasted.register('shiftieError', messageLayout, {
  className: 'toast toast--error',
  icon: Icon.TriangleExclamation,
  duration: 7000,
  position: 'top-center',
});

Vue.toasted.register('shiftieInfo', messageLayout, {
  className: 'toast toast--info',
  icon: Icon.CircleInfo,
  duration: 5000,
});

Vue.toasted.register('shiftieSuccess', messageLayout, {
  className: 'toast toast--success',
  icon: Icon.Check,
  duration: 4000,
});

Vue.toasted.register('shiftieWarning', messageLayout, {
  className: 'toast toast--warning',
  icon: Icon.Exclamation,
  duration: 6000,
  position: 'top-center',
});

// Export our custom toast notifications

/**
 * Title can be used to override the default path
 * @param message {string}
 * @param titleOverride {VueI18n.TranslateResult}
 */
export const showError = (
  message: VueI18n.TranslateResult,
  titleOverride: VueI18n.TranslateResult = '',
) =>
  Vue.toasted.global.shiftieError({
    message,
    titleOverride,
    type: ToastTypes.Error,
  });

/**
 * Title can be used to override the default path
 * @param message {string}
 * @param titleOverride {VueI18n.TranslateResult}
 */
export const showInfo = (
  message: VueI18n.TranslateResult,
  titleOverride: VueI18n.TranslateResult = '',
) =>
  Vue.toasted.global.shiftieInfo({
    message,
    titleOverride,
    type: ToastTypes.Info,
  });

/**
 * Title can be used to override the default path
 * @param message {string}
 * @param titleOverride {VueI18n.TranslateResult}
 */
export const showSuccess = (
  message: VueI18n.TranslateResult,
  titleOverride: VueI18n.TranslateResult = '',
) =>
  Vue.toasted.global.shiftieSuccess({
    message,
    titleOverride,
    type: ToastTypes.Success,
  });

/**
 * Title can be used to override the default path
 * @param message {string}
 * @param titleOverride {VueI18n.TranslateResult}
 */
export const showWarning = (
  message: VueI18n.TranslateResult,
  titleOverride: VueI18n.TranslateResult = '',
) =>
  Vue.toasted.global.shiftieWarning({
    message,
    titleOverride,
    type: ToastTypes.Warning,
  });
