/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * 
 * @export
 * @interface ListTagsResponse
 */
export interface ListTagsResponse {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ListTagsResponse
     */
    data: Array<Tag>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListTagsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListTagsResponse interface.
 */
export function instanceOfListTagsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListTagsResponseFromJSON(json: any): ListTagsResponse {
    return ListTagsResponseFromJSONTyped(json, false);
}

export function ListTagsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTagsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(TagFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListTagsResponseToJSON(value?: ListTagsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(TagToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

