/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeavePolicy } from './LeavePolicy';
import {
    LeavePolicyFromJSON,
    LeavePolicyFromJSONTyped,
    LeavePolicyToJSON,
} from './LeavePolicy';

/**
 * 
 * @export
 * @interface UpdateLeavePolicyResponse
 */
export interface UpdateLeavePolicyResponse {
    /**
     * 
     * @type {LeavePolicy}
     * @memberof UpdateLeavePolicyResponse
     */
    data: LeavePolicy;
}

/**
 * Check if a given object implements the UpdateLeavePolicyResponse interface.
 */
export function instanceOfUpdateLeavePolicyResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateLeavePolicyResponseFromJSON(json: any): UpdateLeavePolicyResponse {
    return UpdateLeavePolicyResponseFromJSONTyped(json, false);
}

export function UpdateLeavePolicyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeavePolicyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeavePolicyFromJSON(json['data']),
    };
}

export function UpdateLeavePolicyResponseToJSON(value?: UpdateLeavePolicyResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeavePolicyToJSON(value['data']),
    };
}

