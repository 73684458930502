/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCreditRenewalSettingsData
 */
export interface UpdateCreditRenewalSettingsData {
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditRenewalSettingsData
     */
    type: UpdateCreditRenewalSettingsDataTypeEnum;
    /**
     * How much the credits should be topped up by on each renewal.
     * @type {number}
     * @memberof UpdateCreditRenewalSettingsData
     */
    renewalValue?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCreditRenewalSettingsData
     */
    lowBalanceWarningThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCreditRenewalSettingsData
     */
    triggerRenewalThreshold?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCreditRenewalSettingsData
     */
    renewalsEnabled?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UpdateCreditRenewalSettingsDataTypeEnum {
    Referral = 'Referral',
    Sms = 'SMS'
}


/**
 * Check if a given object implements the UpdateCreditRenewalSettingsData interface.
 */
export function instanceOfUpdateCreditRenewalSettingsData(value: object): boolean {
    if (!('type' in value)) return false;
    return true;
}

export function UpdateCreditRenewalSettingsDataFromJSON(json: any): UpdateCreditRenewalSettingsData {
    return UpdateCreditRenewalSettingsDataFromJSONTyped(json, false);
}

export function UpdateCreditRenewalSettingsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCreditRenewalSettingsData {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'renewalValue': json['renewalValue'] == null ? undefined : json['renewalValue'],
        'lowBalanceWarningThreshold': json['lowBalanceWarningThreshold'] == null ? undefined : json['lowBalanceWarningThreshold'],
        'triggerRenewalThreshold': json['triggerRenewalThreshold'] == null ? undefined : json['triggerRenewalThreshold'],
        'renewalsEnabled': json['renewalsEnabled'] == null ? undefined : json['renewalsEnabled'],
    };
}

export function UpdateCreditRenewalSettingsDataToJSON(value?: UpdateCreditRenewalSettingsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'renewalValue': value['renewalValue'],
        'lowBalanceWarningThreshold': value['lowBalanceWarningThreshold'],
        'triggerRenewalThreshold': value['triggerRenewalThreshold'],
        'renewalsEnabled': value['renewalsEnabled'],
    };
}

