/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftSwap } from './ShiftSwap';
import {
    ShiftSwapFromJSON,
    ShiftSwapFromJSONTyped,
    ShiftSwapToJSON,
} from './ShiftSwap';

/**
 * 
 * @export
 * @interface CreateShiftSwapResponse
 */
export interface CreateShiftSwapResponse {
    /**
     * 
     * @type {ShiftSwap}
     * @memberof CreateShiftSwapResponse
     */
    data?: ShiftSwap;
}

/**
 * Check if a given object implements the CreateShiftSwapResponse interface.
 */
export function instanceOfCreateShiftSwapResponse(value: object): boolean {
    return true;
}

export function CreateShiftSwapResponseFromJSON(json: any): CreateShiftSwapResponse {
    return CreateShiftSwapResponseFromJSONTyped(json, false);
}

export function CreateShiftSwapResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftSwapResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftSwapFromJSON(json['data']),
    };
}

export function CreateShiftSwapResponseToJSON(value?: CreateShiftSwapResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftSwapToJSON(value['data']),
    };
}

