
import Button from '@/components/buttons/Button.vue';
import TooltipIconClick from '@/components/info/TooltipIconClick.vue';
import { fullName } from '@/lib/employee/employeeFunctions';
import { AppWarningEnum } from '@/lib/enum/AppWarningEnum';
import { Icon } from '@/lib/enum/Icon';
import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import { keyOfFirstTruthyValue } from '@/util/objectFunctions';

export default {
  name: 'TopbarWarning',

  components: {
    Button,
    TooltipIconClick,
  },

  computed: {
    loggedInEmployee: () => store.getters.loggedInEmployee,
    currentCompany: () => store.getters.currentCompany,

    warnings: () => store.state.appWarnings.warning,
    isImpersonationSession: () => store.state.isImpersonationSession,

    showWarning(): boolean {
      return !!this.warnings.length || this.isImpersonationSession;
    },

    warning(): AppWarningEnum | null {
      if (!this.warnings) {
        return null;
      }

      return (
        keyOfFirstTruthyValue({
          [AppWarningEnum.Refresh]: this.warnings.includes(
            AppWarningEnum.Refresh,
          ),
          [AppWarningEnum.WebsocketDisconnected]: this.warnings.includes(
            AppWarningEnum.WebsocketDisconnected,
          ),
          [AppWarningEnum.WebsocketReconnecting]: this.warnings.includes(
            AppWarningEnum.WebsocketReconnecting,
          ),
          [AppWarningEnum.Unverified]: this.warnings.includes(
            AppWarningEnum.Unverified,
          ),
        }) || null
      );
    },

    icon(): string {
      if (this.isImpersonationSession) {
        return Icon.User;
      }
      if (this.warning === AppWarningEnum.WebsocketReconnecting) {
        return Icon.Loading;
      }
      if (
        [AppWarningEnum.Refresh, AppWarningEnum.WebsocketDisconnected].includes(
          this.warning,
        )
      ) {
        return Icon.ArrowsRotate;
      }
      if (this.warning === AppWarningEnum.Unverified) {
        return Icon.Envelope;
      }
      return '';
    },

    title(): string {
      if (this.warning === AppWarningEnum.Refresh) {
        return this.$t('warningMessage.refreshShiftie').toString();
      }
      if (this.warning === AppWarningEnum.WebsocketReconnecting) {
        return this.$t('warningMessage.reconnectingWebsockets').toString();
      }
      if (this.warning === AppWarningEnum.WebsocketDisconnected) {
        return this.$t('warningMessage.reloadShiftie').toString();
      }
      if (this.warning === AppWarningEnum.Unverified) {
        return this.$t('warningMessage.emailUnverified').toString();
      }
      return '';
    },

    tooltip(): string {
      if (this.warning === AppWarningEnum.Refresh) {
        return this.$t('tooltip.refreshShiftie').toString();
      }
      if (this.warning === AppWarningEnum.WebsocketReconnecting) {
        return this.$t('tooltip.reconnectingShiftieWebsockets').toString();
      }
      if (this.warning === AppWarningEnum.WebsocketDisconnected) {
        return this.$t('tooltip.refreshShiftieWebsocket').toString();
      }
      if (this.warning === AppWarningEnum.Unverified) {
        return this.$t('info.verifyEmailSub').toString();
      }
      return '';
    },

    clickable(): boolean {
      return (
        !this.isImpersonationSession &&
        this.warning !== AppWarningEnum.Unverified
      );
    },
  },

  methods: {
    fullName,

    refresh() {
      window.location.reload();
    },
    logout() {
      redirect(routes.logout.route());
    },
  },
};
