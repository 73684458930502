/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AbsenceCategory } from './AbsenceCategory';
import {
    AbsenceCategoryFromJSON,
    AbsenceCategoryFromJSONTyped,
    AbsenceCategoryToJSON,
} from './AbsenceCategory';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface Absence
 */
export interface Absence {
    /**
     * 
     * @type {number}
     * @memberof Absence
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Absence
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof Absence
     */
    reporterId: number | null;
    /**
     * 
     * @type {number}
     * @memberof Absence
     */
    absenceCategoryId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof Absence
     */
    startedOn: ShiftiePlainDate;
    /**
     * The last day of absence (inclusive). Treated as the company's timezone, *not UTC!*.
     * @type {ShiftiePlainDate}
     * @memberof Absence
     */
    endedOn: ShiftiePlainDate | null;
    /**
     * 
     * @type {boolean}
     * @memberof Absence
     */
    startedMidday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Absence
     */
    endedMidday: boolean;
    /**
     * Working time lost due to absence
     * @type {number}
     * @memberof Absence
     */
    lost: number;
    /**
     * Whether the employee will be paid for this period of absence
     * @type {boolean}
     * @memberof Absence
     */
    paid: boolean;
    /**
     * 
     * @type {string}
     * @memberof Absence
     */
    notes: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Absence
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Absence
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {Employee}
     * @memberof Absence
     */
    employee?: Employee;
    /**
     * 
     * @type {AbsenceCategory}
     * @memberof Absence
     */
    absenceCategory?: AbsenceCategory;
}

/**
 * Check if a given object implements the Absence interface.
 */
export function instanceOfAbsence(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('reporterId' in value)) return false;
    if (!('absenceCategoryId' in value)) return false;
    if (!('startedOn' in value)) return false;
    if (!('endedOn' in value)) return false;
    if (!('startedMidday' in value)) return false;
    if (!('endedMidday' in value)) return false;
    if (!('lost' in value)) return false;
    if (!('paid' in value)) return false;
    if (!('notes' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function AbsenceFromJSON(json: any): Absence {
    return AbsenceFromJSONTyped(json, false);
}

export function AbsenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Absence {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'employeeId': json['employeeId'],
        'reporterId': json['reporterId'],
        'absenceCategoryId': json['absenceCategoryId'],
        'startedOn': ShiftiePlainDateFromJSON(json['startedOn']),
        'endedOn': ShiftiePlainDateFromJSON(json['endedOn']),
        'startedMidday': json['startedMidday'],
        'endedMidday': json['endedMidday'],
        'lost': json['lost'],
        'paid': json['paid'],
        'notes': json['notes'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'employee': json['employee'] == null ? undefined : EmployeeFromJSON(json['employee']),
        'absenceCategory': json['absenceCategory'] == null ? undefined : AbsenceCategoryFromJSON(json['absenceCategory']),
    };
}

export function AbsenceToJSON(value?: Absence | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'employeeId': value['employeeId'],
        'reporterId': value['reporterId'],
        'absenceCategoryId': value['absenceCategoryId'],
        'startedOn': ShiftiePlainDateToJSON(value['startedOn']),
        'endedOn': ShiftiePlainDateToJSON(value['endedOn']),
        'startedMidday': value['startedMidday'],
        'endedMidday': value['endedMidday'],
        'lost': value['lost'],
        'paid': value['paid'],
        'notes': value['notes'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'employee': EmployeeToJSON(value['employee']),
        'absenceCategory': AbsenceCategoryToJSON(value['absenceCategory']),
    };
}

