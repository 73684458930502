export enum dateTimeFormats {
  day = 'day',
  dayMonth = 'dayMonth',
  dayMonthLong = 'dayMonthLong',
  dayMonthYear = 'dayMonthYear',
  dayMonthYearHourMinute = 'dayMonthYearHourMinute',
  dayMonthLongYear = 'dayMonthLongYear',
  dayMonthLongYearHourMinute = 'dayMonthLongYearHourMinute',
  weekday = 'weekday',
  weekdayShort = 'weekdayShort',
  weekdayDay = 'weekdayDay',
  weekdayLongDay = 'weekdayLongDay',
  weekdayDayMonth = 'weekdayDayMonth',
  weekdayLongDayMonthLong = 'weekdayLongDayMonthLong',
  weekdayLongDayMonthLongYear = 'weekdayLongDayMonthLongYear',
  weekdayDayYear = 'weekdayDayYear',
  weekdayDayMonthYear = 'weekdayDayMonthYear',
  weekdayDayMonthLongYear = 'weekdayDayMonthLongYear',
  weekdayShortMonthShortDayYear = 'weekdayShortMonthShortDayYear',
  monthDayYear = 'monthDayYear',
  monthLong = 'monthLong',
  monthShort = 'monthShort',
  monthYear = 'monthYear',
  monthShortYear = 'monthShortYear',
  hour = 'hour',
  hourMinute = 'hourMinute',
  hourMinuteSecond = 'hourMinuteSecond',
  year = 'year',
}

const formats = {
  [dateTimeFormats.day]: {
    // '13
    day: 'numeric',
  },
  [dateTimeFormats.dayMonth]: {
    // Should be '13 Mar' but failing pipeline tests. Switched to 'Mar 13'
    day: 'numeric',
    month: 'short',
  },
  [dateTimeFormats.dayMonthLong]: {
    // '30 June'
    day: 'numeric',
    month: 'long',
  },
  [dateTimeFormats.dayMonthYear]: {
    // 'Should be '13 Mar 2020' but failing pipeline tests. Switched to 'Mar 13 2020'
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  [dateTimeFormats.dayMonthYearHourMinute]: {
    // 9 Mar 2028 @ 10:42
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  [dateTimeFormats.dayMonthLongYear]: {
    // '20 September 2020'
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  [dateTimeFormats.dayMonthLongYearHourMinute]: {
    // 9 March 2028 @ 10:42
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  [dateTimeFormats.weekday]: {
    // 'Tuesday'
    weekday: 'long',
  },
  [dateTimeFormats.weekdayShort]: {
    // 'Tue'
    weekday: 'short',
  },
  [dateTimeFormats.weekdayDay]: {
    // 'Fri 13'
    weekday: 'short',
    day: 'numeric',
  },
  [dateTimeFormats.weekdayLongDay]: {
    // 'Friday 13'
    weekday: 'long',
    day: 'numeric',
  },
  [dateTimeFormats.weekdayDayMonth]: {
    // 'Fri, 13 Mar'
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  },
  [dateTimeFormats.weekdayLongDayMonthLong]: {
    // 'Friday, 13 March'
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  },
  [dateTimeFormats.weekdayLongDayMonthLongYear]: {
    // 'Friday, 13 March 2023'
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  [dateTimeFormats.weekdayDayYear]: {
    weekday: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  [dateTimeFormats.weekdayDayMonthYear]: {
    // 'Fri, 13 Mar 2020'
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  [dateTimeFormats.weekdayDayMonthLongYear]: {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  [dateTimeFormats.weekdayShortMonthShortDayYear]: {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  [dateTimeFormats.monthDayYear]: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  [dateTimeFormats.monthLong]: {
    // 'September'
    month: 'long',
  },
  [dateTimeFormats.monthShort]: {
    // 'Sep'
    month: 'short',
  },
  [dateTimeFormats.monthShortYear]: {
    // 'Mar 2020'
    month: 'short',
    year: 'numeric',
  },
  [dateTimeFormats.monthYear]: {
    // 'March 2020'
    month: 'long',
    year: 'numeric',
  },
  [dateTimeFormats.hour]: {
    // 5 pm (when 12 hour locale); 17 (when 24 hour locale)
    // 12 hour set as false for default
    hour: 'numeric',
  },
  [dateTimeFormats.hourMinute]: {
    // 5:39 pm (when 12 hour locale); 17:39 (when 24 hour locale)
    // 12 hour set as false for default
    hour: 'numeric',
    minute: 'numeric',
  },
  [dateTimeFormats.hourMinuteSecond]: {
    // 5:39:23 pm (when 12 hour locale); 17:39:23 (when 24 hour locale)
    // 12 hour set as false for default
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  [dateTimeFormats.year]: {
    // single output of long numeric year: '2022'
    year: 'numeric',
  },
};

// Make sure that all locales in i18n.supportedLocales are covered here
// The repetition of locales is an annoying hack to work around a bug in VueI18n that ignores locale extensions
export const i18nDateTimeFormats = {
  'en-gb': formats,
  'en-gb-u-hc-h12': formats,
  'en-gb-u-hc-h24': formats,
  'en-us': formats,
  'en-us-u-hc-h12': formats,
  'en-us-u-hc-h24': formats,
  fr: formats,
};
