/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListTimezonesResponse,
} from '../models/index';
import {
    ListTimezonesResponseFromJSON,
    ListTimezonesResponseToJSON,
} from '../models/index';

export interface ListTimezonesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

/**
 * 
 */
export class TimezoneApi extends runtime.BaseAPI {

    /**
     * List of all available timezones
     * List Timezones
     */
    async listTimezonesRaw(requestParameters: ListTimezonesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTimezonesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/timezones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTimezonesResponseFromJSON(jsonValue));
    }

    /**
     * List of all available timezones
     * List Timezones
     */
    async listTimezones(requestParameters: ListTimezonesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTimezonesResponse> {
        const response = await this.listTimezonesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
