/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOAuthClientData,
  CreateOauthClientResponse,
  GetOauthTokenData,
  GetOauthTokenResponse,
  ListOauthClientsResponse,
  RotateOauthClientSecretResponse,
  ShowOauthClientResponse,
  ShowOauthClientSecretResponse,
  UpdateOAuthClientData,
  UpdateOauthClientResponse,
} from '../models/index';
import {
    CreateOAuthClientDataFromJSON,
    CreateOAuthClientDataToJSON,
    CreateOauthClientResponseFromJSON,
    CreateOauthClientResponseToJSON,
    GetOauthTokenDataFromJSON,
    GetOauthTokenDataToJSON,
    GetOauthTokenResponseFromJSON,
    GetOauthTokenResponseToJSON,
    ListOauthClientsResponseFromJSON,
    ListOauthClientsResponseToJSON,
    RotateOauthClientSecretResponseFromJSON,
    RotateOauthClientSecretResponseToJSON,
    ShowOauthClientResponseFromJSON,
    ShowOauthClientResponseToJSON,
    ShowOauthClientSecretResponseFromJSON,
    ShowOauthClientSecretResponseToJSON,
    UpdateOAuthClientDataFromJSON,
    UpdateOAuthClientDataToJSON,
    UpdateOauthClientResponseFromJSON,
    UpdateOauthClientResponseToJSON,
} from '../models/index';

export interface CreateOauthClientRequest {
    createOAuthClientData?: CreateOAuthClientData;
}

export interface DeleteOauthClientRequest {
    id: number;
}

export interface GetOauthTokenRequest {
    getOauthTokenData?: GetOauthTokenData;
}

export interface RotateOauthClientSecretRequest {
    id: number;
}

export interface ShowOauthClientRequest {
    id: number;
}

export interface ShowOauthClientSecretRequest {
    id: number;
}

export interface UpdateOauthClientRequest {
    id: number;
    updateOAuthClientData?: UpdateOAuthClientData;
}

/**
 * 
 */
export class OauthApi extends runtime.BaseAPI {

    /**
     * Create OAuth Client
     */
    async createOauthClientRaw(requestParameters: CreateOauthClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOauthClientResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/oauth/clients`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOAuthClientDataToJSON(requestParameters['createOAuthClientData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOauthClientResponseFromJSON(jsonValue));
    }

    /**
     * Create OAuth Client
     */
    async createOauthClient(requestParameters: CreateOauthClientRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOauthClientResponse> {
        const response = await this.createOauthClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Delete OAuth Client
     */
    async deleteOauthClientRaw(requestParameters: DeleteOauthClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteOauthClient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/oauth/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     * Delete OAuth Client
     */
    async deleteOauthClient(requestParameters: DeleteOauthClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOauthClientRaw(requestParameters, initOverrides);
    }

    /**
     * Obtain a new token for an existing OAuth client. Currently supported grant types are: - `client_credentials`
     * Get OAuth Token
     */
    async getOauthTokenRaw(requestParameters: GetOauthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOauthTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oauth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetOauthTokenDataToJSON(requestParameters['getOauthTokenData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOauthTokenResponseFromJSON(jsonValue));
    }

    /**
     * Obtain a new token for an existing OAuth client. Currently supported grant types are: - `client_credentials`
     * Get OAuth Token
     */
    async getOauthToken(requestParameters: GetOauthTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOauthTokenResponse> {
        const response = await this.getOauthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * List OAuth Clients
     */
    async listOauthClientsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOauthClientsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/oauth/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOauthClientsResponseFromJSON(jsonValue));
    }

    /**
     * 
     * List OAuth Clients
     */
    async listOauthClients(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOauthClientsResponse> {
        const response = await this.listOauthClientsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     * Rotate OAuth Client Secret
     */
    async rotateOauthClientSecretRaw(requestParameters: RotateOauthClientSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RotateOauthClientSecretResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling rotateOauthClientSecret().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/oauth/clients/{id}/secret`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RotateOauthClientSecretResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Rotate OAuth Client Secret
     */
    async rotateOauthClientSecret(requestParameters: RotateOauthClientSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RotateOauthClientSecretResponse> {
        const response = await this.rotateOauthClientSecretRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show OAuth Client
     */
    async showOauthClientRaw(requestParameters: ShowOauthClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowOauthClientResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showOauthClient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/oauth/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowOauthClientResponseFromJSON(jsonValue));
    }

    /**
     * Show OAuth Client
     */
    async showOauthClient(requestParameters: ShowOauthClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowOauthClientResponse> {
        const response = await this.showOauthClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the decrypted secret of an OAuth client.
     * Show OAuth Client Secret
     */
    async showOauthClientSecretRaw(requestParameters: ShowOauthClientSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowOauthClientSecretResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showOauthClientSecret().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/oauth/clients/{id}/secret`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowOauthClientSecretResponseFromJSON(jsonValue));
    }

    /**
     * Get the decrypted secret of an OAuth client.
     * Show OAuth Client Secret
     */
    async showOauthClientSecret(requestParameters: ShowOauthClientSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowOauthClientSecretResponse> {
        const response = await this.showOauthClientSecretRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Update OAuth Client
     */
    async updateOauthClientRaw(requestParameters: UpdateOauthClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateOauthClientResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateOauthClient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/oauth/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOAuthClientDataToJSON(requestParameters['updateOAuthClientData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateOauthClientResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Update OAuth Client
     */
    async updateOauthClient(requestParameters: UpdateOauthClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateOauthClientResponse> {
        const response = await this.updateOauthClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
