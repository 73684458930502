<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 329 112"
  >
    <g transform="translate(-244 -1158)">
      <path
        d="M18,112a14,14,0,1,1,0-28H48.015a6,6,0,0,0,0-12H227.792a6,6,0,0,0,0,12v0H315a14,14,0,1,1,0,28ZM303,72a8,8,0,0,1,0-16h16a8,8,0,0,1,0,16ZM27,72a8,8,0,0,1,0-16H90.015a6,6,0,0,0-.005-12H239.792a6,6,0,0,0,0,12v0H279a8,8,0,1,1,0,16ZM48,44a8,8,0,1,1,0-16H95.015a6,6,0,0,0,0-12H265.792a6,6,0,0,0,0,12v0H307a8,8,0,0,1,0,16ZM8,44A8,8,0,1,1,8,28H25a8,8,0,1,1,0,16ZM42,16A8,8,0,0,1,42,0H281a8,8,0,0,1,0,16Z"
        transform="translate(244 1158)"
        fill="#f4f3f8"
      />
      <g transform="translate(357 1135)">
        <path
          d="M163.983,158.906l10.673,8.726v42.583a3.745,3.745,0,0,1-3.745,3.745H105.745A3.745,3.745,0,0,1,102,210.215V167.632l10.674-8.726Z"
          transform="translate(-83.473 -97.308)"
          fill="#e8e7ee"
        />
        <path
          d="M160.762,86.945v-33.2A3.745,3.745,0,0,1,164.508,50h43.818a3.745,3.745,0,0,1,3.746,3.745v33.2L186.417,106.3Z"
          transform="translate(-131.561 -8.184)"
          fill="#fff"
        />
        <path
          d="M256.708,126.78a12.921,12.921,0,1,1-12.921-12.921A12.921,12.921,0,0,1,256.708,126.78Z"
          transform="translate(-188.932 -60.443)"
          fill="#81bb5b"
        />
        <path
          d="M122.612,68.918l-10.007-8.181V45.561A5.568,5.568,0,0,0,107.044,40H92.4a1.816,1.816,0,0,0,0,3.633h14.643a1.931,1.931,0,0,1,1.929,1.928V77.8L85.135,95.926,61.3,77.8v-16.2h0V45.561a1.931,1.931,0,0,1,1.929-1.928H77.869a1.816,1.816,0,0,0,0-3.633H63.226a5.568,5.568,0,0,0-5.562,5.561V60.737L47.657,68.918a1.816,1.816,0,0,0-.667,1.406V90.49H38.816a1.816,1.816,0,1,0,0,3.633H46.99v18.785a5.567,5.567,0,0,0,5.561,5.561h65.166a5.567,5.567,0,0,0,5.561-5.561V70.324A1.816,1.816,0,0,0,122.612,68.918Zm-2.966,43.164L98.155,90.59l21.492-16.34Zm-7.041-46.653,6.091,4.979-6.091,4.63ZM85.135,100.024a1.816,1.816,0,0,0,1.1-.37l9-6.844,22.027,22.027H53.006L75.033,92.809l9,6.844A1.812,1.812,0,0,0,85.135,100.024ZM50.623,74.25,72.115,90.59,50.623,112.082Zm7.041.789-6.091-4.63,6.091-4.979Z"
          transform="translate(-30.279)"
          fill="#2c1841"
        />
        <path
          d="M11.625,381.965H1.816a1.816,1.816,0,1,0,0,3.633h9.809a1.816,1.816,0,0,0,0-3.633Z"
          transform="translate(0 -279.85)"
          fill="#2c1841"
        />
        <path
          d="M293.816,43.633a1.817,1.817,0,1,0-1.284-.532A1.83,1.83,0,0,0,293.816,43.633Z"
          transform="translate(-238.961)"
        />
        <path
          d="M220.867,118.6A14.737,14.737,0,1,0,235.6,103.859,14.754,14.754,0,0,0,220.867,118.6Zm25.841,0a11.1,11.1,0,1,1-11.1-11.1A11.117,11.117,0,0,1,246.708,118.6Z"
          transform="translate(-180.749 -52.26)"
          fill="#fff"
        />
        <path
          d="M272.452,162.456a1.816,1.816,0,0,0-2.569,2.568l3.206,3.207a1.816,1.816,0,0,0,2.568,0l5.627-5.627a1.816,1.816,0,1,0-2.569-2.568l-4.342,4.342Z"
          transform="translate(-220.426 -97.797)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CheckEnvelopeSvg',
};
</script>
