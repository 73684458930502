
import SidebarNav from '@/components/nav/SidebarNav.vue';
import { StorageEnum } from '@/lib/enum/StorageEnum';
import { MenuSection } from '@/lib/menus/helpers';
import { reportsMenu } from '@/lib/menus/reportsMenu';
import { redirect } from '@/router/router';
import { setLocalStorageItem } from '@/util/storageFunctions';
import { viewport } from '@/util/windowFunctions';

export default {
  name: 'ReportsMenu',
  components: { SidebarNav },
  beforeRouteEnter(to, from, next) {
    setLocalStorageItem(StorageEnum.FromRoute, from.name);
    next();
  },
  data() {
    return {
      viewport,
    };
  },
  computed: {
    menu(): MenuSection[] {
      return [reportsMenu.shiftCost(), reportsMenu.logs()];
    },
  },
  methods: {
    redirect,
  },
};
