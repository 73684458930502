/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeNotificationChannel
 */
export interface EmployeeNotificationChannel {
    /**
     * 
     * @type {string}
     * @memberof EmployeeNotificationChannel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeNotificationChannel
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeNotificationChannel
     */
    employeeId: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeNotificationChannel
     */
    group: EmployeeNotificationChannelGroupEnum;
    /**
     * 
     * @type {string}
     * @memberof EmployeeNotificationChannel
     */
    channel: EmployeeNotificationChannelChannelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeNotificationChannel
     */
    enabled: boolean;
}

/**
* @export
* @enum {string}
*/
export enum EmployeeNotificationChannelGroupEnum {
    Billing = 'Billing',
    Document = 'Document',
    Leave = 'Leave',
    Shift = 'Shift',
    Absence = 'Absence',
    Timesheet = 'Timesheet'
}
/**
* @export
* @enum {string}
*/
export enum EmployeeNotificationChannelChannelEnum {
    Email = 'Email',
    Push = 'Push',
    Sms = 'Sms'
}


/**
 * Check if a given object implements the EmployeeNotificationChannel interface.
 */
export function instanceOfEmployeeNotificationChannel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('group' in value)) return false;
    if (!('channel' in value)) return false;
    if (!('enabled' in value)) return false;
    return true;
}

export function EmployeeNotificationChannelFromJSON(json: any): EmployeeNotificationChannel {
    return EmployeeNotificationChannelFromJSONTyped(json, false);
}

export function EmployeeNotificationChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeNotificationChannel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'group': json['group'],
        'channel': json['channel'],
        'enabled': json['enabled'],
    };
}

export function EmployeeNotificationChannelToJSON(value?: EmployeeNotificationChannel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'group': value['group'],
        'channel': value['channel'],
        'enabled': value['enabled'],
    };
}

