/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingDowngrade } from './BillingDowngrade';
import {
    BillingDowngradeFromJSON,
    BillingDowngradeFromJSONTyped,
    BillingDowngradeToJSON,
} from './BillingDowngrade';

/**
 * 
 * @export
 * @interface ShowPendingDowngradeResponse
 */
export interface ShowPendingDowngradeResponse {
    /**
     * 
     * @type {BillingDowngrade}
     * @memberof ShowPendingDowngradeResponse
     */
    data?: BillingDowngrade;
}

/**
 * Check if a given object implements the ShowPendingDowngradeResponse interface.
 */
export function instanceOfShowPendingDowngradeResponse(value: object): boolean {
    return true;
}

export function ShowPendingDowngradeResponseFromJSON(json: any): ShowPendingDowngradeResponse {
    return ShowPendingDowngradeResponseFromJSONTyped(json, false);
}

export function ShowPendingDowngradeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowPendingDowngradeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : BillingDowngradeFromJSON(json['data']),
    };
}

export function ShowPendingDowngradeResponseToJSON(value?: ShowPendingDowngradeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': BillingDowngradeToJSON(value['data']),
    };
}

