/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HistoryChangesInner
 */
export interface HistoryChangesInner {
    /**
     * Name of the attribute that changed.
     * @type {string}
     * @memberof HistoryChangesInner
     */
    attribute: string;
    /**
     * Old value of the attribute, represented as a string.
     * @type {string}
     * @memberof HistoryChangesInner
     */
    oldValue: string;
    /**
     * New value of the attribute, represented as a string.
     * @type {string}
     * @memberof HistoryChangesInner
     */
    newValue: string;
}

/**
 * Check if a given object implements the HistoryChangesInner interface.
 */
export function instanceOfHistoryChangesInner(value: object): boolean {
    if (!('attribute' in value)) return false;
    if (!('oldValue' in value)) return false;
    if (!('newValue' in value)) return false;
    return true;
}

export function HistoryChangesInnerFromJSON(json: any): HistoryChangesInner {
    return HistoryChangesInnerFromJSONTyped(json, false);
}

export function HistoryChangesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryChangesInner {
    if (json == null) {
        return json;
    }
    return {
        
        'attribute': json['attribute'],
        'oldValue': json['oldValue'],
        'newValue': json['newValue'],
    };
}

export function HistoryChangesInnerToJSON(value?: HistoryChangesInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attribute': value['attribute'],
        'oldValue': value['oldValue'],
        'newValue': value['newValue'],
    };
}

