import { isRef, Ref } from 'vue';

export const withLoading = <
  Args extends Array<any>,
  T extends (...args: Args) => Promise<any>,
>(
  loading: Ref<boolean> | ((v: boolean) => any),
  fn: T,
): T => {
  const setLoading = isRef(loading)
    ? (v: boolean) => {
        loading.value = v;
      }
    : loading;
  return (async (...args: Args) => {
    setLoading(true);
    try {
      return await fn(...args);
    } finally {
      setLoading(false);
    }
  }) as T;
};
