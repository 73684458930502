/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateJobRoleData,
  CreateJobRoleResponse,
  ListJobRolesResponse,
  ShowJobRoleResponse,
  UpdateJobRoleData,
  UpdateJobRoleResponse,
} from '../models/index';
import {
    CreateJobRoleDataFromJSON,
    CreateJobRoleDataToJSON,
    CreateJobRoleResponseFromJSON,
    CreateJobRoleResponseToJSON,
    ListJobRolesResponseFromJSON,
    ListJobRolesResponseToJSON,
    ShowJobRoleResponseFromJSON,
    ShowJobRoleResponseToJSON,
    UpdateJobRoleDataFromJSON,
    UpdateJobRoleDataToJSON,
    UpdateJobRoleResponseFromJSON,
    UpdateJobRoleResponseToJSON,
} from '../models/index';

export interface CreateJobRoleRequest {
    _with?: Array<string>;
    createJobRoleData?: CreateJobRoleData;
}

export interface ListJobRolesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowJobRoleRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateJobRoleRequest {
    id: number;
    _with?: Array<string>;
    updateJobRoleData?: UpdateJobRoleData;
}

/**
 * 
 */
export class JobRoleApi extends runtime.BaseAPI {

    /**
     * Create a new job role
     * Create Job Role
     */
    async createJobRoleRaw(requestParameters: CreateJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateJobRoleResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.job-role.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job-roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobRoleDataToJSON(requestParameters['createJobRoleData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateJobRoleResponseFromJSON(jsonValue));
    }

    /**
     * Create a new job role
     * Create Job Role
     */
    async createJobRole(requestParameters: CreateJobRoleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateJobRoleResponse> {
        const response = await this.createJobRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List or filter all available job roles
     * List Job Roles
     */
    async listJobRolesRaw(requestParameters: ListJobRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListJobRolesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.job-role.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job-roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListJobRolesResponseFromJSON(jsonValue));
    }

    /**
     * List or filter all available job roles
     * List Job Roles
     */
    async listJobRoles(requestParameters: ListJobRolesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListJobRolesResponse> {
        const response = await this.listJobRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single job role
     * Show Job Role
     */
    async showJobRoleRaw(requestParameters: ShowJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowJobRoleResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showJobRole().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.job-role.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job-roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowJobRoleResponseFromJSON(jsonValue));
    }

    /**
     * Get a single job role
     * Show Job Role
     */
    async showJobRole(requestParameters: ShowJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowJobRoleResponse> {
        const response = await this.showJobRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing job role
     * Update Job Role
     */
    async updateJobRoleRaw(requestParameters: UpdateJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateJobRoleResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateJobRole().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.job-role.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job-roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobRoleDataToJSON(requestParameters['updateJobRoleData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateJobRoleResponseFromJSON(jsonValue));
    }

    /**
     * Update an existing job role
     * Update Job Role
     */
    async updateJobRole(requestParameters: UpdateJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateJobRoleResponse> {
        const response = await this.updateJobRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
