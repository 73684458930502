/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveAdjustment } from './LeaveAdjustment';
import {
    LeaveAdjustmentFromJSON,
    LeaveAdjustmentFromJSONTyped,
    LeaveAdjustmentToJSON,
} from './LeaveAdjustment';

/**
 * 
 * @export
 * @interface CreateLeaveAdjustmentResponse
 */
export interface CreateLeaveAdjustmentResponse {
    /**
     * 
     * @type {LeaveAdjustment}
     * @memberof CreateLeaveAdjustmentResponse
     */
    data: LeaveAdjustment;
}

/**
 * Check if a given object implements the CreateLeaveAdjustmentResponse interface.
 */
export function instanceOfCreateLeaveAdjustmentResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateLeaveAdjustmentResponseFromJSON(json: any): CreateLeaveAdjustmentResponse {
    return CreateLeaveAdjustmentResponseFromJSONTyped(json, false);
}

export function CreateLeaveAdjustmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveAdjustmentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveAdjustmentFromJSON(json['data']),
    };
}

export function CreateLeaveAdjustmentResponseToJSON(value?: CreateLeaveAdjustmentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeaveAdjustmentToJSON(value['data']),
    };
}

