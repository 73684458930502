/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface BudgetSummary
 */
export interface BudgetSummary {
    /**
     * A deterministic identifier for the report.
     * @type {string}
     * @memberof BudgetSummary
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof BudgetSummary
     */
    companyId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BudgetSummary
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BudgetSummary
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {string}
     * @memberof BudgetSummary
     */
    format: BudgetSummaryFormatEnum;
    /**
     * Estimated cost based on scheduled shifts.
     * @type {number}
     * @memberof BudgetSummary
     */
    scheduledWages: number;
    /**
     * Actual cost based on completed timesheet entries.
     * @type {number}
     * @memberof BudgetSummary
     */
    actualWages: number;
    /**
     * Estimated hours based on scheduled shifts.
     * @type {number}
     * @memberof BudgetSummary
     */
    scheduledHours: number;
    /**
     * Actual worked hours based on completed timesheet entries.
     * @type {number}
     * @memberof BudgetSummary
     */
    actualHours: number;
}

/**
* @export
* @enum {string}
*/
export enum BudgetSummaryFormatEnum {
    Daily = 'Daily',
    Weekly = 'Weekly'
}


/**
 * Check if a given object implements the BudgetSummary interface.
 */
export function instanceOfBudgetSummary(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('startDate' in value)) return false;
    if (!('endDate' in value)) return false;
    if (!('format' in value)) return false;
    if (!('scheduledWages' in value)) return false;
    if (!('actualWages' in value)) return false;
    if (!('scheduledHours' in value)) return false;
    if (!('actualHours' in value)) return false;
    return true;
}

export function BudgetSummaryFromJSON(json: any): BudgetSummary {
    return BudgetSummaryFromJSONTyped(json, false);
}

export function BudgetSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'format': json['format'],
        'scheduledWages': json['scheduledWages'],
        'actualWages': json['actualWages'],
        'scheduledHours': json['scheduledHours'],
        'actualHours': json['actualHours'],
    };
}

export function BudgetSummaryToJSON(value?: BudgetSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'format': value['format'],
        'scheduledWages': value['scheduledWages'],
        'actualWages': value['actualWages'],
        'scheduledHours': value['scheduledHours'],
        'actualHours': value['actualHours'],
    };
}

