/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftTemplate } from './ShiftTemplate';
import {
    ShiftTemplateFromJSON,
    ShiftTemplateFromJSONTyped,
    ShiftTemplateToJSON,
} from './ShiftTemplate';

/**
 * 
 * @export
 * @interface CreateShiftTemplateResponse
 */
export interface CreateShiftTemplateResponse {
    /**
     * 
     * @type {ShiftTemplate}
     * @memberof CreateShiftTemplateResponse
     */
    data?: ShiftTemplate;
}

/**
 * Check if a given object implements the CreateShiftTemplateResponse interface.
 */
export function instanceOfCreateShiftTemplateResponse(value: object): boolean {
    return true;
}

export function CreateShiftTemplateResponseFromJSON(json: any): CreateShiftTemplateResponse {
    return CreateShiftTemplateResponseFromJSONTyped(json, false);
}

export function CreateShiftTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftTemplateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftTemplateFromJSON(json['data']),
    };
}

export function CreateShiftTemplateResponseToJSON(value?: CreateShiftTemplateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftTemplateToJSON(value['data']),
    };
}

