import { Employee, Location } from '../../../api/v1';

export const clockInFromPersonalDevicesAtLocationFilter =
  (employee: Employee) => (location: Location) =>
    employee.locationIds.includes(location.id) &&
    location.allowClockInFromPersonalDevices;

export const clockInViaPortalsAtLocationFilter =
  (employee: Employee) => (location: Location) =>
    employee.locationIds.includes(location.id) &&
    location.allowClockInFromPortals;
