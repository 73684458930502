/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AbsenceCategory
 */
export interface AbsenceCategory {
    /**
     * 
     * @type {number}
     * @memberof AbsenceCategory
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceCategory
     */
    name: string;
}

/**
 * Check if a given object implements the AbsenceCategory interface.
 */
export function instanceOfAbsenceCategory(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function AbsenceCategoryFromJSON(json: any): AbsenceCategory {
    return AbsenceCategoryFromJSONTyped(json, false);
}

export function AbsenceCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbsenceCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function AbsenceCategoryToJSON(value?: AbsenceCategory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

