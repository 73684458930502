/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplyScheduleTemplateData,
  CreateScheduleTemplateData,
  CreateScheduleTemplateResponse,
  ListScheduleTemplatesResponse,
  ShowScheduleTemplateResponse,
  UpdateScheduleTemplateData,
  UpdateScheduleTemplateResponse,
} from '../models/index';
import {
    ApplyScheduleTemplateDataFromJSON,
    ApplyScheduleTemplateDataToJSON,
    CreateScheduleTemplateDataFromJSON,
    CreateScheduleTemplateDataToJSON,
    CreateScheduleTemplateResponseFromJSON,
    CreateScheduleTemplateResponseToJSON,
    ListScheduleTemplatesResponseFromJSON,
    ListScheduleTemplatesResponseToJSON,
    ShowScheduleTemplateResponseFromJSON,
    ShowScheduleTemplateResponseToJSON,
    UpdateScheduleTemplateDataFromJSON,
    UpdateScheduleTemplateDataToJSON,
    UpdateScheduleTemplateResponseFromJSON,
    UpdateScheduleTemplateResponseToJSON,
} from '../models/index';

export interface ApplyScheduleTemplateRequest {
    id: number;
    applyScheduleTemplateData?: ApplyScheduleTemplateData;
}

export interface CreateScheduleTemplateRequest {
    _with?: Array<string>;
    createScheduleTemplateData?: CreateScheduleTemplateData;
}

export interface DeleteScheduleTemplateRequest {
    id: number;
}

export interface ListScheduleTemplatesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowScheduleTemplateRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateScheduleTemplateRequest {
    id: number;
    _with?: Array<string>;
    updateScheduleTemplateData?: UpdateScheduleTemplateData;
}

/**
 * 
 */
export class ScheduleTemplateApi extends runtime.BaseAPI {

    /**
     * Fill a date range with Shifts from a Schedule Template
     * Apply Schedule Template
     */
    async applyScheduleTemplateRaw(requestParameters: ApplyScheduleTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling applyScheduleTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-template.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplyScheduleTemplateDataToJSON(requestParameters['applyScheduleTemplateData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Fill a date range with Shifts from a Schedule Template
     * Apply Schedule Template
     */
    async applyScheduleTemplate(requestParameters: ApplyScheduleTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applyScheduleTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new ScheduleTemplate
     * Create Schedule Template
     */
    async createScheduleTemplateRaw(requestParameters: CreateScheduleTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateScheduleTemplateResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-template.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateScheduleTemplateDataToJSON(requestParameters['createScheduleTemplateData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateScheduleTemplateResponseFromJSON(jsonValue));
    }

    /**
     * Create a new ScheduleTemplate
     * Create Schedule Template
     */
    async createScheduleTemplate(requestParameters: CreateScheduleTemplateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateScheduleTemplateResponse> {
        const response = await this.createScheduleTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a single Schedule Template
     * Delete Schedule Template
     */
    async deleteScheduleTemplateRaw(requestParameters: DeleteScheduleTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteScheduleTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-template.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single Schedule Template
     * Delete Schedule Template
     */
    async deleteScheduleTemplate(requestParameters: DeleteScheduleTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteScheduleTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * List all Schedule Templates
     * List Schedule Templates
     */
    async listScheduleTemplatesRaw(requestParameters: ListScheduleTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListScheduleTemplatesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-template.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListScheduleTemplatesResponseFromJSON(jsonValue));
    }

    /**
     * List all Schedule Templates
     * List Schedule Templates
     */
    async listScheduleTemplates(requestParameters: ListScheduleTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListScheduleTemplatesResponse> {
        const response = await this.listScheduleTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single Schedule Template
     * Show Schedule Template
     */
    async showScheduleTemplateRaw(requestParameters: ShowScheduleTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowScheduleTemplateResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showScheduleTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-template.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowScheduleTemplateResponseFromJSON(jsonValue));
    }

    /**
     * Show a single Schedule Template
     * Show Schedule Template
     */
    async showScheduleTemplate(requestParameters: ShowScheduleTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowScheduleTemplateResponse> {
        const response = await this.showScheduleTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a single Schedule Template
     * Update Schedule Template
     */
    async updateScheduleTemplateRaw(requestParameters: UpdateScheduleTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateScheduleTemplateResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateScheduleTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-template.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateScheduleTemplateDataToJSON(requestParameters['updateScheduleTemplateData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateScheduleTemplateResponseFromJSON(jsonValue));
    }

    /**
     * Update a single Schedule Template
     * Update Schedule Template
     */
    async updateScheduleTemplate(requestParameters: UpdateScheduleTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateScheduleTemplateResponse> {
        const response = await this.updateScheduleTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
