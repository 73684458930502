import { PlainDate } from '@/lib/date-time/PlainDate';
import { objectHasOwn, shallowEqual } from '@/util/objectFunctions';
import { capitalise, toPlural, toSingular } from '@/util/stringFunctions';

export type EntityFilter<T extends object> = (entity: T) => boolean;

/** This list should match whatever the API currently supports */
const supportedOperators = [
  'eq',
  'neq',
  'gt',
  'gte',
  'lt',
  'lte',
  'contains',
  'startsWith',
  'endsWith',
  'in',
  'notIn',
  'isNull',
] as const;

type SupportedOperator = typeof supportedOperators[number];
type Conditions = Partial<
  Record<
    SupportedOperator,
    string | number | boolean | Date | string[] | number[]
  >
>;
export type Where<T extends object> = Partial<Record<keyof T, Conditions>>;

/**
 * If the value of an entity's property is a PlainDate object, convert any PlainDate to a string
 * If the value of an entity's property is a Date object, convert any string value to a Date
 */
const castIfDate = (
  whereValue: unknown,
  entityValue: unknown,
): [unknown, unknown] => {
  if (entityValue instanceof PlainDate && typeof whereValue === 'string') {
    return [whereValue, entityValue.toString()];
  }
  if (entityValue instanceof Date && typeof whereValue === 'string') {
    return [new Date(whereValue), entityValue];
  }
  return [whereValue, entityValue];
};

const compare: Record<SupportedOperator, (a: any, b: any) => boolean> = {
  eq: (a, b) => shallowEqual(a, b),
  neq: (a, b) => !shallowEqual(a, b),
  gt: (a, b) => a > b,
  gte: (a, b) => a >= b,
  lt: (a, b) => a < b,
  lte: (a, b) => a <= b,
  contains: (a, b) => {
    if (typeof a === 'string')
      return a.toLowerCase().includes(String(b).toLowerCase());
    if (Array.isArray(a)) {
      return Array.isArray(b) ? b.every((v) => a.includes(v)) : a.includes(b);
    }
    return false;
  },
  startsWith: (a, b) => String(a).startsWith(String(b)),
  endsWith: (a, b) => String(a).endsWith(String(b)),
  in: (a, b) => (Array.isArray(a) ? a : [a]).some((v) => b.includes(v)),
  notIn: (a, b) => (Array.isArray(a) ? a : [a]).every((v) => !b.includes(v)),
  isNull: (a, b) => (a === null) === b,
};

export const relationKeyToField = (key: string): string => {
  if (!key.includes('.')) {
    return key;
  }
  const words = key.split('.');
  if (words.length !== 2) {
    throw new Error(
      `Invalid relation key can not be turned into entity key: ${key}`,
    );
  }

  // tags.id -> tagIds
  return `${toSingular(words[0])}${capitalise(toPlural(words[1]))}`;
};

const validateEntity = <
  InterfaceMap extends Record<string, object>,
  Key extends keyof InterfaceMap,
>(
  entityName: Key,
  entity: InterfaceMap[Key],
  keys: string[],
): void | never => {
  const invalidProperties = keys.filter((f) => !objectHasOwn(entity, f));
  if (invalidProperties.length) {
    throw new Error(
      `Entity ${
        entityName as string
      } does not have these properties: ${invalidProperties.join(
        ', ',
      )}, maybe invalid relation used`,
    );
  }
};

/**
 * When a websocket message comes in to say that an entity has changed, we need to know which realtime collections
 * it should be added to, updated in, or removed from.
 * We do this by converting the 'where' object (used in API requests) to the equivalent Javascript filter.
 */
export const createFilterFromWhereObject = <
  InterfaceMap extends Record<string, object>,
  Key extends keyof InterfaceMap,
>(
  entityName: Key,
  where: Where<InterfaceMap[Key]>,
): EntityFilter<InterfaceMap[Key]> => {
  const transformedKeys = Object.keys(where).map(relationKeyToField);
  return (entity: InterfaceMap[Key]) => {
    validateEntity(entityName, entity, transformedKeys);

    /**
     * The double-negatives here look a bit strange, but essentially, we're searching for the first condition that
     * doesn't match, hence the use of '.some()'. Then, because this is a JS filter, we invert that to return our
     * boolean that decides whether the entity is included in the given collection.
     */
    return !Object.keys(where).some((property) =>
      Object.keys(where[property]).some((operator) => {
        const [whereValue, entityValue] = castIfDate(
          where[property][operator],
          entity[relationKeyToField(property)],
        );
        return !compare[operator](entityValue, whereValue);
      }),
    );
  };
};
