/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents details about a Stripe Payment Method created for a company
 * @export
 * @interface BillingPaymentMethod
 */
export interface BillingPaymentMethod {
    /**
     * 
     * @type {number}
     * @memberof BillingPaymentMethod
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BillingPaymentMethod
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof BillingPaymentMethod
     */
    billingDetailId: number;
    /**
     * The ID of the PaymentMethod object - generated by stripe
     * @type {string}
     * @memberof BillingPaymentMethod
     */
    stripePaymentMethodId: string;
    /**
     * The last 4 digits of the assigned card
     * @type {string}
     * @memberof BillingPaymentMethod
     */
    last4: string;
    /**
     * The expiry month of the card
     * @type {number}
     * @memberof BillingPaymentMethod
     */
    expMonth: number;
    /**
     * The expiry year of the card
     * @type {number}
     * @memberof BillingPaymentMethod
     */
    expYear: number;
    /**
     * The brand of the card
     * @type {string}
     * @memberof BillingPaymentMethod
     */
    brand: string;
    /**
     * Determines if this payment method is used for stripe billing
     * @type {boolean}
     * @memberof BillingPaymentMethod
     */
    isPrimary?: boolean;
    /**
     * A link to the icon related to the branch of the payment method
     * @type {string}
     * @memberof BillingPaymentMethod
     */
    brandIcon?: string;
}

/**
 * Check if a given object implements the BillingPaymentMethod interface.
 */
export function instanceOfBillingPaymentMethod(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('billingDetailId' in value)) return false;
    if (!('stripePaymentMethodId' in value)) return false;
    if (!('last4' in value)) return false;
    if (!('expMonth' in value)) return false;
    if (!('expYear' in value)) return false;
    if (!('brand' in value)) return false;
    return true;
}

export function BillingPaymentMethodFromJSON(json: any): BillingPaymentMethod {
    return BillingPaymentMethodFromJSONTyped(json, false);
}

export function BillingPaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingPaymentMethod {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'billingDetailId': json['billingDetailId'],
        'stripePaymentMethodId': json['stripePaymentMethodId'],
        'last4': json['last4'],
        'expMonth': json['expMonth'],
        'expYear': json['expYear'],
        'brand': json['brand'],
        'isPrimary': json['isPrimary'] == null ? undefined : json['isPrimary'],
        'brandIcon': json['brandIcon'] == null ? undefined : json['brandIcon'],
    };
}

export function BillingPaymentMethodToJSON(value?: BillingPaymentMethod | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'billingDetailId': value['billingDetailId'],
        'stripePaymentMethodId': value['stripePaymentMethodId'],
        'last4': value['last4'],
        'expMonth': value['expMonth'],
        'expYear': value['expYear'],
        'brand': value['brand'],
        'isPrimary': value['isPrimary'],
        'brandIcon': value['brandIcon'],
    };
}

