
import SwitchBetweenInput from '@/components/inputs/SwitchBetweenInput.vue';
import { PlanNameEnum } from '@/lib/enum/billing/BillingEnum';
import { fetchCollection } from '@/lib/realtime/realtimeFunctions';
import { Entity } from '@/lib/store/realtimeEntities';
import store from '@/store';
import { viewport } from '@/util/windowFunctions';
import PackageCardV2 from '@/views/sign-up/components/PackageCardV2.vue';
import { PaymentMethod } from '@stripe/stripe-js';
import { PropType } from 'vue';
import { BillingDetails, BillingPlanIntervalEnum } from '../../../../api/v1';

export default {
  name: 'ChooseBillingPlan',
  components: { PackageCardV2, SwitchBetweenInput },
  props: {
    subscriptionInterval: {
      type: String as PropType<BillingPlanIntervalEnum>,
      default: BillingPlanIntervalEnum.Monthly,
    },
    billingDetails: {
      type: Object as PropType<BillingDetails>,
      default: null,
    },
    paymentMethod: {
      type: Object as PropType<PaymentMethod>,
      default: null,
    },
  },
  data() {
    return {
      BillingPlanIntervalEnum,
      PlanNameEnum,
      viewport,
      billingPlans: fetchCollection(Entity.BillingPlan, {}, this),
    };
  },
  computed: {
    currentPlan: () => store.state.billing.billingPlan,
    isTrialVersion: () => store.getters['billing/isTrialVersion'],
    filteredPlans() {
      return this.billingPlans.data.filter(
        (p) => p.price > 0 && p.interval === this.subscriptionInterval,
      );
    },
  },
  methods: {
    isCurrentPlan(plan) {
      return plan.id === this.currentPlan.id;
    },
  },
};
