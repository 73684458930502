/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShowVAPIDKeyResponseData
 */
export interface ShowVAPIDKeyResponseData {
    /**
     * 
     * @type {string}
     * @memberof ShowVAPIDKeyResponseData
     */
    publicKey: string;
}

/**
 * Check if a given object implements the ShowVAPIDKeyResponseData interface.
 */
export function instanceOfShowVAPIDKeyResponseData(value: object): boolean {
    if (!('publicKey' in value)) return false;
    return true;
}

export function ShowVAPIDKeyResponseDataFromJSON(json: any): ShowVAPIDKeyResponseData {
    return ShowVAPIDKeyResponseDataFromJSONTyped(json, false);
}

export function ShowVAPIDKeyResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowVAPIDKeyResponseData {
    if (json == null) {
        return json;
    }
    return {
        
        'publicKey': json['publicKey'],
    };
}

export function ShowVAPIDKeyResponseDataToJSON(value?: ShowVAPIDKeyResponseData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'publicKey': value['publicKey'],
    };
}

