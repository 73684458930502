/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigiticketsConnection } from './DigiticketsConnection';
import {
    DigiticketsConnectionFromJSON,
    DigiticketsConnectionFromJSONTyped,
    DigiticketsConnectionToJSON,
} from './DigiticketsConnection';

/**
 * 
 * @export
 * @interface ShowDigiticketsConnectionResponse
 */
export interface ShowDigiticketsConnectionResponse {
    /**
     * 
     * @type {DigiticketsConnection}
     * @memberof ShowDigiticketsConnectionResponse
     */
    data: DigiticketsConnection;
}

/**
 * Check if a given object implements the ShowDigiticketsConnectionResponse interface.
 */
export function instanceOfShowDigiticketsConnectionResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowDigiticketsConnectionResponseFromJSON(json: any): ShowDigiticketsConnectionResponse {
    return ShowDigiticketsConnectionResponseFromJSONTyped(json, false);
}

export function ShowDigiticketsConnectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowDigiticketsConnectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': DigiticketsConnectionFromJSON(json['data']),
    };
}

export function ShowDigiticketsConnectionResponseToJSON(value?: ShowDigiticketsConnectionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': DigiticketsConnectionToJSON(value['data']),
    };
}

