/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface ApplyScheduleTemplateData
 */
export interface ApplyScheduleTemplateData {
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof ApplyScheduleTemplateData
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof ApplyScheduleTemplateData
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {string}
     * @memberof ApplyScheduleTemplateData
     */
    conflictMode: ApplyScheduleTemplateDataConflictModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ApplyScheduleTemplateDataConflictModeEnum {
    UnassignAll = 'Unassign All',
    Unassign = 'Unassign',
    Overwrite = 'Overwrite',
    Omit = 'Omit'
}


/**
 * Check if a given object implements the ApplyScheduleTemplateData interface.
 */
export function instanceOfApplyScheduleTemplateData(value: object): boolean {
    if (!('startDate' in value)) return false;
    if (!('endDate' in value)) return false;
    if (!('conflictMode' in value)) return false;
    return true;
}

export function ApplyScheduleTemplateDataFromJSON(json: any): ApplyScheduleTemplateData {
    return ApplyScheduleTemplateDataFromJSONTyped(json, false);
}

export function ApplyScheduleTemplateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyScheduleTemplateData {
    if (json == null) {
        return json;
    }
    return {
        
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'conflictMode': json['conflictMode'],
    };
}

export function ApplyScheduleTemplateDataToJSON(value?: ApplyScheduleTemplateData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'conflictMode': value['conflictMode'],
    };
}

