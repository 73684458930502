/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface CreatePayCycleData
 */
export interface CreatePayCycleData {
    /**
     * 
     * @type {string}
     * @memberof CreatePayCycleData
     */
    frequency: CreatePayCycleDataFrequencyEnum;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreatePayCycleData
     */
    startsOn: ShiftiePlainDate;
}

/**
* @export
* @enum {string}
*/
export enum CreatePayCycleDataFrequencyEnum {
    Weekly = 'Weekly',
    TwoWeekly = 'TwoWeekly',
    FourWeekly = 'FourWeekly',
    Monthly = 'Monthly'
}


/**
 * Check if a given object implements the CreatePayCycleData interface.
 */
export function instanceOfCreatePayCycleData(value: object): boolean {
    if (!('frequency' in value)) return false;
    if (!('startsOn' in value)) return false;
    return true;
}

export function CreatePayCycleDataFromJSON(json: any): CreatePayCycleData {
    return CreatePayCycleDataFromJSONTyped(json, false);
}

export function CreatePayCycleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePayCycleData {
    if (json == null) {
        return json;
    }
    return {
        
        'frequency': json['frequency'],
        'startsOn': ShiftiePlainDateFromJSON(json['startsOn']),
    };
}

export function CreatePayCycleDataToJSON(value?: CreatePayCycleData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'frequency': value['frequency'],
        'startsOn': ShiftiePlainDateToJSON(value['startsOn']),
    };
}

