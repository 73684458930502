import { isTruthy } from '@/lib/helpers/booleans';
import router from '@/router/router';
import Vue from 'vue';
import VueGtag from 'vue-gtag';

export const initialiseGoogleAnalytics = () => {
  // Initialise Google Analytics (gtag)
  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GA_MEASUREMENT_ID,
        appName: `Shiftie (${process.env.VUE_APP_ENV})`,
        enabled: isTruthy(process.env.VUE_APP_GA_ENABLED),
      },
    },
    router,
  );
};
