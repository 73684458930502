// eslint-disable-next-line max-classes-per-file
import { PlainDate } from '@/lib/date-time/PlainDate';
import { ConditionBuilder } from '@/lib/realtime/ConditionBuilder';
import { entityRepository } from '@/lib/realtime/EntityRepository';
import {
  EntityInstance,
  EntityInterfaceMap,
  EntityName,
  GetParameterType,
  SubscriptionResponse,
} from '@/lib/realtime/realtimeTypes';
import { shallowEqual } from '@/util/objectFunctions';
import Vue from 'vue';

/**
 * This seems a bit pointless, but it allows us to initialise the collections in our components with an appropriately
 * typed response, prior to making the real request once the component is mounted.
 */
export const emptyCollectionResponse = <T extends EntityName>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _: T,
): SubscriptionResponse<T> => ({
  data: [],
  isLoading: true,
  promise: null,
  unsubscribe: () => {},
});

type Filter<T extends EntityName> = (entity: EntityInstance<T>) => boolean;

/** This list should match whatever the API currently supports */
const supportedOperators = [
  'eq',
  'neq',
  'gt',
  'gte',
  'lt',
  'lte',
  'contains',
  'startsWith',
  'endsWith',
  'in',
  'notIn',
  'isNull',
] as const;

type SupportedOperator = typeof supportedOperators[number];
type Conditions = Partial<
  Record<
    SupportedOperator,
    string | number | boolean | Date | string[] | number[]
  >
>;
export type Where<T extends EntityName> = Partial<
  Record<keyof EntityInstance<T>, Conditions>
>;

/**
 * If the value of an entity's property is a PlainDate object, convert any PlainDate to a string
 * If the value of an entity's property is a Date object, convert any string value to a Date
 */
const castIfDate = (
  whereValue: unknown,
  entityValue: unknown,
): [unknown, unknown] => {
  if (entityValue instanceof PlainDate && typeof whereValue === 'string') {
    return [whereValue, entityValue.toString()];
  }
  if (entityValue instanceof Date && typeof whereValue === 'string') {
    return [new Date(whereValue), entityValue];
  }
  return [whereValue, entityValue];
};

const compare: Record<SupportedOperator, (a: any, b: any) => boolean> = {
  eq: (a, b) => shallowEqual(a, b),
  neq: (a, b) => !shallowEqual(a, b),
  gt: (a, b) => a > b,
  gte: (a, b) => a >= b,
  lt: (a, b) => a < b,
  lte: (a, b) => a <= b,
  contains: (a, b) => {
    if (typeof a === 'string') return a.includes(String(b));
    if (Array.isArray(a)) {
      return Array.isArray(b) ? b.every((v) => a.includes(v)) : a.includes(b);
    }
    return false;
  },
  startsWith: (a, b) => String(a).startsWith(String(b)),
  endsWith: (a, b) => String(a).endsWith(String(b)),
  in: (a, b) => b.includes(a),
  notIn: (a, b) => !b.includes(a),
  isNull: (a, b) => (a === null) === b,
};

/**
 * When a websocket message comes in to say that an entity has changed, we need to know which realtime collections
 * it should be added to, updated in, or removed from.
 * We do this by converting the 'where' object (used in API requests) to the equivalent Javascript filter.
 */
export const createFilterFromWhereObject = <T extends EntityName>(
  entityName: T,
  where: Where<T>,
): Filter<T> => {
  const nestedProperty = Object.keys(where).find((k) => k.includes('.'));
  if (nestedProperty) {
    throw new Error(
      `Dot syntax in property ${nestedProperty} is not supported in where object`,
    );
  }

  return (entity: EntityInstance<T>) => {
    /**
     * The double-negatives here look a bit strange, but essentially, we're searching for the first condition that
     * doesn't match, hence the use of '.some()'. Then, because this is a JS filter, we invert that to return our
     * boolean that decides whether the entity is included in the given collection.
     */
    const doesntMatch = Object.keys(where).some((property) => {
      return Object.keys(where[property]).some((operator) => {
        const [whereValue, entityValue] = castIfDate(
          where[property][operator],
          entity[property],
        );
        return !compare[operator](entityValue, whereValue);
      });
    });
    return !doesntMatch;
  };
};

/**
 * Fetch a collection for a given entity type, which will receive realtime updates.
 *
 * Response format and general usage is modelled on Vue Query's useQuery function.
 * @see https://vue-query.vercel.app/#/guides/queries
 */
export const fetchCollection = <T extends EntityName>(
  entityName: T,
  where: Where<T> = {},
  component: Vue = null,
  params: GetParameterType<T> = {} as GetParameterType<T>,
): SubscriptionResponse<T> => {
  const filter = createFilterFromWhereObject(entityName, where);
  const subscribe = () =>
    entityRepository.subscribe(entityName, where, filter, component, params);
  /**
   * The next section may look a bit strange, but basically, if a component instance has been passed,
   * we set up a hook to automatically unsubscribe when the component is destroyed. This avoids massive build-up
   * of subscriptions as a user navigates around the app, and means developers don't have to manually implement this
   * themselves in each component.
   *
   * However, the gotcha is that this during development using hot module replacement, the component will also get
   * destroyed and recreated. To allow the subscription to be automatically reestablished on component re-creation,
   * we also register a 'mounted' hook.
   */
  const result = subscribe();
  if (component) {
    // If a component is provided, automatically remove the subscription when the component is destroyed
    component.$on('hook:destroyed', () => {
      entityRepository.unsubscribe(entityName, where, component);
    });
    component.$on('hook:mounted', () => {
      // This may be calling subscribe() for a second time, but our repository should avoid any duplication of effort
      const newResult = subscribe();
      // We're deliberately mutating the original result object here as it's already been returned, and as the Vue
      // component has a reference to it, this will allow the reactivity to work.
      Object.assign(result, newResult);
    });
  }
  return result;
};

class RealtimeQuery<T extends EntityName> extends ConditionBuilder<
  EntityInterfaceMap[T]
> {
  #component = null as null | Vue;

  #entity = undefined as T;

  #params = {} as NonNullable<GetParameterType<T>>;

  constructor(entity: T, component: Vue | null, params: GetParameterType<T>) {
    super();
    this.#entity = entity;
    this.#component = component;
    this.#params = params;
    // If params exist then we need to attach them as a where filter
    Object.keys(params).forEach((key) => {
      this.where(key as keyof EntityInterfaceMap[T], 'eq', params[key]);
    });
  }

  fetch(): SubscriptionResponse<T> {
    return fetchCollection(
      this.#entity,
      this.conditions,
      this.#component,
      this.#params,
    );
  }

  empty(): SubscriptionResponse<T> {
    return emptyCollectionResponse(this.#entity);
  }
}

export const realtimeQuery = <T extends EntityName>(
  entityName: T,
  component: Vue | null,
  params: GetParameterType<T> = {} as GetParameterType<T>,
): RealtimeQuery<T> => {
  return new RealtimeQuery(entityName, component, params);
};

/**
 * Find a single entity by its ID
 */
export const findEntity = async <T extends EntityName>(
  entityName: T,
  id: number,
): Promise<EntityInterfaceMap[T] | undefined> => {
  const query = realtimeQuery(entityName, null).where('id', 'eq', id).fetch();
  await query.promise;
  return query.data[0] ?? undefined;
};

/**
 * Find a single entity by given key
 */
export const findEntityWhere = async <
  T extends EntityName,
  K extends keyof EntityInterfaceMap[T],
>(
  entityName: T,
  property: K,
  value: any,
): Promise<EntityInterfaceMap[T] | undefined> => {
  const query = realtimeQuery(entityName, null)
    .where(property, 'eq', value)
    .fetch();
  await query.promise;
  return query.data[0] ?? undefined;
};
