/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * Provides a summary of an employee's absence calculations for a given holiday year
 * @export
 * @interface EmployeeAbsenceSummary
 */
export interface EmployeeAbsenceSummary {
    /**
     * A deterministic identifier for the report.
     * @type {string}
     * @memberof EmployeeAbsenceSummary
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeAbsenceSummary
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeAbsenceSummary
     */
    employeeId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeeAbsenceSummary
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeeAbsenceSummary
     */
    endDate: ShiftiePlainDate;
    /**
     * The number of working days lost due to absence for this holiday year
     * @type {number}
     * @memberof EmployeeAbsenceSummary
     */
    daysLost: number;
    /**
     * The number of periods of absence during this holiday year
     * @type {number}
     * @memberof EmployeeAbsenceSummary
     */
    absences: number;
    /**
     * The Bradford Factor score for this holiday year
     * @type {number}
     * @memberof EmployeeAbsenceSummary
     */
    bradfordFactor: number;
}

/**
 * Check if a given object implements the EmployeeAbsenceSummary interface.
 */
export function instanceOfEmployeeAbsenceSummary(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('startDate' in value)) return false;
    if (!('endDate' in value)) return false;
    if (!('daysLost' in value)) return false;
    if (!('absences' in value)) return false;
    if (!('bradfordFactor' in value)) return false;
    return true;
}

export function EmployeeAbsenceSummaryFromJSON(json: any): EmployeeAbsenceSummary {
    return EmployeeAbsenceSummaryFromJSONTyped(json, false);
}

export function EmployeeAbsenceSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeAbsenceSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'daysLost': json['daysLost'],
        'absences': json['absences'],
        'bradfordFactor': json['bradfordFactor'],
    };
}

export function EmployeeAbsenceSummaryToJSON(value?: EmployeeAbsenceSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'daysLost': value['daysLost'],
        'absences': value['absences'],
        'bradfordFactor': value['bradfordFactor'],
    };
}

