<template>
  <div class="all:p-4 bg-white">
    <div
      class="background-gradient text-white rounded all:p-4 all:flex all:flex-col justify-space-between h-full"
    >
      <div>
        <p class="text-14 mb-0">
          {{ currentCompany.name }}
        </p>
        <p class="text-26 leading-5 word-space-wide">
          {{ $t('info.account.cardNumber', { last4: paymentMethod.last4 }) }}
        </p>
      </div>

      <div class="flex justify-between mt-4 align-end">
        <div>
          <span class="d-block text-12">{{
            $t('info.account.validUntil')
          }}</span>
          <p class="text-20 mb-0">
            {{ paymentMethod.expMonth }}/{{ paymentMethod.expYear }}
          </p>
        </div>
        <div class="background-transparent-white rounded all:p-1">
          <img
            class="brand-icon"
            :src="paymentMethod.brandIcon"
            :alt="$t('image.alt.paymentBrandIcon')"
          />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import store from '@/store';

export default {
  name: 'PaymentMethod',

  props: {
    paymentMethod: {
      type: Object,
      required: true,
    },
  },

  computed: {
    currentCompany: () => store.getters.currentCompany,
  },
};
</script>

<style scoped lang="scss">
.background-gradient {
  @include bgGradientRL;
}

.background-transparent-white {
  background-color: rgba(255, 255, 255, 0.6);
}

.brand-icon {
  max-width: 50px;
}

.word-space-wide {
  word-spacing: 0.5rem;
}
</style>
