
import { CompanySetting } from '../../../api/v1';
import { Icon } from '../../lib/enum/Icon';

export default {
  name: 'CompanyLogo',

  props: {
    size: {
      type: Number,
      default: 38,
    },

    companySetting: {
      type: Object as () => CompanySetting,
      required: true,
    },
  },

  data() {
    return {
      Icon,
      imageLoadFailed: false,
    };
  },

  computed: {
    src() {
      return this.companySetting.logo?.url;
    },
  },
};
