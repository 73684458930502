/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Contains caching meta data relating to the current response, such as when the result was cached at.
 * @export
 * @interface CacheMeta
 */
export interface CacheMeta {
    /**
     * A datetime representing the last time the cached result was updated from source.
     * @type {string}
     * @memberof CacheMeta
     */
    lastUpdatedAt: string;
    /**
     * A datetime representing when the cached data will become stale, and future requests will result in re-fetching the underlying data.
     * @type {string}
     * @memberof CacheMeta
     */
    staleAt: string;
}

/**
 * Check if a given object implements the CacheMeta interface.
 */
export function instanceOfCacheMeta(value: object): boolean {
    if (!('lastUpdatedAt' in value)) return false;
    if (!('staleAt' in value)) return false;
    return true;
}

export function CacheMetaFromJSON(json: any): CacheMeta {
    return CacheMetaFromJSONTyped(json, false);
}

export function CacheMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CacheMeta {
    if (json == null) {
        return json;
    }
    return {
        
        'lastUpdatedAt': json['lastUpdatedAt'],
        'staleAt': json['staleAt'],
    };
}

export function CacheMetaToJSON(value?: CacheMeta | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lastUpdatedAt': value['lastUpdatedAt'],
        'staleAt': value['staleAt'],
    };
}

