/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveRequestUsageCalculation } from './LeaveRequestUsageCalculation';
import {
    LeaveRequestUsageCalculationFromJSON,
    LeaveRequestUsageCalculationFromJSONTyped,
    LeaveRequestUsageCalculationToJSON,
} from './LeaveRequestUsageCalculation';

/**
 * 
 * @export
 * @interface CreateLeaveRequestUsageCalculationResponse
 */
export interface CreateLeaveRequestUsageCalculationResponse {
    /**
     * 
     * @type {LeaveRequestUsageCalculation}
     * @memberof CreateLeaveRequestUsageCalculationResponse
     */
    data?: LeaveRequestUsageCalculation;
}

/**
 * Check if a given object implements the CreateLeaveRequestUsageCalculationResponse interface.
 */
export function instanceOfCreateLeaveRequestUsageCalculationResponse(value: object): boolean {
    return true;
}

export function CreateLeaveRequestUsageCalculationResponseFromJSON(json: any): CreateLeaveRequestUsageCalculationResponse {
    return CreateLeaveRequestUsageCalculationResponseFromJSONTyped(json, false);
}

export function CreateLeaveRequestUsageCalculationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveRequestUsageCalculationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : LeaveRequestUsageCalculationFromJSON(json['data']),
    };
}

export function CreateLeaveRequestUsageCalculationResponseToJSON(value?: CreateLeaveRequestUsageCalculationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeaveRequestUsageCalculationToJSON(value['data']),
    };
}

