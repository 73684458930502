export default {
  namespaced: true,

  state: {
    date: null,
  },

  mutations: {
    SET_DATE(state, date: Date) {
      state.date = date;
    },
  },

  actions: {
    setDate({ commit }, date: Date) {
      commit('SET_DATE', date);
    },
  },
};
