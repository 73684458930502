/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCostsByLocation
 */
export interface ReportCostsByLocation {
    /**
     * 
     * @type {string}
     * @memberof ReportCostsByLocation
     */
    locationName: string;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByLocation
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByLocation
     */
    employees: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportCostsByLocation
     */
    employeeIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByLocation
     */
    shifts: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByLocation
     */
    hoursScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByLocation
     */
    hoursWorked: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByLocation
     */
    costsScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByLocation
     */
    costsWorked: number | null;
}

/**
 * Check if a given object implements the ReportCostsByLocation interface.
 */
export function instanceOfReportCostsByLocation(value: object): boolean {
    if (!('locationName' in value)) return false;
    if (!('locationId' in value)) return false;
    if (!('employees' in value)) return false;
    if (!('employeeIds' in value)) return false;
    if (!('shifts' in value)) return false;
    if (!('hoursScheduled' in value)) return false;
    if (!('hoursWorked' in value)) return false;
    if (!('costsScheduled' in value)) return false;
    if (!('costsWorked' in value)) return false;
    return true;
}

export function ReportCostsByLocationFromJSON(json: any): ReportCostsByLocation {
    return ReportCostsByLocationFromJSONTyped(json, false);
}

export function ReportCostsByLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCostsByLocation {
    if (json == null) {
        return json;
    }
    return {
        
        'locationName': json['locationName'],
        'locationId': json['locationId'],
        'employees': json['employees'],
        'employeeIds': json['employeeIds'],
        'shifts': json['shifts'],
        'hoursScheduled': json['hoursScheduled'],
        'hoursWorked': json['hoursWorked'],
        'costsScheduled': json['costsScheduled'],
        'costsWorked': json['costsWorked'],
    };
}

export function ReportCostsByLocationToJSON(value?: ReportCostsByLocation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'locationName': value['locationName'],
        'locationId': value['locationId'],
        'employees': value['employees'],
        'employeeIds': value['employeeIds'],
        'shifts': value['shifts'],
        'hoursScheduled': value['hoursScheduled'],
        'hoursWorked': value['hoursWorked'],
        'costsScheduled': value['costsScheduled'],
        'costsWorked': value['costsWorked'],
    };
}

