import { billingApi } from '@/api';
import spacetime from 'spacetime';
import {
  BillingDetails,
  BillingOverview,
  BillingPlan,
  BillingPlanFeaturesEnum,
  BillingSubscription,
  BillingSubscriptionTypeEnum,
} from '../../api/v1';

export default {
  namespaced: true,

  state: {
    billingPlan: null as BillingPlan,
    currentSubscription: null as BillingSubscription,
    hasBillingDetails: null as BillingDetails,
    billingOverview: null as BillingOverview,
  },

  getters: {
    getCompanyFeatures(state): BillingPlanFeaturesEnum[] {
      return state.billingPlan?.features ?? [];
    },

    isUpgradable(state): boolean {
      return !!state.billingPlan?.isUpgradable;
    },

    allowsReferrals(state): boolean {
      return !!state.billingPlan?.allowsReferrals;
    },

    isTrialVersion(state): boolean {
      if (!state.currentSubscription) return false;
      return (
        state.currentSubscription.type === BillingSubscriptionTypeEnum.Trial
      );
    },

    trialDaysLeft(state, getters, rootState, rootGetters): number {
      if (!state.currentSubscription || !state.currentSubscription.endsOn)
        return 0;

      const now = spacetime.now(rootGetters.timezone).startOf('day');
      const trialEnds = state.currentSubscription.endsOn.toDate(
        rootGetters.timezone,
      );
      return now.diff(trialEnds, 'day');
    },
  },

  mutations: {
    SET_BILLING_PLAN(state, data) {
      state.billingPlan = data;
    },
    SET_CURRENT_SUBSCRIPTION(state, data) {
      state.currentSubscription = data;
    },
    HAS_BILLING_DETAILS(state, data: boolean) {
      state.hasBillingDetails = data;
    },
    SET_BILLING_OVERVIEW(
      state,
      billingOverview: BillingOverview = {
        hasDetails: state.hasDetails,
        hasPaymentMethod: state.hasPaymentMethod,
      },
    ) {
      state.billingOverview = billingOverview;
    },
  },

  actions: {
    async setCompanyBillingPlan({ commit }) {
      const { data: currentSubscription } =
        await billingApi.showCurrentSubscription({});

      commit('SET_BILLING_PLAN', currentSubscription.billingPlan);
      commit('SET_CURRENT_SUBSCRIPTION', currentSubscription);
    },

    async setBillingOverview({ commit }) {
      const { data: billingOverview } = await billingApi.showBillingOverview();
      commit('SET_BILLING_OVERVIEW', billingOverview);
    },
  },
};
