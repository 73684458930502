/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkClockOutData
 */
export interface BulkClockOutData {
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkClockOutData
     */
    employeeIds: Array<number>;
}

/**
 * Check if a given object implements the BulkClockOutData interface.
 */
export function instanceOfBulkClockOutData(value: object): boolean {
    if (!('employeeIds' in value)) return false;
    return true;
}

export function BulkClockOutDataFromJSON(json: any): BulkClockOutData {
    return BulkClockOutDataFromJSONTyped(json, false);
}

export function BulkClockOutDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkClockOutData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeIds': json['employeeIds'],
    };
}

export function BulkClockOutDataToJSON(value?: BulkClockOutData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employeeIds': value['employeeIds'],
    };
}

