var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"left":"","bottom":"","max-width":"400","min-width":"400","offset-y":"","attach":"#activator","content-class":!_vm.viewport.lg ? 'batch-jobs-container' : ''},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({attrs:{"id":"activator"}},on),[_c('i',{staticClass:"text-16 rounded-full cursor-pointer lg:text-gray-600 p-2",class:[
          _vm.Icon.ArrowsRotate,
          {
            'animate-spin': _vm.isInProgress,
            'text-red': _vm.hasErrors,
            hidden: !_vm.jobs.length,
          },
          _vm.viewport.lg ? 'hover:bg-purple-50' : 'hover:bg-purple-800',
        ]})])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"p-3 flex flex-col gap-2 text-black",on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"text-18 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$tc('backgroundJobs.title'))+" ")]),_c('div',{staticClass:"flex flex-col gap-3 max-h-[400px] overflow-auto"},_vm._l((_vm.jobs),function(job){return _c('div',{key:job.id,staticClass:"flex flex-col pb-4 border-b-1 border-gray-200 last:border-b-0 last:pb-0"},[_c('div',{staticClass:"flex justify-space-between",class:{
            'mb-2': job.state !== _vm.BatchJobState.Failed || !job.extra?.error,
          }},[_c('span',{staticClass:"text-14 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getJobName(job.type))+" ")]),(job.state !== _vm.BatchJobState.Running)?_c('div',{staticClass:"flex gap-2 align-stretch"},[(job.state === _vm.BatchJobState.Complete)?_c('i',{staticClass:"flex align-center text-18 text-green",class:_vm.Icon.CircleCheckSolid}):(job.state === _vm.BatchJobState.Failed)?_c('i',{staticClass:"flex align-center text-18 text-red",class:_vm.Icon.XmarkSolidCircle}):_vm._e(),_c('i',{staticClass:"flex align-center text-18 cursor-pointer rounded-full text-gray-600 px-1 hover:bg-purple-50 hover:text-purple",class:_vm.Icon.Xmark,on:{"click":function($event){return _vm.deleteJob(job.id)}}})]):_vm._e()]),(job.state === _vm.BatchJobState.Failed && job.extra?.error)?_c('span',{staticClass:"text-12 text-red mb-2"},[_vm._v(" "+_vm._s(job.extra.error)+" ")]):_vm._e(),_c('PercentageBar',{attrs:{"hide-numbers":"","range":{ min: 0, max: job.jobCount },"filled":job.jobCount - job.remainingJobs,"colour":job.state === _vm.BatchJobState.Failed ? 'Red' : 'Green',"height":"0.5rem"}})],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }