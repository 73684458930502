
/**
 * Define your list of inputs with the `list` property. This should be an array with at least
 * a `value`, `name` and `checked` value within it i.e `{ name: 'Input 1', value: 'input1' }`
 *
 * You can use a legend by supplying the `legend` prop with a string
 *
 * `radioGroup` is a unique string to group the radio inputs.
 */
import { viewport } from '../../util/windowFunctions';

export default {
  name: 'RadioButtons',

  props: {
    value: {
      // null allows us to do things like "all locations" in filters
      type: [String, Number, Boolean, null],
      default: null,
    },

    list: {
      type: Array,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: String,
      default: '',
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    radioGroup: {
      type: String,
      required: true,
    },
    labelLeft: {
      type: Boolean,
      default: false,
    },

    inputValue: {
      type: String,
      default: 'value',
    },
    maxWidth: {
      type: String,
      default: '100%',
    },
  },

  data() {
    return {
      viewport,
    };
  },

  methods: {
    checked(item) {
      this.$emit('input', item[this.inputValue]);
    },
  },
};
