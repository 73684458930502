
export default {
  name: 'DateSquareV2',

  props: {
    date: {
      type: Date,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
      validator: (prop) => ['sm', 'md', 'lg', 'xl'].includes(prop),
    },
  },
};
