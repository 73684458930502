/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface PayPeriod1
 */
export interface PayPeriod1 {
    /**
     * 
     * @type {number}
     * @memberof PayPeriod1
     */
    payCycleId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof PayPeriod1
     */
    startsOn: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof PayPeriod1
     */
    endsOn: ShiftiePlainDate;
}

/**
 * Check if a given object implements the PayPeriod1 interface.
 */
export function instanceOfPayPeriod1(value: object): boolean {
    if (!('payCycleId' in value)) return false;
    if (!('startsOn' in value)) return false;
    if (!('endsOn' in value)) return false;
    return true;
}

export function PayPeriod1FromJSON(json: any): PayPeriod1 {
    return PayPeriod1FromJSONTyped(json, false);
}

export function PayPeriod1FromJSONTyped(json: any, ignoreDiscriminator: boolean): PayPeriod1 {
    if (json == null) {
        return json;
    }
    return {
        
        'payCycleId': json['payCycleId'],
        'startsOn': ShiftiePlainDateFromJSON(json['startsOn']),
        'endsOn': ShiftiePlainDateFromJSON(json['endsOn']),
    };
}

export function PayPeriod1ToJSON(value?: PayPeriod1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'payCycleId': value['payCycleId'],
        'startsOn': ShiftiePlainDateToJSON(value['startsOn']),
        'endsOn': ShiftiePlainDateToJSON(value['endsOn']),
    };
}

