import { PlainDate } from '@/lib/date-time/PlainDate';
import { availableDocumentForEmployee } from '@/lib/documents/documentFilters';
import { isApprovable } from '@/lib/leave/leaveRequestFunctions';
import {
  emptyCollectionResponse,
  realtimeQuery,
} from '@/lib/realtime/realtimeFunctions';
import { SubscriptionResponse } from '@/lib/realtime/realtimeTypes';
import { Entity } from '@/lib/store/realtimeEntities';
import { fetchEntity } from '@/stores/entities';
import { sortBy, uniqueObjectsByGetter } from '@/util/arrayFunctions';
import {
  Document,
  DocumentRead,
  EmployeeStatusEnum,
  FeaturesEnabled,
  LeaveRequest,
  LeaveRequestStatusEnum,
  OpenShiftResponse,
  OpenShiftResponseStatusEnum,
  PayCycle,
  ShiftSwap,
  ShiftSwapStatusEnum,
} from '../../api/v1';

export default {
  namespaced: true,

  state: {
    employees: emptyCollectionResponse(Entity.Employee),
    leaveRequests: emptyCollectionResponse(Entity.LeaveRequest),
    openShifts: emptyCollectionResponse(Entity.Shift),
    openShiftResponses: emptyCollectionResponse(Entity.OpenShiftResponse),
    shiftSwapsToRespondTo: emptyCollectionResponse(Entity.ShiftSwap),
    reviewableShiftSwaps: emptyCollectionResponse(Entity.ShiftSwap),
    documents: emptyCollectionResponse(Entity.Document),
    documentReads: emptyCollectionResponse(Entity.DocumentRead),
    payCycles: emptyCollectionResponse(Entity.PayCycle),
    featuresEnabled: undefined as FeaturesEnabled,
  },

  mutations: {
    REQUESTS_CLEAN_UP(state) {
      state.leaveRequests = emptyCollectionResponse(Entity.LeaveRequest);
    },
    SET_EMPLOYEES(
      state,
      // @ts-ignore
      employeeResponse: SubscriptionResponse<Entity.Employee>,
    ) {
      state.employees = employeeResponse;
    },
    SET_LEAVE_REQUESTS(
      state,
      // @ts-ignore
      leaveRequestResponse: SubscriptionResponse<Entity.LeaveRequest>,
    ) {
      state.leaveRequests = leaveRequestResponse;
    },
    SET_OPEN_SHIFTS(
      state,
      // @ts-ignore
      openShiftResponse: SubscriptionResponse<Entity.Shift>,
    ) {
      state.openShifts = openShiftResponse;
    },
    SET_OPEN_SHIFT_RESPONSES(
      state,
      // @ts-ignore
      openShiftResponses: SubscriptionResponse<Entity.OpenShiftResponse>,
    ) {
      state.openShiftResponses = openShiftResponses;
    },
    SET_SHIFT_SWAPS_TO_RESPOND_TO(
      state,
      // @ts-ignore
      shiftSwapsToRespondTo: SubscriptionResponse<Entity.ShiftSwap>,
    ) {
      state.shiftSwapsToRespondTo = shiftSwapsToRespondTo;
    },
    SET_REVIEWABLE_SHIFT_SWAPS(
      state,
      // @ts-ignore
      reviewableShiftSwaps: SubscriptionResponse<Entity.ShiftSwap>,
    ) {
      state.reviewableShiftSwaps = reviewableShiftSwaps;
    },
    SET_DOCUMENTS(
      state,
      // @ts-ignore
      documents: SubscriptionResponse<Entity.Document>,
    ) {
      state.documents = documents;
    },
    SET_DOCUMENT_READS(
      state,
      // @ts-ignore
      documentReads: SubscriptionResponse<Entity.DocumentRead>,
    ) {
      state.documentReads = documentReads;
    },
    // @ts-ignore
    SET_PAY_CYCLES(state, payCycles: SubscriptionResponse<Entity.PayCycle>) {
      state.payCycles = payCycles;
    },
    SET_FEATURES_ENABLED(state, featuresEnabled: FeaturesEnabled) {
      state.featuresEnabled = featuresEnabled;
    },
  },
  getters: {
    shiftSwapsToRespondTo({ shiftSwapsToRespondTo }): ShiftSwap[] {
      return shiftSwapsToRespondTo.data;
    },
    shiftSwapsToReview({ reviewableShiftSwaps }, _, rootState): ShiftSwap[] {
      return reviewableShiftSwaps.data.filter(
        (ss) =>
          rootState.managedEmployeeIds.includes(ss.employeeId) ||
          rootState.managedEmployeeIds.includes(ss.recipientId),
      );
    },
    totalShiftRequests(state, getters): number {
      return new Set(
        [...getters.shiftSwapsToReview, ...getters.shiftSwapsToRespondTo].map(
          (ss) => ss.id,
        ),
      ).size;
    },

    openShiftResponses({
      openShifts,
      openShiftResponses,
    }): OpenShiftResponse[] {
      if (!openShifts.data.length) {
        return [];
      }
      const openShiftIds = openShifts.data.map((s) => s.id);
      const uniqueResponses = uniqueObjectsByGetter(
        openShiftResponses.data,
        'series',
      ) as OpenShiftResponse[];
      return uniqueResponses.filter((response) =>
        openShiftIds.includes(response.shiftId),
      );
    },
    uniqueOpenShifts(state, getters): OpenShiftResponse[] {
      return uniqueObjectsByGetter(
        getters.openShiftResponses as OpenShiftResponse[],
        'shiftId',
      );
    },
    totalOpenShifts(state, getters): number {
      return getters.uniqueOpenShifts.length;
    },

    leaveRequests({ leaveRequests }, getters): LeaveRequest[] {
      if (!getters.leaveApprovalEmployees.length) {
        return [];
      }
      return leaveRequests.data.filter((l) => {
        return (
          isApprovable(l) &&
          getters.leaveApprovalEmployees.map((e) => e.id).includes(l.employeeId)
        );
      });
    },
    totalLeaveRequests(state, getters): number {
      return getters.leaveRequests.length;
    },

    documentsToRead(
      { documents, documentReads },
      getters,
      rootState,
      rootGetters,
    ): Document[] {
      const { loggedInEmployee } = rootGetters;
      const unreadDocuments = documents.data
        .filter(availableDocumentForEmployee(loggedInEmployee))
        .filter(
          (doc: Document) =>
            !documentReads.data.find(
              (docRead: DocumentRead) => docRead.documentId === doc.id,
            ),
        );
      return sortBy(unreadDocuments, '-createdAt');
    },
    totalDocumentsToRead(state, getters): number {
      return getters.documentsToRead.length;
    },

    pendingPayCycle({ payCycles }): PayCycle | undefined {
      if (payCycles.data?.length) {
        return payCycles.data[0];
      }
      return undefined;
    },

    verifyPhoneNumber(state, getters, rootState, rootGetters): boolean {
      const employeeVerifiedNumber =
        rootGetters.loggedInEmployee?.phoneNumberVerifiedAt;
      return !employeeVerifiedNumber && state.featuresEnabled?.smsEnabled;
    },

    totalRequests(state, getters): number {
      return (
        getters.totalLeaveRequests +
        getters.totalOpenShifts +
        getters.totalShiftRequests +
        getters.totalDocumentsToRead +
        Number(!!getters.pendingPayCycle) +
        Number(getters.verifyPhoneNumber)
      );
    },

    leaveApprovalEmployees: ({ employees }, getters, rootState, rootGetters) =>
      employees.data.filter((employee) => {
        const { loggedInEmployee } = rootGetters;
        const { defaultLeaveApproverId } = rootState.settings.leaveSettings;
        if (employee.leaveApproverId) {
          return employee.leaveApproverId === loggedInEmployee.id;
        }
        return defaultLeaveApproverId === loggedInEmployee.id;
      }),
    /**
     * Return only leave requests for employees whose leave-approver is the logged in employee
     */
  },
  actions: {
    async fetchRequests(
      { commit, state },
      { loggedInEmployeeId, managedEmployeeIds, timezone },
    ) {
      const pendingLeaveStatuses = [
        LeaveRequestStatusEnum.PendingCancellation,
        LeaveRequestStatusEnum.PendingApproval,
      ];
      commit(
        'SET_EMPLOYEES',
        realtimeQuery(Entity.Employee, null)
          .whereIn('status', [
            EmployeeStatusEnum.Pending,
            EmployeeStatusEnum.Active,
          ])
          .fetch(),
      );
      commit(
        'SET_LEAVE_REQUESTS',
        realtimeQuery(Entity.LeaveRequest, null)
          .whereIn('status', pendingLeaveStatuses)
          .fetch(),
      );
      commit(
        'SET_OPEN_SHIFTS',
        realtimeQuery(Entity.Shift, null)
          .where('open', 'eq', true)
          .whereNull('employeeId')
          .where('startsAt', 'gt', new Date())
          .whereNotNull('publishedAt')
          .where('creatorId', 'eq', loggedInEmployeeId)
          .fetch(),
      );
      commit(
        'SET_OPEN_SHIFT_RESPONSES',
        realtimeQuery(Entity.OpenShiftResponse, null)
          .where('status', 'eq', OpenShiftResponseStatusEnum.Pending)
          .fetch(),
      );
      commit(
        'SET_SHIFT_SWAPS_TO_RESPOND_TO',
        realtimeQuery(Entity.ShiftSwap, null)
          .where('status', 'eq', ShiftSwapStatusEnum.PendingAcceptance)
          .where('recipientId', 'eq', loggedInEmployeeId)
          .fetch(),
      );
      if (managedEmployeeIds.length) {
        commit(
          'SET_REVIEWABLE_SHIFT_SWAPS',
          // We don't filter by managed employee IDs here, as
          // it involves looking at both employeeId and recipientId
          // and it can also generate a very long query string for
          // a company with a very flat management structure
          realtimeQuery(Entity.ShiftSwap, null)
            .where('status', 'eq', ShiftSwapStatusEnum.PendingApproval)
            .fetch(),
        );
      } else {
        state.reviewableShiftSwaps.isLoading = false;
      }
      commit(
        'SET_DOCUMENTS',
        realtimeQuery(Entity.Document, null)
          .whereNotNull('readDeadline')
          .fetch(),
      );
      commit(
        'SET_DOCUMENT_READS',
        realtimeQuery(Entity.DocumentRead, null)
          .where('employeeId', 'eq', loggedInEmployeeId)
          .fetch(),
      );
      commit(
        'SET_PAY_CYCLES',
        realtimeQuery(Entity.PayCycle, null)
          .where('startsOn', 'gte', PlainDate.fromDate(new Date(), timezone))
          .fetch(),
      );
      const featuresEnabledQuery = fetchEntity(Entity.FeaturesEnabled);
      await featuresEnabledQuery.promise;
      commit('SET_FEATURES_ENABLED', featuresEnabledQuery.data);
    },
  },
};
