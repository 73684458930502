import spacetime from 'spacetime';

export class Now {
  time: Date = new Date();

  updateInterval: number = null;

  init(): void {
    this.time = new Date();

    const msToNextMinute = (): number =>
      spacetime.now().diff(spacetime.now().endOf('minute'), 'millisecond');

    const initUpdateInterval = () => {
      this.updateInterval = setTimeout(() => {
        this.time = new Date();
        initUpdateInterval();
      }, msToNextMinute());
    };
    initUpdateInterval();
  }

  destroy() {
    if (this.updateInterval) clearTimeout(this.updateInterval);
  }

  valueOf(): number {
    return this.time.valueOf();
  }
}
