import en from './en';
import enUs from './en-us';
import fr from './fr';
import lt from './lt';

export const messages = {
  'en-gb': en,
  'en-us': enUs,
  fr,
  lt,
};
