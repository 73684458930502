import AddJobRolesSvg from '@/assets/onboarding/AddJobRolesSvg.vue';
import AddUnavailabilitySvg from '@/assets/onboarding/AddUnavailabilitySvg.vue';
import AddYourTeamSvg from '@/assets/onboarding/AddYourTeamSvg.vue';
import ConfigureLeaveSettingsSvg from '@/assets/onboarding/ConfigureLeaveSettingsSvg.vue';
import ConfigureTimeClockSettingsSvg from '@/assets/onboarding/ConfigureTimeClockSettingsSvg.vue';
import CreateYourShiftsSvg from '@/assets/onboarding/CreateYourShiftsSvg.vue';
import InviteYourTeamSvg from '@/assets/onboarding/InviteYourTeamSvg.vue';
import PublishShiftsSvg from '@/assets/onboarding/PublishShiftsSvg.vue';
import UpdateDetailsSvg from '@/assets/onboarding/UpdateDetailsSvg.vue';
import VerifyMobileSvg from '@/assets/onboarding/VerifyMobileSvg.vue';
import i18n from '@/i18n';
import { ExternalLink, links } from '@/lang/urlLinks';
import { StorageEnum } from '@/lib/enum/StorageEnum';
import { userCan } from '@/lib/permission/userCan';
import { routes } from '@/router/routes';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/util/storageFunctions';
import { Component } from 'vue';
import { Location } from 'vue-router';
import { CompanyOnboarding, EmployeeOnboarding } from '../../../api/v1';

export const getOnboardingClosedKey = (companyId: number, employeeId: number) =>
  `${StorageEnum.OnboardingClosed}C${companyId}E${employeeId}`;

export const setOnboardingClosed = (
  companyId: number,
  employeeId: number,
  closed: boolean,
) =>
  setLocalStorageItem(
    getOnboardingClosedKey(companyId, employeeId),
    `${closed}`,
  );

export const getOnboardingClosed = (companyId: number, employeeId: number) =>
  getLocalStorageItem(getOnboardingClosedKey(companyId, employeeId));

export type Flag = {
  id: number;
  completed: boolean;
  label: string;
  buttonLabel: string;
  description: string;
  image: Component;
  actionLink: Location;
  moreLink?: ExternalLink;
  warning?: string | null;
};

export const getCompanyOnboardingSteps = (
  onboarding: CompanyOnboarding,
): Partial<Omit<CompanyOnboarding, 'id'>> => {
  const permissions = {
    hasAddedJobRoles: userCan.manageJobRoles() && userCan.manageSettings(),
    hasAddedTeam: userCan.manageTeam(),
    hasAddedShifts: userCan.manageShifts(),
    hasInvitedTeam: userCan.manageTeam(),
    hasConfiguredClockingSettings: userCan.manageTimesheetSettings(),
    hasPublishedShifts: userCan.manageShifts(),
    hasConfiguredLeaveSettings: userCan.manageLeaveSettings(),
  };

  return Object.fromEntries(
    Object.entries(onboarding).filter(([k]) => permissions[k]),
  );
};

export const getCompanyOnboardingFlags = (
  onboarding: CompanyOnboarding,
): Flag[] => {
  const onboardingSteps: Record<keyof Omit<CompanyOnboarding, 'id'>, Flag> = {
    hasAddedJobRoles: {
      id: 1,
      label: i18n.tc('onboarding.items.addJobRoles.title'),
      description: i18n.tc('onboarding.items.addJobRoles.description'),
      buttonLabel: i18n.tc('button.addJobRole'),
      image: AddJobRolesSvg,
      actionLink: routes.manageJobRoles.route(),
      moreLink: links.manageJobRoles,
      completed: onboarding.hasAddedJobRoles,
    },
    hasAddedTeam: {
      id: 2,
      label: i18n.tc('onboarding.items.addTeam.title'),
      description: i18n.tc('onboarding.items.addTeam.description'),
      buttonLabel: i18n.tc('button.addEmployee', 2),
      image: AddYourTeamSvg,
      actionLink: routes.team.route(),
      moreLink: links.addTeamMembers,
      completed: onboarding.hasAddedTeam,
    },
    hasAddedShifts: {
      id: 3,
      label: i18n.tc('onboarding.items.addShifts.title'),
      description: i18n.tc('onboarding.items.addShifts.description'),
      buttonLabel: i18n.tc('button.addShift', 2),
      image: CreateYourShiftsSvg,
      actionLink: routes.schedule.route(),
      moreLink: links.addNewShifts,
      completed: onboarding.hasAddedShifts,
    },
    hasInvitedTeam: {
      id: 4,
      label: i18n.tc('onboarding.items.inviteTeam.title'),
      description: i18n.tc('onboarding.items.inviteTeam.description'),
      buttonLabel: i18n.tc('button.inviteTeam'),
      image: InviteYourTeamSvg,
      actionLink: routes.team.route(),
      moreLink: links.inviteEmployeeToShift,
      completed: onboarding.hasInvitedTeam,
      warning: !onboarding.hasAddedTeam
        ? i18n.tc('onboarding.items.inviteTeam.warning')
        : null,
    },
    hasConfiguredClockingSettings: {
      id: 5,
      label: i18n.tc('onboarding.items.configureTimeClock.title'),
      description: i18n.tc('onboarding.items.configureTimeClock.description'),
      buttonLabel: i18n.tc('button.timeClockSettings'),
      image: ConfigureTimeClockSettingsSvg,
      actionLink: routes.timesheetSettings.route(),
      moreLink: links.manageTimesheetSettings,
      completed: onboarding.hasConfiguredClockingSettings,
    },
    hasPublishedShifts: {
      id: 6,
      label: i18n.tc('onboarding.items.publishShifts.title'),
      description: i18n.tc('onboarding.items.publishShifts.description'),
      buttonLabel: i18n.tc('button.publishShift', 2),
      image: PublishShiftsSvg,
      actionLink: routes.schedule.route(),
      moreLink: links.bulkPublishShifts,
      completed: onboarding.hasPublishedShifts,
      warning: !onboarding.hasAddedShifts
        ? i18n.tc('onboarding.items.publishShifts.warning')
        : null,
    },
    hasConfiguredLeaveSettings: {
      id: 7,
      label: i18n.tc('onboarding.items.hasConfiguredLeaveSettings.title'),
      description: i18n.tc(
        'onboarding.items.hasConfiguredLeaveSettings.description',
      ),
      buttonLabel: i18n.tc('button.configureSettings'),
      image: ConfigureLeaveSettingsSvg,
      actionLink: routes.leavePolicySettings.route(),
      completed: onboarding.hasConfiguredLeaveSettings,
    },
  };

  return Object.entries(getCompanyOnboardingSteps(onboarding)).map(
    ([key]) => onboardingSteps[key],
  );
};

export const getEmployeeOnboardingSteps = (
  onboarding: EmployeeOnboarding,
  employeeId: number,
  smsEnabled: boolean,
): Partial<Omit<EmployeeOnboarding, 'id' | 'companyId' | 'employeeId'>> => {
  const permissions = {
    hasAddedPersonalDetails: true,
    hasVerifiedPhoneNumber: smsEnabled,
    hasAddedUnavailability: userCan.addOrEditUnavailability(employeeId),
  };

  return Object.fromEntries(
    Object.entries(onboarding).filter(([k]) => permissions[k]),
  );
};

export const getEmployeeOnboardingFlags = (
  onboarding: EmployeeOnboarding,
  employeeId: number,
  smsEnabled: boolean,
): Flag[] => {
  const onboardingSteps: Record<
    keyof Omit<EmployeeOnboarding, 'id' | 'companyId' | 'employeeId'>,
    Flag
  > = {
    hasAddedPersonalDetails: {
      id: 1,
      completed: onboarding.hasAddedPersonalDetails,
      label: i18n.tc('onboarding.items.hasAddedPersonalDetails.title'),
      buttonLabel: i18n.tc('button.updateDetails'),
      description: i18n.tc(
        'onboarding.items.hasAddedPersonalDetails.description',
      ),
      image: UpdateDetailsSvg,
      actionLink: routes.personalDetails.route(employeeId),
      moreLink: links.addPersonalDetails,
      warning: null,
    },
    hasVerifiedPhoneNumber: {
      id: 2,
      completed: onboarding.hasVerifiedPhoneNumber,
      label: i18n.tc('onboarding.items.hasVerifiedPhoneNumber.title'),
      buttonLabel: i18n.tc('button.verifyNumber'),
      description: i18n.tc(
        'onboarding.items.hasVerifiedPhoneNumber.description',
      ),
      image: VerifyMobileSvg,
      actionLink: routes.verifyPhoneNumber.route(),
      moreLink: links.verifyPhoneNumber,
      warning: null,
    },
    hasAddedUnavailability: {
      id: 3,
      completed: onboarding.hasAddedUnavailability,
      label: i18n.tc('onboarding.items.hasAddedUnavailability.title'),
      buttonLabel: i18n.tc('button.addUnavailability'),
      description: i18n.tc(
        'onboarding.items.hasAddedUnavailability.description',
      ),
      image: AddUnavailabilitySvg,
      actionLink: routes.unavailability.route(employeeId),
      moreLink: links.addUnavailability,
      warning: null,
    },
  };

  return Object.entries(
    getEmployeeOnboardingSteps(onboarding, employeeId, smsEnabled),
  ).map(([key]) => onboardingSteps[key]);
};
