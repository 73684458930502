/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DigiticketsBranch
 */
export interface DigiticketsBranch {
    /**
     * 
     * @type {number}
     * @memberof DigiticketsBranch
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DigiticketsBranch
     */
    name: string;
}

/**
 * Check if a given object implements the DigiticketsBranch interface.
 */
export function instanceOfDigiticketsBranch(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function DigiticketsBranchFromJSON(json: any): DigiticketsBranch {
    return DigiticketsBranchFromJSONTyped(json, false);
}

export function DigiticketsBranchFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigiticketsBranch {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function DigiticketsBranchToJSON(value?: DigiticketsBranch | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

