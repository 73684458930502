/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Contains pagination meta data relating to the current response, such as current page number, total number of results, etc.
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * Current page number
     * @type {number}
     * @memberof Pagination
     */
    currentPage: number;
    /**
     * 
     * @type {string}
     * @memberof Pagination
     */
    firstPageUrl: string;
    /**
     * Result number of the first result in this page
     * @type {number}
     * @memberof Pagination
     */
    from: number | null;
    /**
     * Page number of the last available page
     * @type {number}
     * @memberof Pagination
     */
    lastPage: number;
    /**
     * 
     * @type {string}
     * @memberof Pagination
     */
    lastPageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Pagination
     */
    nextPageUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pagination
     */
    path: string;
    /**
     * Number of results displayed per page
     * @type {number}
     * @memberof Pagination
     */
    perPage: number;
    /**
     * 
     * @type {string}
     * @memberof Pagination
     */
    prevPageUrl: string | null;
    /**
     * Result number of the last result in this page
     * @type {number}
     * @memberof Pagination
     */
    to: number | null;
    /**
     * Total number of results available for this query
     * @type {number}
     * @memberof Pagination
     */
    total: number;
}

/**
 * Check if a given object implements the Pagination interface.
 */
export function instanceOfPagination(value: object): boolean {
    if (!('currentPage' in value)) return false;
    if (!('firstPageUrl' in value)) return false;
    if (!('from' in value)) return false;
    if (!('lastPage' in value)) return false;
    if (!('lastPageUrl' in value)) return false;
    if (!('nextPageUrl' in value)) return false;
    if (!('path' in value)) return false;
    if (!('perPage' in value)) return false;
    if (!('prevPageUrl' in value)) return false;
    if (!('to' in value)) return false;
    if (!('total' in value)) return false;
    return true;
}

export function PaginationFromJSON(json: any): Pagination {
    return PaginationFromJSONTyped(json, false);
}

export function PaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pagination {
    if (json == null) {
        return json;
    }
    return {
        
        'currentPage': json['currentPage'],
        'firstPageUrl': json['firstPageUrl'],
        'from': json['from'],
        'lastPage': json['lastPage'],
        'lastPageUrl': json['lastPageUrl'],
        'nextPageUrl': json['nextPageUrl'],
        'path': json['path'],
        'perPage': json['perPage'],
        'prevPageUrl': json['prevPageUrl'],
        'to': json['to'],
        'total': json['total'],
    };
}

export function PaginationToJSON(value?: Pagination | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currentPage': value['currentPage'],
        'firstPageUrl': value['firstPageUrl'],
        'from': value['from'],
        'lastPage': value['lastPage'],
        'lastPageUrl': value['lastPageUrl'],
        'nextPageUrl': value['nextPageUrl'],
        'path': value['path'],
        'perPage': value['perPage'],
        'prevPageUrl': value['prevPageUrl'],
        'to': value['to'],
        'total': value['total'],
    };
}

