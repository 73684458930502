/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationSettings } from './NotificationSettings';
import {
    NotificationSettingsFromJSON,
    NotificationSettingsFromJSONTyped,
    NotificationSettingsToJSON,
} from './NotificationSettings';

/**
 * 
 * @export
 * @interface UpdateNotificationSettingsResponse
 */
export interface UpdateNotificationSettingsResponse {
    /**
     * 
     * @type {NotificationSettings}
     * @memberof UpdateNotificationSettingsResponse
     */
    data: NotificationSettings;
}

/**
 * Check if a given object implements the UpdateNotificationSettingsResponse interface.
 */
export function instanceOfUpdateNotificationSettingsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateNotificationSettingsResponseFromJSON(json: any): UpdateNotificationSettingsResponse {
    return UpdateNotificationSettingsResponseFromJSONTyped(json, false);
}

export function UpdateNotificationSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNotificationSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': NotificationSettingsFromJSON(json['data']),
    };
}

export function UpdateNotificationSettingsResponseToJSON(value?: UpdateNotificationSettingsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': NotificationSettingsToJSON(value['data']),
    };
}

