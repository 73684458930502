/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmEmployeeData
 */
export interface ConfirmEmployeeData {
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmployeeData
     */
    token: string;
}

/**
 * Check if a given object implements the ConfirmEmployeeData interface.
 */
export function instanceOfConfirmEmployeeData(value: object): boolean {
    if (!('token' in value)) return false;
    return true;
}

export function ConfirmEmployeeDataFromJSON(json: any): ConfirmEmployeeData {
    return ConfirmEmployeeDataFromJSONTyped(json, false);
}

export function ConfirmEmployeeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmEmployeeData {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function ConfirmEmployeeDataToJSON(value?: ConfirmEmployeeData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
    };
}

