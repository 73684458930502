import { sortBy } from '@/util/arrayFunctions';
import * as iso3166 from 'iso-3166-2';

export const countries = Object.keys(iso3166.data).reduce(
  (countryList, countryCode) => {
    const countryObj = {
      name: iso3166.data[countryCode].name,
      countryCode,
      ...iso3166.data[countryCode],
    };
    countryList.push(countryObj);
    return sortBy(countryList, 'name');
  },
  [],
);
