/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOauthTokenData
 */
export interface GetOauthTokenData {
    /**
     * 
     * @type {string}
     * @memberof GetOauthTokenData
     */
    grantType: string;
    /**
     * 
     * @type {string}
     * @memberof GetOauthTokenData
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof GetOauthTokenData
     */
    clientSecret: string;
    /**
     * A list of scopes to grant the token. Note: only scopes available to the client can be requested.
     * @type {Array<string>}
     * @memberof GetOauthTokenData
     */
    scopes: Array<string>;
    /**
     * An optional array of companyIds for which the token should be allowable. Only applies to company scoped endpoints.
     * @type {Array<number>}
     * @memberof GetOauthTokenData
     */
    companies?: Array<number>;
}

/**
 * Check if a given object implements the GetOauthTokenData interface.
 */
export function instanceOfGetOauthTokenData(value: object): boolean {
    if (!('grantType' in value)) return false;
    if (!('clientId' in value)) return false;
    if (!('clientSecret' in value)) return false;
    if (!('scopes' in value)) return false;
    return true;
}

export function GetOauthTokenDataFromJSON(json: any): GetOauthTokenData {
    return GetOauthTokenDataFromJSONTyped(json, false);
}

export function GetOauthTokenDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOauthTokenData {
    if (json == null) {
        return json;
    }
    return {
        
        'grantType': json['grant_type'],
        'clientId': json['clientId'],
        'clientSecret': json['clientSecret'],
        'scopes': json['scopes'],
        'companies': json['companies'] == null ? undefined : json['companies'],
    };
}

export function GetOauthTokenDataToJSON(value?: GetOauthTokenData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'grant_type': value['grantType'],
        'clientId': value['clientId'],
        'clientSecret': value['clientSecret'],
        'scopes': value['scopes'],
        'companies': value['companies'],
    };
}

