/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigiticketsEventSessionsReport } from './DigiticketsEventSessionsReport';
import {
    DigiticketsEventSessionsReportFromJSON,
    DigiticketsEventSessionsReportFromJSONTyped,
    DigiticketsEventSessionsReportToJSON,
} from './DigiticketsEventSessionsReport';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface DigiticketsSessionsOverview
 */
export interface DigiticketsSessionsOverview {
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof DigiticketsSessionsOverview
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsSessionsOverview
     */
    totalSpacesSold: number;
    /**
     * 
     * @type {Array<DigiticketsEventSessionsReport>}
     * @memberof DigiticketsSessionsOverview
     */
    eventSessionReports: Array<DigiticketsEventSessionsReport>;
}

/**
 * Check if a given object implements the DigiticketsSessionsOverview interface.
 */
export function instanceOfDigiticketsSessionsOverview(value: object): boolean {
    if (!('date' in value)) return false;
    if (!('totalSpacesSold' in value)) return false;
    if (!('eventSessionReports' in value)) return false;
    return true;
}

export function DigiticketsSessionsOverviewFromJSON(json: any): DigiticketsSessionsOverview {
    return DigiticketsSessionsOverviewFromJSONTyped(json, false);
}

export function DigiticketsSessionsOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigiticketsSessionsOverview {
    if (json == null) {
        return json;
    }
    return {
        
        'date': ShiftiePlainDateFromJSON(json['date']),
        'totalSpacesSold': json['totalSpacesSold'],
        'eventSessionReports': ((json['eventSessionReports'] as Array<any>).map(DigiticketsEventSessionsReportFromJSON)),
    };
}

export function DigiticketsSessionsOverviewToJSON(value?: DigiticketsSessionsOverview | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': ShiftiePlainDateToJSON(value['date']),
        'totalSpacesSold': value['totalSpacesSold'],
        'eventSessionReports': ((value['eventSessionReports'] as Array<any>).map(DigiticketsEventSessionsReportToJSON)),
    };
}

