/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateScheduleEventData
 */
export interface CreateScheduleEventData {
    /**
     * 
     * @type {string}
     * @memberof CreateScheduleEventData
     */
    scheduleEventType: CreateScheduleEventDataScheduleEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduleEventData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduleEventData
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateScheduleEventData
     */
    dateEnd: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateScheduleEventData
     */
    dateStart: Date;
    /**
     * The IDs of the schedules to apply this annotation to
     * @type {Array<number>}
     * @memberof CreateScheduleEventData
     */
    schedules?: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum CreateScheduleEventDataScheduleEventTypeEnum {
    Announcement = 'Announcement',
    BusinessClosed = 'BusinessClosed',
    LeaveRestricted = 'LeaveRestricted'
}


/**
 * Check if a given object implements the CreateScheduleEventData interface.
 */
export function instanceOfCreateScheduleEventData(value: object): boolean {
    if (!('scheduleEventType' in value)) return false;
    if (!('title' in value)) return false;
    if (!('dateEnd' in value)) return false;
    if (!('dateStart' in value)) return false;
    return true;
}

export function CreateScheduleEventDataFromJSON(json: any): CreateScheduleEventData {
    return CreateScheduleEventDataFromJSONTyped(json, false);
}

export function CreateScheduleEventDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScheduleEventData {
    if (json == null) {
        return json;
    }
    return {
        
        'scheduleEventType': json['scheduleEventType'],
        'description': json['description'] == null ? undefined : json['description'],
        'title': json['title'],
        'dateEnd': (new Date(json['dateEnd'])),
        'dateStart': (new Date(json['dateStart'])),
        'schedules': json['schedules'] == null ? undefined : json['schedules'],
    };
}

export function CreateScheduleEventDataToJSON(value?: CreateScheduleEventData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'scheduleEventType': value['scheduleEventType'],
        'description': value['description'],
        'title': value['title'],
        'dateEnd': ((value['dateEnd']).toISOString()),
        'dateStart': ((value['dateStart']).toISOString()),
        'schedules': value['schedules'],
    };
}

