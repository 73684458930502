export enum NumberFormats {
  Number = 'Number',
}

const formats = {
  [NumberFormats.Number]: {
    useGrouping: true,
  },
};

// Make sure that all locales in i18n.supportedLocales are covered here
// The repetition of locales is an annoying hack to work around a bug in VueI18n that ignores locale extensions
export const i18nNumberFormats = {
  'en-gb': formats,
  'en-us': formats,
  fr: formats,
};
