/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { PublicHoliday } from './PublicHoliday';
import {
    PublicHolidayFromJSON,
    PublicHolidayFromJSONTyped,
    PublicHolidayToJSON,
} from './PublicHoliday';

/**
 * 
 * @export
 * @interface ListPublicHolidaysResponse
 */
export interface ListPublicHolidaysResponse {
    /**
     * 
     * @type {Array<PublicHoliday>}
     * @memberof ListPublicHolidaysResponse
     */
    data: Array<PublicHoliday>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListPublicHolidaysResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListPublicHolidaysResponse interface.
 */
export function instanceOfListPublicHolidaysResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListPublicHolidaysResponseFromJSON(json: any): ListPublicHolidaysResponse {
    return ListPublicHolidaysResponseFromJSONTyped(json, false);
}

export function ListPublicHolidaysResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPublicHolidaysResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(PublicHolidayFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListPublicHolidaysResponseToJSON(value?: ListPublicHolidaysResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(PublicHolidayToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

