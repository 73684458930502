<template>
  <i
    class="text-gray-400 font-bold"
    :class="toggled ? Icon.ChevronDown : Icon.ChevronRight"
    @click="$emit('click')"
  />
</template>

<script>
import { Icon } from '@/lib/enum/Icon';

export default {
  name: 'Chevron',

  props: {
    toggled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Icon,
    };
  },
};
</script>

<style scoped lang="scss">
i {
  width: 15px;
}
</style>
