/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveSettings } from './LeaveSettings';
import {
    LeaveSettingsFromJSON,
    LeaveSettingsFromJSONTyped,
    LeaveSettingsToJSON,
} from './LeaveSettings';

/**
 * 
 * @export
 * @interface UpdateLeaveSettingsResponse
 */
export interface UpdateLeaveSettingsResponse {
    /**
     * 
     * @type {LeaveSettings}
     * @memberof UpdateLeaveSettingsResponse
     */
    data: LeaveSettings;
}

/**
 * Check if a given object implements the UpdateLeaveSettingsResponse interface.
 */
export function instanceOfUpdateLeaveSettingsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateLeaveSettingsResponseFromJSON(json: any): UpdateLeaveSettingsResponse {
    return UpdateLeaveSettingsResponseFromJSONTyped(json, false);
}

export function UpdateLeaveSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeaveSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveSettingsFromJSON(json['data']),
    };
}

export function UpdateLeaveSettingsResponseToJSON(value?: UpdateLeaveSettingsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeaveSettingsToJSON(value['data']),
    };
}

