/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
} from './Referral';

/**
 * 
 * @export
 * @interface ListReferralsResponse
 */
export interface ListReferralsResponse {
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ListReferralsResponse
     */
    data: Array<Referral>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListReferralsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListReferralsResponse interface.
 */
export function instanceOfListReferralsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListReferralsResponseFromJSON(json: any): ListReferralsResponse {
    return ListReferralsResponseFromJSONTyped(json, false);
}

export function ListReferralsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListReferralsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ReferralFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListReferralsResponseToJSON(value?: ListReferralsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(ReferralToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

