/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OpenShiftResponse } from './OpenShiftResponse';
import {
    OpenShiftResponseFromJSON,
    OpenShiftResponseFromJSONTyped,
    OpenShiftResponseToJSON,
} from './OpenShiftResponse';

/**
 * 
 * @export
 * @interface CreateOpenShiftResponseResponse
 */
export interface CreateOpenShiftResponseResponse {
    /**
     * 
     * @type {OpenShiftResponse}
     * @memberof CreateOpenShiftResponseResponse
     */
    data?: OpenShiftResponse;
}

/**
 * Check if a given object implements the CreateOpenShiftResponseResponse interface.
 */
export function instanceOfCreateOpenShiftResponseResponse(value: object): boolean {
    return true;
}

export function CreateOpenShiftResponseResponseFromJSON(json: any): CreateOpenShiftResponseResponse {
    return CreateOpenShiftResponseResponseFromJSONTyped(json, false);
}

export function CreateOpenShiftResponseResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOpenShiftResponseResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : OpenShiftResponseFromJSON(json['data']),
    };
}

export function CreateOpenShiftResponseResponseToJSON(value?: CreateOpenShiftResponseResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': OpenShiftResponseToJSON(value['data']),
    };
}

