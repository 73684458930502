import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

const key = process.env.VUE_APP_MAPBOX_KEY;
export const KM_PER_DEGREE = 111.32;
const EarthRadiusInMeters = 6371000;
/**
 * Represents a longitude value in degrees using a float in the range -180 to 180.
 * @typedef {number} Longitude
 */
type Longitude = number;
/**
 * Represents a latitude value in degrees using a float in the range -90 to 90.
 * @typedef {number} Latitude
 */
type Latitude = number;
/**
 * Represents a set of coordinates as a tuple of longitude and latitude values.
 * @typedef {[Longitude, Latitude]} Coordinates
 */
export type Coordinates = [Longitude, Latitude];

export const mapInit = (
  container: string,
  center: Coordinates,
  zoom: number,
) => {
  mapboxgl.accessToken = key;
  return new mapboxgl.Map({
    style: 'mapbox://styles/mapbox/streets-v12',
    attributionControl: false,
    interactive: true,
    center,
    container,
    doubleClickZoom: true,
    zoom,
  });
};

export const addMarker = (
  map: mapboxgl.Map,
  coordinates: Coordinates,
  options: { draggable?: boolean; color?: string; element?: HTMLElement } = {
    draggable: false,
  },
) =>
  new mapboxgl.Marker(options)
    .setLngLat({ lng: coordinates[0], lat: coordinates[1] })
    .addTo(map);

export const addSearch = (
  containerId: string,
  map: mapboxgl.Map,
  placeholder: TranslateResult,
  handleInput: () => void | null = null,
) => {
  const geocoder = new MapboxGeocoder({
    accessToken: key,
    marker: false,
    reverseGeocode: true,
    mapboxgl,
  });

  const container = document.getElementById(containerId);
  if (container) {
    container.replaceWith(geocoder.onAdd(map));
  }

  const input = document.querySelector(
    '.mapboxgl-ctrl-geocoder--input',
  ) as HTMLInputElement | null;
  if (input) {
    input.placeholder = placeholder.toString();
    if (handleInput) {
      input.addEventListener('input', handleInput);
    }
  }

  geocoder.clear();

  if (input) {
    input.blur();
  }

  return geocoder;
};

// https://stackoverflow.com/a/39006388/5770546
export const createGeoJSONCircle = (
  center: Coordinates,
  radiusMeters: number,
  points: number = 64,
) => {
  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const ret = [];
  const distanceX =
    radiusMeters /
    1000 /
    (KM_PER_DEGREE * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = radiusMeters / 1000 / KM_PER_DEGREE;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i += 1) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'geojson',
    data: {
      type: 'Polygon',
      coordinates: [ret],
    },
  };
};

/**
 * Bounding latitude will return the highest point if the number you provide is above it,
 * Will return the lowest point if the number you provide is below it
 * @param latitude: number
 */
export const boundLatitude = (latitude: number): number => {
  const max = 90;
  const min = -90;
  if (latitude > max) return max;
  if (latitude < min) return min;
  return latitude;
};
/**
 * Bounding longitude will return the overlap into a max or min longitude value
 * Useful for mapbox.fitBounds where you want space either side
 * @param longitude: number
 */
export const boundLongitude = (longitude: number): number => {
  const max = 180;
  const min = -180;
  if (longitude > max) return min + (longitude % max);
  if (longitude < min) return max + (longitude % max);
  return longitude;
};
/**
 * Transform distance to North or to South directions from meters to latitude degrees
 * @param distanceInMeters: number
 */
export const distanceInMetersToLatitudeDegrees = (distanceInMeters: number) =>
  (distanceInMeters / EarthRadiusInMeters) * (180 / Math.PI);
