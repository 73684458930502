/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingPlan } from './BillingPlan';
import {
    BillingPlanFromJSON,
    BillingPlanFromJSONTyped,
    BillingPlanToJSON,
} from './BillingPlan';

/**
 * 
 * @export
 * @interface ShowBillingPlanResponse
 */
export interface ShowBillingPlanResponse {
    /**
     * 
     * @type {BillingPlan}
     * @memberof ShowBillingPlanResponse
     */
    data: BillingPlan;
}

/**
 * Check if a given object implements the ShowBillingPlanResponse interface.
 */
export function instanceOfShowBillingPlanResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowBillingPlanResponseFromJSON(json: any): ShowBillingPlanResponse {
    return ShowBillingPlanResponseFromJSONTyped(json, false);
}

export function ShowBillingPlanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowBillingPlanResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingPlanFromJSON(json['data']),
    };
}

export function ShowBillingPlanResponseToJSON(value?: ShowBillingPlanResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': BillingPlanToJSON(value['data']),
    };
}

