/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateWorkPatternData,
  CreateWorkPatternResponse,
  ListWorkPatternsResponse,
  ShowWorkPatternResponse,
  UpdateWorkPatternData,
  UpdateWorkPatternResponse,
} from '../models/index';
import {
    CreateWorkPatternDataFromJSON,
    CreateWorkPatternDataToJSON,
    CreateWorkPatternResponseFromJSON,
    CreateWorkPatternResponseToJSON,
    ListWorkPatternsResponseFromJSON,
    ListWorkPatternsResponseToJSON,
    ShowWorkPatternResponseFromJSON,
    ShowWorkPatternResponseToJSON,
    UpdateWorkPatternDataFromJSON,
    UpdateWorkPatternDataToJSON,
    UpdateWorkPatternResponseFromJSON,
    UpdateWorkPatternResponseToJSON,
} from '../models/index';

export interface CreateWorkPatternRequest {
    createWorkPatternData?: CreateWorkPatternData;
}

export interface DeleteWorkPatternRequest {
    id: number;
}

export interface ListWorkPatternsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowWorkPatternRequest {
    id: number;
}

export interface UpdateWorkPatternRequest {
    id: number;
    updateWorkPatternData?: UpdateWorkPatternData;
}

/**
 * 
 */
export class WorkPatternApi extends runtime.BaseAPI {

    /**
     * Create Work Pattern
     */
    async createWorkPatternRaw(requestParameters: CreateWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateWorkPatternResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkPatternDataToJSON(requestParameters['createWorkPatternData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateWorkPatternResponseFromJSON(jsonValue));
    }

    /**
     * Create Work Pattern
     */
    async createWorkPattern(requestParameters: CreateWorkPatternRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateWorkPatternResponse> {
        const response = await this.createWorkPatternRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Work Pattern
     */
    async deleteWorkPatternRaw(requestParameters: DeleteWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteWorkPattern().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Work Pattern
     */
    async deleteWorkPattern(requestParameters: DeleteWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteWorkPatternRaw(requestParameters, initOverrides);
    }

    /**
     * List Work Patterns
     */
    async listWorkPatternsRaw(requestParameters: ListWorkPatternsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListWorkPatternsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListWorkPatternsResponseFromJSON(jsonValue));
    }

    /**
     * List Work Patterns
     */
    async listWorkPatterns(requestParameters: ListWorkPatternsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListWorkPatternsResponse> {
        const response = await this.listWorkPatternsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Work Pattern
     */
    async showWorkPatternRaw(requestParameters: ShowWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowWorkPatternResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showWorkPattern().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowWorkPatternResponseFromJSON(jsonValue));
    }

    /**
     * Show Work Pattern
     */
    async showWorkPattern(requestParameters: ShowWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowWorkPatternResponse> {
        const response = await this.showWorkPatternRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Work Pattern
     */
    async updateWorkPatternRaw(requestParameters: UpdateWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateWorkPatternResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateWorkPattern().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkPatternDataToJSON(requestParameters['updateWorkPatternData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateWorkPatternResponseFromJSON(jsonValue));
    }

    /**
     * Update Work Pattern
     */
    async updateWorkPattern(requestParameters: UpdateWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateWorkPatternResponse> {
        const response = await this.updateWorkPatternRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
