/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingPaymentMethod } from './BillingPaymentMethod';
import {
    BillingPaymentMethodFromJSON,
    BillingPaymentMethodFromJSONTyped,
    BillingPaymentMethodToJSON,
} from './BillingPaymentMethod';

/**
 * 
 * @export
 * @interface SetPaymentMethodResponse
 */
export interface SetPaymentMethodResponse {
    /**
     * 
     * @type {BillingPaymentMethod}
     * @memberof SetPaymentMethodResponse
     */
    data: BillingPaymentMethod;
}

/**
 * Check if a given object implements the SetPaymentMethodResponse interface.
 */
export function instanceOfSetPaymentMethodResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function SetPaymentMethodResponseFromJSON(json: any): SetPaymentMethodResponse {
    return SetPaymentMethodResponseFromJSONTyped(json, false);
}

export function SetPaymentMethodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPaymentMethodResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingPaymentMethodFromJSON(json['data']),
    };
}

export function SetPaymentMethodResponseToJSON(value?: SetPaymentMethodResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': BillingPaymentMethodToJSON(value['data']),
    };
}

