/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CacheMeta } from './CacheMeta';
import {
    CacheMetaFromJSON,
    CacheMetaFromJSONTyped,
    CacheMetaToJSON,
} from './CacheMeta';
import type { WeatherReport } from './WeatherReport';
import {
    WeatherReportFromJSON,
    WeatherReportFromJSONTyped,
    WeatherReportToJSON,
} from './WeatherReport';

/**
 * 
 * @export
 * @interface ListWeatherForAreaResponse
 */
export interface ListWeatherForAreaResponse {
    /**
     * 
     * @type {CacheMeta}
     * @memberof ListWeatherForAreaResponse
     */
    cache: CacheMeta;
    /**
     * 
     * @type {Array<WeatherReport>}
     * @memberof ListWeatherForAreaResponse
     */
    data: Array<WeatherReport>;
}

/**
 * Check if a given object implements the ListWeatherForAreaResponse interface.
 */
export function instanceOfListWeatherForAreaResponse(value: object): boolean {
    if (!('cache' in value)) return false;
    if (!('data' in value)) return false;
    return true;
}

export function ListWeatherForAreaResponseFromJSON(json: any): ListWeatherForAreaResponse {
    return ListWeatherForAreaResponseFromJSONTyped(json, false);
}

export function ListWeatherForAreaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListWeatherForAreaResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'cache': CacheMetaFromJSON(json['cache']),
        'data': ((json['data'] as Array<any>).map(WeatherReportFromJSON)),
    };
}

export function ListWeatherForAreaResponseToJSON(value?: ListWeatherForAreaResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cache': CacheMetaToJSON(value['cache']),
        'data': ((value['data'] as Array<any>).map(WeatherReportToJSON)),
    };
}

