/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateLeavePolicyData,
  CreateLeavePolicyResponse,
  ListLeavePoliciesResponse,
  ShowLeavePolicyResponse,
  UpdateLeavePolicyData,
  UpdateLeavePolicyResponse,
} from '../models/index';
import {
    CreateLeavePolicyDataFromJSON,
    CreateLeavePolicyDataToJSON,
    CreateLeavePolicyResponseFromJSON,
    CreateLeavePolicyResponseToJSON,
    ListLeavePoliciesResponseFromJSON,
    ListLeavePoliciesResponseToJSON,
    ShowLeavePolicyResponseFromJSON,
    ShowLeavePolicyResponseToJSON,
    UpdateLeavePolicyDataFromJSON,
    UpdateLeavePolicyDataToJSON,
    UpdateLeavePolicyResponseFromJSON,
    UpdateLeavePolicyResponseToJSON,
} from '../models/index';

export interface CreateLeavePolicyRequest {
    createLeavePolicyData?: CreateLeavePolicyData;
}

export interface DeleteLeavePolicyRequest {
    id: number;
}

export interface ListLeavePoliciesRequest {
    page?: number;
    perPage?: number;
    orderBy?: Array<string>;
    where?: { [key: string]: object; };
    limit?: number;
}

export interface ShowLeavePolicyRequest {
    id: number;
}

export interface UpdateLeavePolicyRequest {
    id: number;
    updateLeavePolicyData?: UpdateLeavePolicyData;
}

/**
 * 
 */
export class LeavePolicyApi extends runtime.BaseAPI {

    /**
     * Create Leave Policy
     */
    async createLeavePolicyRaw(requestParameters: CreateLeavePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLeavePolicyResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-policy.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeavePolicyDataToJSON(requestParameters['createLeavePolicyData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLeavePolicyResponseFromJSON(jsonValue));
    }

    /**
     * Create Leave Policy
     */
    async createLeavePolicy(requestParameters: CreateLeavePolicyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLeavePolicyResponse> {
        const response = await this.createLeavePolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Delete Leave Policy
     */
    async deleteLeavePolicyRaw(requestParameters: DeleteLeavePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteLeavePolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-policy.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     * Delete Leave Policy
     */
    async deleteLeavePolicy(requestParameters: DeleteLeavePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLeavePolicyRaw(requestParameters, initOverrides);
    }

    /**
     * List Leave Policies
     */
    async listLeavePoliciesRaw(requestParameters: ListLeavePoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeavePoliciesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-policy.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeavePoliciesResponseFromJSON(jsonValue));
    }

    /**
     * List Leave Policies
     */
    async listLeavePolicies(requestParameters: ListLeavePoliciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeavePoliciesResponse> {
        const response = await this.listLeavePoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Leave Policy
     */
    async showLeavePolicyRaw(requestParameters: ShowLeavePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowLeavePolicyResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showLeavePolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-policy.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowLeavePolicyResponseFromJSON(jsonValue));
    }

    /**
     * Show Leave Policy
     */
    async showLeavePolicy(requestParameters: ShowLeavePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowLeavePolicyResponse> {
        const response = await this.showLeavePolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Leave Policy
     */
    async updateLeavePolicyRaw(requestParameters: UpdateLeavePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateLeavePolicyResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLeavePolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-policy.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeavePolicyDataToJSON(requestParameters['updateLeavePolicyData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateLeavePolicyResponseFromJSON(jsonValue));
    }

    /**
     * Update Leave Policy
     */
    async updateLeavePolicy(requestParameters: UpdateLeavePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateLeavePolicyResponse> {
        const response = await this.updateLeavePolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
