
import CloseButton from '@/components/buttons/dedicated/CloseButton.vue';
import EmployeeAvatar from '@/components/employee/EmployeeAvatar.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import { Icon } from '@/lib/enum/Icon';
import { fetchCollection } from '@/lib/realtime/realtimeFunctions';
import { Entity } from '@/lib/store/realtimeEntities';
import { viewport } from '@/util/windowFunctions';
import { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';
import { Employee } from '../../../../../api/v1';

export default {
  name: 'ShiftDialogHeader',
  components: {
    CloseButton,
    EmployeeAvatar,
    BubbleIcon,
  },
  props: {
    title: {
      type: String as PropType<string | TranslateResult>,
      required: true,
    },
    subHeading: {
      type: String as PropType<string | TranslateResult>,
      default: '',
    },
    invertHeading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String as () => Icon,
      default: Icon.User,
    },
    employeeId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      Icon,
      employees: fetchCollection(Entity.Employee),
      viewport,
    };
  },

  computed: {
    employee(): Employee | null {
      if (!this.employeeId) return null;
      return this.employees.data.find((e) => e.id === this.employeeId);
    },
  },
};
