/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
} from './Document';

/**
 * 
 * @export
 * @interface CreateDocumentResponse
 */
export interface CreateDocumentResponse {
    /**
     * 
     * @type {Document}
     * @memberof CreateDocumentResponse
     */
    data?: Document;
}

/**
 * Check if a given object implements the CreateDocumentResponse interface.
 */
export function instanceOfCreateDocumentResponse(value: object): boolean {
    return true;
}

export function CreateDocumentResponseFromJSON(json: any): CreateDocumentResponse {
    return CreateDocumentResponseFromJSONTyped(json, false);
}

export function CreateDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocumentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : DocumentFromJSON(json['data']),
    };
}

export function CreateDocumentResponseToJSON(value?: CreateDocumentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': DocumentToJSON(value['data']),
    };
}

