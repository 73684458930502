/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface CreateAbsenceRequestData
 */
export interface CreateAbsenceRequestData {
    /**
     * 
     * @type {number}
     * @memberof CreateAbsenceRequestData
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAbsenceRequestData
     */
    absenceCategoryId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateAbsenceRequestData
     */
    startedOn: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateAbsenceRequestData
     */
    endedOn?: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAbsenceRequestData
     */
    startedMidday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAbsenceRequestData
     */
    endedMidday?: boolean;
    /**
     * Whether the employee will be paid for this period of absence
     * @type {boolean}
     * @memberof CreateAbsenceRequestData
     */
    paid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAbsenceRequestData
     */
    notes?: string;
}

/**
 * Check if a given object implements the CreateAbsenceRequestData interface.
 */
export function instanceOfCreateAbsenceRequestData(value: object): boolean {
    if (!('employeeId' in value)) return false;
    if (!('absenceCategoryId' in value)) return false;
    if (!('startedOn' in value)) return false;
    return true;
}

export function CreateAbsenceRequestDataFromJSON(json: any): CreateAbsenceRequestData {
    return CreateAbsenceRequestDataFromJSONTyped(json, false);
}

export function CreateAbsenceRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAbsenceRequestData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'absenceCategoryId': json['absenceCategoryId'],
        'startedOn': ShiftiePlainDateFromJSON(json['startedOn']),
        'endedOn': json['endedOn'] == null ? undefined : ShiftiePlainDateFromJSON(json['endedOn']),
        'startedMidday': json['startedMidday'] == null ? undefined : json['startedMidday'],
        'endedMidday': json['endedMidday'] == null ? undefined : json['endedMidday'],
        'paid': json['paid'] == null ? undefined : json['paid'],
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

export function CreateAbsenceRequestDataToJSON(value?: CreateAbsenceRequestData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employeeId': value['employeeId'],
        'absenceCategoryId': value['absenceCategoryId'],
        'startedOn': ShiftiePlainDateToJSON(value['startedOn']),
        'endedOn': ShiftiePlainDateToJSON(value['endedOn']),
        'startedMidday': value['startedMidday'],
        'endedMidday': value['endedMidday'],
        'paid': value['paid'],
        'notes': value['notes'],
    };
}

