/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { ShiftSwap } from './ShiftSwap';
import {
    ShiftSwapFromJSON,
    ShiftSwapFromJSONTyped,
    ShiftSwapToJSON,
} from './ShiftSwap';

/**
 * 
 * @export
 * @interface ListShiftSwapsResponse
 */
export interface ListShiftSwapsResponse {
    /**
     * 
     * @type {Array<ShiftSwap>}
     * @memberof ListShiftSwapsResponse
     */
    data: Array<ShiftSwap>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftSwapsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListShiftSwapsResponse interface.
 */
export function instanceOfListShiftSwapsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListShiftSwapsResponseFromJSON(json: any): ListShiftSwapsResponse {
    return ListShiftSwapsResponseFromJSONTyped(json, false);
}

export function ListShiftSwapsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftSwapsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ShiftSwapFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListShiftSwapsResponseToJSON(value?: ListShiftSwapsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(ShiftSwapToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

