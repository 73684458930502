/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';

/**
 * 
 * @export
 * @interface ShowEmployeeResponse
 */
export interface ShowEmployeeResponse {
    /**
     * 
     * @type {Employee}
     * @memberof ShowEmployeeResponse
     */
    data?: Employee;
}

/**
 * Check if a given object implements the ShowEmployeeResponse interface.
 */
export function instanceOfShowEmployeeResponse(value: object): boolean {
    return true;
}

export function ShowEmployeeResponseFromJSON(json: any): ShowEmployeeResponse {
    return ShowEmployeeResponseFromJSONTyped(json, false);
}

export function ShowEmployeeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : EmployeeFromJSON(json['data']),
    };
}

export function ShowEmployeeResponseToJSON(value?: ShowEmployeeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeToJSON(value['data']),
    };
}

