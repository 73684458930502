/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Describes a role that an employee can fulfill within a company
 * @export
 * @interface JobRole
 */
export interface JobRole {
    /**
     * 
     * @type {number}
     * @memberof JobRole
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof JobRole
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof JobRole
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof JobRole
     */
    status: JobRoleStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JobRole
     */
    colour: string;
    /**
     * 
     * @type {Date}
     * @memberof JobRole
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof JobRole
     */
    updatedAt: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum JobRoleStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the JobRole interface.
 */
export function instanceOfJobRole(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('status' in value)) return false;
    if (!('colour' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function JobRoleFromJSON(json: any): JobRole {
    return JobRoleFromJSONTyped(json, false);
}

export function JobRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobRole {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'status': json['status'],
        'colour': json['colour'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

export function JobRoleToJSON(value?: JobRole | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'status': value['status'],
        'colour': value['colour'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

