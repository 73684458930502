<template>
  <div class="all:flex">
    <span
      v-for="n in iteration"
      :key="n"
      :style="[{ '--n': n }, { '--total': iteration }]"
      :class="`bg-${colour}`"
    />
  </div>
</template>

<script>
export default {
  name: 'LoadingDot',

  props: {
    iteration: {
      type: Number,
      required: true,
    },
    colour: {
      type: String,
      default: 'gray-200',
    },
  },
};
</script>

<style scoped lang="scss">
span {
  animation: fadeIn 1s ease infinite alternate;
  animation-delay: calc(0.1s * (var(--total) + var(--n)));
  display: inline-flex;
  border-radius: 50%;
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
}
</style>
