/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeJobRole } from './EmployeeJobRole';
import {
    EmployeeJobRoleFromJSON,
    EmployeeJobRoleFromJSONTyped,
    EmployeeJobRoleToJSON,
} from './EmployeeJobRole';

/**
 * 
 * @export
 * @interface ShowEmployeeJobRoleResponse
 */
export interface ShowEmployeeJobRoleResponse {
    /**
     * 
     * @type {EmployeeJobRole}
     * @memberof ShowEmployeeJobRoleResponse
     */
    data?: EmployeeJobRole;
}

/**
 * Check if a given object implements the ShowEmployeeJobRoleResponse interface.
 */
export function instanceOfShowEmployeeJobRoleResponse(value: object): boolean {
    return true;
}

export function ShowEmployeeJobRoleResponseFromJSON(json: any): ShowEmployeeJobRoleResponse {
    return ShowEmployeeJobRoleResponseFromJSONTyped(json, false);
}

export function ShowEmployeeJobRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeJobRoleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : EmployeeJobRoleFromJSON(json['data']),
    };
}

export function ShowEmployeeJobRoleResponseToJSON(value?: ShowEmployeeJobRoleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeJobRoleToJSON(value['data']),
    };
}

