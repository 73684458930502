<template>
  <div class="all:flex">
    <Sidebar />

    <div class="w-full all:flex all:flex-col">
      <TopbarWarning />
      <Topbar />
      <FreshdeskLauncher />
      <!-- Layout component -->
      <component :is="layout">
        <!-- The routes are loaded here -->
        <slot />
        <OnboardingWidget />
      </component>
    </div>
  </div>
</template>

<script>
import FreshdeskLauncher from '@/components/FreshdeskLauncher';
import TopbarWarning from '@/components/info/TopbarWarning.vue';
import OnboardingWidget from '@/components/widgets/onboarding-widget/OnboardingWidget.vue';
import Sidebar from '@/layouts/components/Sidebar';
import Topbar from '@/layouts/components/Topbar';
import DefaultPageLayout from '@/layouts/page/DefaultPageLayout';
import EmptyPageLayout from '@/layouts/page/EmptyPageLayout';
import ReportsLayout from '@/layouts/page/ReportsLayout.vue';
import SettingsLayout from '@/layouts/page/SettingsLayout';
import { Layout } from '@/lib/enum/LayoutEnum';
import { userCan } from '@/lib/permission/userCan';
import { routes } from '@/router/routes';

export default {
  name: 'DefaultLayout',

  components: {
    TopbarWarning,
    OnboardingWidget,
    FreshdeskLauncher,
    Sidebar,
    Topbar,
  },

  data() {
    return {
      routes,
      userCan,
    };
  },

  computed: {
    layout() {
      const contentLayout = this.$route.matched[0]?.meta?.contentLayout;
      if (contentLayout === Layout.Settings) {
        return SettingsLayout;
      }
      if (contentLayout === Layout.Reports) {
        return ReportsLayout;
      }
      if (contentLayout === Layout.EmptyPageLayout) {
        return EmptyPageLayout;
      }
      return DefaultPageLayout;
    },
  },
};
</script>
