/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifyEmailData
 */
export interface VerifyEmailData {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailData
     */
    token: string;
}

/**
 * Check if a given object implements the VerifyEmailData interface.
 */
export function instanceOfVerifyEmailData(value: object): boolean {
    if (!('token' in value)) return false;
    return true;
}

export function VerifyEmailDataFromJSON(json: any): VerifyEmailData {
    return VerifyEmailDataFromJSONTyped(json, false);
}

export function VerifyEmailDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyEmailData {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function VerifyEmailDataToJSON(value?: VerifyEmailData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
    };
}

