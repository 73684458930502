export enum EventsEnum {
  AddLeave = 'AddLeave',
  AddUnavailability = 'AddUnavailability',
  FetchSchedule = 'FetchSchedule',
  OpenPublishScheduleDialog = 'OpenPublishScheduleDialog',
  ToggleContextMenu = 'ToggleContextMenu',
  ToggleFilter = 'ToggleFilter',
  ToggleFreshdeskLauncher = 'ToggleFreshdeskLauncher',
  ToggleModal = 'ToggleModal',
  ToggleBillingDialog = 'ToggleBillingDialog',
  TogglePackageDialog = 'TogglePackageDialog',
  ToggleRestoreDialog = 'ToggleRestoreDialog',
  ToggleShiftMenu = 'ToggleShiftMenu',
}
