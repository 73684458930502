/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
} from './JobRole';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListJobRolesResponse
 */
export interface ListJobRolesResponse {
    /**
     * 
     * @type {Array<JobRole>}
     * @memberof ListJobRolesResponse
     */
    data: Array<JobRole>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListJobRolesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListJobRolesResponse interface.
 */
export function instanceOfListJobRolesResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListJobRolesResponseFromJSON(json: any): ListJobRolesResponse {
    return ListJobRolesResponseFromJSONTyped(json, false);
}

export function ListJobRolesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListJobRolesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(JobRoleFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListJobRolesResponseToJSON(value?: ListJobRolesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(JobRoleToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

