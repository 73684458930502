import debounce from 'lodash.debounce';
import { reactive } from 'vue';

export const requestDownload = (url: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
};

/*
  Reactive vue object that contains the current window size.
  Since Vue 3 uses Proxy instead of mutating an existing object,
  we should use the reactive object
  while updating its values outside any component.
*/
export const windowSize = reactive({
  width: window.screen.width,
  height: window.screen.height,
});
window.addEventListener(
  'resize',
  debounce(() => {
    windowSize.height = window.innerHeight;
    windowSize.width = window.innerWidth;
  }),
);
