/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginData
 */
export interface LoginData {
    /**
     * 
     * @type {string}
     * @memberof LoginData
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginData
     */
    password: string;
    /**
     * Which scopes the issues access token should be assigned.
     * @type {Array<string>}
     * @memberof LoginData
     */
    scopes: Array<string>;
    /**
     * An optional array of companyIds for which the token should be allowable. Only applies to company scoped endpoints.
     * @type {Array<number>}
     * @memberof LoginData
     */
    companies?: Array<number>;
}

/**
 * Check if a given object implements the LoginData interface.
 */
export function instanceOfLoginData(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('password' in value)) return false;
    if (!('scopes' in value)) return false;
    return true;
}

export function LoginDataFromJSON(json: any): LoginData {
    return LoginDataFromJSONTyped(json, false);
}

export function LoginDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginData {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'scopes': json['scopes'],
        'companies': json['companies'] == null ? undefined : json['companies'],
    };
}

export function LoginDataToJSON(value?: LoginData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'password': value['password'],
        'scopes': value['scopes'],
        'companies': value['companies'],
    };
}

