/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOpenShiftResponseData
 */
export interface CreateOpenShiftResponseData {
    /**
     * 
     * @type {number}
     * @memberof CreateOpenShiftResponseData
     */
    shiftId: number;
}

/**
 * Check if a given object implements the CreateOpenShiftResponseData interface.
 */
export function instanceOfCreateOpenShiftResponseData(value: object): boolean {
    if (!('shiftId' in value)) return false;
    return true;
}

export function CreateOpenShiftResponseDataFromJSON(json: any): CreateOpenShiftResponseData {
    return CreateOpenShiftResponseDataFromJSONTyped(json, false);
}

export function CreateOpenShiftResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOpenShiftResponseData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftId': json['shiftId'],
    };
}

export function CreateOpenShiftResponseDataToJSON(value?: CreateOpenShiftResponseData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shiftId': value['shiftId'],
    };
}

