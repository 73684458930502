import i18n from '@/i18n';
import {
  MenuItem,
  MenuSection,
  toItemMap,
  toSectionMap,
} from '@/lib/menus/helpers';
import { userCan } from '@/lib/permission/userCan';
import { routes } from '@/router/routes';
import { Icon } from '../enum/Icon';

const reportItem = toItemMap({
  /* shift cost ('hide' prop is being handled by parent item) */
  shiftDailyTotals: (): MenuItem => ({
    name: i18n.tc('route.title.shiftDailyTotals'),
    route: routes.shiftDailyTotals.route(),
    hide: false,
  }),
  shiftTotalsByEmployee: (): MenuItem => ({
    name: i18n.tc('route.title.shiftTotalsByEmployee'),
    route: routes.shiftTotalsByEmployee.route(),
    hide: false,
  }),
  shiftTotalsByJobRole: (): MenuItem => ({
    name: i18n.tc('route.title.shiftTotalsByJobRole'),
    route: routes.shiftTotalsByJobRole.route(),
    hide: false,
  }),
  shiftTotalsByLocation: (): MenuItem => ({
    name: i18n.tc('route.title.shiftTotalsByLocation'),
    route: routes.shiftTotalsByLocation.route(),
    hide: false,
  }),
  shiftTotalsByGroup: (): MenuItem => ({
    name: i18n.tc('route.title.shiftTotalsByGroup'),
    route: routes.shiftTotalsByGroup.route(),
    hide: false,
  }),
  shiftTotalsByEmploymentType: (): MenuItem => ({
    name: i18n.tc('route.title.shiftTotalsByEmploymentType'),
    route: routes.shiftTotalsByEmploymentType.route(),
    hide: false,
  }),
  /* shift cost */
  /* logs */
  messageHistory: (): MenuItem => ({
    name: i18n.t('route.title.messageHistory'),
    route: routes.messageHistory.route(),
    hide: !userCan.viewMessageLogs(),
  }),
  /* logs */
});

export const reportsMenu = toSectionMap({
  shiftCost: (): MenuSection => ({
    name: i18n.tc('nav.reports.menu.hoursAndCosts'),
    icon: Icon.CalendarWeek,
    open: (routeName: string) =>
      reportsMenu
        .shiftCost()
        .children.some((child) => child.route.name === routeName),
    hide: !userCan.viewShiftCostReports(),
    children: [
      reportItem.shiftDailyTotals(),
      reportItem.shiftTotalsByEmployee(),
      reportItem.shiftTotalsByJobRole(),
      reportItem.shiftTotalsByLocation(),
      reportItem.shiftTotalsByGroup(),
      reportItem.shiftTotalsByEmploymentType(),
    ],
  }),
  logs: (): MenuSection => ({
    name: i18n.tc('nav.reports.menu.logs'),
    icon: Icon.ClipboardList,
    open: (routeName: string) =>
      reportsMenu
        .logs()
        .children.some((child) => child.route.name === routeName),
    hide: !userCan.viewMessageLogs(), // might be changed if 'children' array would have more items
    children: [reportItem.messageHistory()],
  }),
});
