
import ToolTipIcon from '@/components/info/ToolTipIcon';
import Chevron from '@/components/interface/Chevron';
import { Icon } from '@/lib/enum/Icon';

/**
 * Base component for an accordion.
 *
 * `heading` property is used to define the header for the box
 *
 * `closed` boolean property to define whether the box should default to an open or closed state.
 *
 *  `togglePosition` property defines if the toggle is below or above the content
 *
 * Use the `default` slots to fill any number of components within the box. This is typically used within the `Sidebar`
 * components and `Checkbox` components as a filter.
 */

const togglePositions = {
  top: 'top',
  bottom: 'bottom',
};

const iconAlignment = {
  center: 'center',
  right: 'right',
};

export default {
  name: 'AccordionBox',

  components: {
    Chevron,
    ToolTipIcon,
  },

  props: {
    heading: {
      type: String,
      default: null,
    },

    subHeading: {
      type: String,
      default: null,
    },

    closed: {
      type: Boolean,
      default: false,
    },

    togglePosition: {
      type: String,
      default: togglePositions.top,
      validator: (prop) => Object.values(togglePositions).includes(prop),
    },

    tooltip: {
      type: String,
      default: null,
    },

    iconLeft: {
      type: String, // Icon enum
      default: null,
    },
    // Causes box to be permanently open and untoggleable
    disabled: {
      type: Boolean,
      default: false,
    },

    hoverEffect: {
      type: Boolean,
      default: false,
    },

    hidden: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Icon,
      togglePositions,
      iconAlignment,
      open: this.closed ? [1] : [0],
    };
  },
};
