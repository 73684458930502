/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SentEmail } from './SentEmail';
import {
    SentEmailFromJSON,
    SentEmailFromJSONTyped,
    SentEmailToJSON,
} from './SentEmail';

/**
 * 
 * @export
 * @interface EmployeeMessageRecipient
 */
export interface EmployeeMessageRecipient {
    /**
     * 
     * @type {string}
     * @memberof EmployeeMessageRecipient
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMessageRecipient
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMessageRecipient
     */
    recipientId: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMessageRecipient
     */
    employeeMessageId: string;
    /**
     * 
     * @type {SentEmail}
     * @memberof EmployeeMessageRecipient
     */
    sentEmail: SentEmail;
}

/**
 * Check if a given object implements the EmployeeMessageRecipient interface.
 */
export function instanceOfEmployeeMessageRecipient(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('recipientId' in value)) return false;
    if (!('employeeMessageId' in value)) return false;
    if (!('sentEmail' in value)) return false;
    return true;
}

export function EmployeeMessageRecipientFromJSON(json: any): EmployeeMessageRecipient {
    return EmployeeMessageRecipientFromJSONTyped(json, false);
}

export function EmployeeMessageRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeMessageRecipient {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'recipientId': json['recipientId'],
        'employeeMessageId': json['employeeMessageId'],
        'sentEmail': SentEmailFromJSON(json['sentEmail']),
    };
}

export function EmployeeMessageRecipientToJSON(value?: EmployeeMessageRecipient | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'recipientId': value['recipientId'],
        'employeeMessageId': value['employeeMessageId'],
        'sentEmail': SentEmailToJSON(value['sentEmail']),
    };
}

