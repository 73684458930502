/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListCompanyUsersResponse,
} from '../models/index';
import {
    ListCompanyUsersResponseFromJSON,
    ListCompanyUsersResponseToJSON,
} from '../models/index';

export interface ListCompanyUsersRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * List basic details for all the users in the current company.
     * List Companies Users
     */
    async listCompanyUsersRaw(requestParameters: ListCompanyUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListCompanyUsersResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.user.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCompanyUsersResponseFromJSON(jsonValue));
    }

    /**
     * List basic details for all the users in the current company.
     * List Companies Users
     */
    async listCompanyUsers(requestParameters: ListCompanyUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCompanyUsersResponse> {
        const response = await this.listCompanyUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
