<template>
  <div class="flex w-full route-container">
    <div class="route-wrapper mx-auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultPageLayout',
};
</script>

<style scoped lang="scss">
.route-container {
  transition: all 0.5s ease;
  max-width: calc(100vw - 65px) !important;

  .route-wrapper {
    width: 100%;
    max-width: $desktopMax;
    padding: 2rem;
  }
}
</style>
