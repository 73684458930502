/**
 * @export
 * @enum {string}
 */
export enum PlanChangeIntervalEnum {
  MonthlyToMonthly = 'monthlyToMonthly',
  MonthlyToAnnually = 'monthlyToAnnually',
  AnnuallyToMonthly = 'annuallyToMonthly',
  AnnuallyToAnnually = 'annuallyToAnnually',
}

/**
 * @export
 * @enum {string}
 */
export enum PlanChangeLevelEnum {
  Downgrade = 'downgrade',
  Upgrade = 'upgrade',
  LikeForLike = 'likeForLike',
}

export enum PlanNameEnum {
  StartupShiftie = 'Startup Shiftie',
  ALittleBitShiftie = 'A Little Bit Shiftie',
  VeryShiftie = 'Very Shiftie',
}
