import { employeeApi, fetchAll, shiftApi, timesheetApi } from '@/api';
import { Entity } from '@/lib/store/realtimeEntities';
import { BaseAPI } from '../../../api/v1';

const genericFetcher = (api: BaseAPI, listFunction) => async (ids: number[]) =>
  (
    await fetchAll(listFunction.bind(api), {
      where: {
        id: { in: ids },
      },
      perPage: 500,
    })
  ).data;

export const batchEntityConfig = {
  [Entity.Employee]: genericFetcher(employeeApi, employeeApi.listEmployees),
  [Entity.Shift]: genericFetcher(shiftApi, shiftApi.listShifts),
  [Entity.TimesheetEntry]: genericFetcher(
    timesheetApi,
    timesheetApi.listTimesheetEntries,
  ),
};
