/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftSwap } from './ShiftSwap';
import {
    ShiftSwapFromJSON,
    ShiftSwapFromJSONTyped,
    ShiftSwapToJSON,
} from './ShiftSwap';

/**
 * 
 * @export
 * @interface AcceptShiftSwapResponse
 */
export interface AcceptShiftSwapResponse {
    /**
     * 
     * @type {ShiftSwap}
     * @memberof AcceptShiftSwapResponse
     */
    data?: ShiftSwap;
}

/**
 * Check if a given object implements the AcceptShiftSwapResponse interface.
 */
export function instanceOfAcceptShiftSwapResponse(value: object): boolean {
    return true;
}

export function AcceptShiftSwapResponseFromJSON(json: any): AcceptShiftSwapResponse {
    return AcceptShiftSwapResponseFromJSONTyped(json, false);
}

export function AcceptShiftSwapResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptShiftSwapResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftSwapFromJSON(json['data']),
    };
}

export function AcceptShiftSwapResponseToJSON(value?: AcceptShiftSwapResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftSwapToJSON(value['data']),
    };
}

