/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatePayCycleData,
  CreatePayCycleResponse,
  ListPayCyclesResponse,
  ShowPayCycleResponse,
} from '../models/index';
import {
    CreatePayCycleDataFromJSON,
    CreatePayCycleDataToJSON,
    CreatePayCycleResponseFromJSON,
    CreatePayCycleResponseToJSON,
    ListPayCyclesResponseFromJSON,
    ListPayCyclesResponseToJSON,
    ShowPayCycleResponseFromJSON,
    ShowPayCycleResponseToJSON,
} from '../models/index';

export interface CreatePayCycleRequest {
    createPayCycleData?: CreatePayCycleData;
}

export interface DeletePayCycleRequest {
    id: number;
}

export interface ListPayCyclesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

export interface ShowPayCycleRequest {
    id: number;
}

/**
 * 
 */
export class PayCycleApi extends runtime.BaseAPI {

    /**
     * 
     * Create Pay Cycle
     */
    async createPayCycleRaw(requestParameters: CreatePayCycleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePayCycleResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.pay-cycle.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pay-cycles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePayCycleDataToJSON(requestParameters['createPayCycleData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePayCycleResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Create Pay Cycle
     */
    async createPayCycle(requestParameters: CreatePayCycleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePayCycleResponse> {
        const response = await this.createPayCycleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Delete Pay Cycle
     */
    async deletePayCycleRaw(requestParameters: DeletePayCycleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deletePayCycle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.pay-cycle.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pay-cycles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     * Delete Pay Cycle
     */
    async deletePayCycle(requestParameters: DeletePayCycleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePayCycleRaw(requestParameters, initOverrides);
    }

    /**
     * List Pay Cycles
     */
    async listPayCyclesRaw(requestParameters: ListPayCyclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPayCyclesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.pay-cycle.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pay-cycles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPayCyclesResponseFromJSON(jsonValue));
    }

    /**
     * List Pay Cycles
     */
    async listPayCycles(requestParameters: ListPayCyclesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPayCyclesResponse> {
        const response = await this.listPayCyclesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Pay Cycle
     */
    async showPayCycleRaw(requestParameters: ShowPayCycleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowPayCycleResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showPayCycle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.pay-cycle.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pay-cycles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowPayCycleResponseFromJSON(jsonValue));
    }

    /**
     * Show Pay Cycle
     */
    async showPayCycle(requestParameters: ShowPayCycleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowPayCycleResponse> {
        const response = await this.showPayCycleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
