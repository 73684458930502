/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftTemplate } from './ShiftTemplate';
import {
    ShiftTemplateFromJSON,
    ShiftTemplateFromJSONTyped,
    ShiftTemplateToJSON,
} from './ShiftTemplate';

/**
 * 
 * @export
 * @interface UpdateShiftTemplateResponse
 */
export interface UpdateShiftTemplateResponse {
    /**
     * 
     * @type {ShiftTemplate}
     * @memberof UpdateShiftTemplateResponse
     */
    data?: ShiftTemplate;
}

/**
 * Check if a given object implements the UpdateShiftTemplateResponse interface.
 */
export function instanceOfUpdateShiftTemplateResponse(value: object): boolean {
    return true;
}

export function UpdateShiftTemplateResponseFromJSON(json: any): UpdateShiftTemplateResponse {
    return UpdateShiftTemplateResponseFromJSONTyped(json, false);
}

export function UpdateShiftTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShiftTemplateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftTemplateFromJSON(json['data']),
    };
}

export function UpdateShiftTemplateResponseToJSON(value?: UpdateShiftTemplateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftTemplateToJSON(value['data']),
    };
}

