/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface UpdateEmployeeData
 */
export interface UpdateEmployeeData {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeData
     */
    medicalDetails?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeData
     */
    leaveApproverId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeData
     */
    lineManagerId?: number;
    /**
     * The ID of a photo stored via the /uploads endpoint
     * @type {number}
     * @memberof UpdateEmployeeData
     */
    photoId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeData
     */
    status?: UpdateEmployeeDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeData
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeData
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeData
     */
    workPatternId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeData
     */
    employeeGroupId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeData
     */
    defaultJobRoleId?: number;
    /**
     * A leave policy to define when and how the employee can take leave. Note: changing this value to an employee with an existing leave policy may result in leave periods being regenerated when the new policy results in a different start date.
     * @type {number}
     * @memberof UpdateEmployeeData
     */
    leavePolicyId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeData
     */
    showContactDetails?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeData
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeData
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeData
     */
    jobTitle?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeData
     */
    exemptFromOvertime?: boolean;
    /**
     * The number of hours this employee would usually work in a week
     * @type {number}
     * @memberof UpdateEmployeeData
     */
    workingHoursPerWeek?: number;
    /**
     * The number of hours taken from this employee's leave allowance for a day's worth of leave
     * @type {number}
     * @memberof UpdateEmployeeData
     */
    leaveHoursUsedPerDay?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeData
     */
    employmentType?: UpdateEmployeeDataEmploymentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeData
     */
    canRequestLeave?: boolean;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof UpdateEmployeeData
     */
    joinDate?: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof UpdateEmployeeData
     */
    probationDate?: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof UpdateEmployeeData
     */
    terminationDate?: ShiftiePlainDate;
    /**
     * A list of attributes/skills that the employee has
     * @type {Array<number>}
     * @memberof UpdateEmployeeData
     */
    employeeAttributes?: Array<number>;
    /**
     * A list of locations that the employee belongs to
     * @type {Array<number>}
     * @memberof UpdateEmployeeData
     */
    locations?: Array<number>;
    /**
     * A list of job roles that the employee belongs to
     * @type {Array<number>}
     * @memberof UpdateEmployeeData
     */
    jobRoles?: Array<number>;
    /**
     * A list of access roles which defines permissions for the employee
     * @type {Array<number>}
     * @memberof UpdateEmployeeData
     */
    accessRoles?: Array<number>;
    /**
     * A list of schedules that the employee should be assigned to.
     * @type {Array<number>}
     * @memberof UpdateEmployeeData
     */
    schedules?: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum UpdateEmployeeDataStatusEnum {
    Pending = 'Pending',
    Archived = 'Archived'
}
/**
* @export
* @enum {string}
*/
export enum UpdateEmployeeDataEmploymentTypeEnum {
    Agency = 'Agency',
    AnnualisedContract = 'Annualised Contract',
    ApprenticeTrainee = 'Apprentice / Trainee',
    Casual = 'Casual',
    FixedTerm = 'Fixed Term',
    FullTime = 'Full Time',
    PartTime = 'Part Time',
    SelfEmployed = 'Self-Employed',
    Temporary = 'Temporary',
    Volunteer = 'Volunteer'
}


/**
 * Check if a given object implements the UpdateEmployeeData interface.
 */
export function instanceOfUpdateEmployeeData(value: object): boolean {
    return true;
}

export function UpdateEmployeeDataFromJSON(json: any): UpdateEmployeeData {
    return UpdateEmployeeDataFromJSONTyped(json, false);
}

export function UpdateEmployeeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeeData {
    if (json == null) {
        return json;
    }
    return {
        
        'medicalDetails': json['medicalDetails'] == null ? undefined : json['medicalDetails'],
        'leaveApproverId': json['leaveApproverId'] == null ? undefined : json['leaveApproverId'],
        'lineManagerId': json['lineManagerId'] == null ? undefined : json['lineManagerId'],
        'photoId': json['photoId'] == null ? undefined : json['photoId'],
        'status': json['status'] == null ? undefined : json['status'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'workPatternId': json['workPatternId'] == null ? undefined : json['workPatternId'],
        'employeeGroupId': json['employeeGroupId'] == null ? undefined : json['employeeGroupId'],
        'defaultJobRoleId': json['defaultJobRoleId'] == null ? undefined : json['defaultJobRoleId'],
        'leavePolicyId': json['leavePolicyId'] == null ? undefined : json['leavePolicyId'],
        'showContactDetails': json['showContactDetails'] == null ? undefined : json['showContactDetails'],
        'contactEmail': json['contactEmail'] == null ? undefined : json['contactEmail'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'exemptFromOvertime': json['exemptFromOvertime'] == null ? undefined : json['exemptFromOvertime'],
        'workingHoursPerWeek': json['workingHoursPerWeek'] == null ? undefined : json['workingHoursPerWeek'],
        'leaveHoursUsedPerDay': json['leaveHoursUsedPerDay'] == null ? undefined : json['leaveHoursUsedPerDay'],
        'employmentType': json['employmentType'] == null ? undefined : json['employmentType'],
        'canRequestLeave': json['canRequestLeave'] == null ? undefined : json['canRequestLeave'],
        'joinDate': json['joinDate'] == null ? undefined : ShiftiePlainDateFromJSON(json['joinDate']),
        'probationDate': json['probationDate'] == null ? undefined : ShiftiePlainDateFromJSON(json['probationDate']),
        'terminationDate': json['terminationDate'] == null ? undefined : ShiftiePlainDateFromJSON(json['terminationDate']),
        'employeeAttributes': json['employeeAttributes'] == null ? undefined : json['employeeAttributes'],
        'locations': json['locations'] == null ? undefined : json['locations'],
        'jobRoles': json['jobRoles'] == null ? undefined : json['jobRoles'],
        'accessRoles': json['accessRoles'] == null ? undefined : json['accessRoles'],
        'schedules': json['schedules'] == null ? undefined : json['schedules'],
    };
}

export function UpdateEmployeeDataToJSON(value?: UpdateEmployeeData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'medicalDetails': value['medicalDetails'],
        'leaveApproverId': value['leaveApproverId'],
        'lineManagerId': value['lineManagerId'],
        'photoId': value['photoId'],
        'status': value['status'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'workPatternId': value['workPatternId'],
        'employeeGroupId': value['employeeGroupId'],
        'defaultJobRoleId': value['defaultJobRoleId'],
        'leavePolicyId': value['leavePolicyId'],
        'showContactDetails': value['showContactDetails'],
        'contactEmail': value['contactEmail'],
        'phoneNumber': value['phoneNumber'],
        'jobTitle': value['jobTitle'],
        'exemptFromOvertime': value['exemptFromOvertime'],
        'workingHoursPerWeek': value['workingHoursPerWeek'],
        'leaveHoursUsedPerDay': value['leaveHoursUsedPerDay'],
        'employmentType': value['employmentType'],
        'canRequestLeave': value['canRequestLeave'],
        'joinDate': ShiftiePlainDateToJSON(value['joinDate']),
        'probationDate': ShiftiePlainDateToJSON(value['probationDate']),
        'terminationDate': ShiftiePlainDateToJSON(value['terminationDate']),
        'employeeAttributes': value['employeeAttributes'],
        'locations': value['locations'],
        'jobRoles': value['jobRoles'],
        'accessRoles': value['accessRoles'],
        'schedules': value['schedules'],
    };
}

