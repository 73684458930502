/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLeaveTypeData
 */
export interface CreateLeaveTypeData {
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveTypeData
     */
    deductionLeaveTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    colour: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveTypeData
     */
    paid: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    payCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    leaveAllowanceType: CreateLeaveTypeDataLeaveAllowanceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    leaveAllowanceUnit: CreateLeaveTypeDataLeaveAllowanceUnitEnum;
    /**
     * Leave allowance for this leave type when using the 'Fixed' allowance type.
     * @type {number}
     * @memberof CreateLeaveTypeData
     */
    leaveAllowance?: number;
    /**
     * What percentage of worked hours an employee accrues for this leave type. A rate of 100 means leave is accrued at the same rate as hours worked.
     * @type {number}
     * @memberof CreateLeaveTypeData
     */
    accrualRatePerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveTypeData
     */
    accrualLimitDaysPerPeriod?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveTypeData
     */
    accrualAllowedOnOvertimeHours?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CreateLeaveTypeDataLeaveAllowanceTypeEnum {
    Accrued = 'Accrued',
    DeductsFromAnother = 'Deducts From Another',
    Fixed = 'Fixed',
    Unlimited = 'Unlimited'
}
/**
* @export
* @enum {string}
*/
export enum CreateLeaveTypeDataLeaveAllowanceUnitEnum {
    Days = 'Days',
    Hours = 'Hours'
}


/**
 * Check if a given object implements the CreateLeaveTypeData interface.
 */
export function instanceOfCreateLeaveTypeData(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('colour' in value)) return false;
    if (!('paid' in value)) return false;
    if (!('payCode' in value)) return false;
    if (!('leaveAllowanceType' in value)) return false;
    if (!('leaveAllowanceUnit' in value)) return false;
    return true;
}

export function CreateLeaveTypeDataFromJSON(json: any): CreateLeaveTypeData {
    return CreateLeaveTypeDataFromJSONTyped(json, false);
}

export function CreateLeaveTypeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveTypeData {
    if (json == null) {
        return json;
    }
    return {
        
        'deductionLeaveTypeId': json['deductionLeaveTypeId'] == null ? undefined : json['deductionLeaveTypeId'],
        'name': json['name'],
        'colour': json['colour'],
        'paid': json['paid'],
        'payCode': json['payCode'],
        'leaveAllowanceType': json['leaveAllowanceType'],
        'leaveAllowanceUnit': json['leaveAllowanceUnit'],
        'leaveAllowance': json['leaveAllowance'] == null ? undefined : json['leaveAllowance'],
        'accrualRatePerHour': json['accrualRatePerHour'] == null ? undefined : json['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': json['accrualLimitDaysPerPeriod'] == null ? undefined : json['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': json['accrualAllowedOnOvertimeHours'] == null ? undefined : json['accrualAllowedOnOvertimeHours'],
    };
}

export function CreateLeaveTypeDataToJSON(value?: CreateLeaveTypeData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'deductionLeaveTypeId': value['deductionLeaveTypeId'],
        'name': value['name'],
        'colour': value['colour'],
        'paid': value['paid'],
        'payCode': value['payCode'],
        'leaveAllowanceType': value['leaveAllowanceType'],
        'leaveAllowanceUnit': value['leaveAllowanceUnit'],
        'leaveAllowance': value['leaveAllowance'],
        'accrualRatePerHour': value['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': value['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': value['accrualAllowedOnOvertimeHours'],
    };
}

