
import SlidePanel from '@/components/interface/SlidePanel';
import MenuItem from '@/components/menu/MenuItem';
import { Icon } from '@/lib/enum/Icon';
import { NavItem } from '@/lib/navigation';
import { PropType } from 'vue';

export default {
  name: 'DesktopMoreMenu',
  components: { MenuItem, SlidePanel },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array as PropType<NavItem[]>,
      required: true,
    },
    navClick: {
      type: Function as PropType<(item: NavItem) => void>,
      required: true,
    },
  },
  data() {
    return {
      Icon,
    };
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
  },
};
