/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ShowTokenMetaResponse,
} from '../models/index';
import {
    ShowTokenMetaResponseFromJSON,
    ShowTokenMetaResponseToJSON,
} from '../models/index';

export interface ShowTokenMetaRequest {
    token: string;
}

/**
 * 
 */
export class TokenApi extends runtime.BaseAPI {

    /**
     * Show meta data about a supplied token.
     * Show Token Meta
     */
    async showTokenMetaRaw(requestParameters: ShowTokenMetaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowTokenMetaResponse>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling showTokenMeta().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/token-meta/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters['token']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowTokenMetaResponseFromJSON(jsonValue));
    }

    /**
     * Show meta data about a supplied token.
     * Show Token Meta
     */
    async showTokenMeta(requestParameters: ShowTokenMetaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowTokenMetaResponse> {
        const response = await this.showTokenMetaRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
