// This only handles registering the service worker, found at src/sw.js
// and checking if the browser supports push notifications.
import Push from './plugins/Push';

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      const swReg = await navigator.serviceWorker.register(
        `${process.env.BASE_URL}sw.js`,
      );

      if (
        typeof ServiceWorkerRegistration !== 'undefined' &&
        typeof Notification !== 'undefined' &&
        Notification.permission !== 'denied' &&
        'showNotification' in ServiceWorkerRegistration.prototype &&
        'PushManager' in window
      ) {
        Push.init(swReg.pushManager);
      }
    });
  }
};
