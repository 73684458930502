export default {
  namespaced: true,

  state: {
    loading: 0,
    redirectLoading: 0,
    forceReload: 0,
  },

  getters: {
    isRedirecting(state): boolean {
      return !!state.redirectLoading;
    },

    globalLoading(state, getters): boolean {
      return !!state.loading || getters.isRedirecting;
    },

    forceReload(state): number {
      return state.forceReload;
    },
  },

  mutations: {
    START_LOADING(state) {
      state.loading += 1;
    },
    END_LOADING(state) {
      if (state.loading > 0) {
        state.loading -= 1;
      }
    },

    START_REDIRECT_LOADING(state) {
      state.redirectLoading += 1;
    },
    END_REDIRECT_LOADING(state) {
      if (state.redirectLoading > 0) {
        state.redirectLoading -= 1;
      }
    },

    FORCE_RELOAD(state) {
      state.forceReload += 1;
    },
  },

  actions: {
    forceReload({ commit }) {
      commit('FORCE_RELOAD');
    },
  },
};
