/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveAdjustment } from './LeaveAdjustment';
import {
    LeaveAdjustmentFromJSON,
    LeaveAdjustmentFromJSONTyped,
    LeaveAdjustmentToJSON,
} from './LeaveAdjustment';

/**
 * 
 * @export
 * @interface ShowLeaveAdjustmentResponse
 */
export interface ShowLeaveAdjustmentResponse {
    /**
     * 
     * @type {LeaveAdjustment}
     * @memberof ShowLeaveAdjustmentResponse
     */
    data: LeaveAdjustment;
}

/**
 * Check if a given object implements the ShowLeaveAdjustmentResponse interface.
 */
export function instanceOfShowLeaveAdjustmentResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowLeaveAdjustmentResponseFromJSON(json: any): ShowLeaveAdjustmentResponse {
    return ShowLeaveAdjustmentResponseFromJSONTyped(json, false);
}

export function ShowLeaveAdjustmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowLeaveAdjustmentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveAdjustmentFromJSON(json['data']),
    };
}

export function ShowLeaveAdjustmentResponseToJSON(value?: ShowLeaveAdjustmentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeaveAdjustmentToJSON(value['data']),
    };
}

