/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeMessage } from './EmployeeMessage';
import {
    EmployeeMessageFromJSON,
    EmployeeMessageFromJSONTyped,
    EmployeeMessageToJSON,
} from './EmployeeMessage';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListEmployeeMessagesResponse
 */
export interface ListEmployeeMessagesResponse {
    /**
     * 
     * @type {Array<EmployeeMessage>}
     * @memberof ListEmployeeMessagesResponse
     */
    data: Array<EmployeeMessage>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListEmployeeMessagesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListEmployeeMessagesResponse interface.
 */
export function instanceOfListEmployeeMessagesResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListEmployeeMessagesResponseFromJSON(json: any): ListEmployeeMessagesResponse {
    return ListEmployeeMessagesResponseFromJSONTyped(json, false);
}

export function ListEmployeeMessagesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEmployeeMessagesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EmployeeMessageFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListEmployeeMessagesResponseToJSON(value?: ListEmployeeMessagesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(EmployeeMessageToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

