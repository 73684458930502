/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUploadData
 */
export interface CreateUploadData {
    /**
     * 
     * @type {string}
     * @memberof CreateUploadData
     */
    purpose: CreateUploadDataPurposeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUploadData
     */
    originalFilename: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateUploadDataPurposeEnum {
    CompanyLogo = 'CompanyLogo',
    Document = 'Document',
    EmployeeImport = 'EmployeeImport',
    EmployeePhoto = 'EmployeePhoto',
    Invoices = 'Invoices',
    Calendar = 'Calendar'
}


/**
 * Check if a given object implements the CreateUploadData interface.
 */
export function instanceOfCreateUploadData(value: object): boolean {
    if (!('purpose' in value)) return false;
    if (!('originalFilename' in value)) return false;
    return true;
}

export function CreateUploadDataFromJSON(json: any): CreateUploadData {
    return CreateUploadDataFromJSONTyped(json, false);
}

export function CreateUploadDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUploadData {
    if (json == null) {
        return json;
    }
    return {
        
        'purpose': json['purpose'],
        'originalFilename': json['originalFilename'],
    };
}

export function CreateUploadDataToJSON(value?: CreateUploadData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'purpose': value['purpose'],
        'originalFilename': value['originalFilename'],
    };
}

