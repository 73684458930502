/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAbsenceRequestData,
  CreateAbsenceResponse,
  ListAbsenceCategoriesResponse,
  ListAbsenceResponse,
  ShowAbsenceResponse,
  UpdateAbsenceRequestData,
  UpdateAbsenceResponse,
} from '../models/index';
import {
    CreateAbsenceRequestDataFromJSON,
    CreateAbsenceRequestDataToJSON,
    CreateAbsenceResponseFromJSON,
    CreateAbsenceResponseToJSON,
    ListAbsenceCategoriesResponseFromJSON,
    ListAbsenceCategoriesResponseToJSON,
    ListAbsenceResponseFromJSON,
    ListAbsenceResponseToJSON,
    ShowAbsenceResponseFromJSON,
    ShowAbsenceResponseToJSON,
    UpdateAbsenceRequestDataFromJSON,
    UpdateAbsenceRequestDataToJSON,
    UpdateAbsenceResponseFromJSON,
    UpdateAbsenceResponseToJSON,
} from '../models/index';

export interface CreateAbsenceRequest {
    _with?: Array<string>;
    createAbsenceRequestData?: CreateAbsenceRequestData;
}

export interface DeleteAbsenceRequest {
    id: number;
}

export interface ListAbsenceRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListAbsenceCategoriesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowAbsenceRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateAbsenceRequest {
    id: number;
    _with?: Array<string>;
    updateAbsenceRequestData?: UpdateAbsenceRequestData;
}

/**
 * 
 */
export class AbsenceApi extends runtime.BaseAPI {

    /**
     * Create a new absence record
     * Create Absence
     */
    async createAbsenceRaw(requestParameters: CreateAbsenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAbsenceResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.absence.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/absence`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAbsenceRequestDataToJSON(requestParameters['createAbsenceRequestData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAbsenceResponseFromJSON(jsonValue));
    }

    /**
     * Create a new absence record
     * Create Absence
     */
    async createAbsence(requestParameters: CreateAbsenceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAbsenceResponse> {
        const response = await this.createAbsenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a specific absence record
     * Delete Absence Record
     */
    async deleteAbsenceRaw(requestParameters: DeleteAbsenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAbsence().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.absence.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/absence/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific absence record
     * Delete Absence Record
     */
    async deleteAbsence(requestParameters: DeleteAbsenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAbsenceRaw(requestParameters, initOverrides);
    }

    /**
     * List all absence records
     * List Absence
     */
    async listAbsenceRaw(requestParameters: ListAbsenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAbsenceResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.absence.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/absence`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAbsenceResponseFromJSON(jsonValue));
    }

    /**
     * List all absence records
     * List Absence
     */
    async listAbsence(requestParameters: ListAbsenceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAbsenceResponse> {
        const response = await this.listAbsenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all absence categories
     * List Absence Categories
     */
    async listAbsenceCategoriesRaw(requestParameters: ListAbsenceCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAbsenceCategoriesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.absence-category.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/absence-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAbsenceCategoriesResponseFromJSON(jsonValue));
    }

    /**
     * List all absence categories
     * List Absence Categories
     */
    async listAbsenceCategories(requestParameters: ListAbsenceCategoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAbsenceCategoriesResponse> {
        const response = await this.listAbsenceCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a absence record
     * Show Absence
     */
    async showAbsenceRaw(requestParameters: ShowAbsenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowAbsenceResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showAbsence().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.absence.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/absence/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowAbsenceResponseFromJSON(jsonValue));
    }

    /**
     * Show a absence record
     * Show Absence
     */
    async showAbsence(requestParameters: ShowAbsenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowAbsenceResponse> {
        const response = await this.showAbsenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a absence record
     * Update Absence
     */
    async updateAbsenceRaw(requestParameters: UpdateAbsenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateAbsenceResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAbsence().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.absence.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/absence/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAbsenceRequestDataToJSON(requestParameters['updateAbsenceRequestData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateAbsenceResponseFromJSON(jsonValue));
    }

    /**
     * Update a absence record
     * Update Absence
     */
    async updateAbsence(requestParameters: UpdateAbsenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateAbsenceResponse> {
        const response = await this.updateAbsenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
