
import CloseButton from '@/components/buttons/dedicated/CloseButton.vue';
import { PropType } from 'vue';
import { Employee } from '../../../../api/v1';
import EmployeeAvatar from '../../../components/employee/EmployeeAvatar.vue';
import TooltipIconClick from '../../../components/info/TooltipIconClick.vue';
import BubbleIcon from '../../../components/interface/BubbleIcon.vue';
import { Icon } from '../../../lib/enum/Icon';

export default {
  name: 'DialogHeader',

  components: {
    BubbleIcon,
    CloseButton,
    TooltipIconClick,
    EmployeeAvatar,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    subHeading: {
      type: String,
      default: '',
    },
    employee: {
      type: Object as PropType<Employee | null>,
      default: null,
    },
    headerIcon: {
      type: String,
      default: null,
      validator: (prop) => Object.values(Icon).includes(prop),
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    tooltipTitle: {
      type: String,
      default: '',
    },
    tooltipMessage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      Icon,
    };
  },
};
