/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLeavePolicyData
 */
export interface CreateLeavePolicyData {
    /**
     * Which leave type should be used as the default. Omitting this will use the first available leave type.
     * @type {number}
     * @memberof CreateLeavePolicyData
     */
    defaultLeaveTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLeavePolicyData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeavePolicyData
     */
    startDateType: CreateLeavePolicyDataStartDateTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateLeavePolicyData
     */
    startDay?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeavePolicyData
     */
    startMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeavePolicyData
     */
    leaveNoticePeriodInDays: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeavePolicyData
     */
    leaveAllowanceCanBeExceeded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeavePolicyData
     */
    excludePublicHolidaysFromWorkingTimeLost: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateLeavePolicyData
     */
    leaveTypeIds: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum CreateLeavePolicyDataStartDateTypeEnum {
    SpecificDate = 'Specific Date',
    EmployeeJoinDate = 'Employee Join Date'
}


/**
 * Check if a given object implements the CreateLeavePolicyData interface.
 */
export function instanceOfCreateLeavePolicyData(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('startDateType' in value)) return false;
    if (!('leaveNoticePeriodInDays' in value)) return false;
    if (!('leaveAllowanceCanBeExceeded' in value)) return false;
    if (!('excludePublicHolidaysFromWorkingTimeLost' in value)) return false;
    if (!('leaveTypeIds' in value)) return false;
    return true;
}

export function CreateLeavePolicyDataFromJSON(json: any): CreateLeavePolicyData {
    return CreateLeavePolicyDataFromJSONTyped(json, false);
}

export function CreateLeavePolicyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeavePolicyData {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultLeaveTypeId': json['defaultLeaveTypeId'] == null ? undefined : json['defaultLeaveTypeId'],
        'name': json['name'],
        'startDateType': json['startDateType'],
        'startDay': json['startDay'] == null ? undefined : json['startDay'],
        'startMonth': json['startMonth'] == null ? undefined : json['startMonth'],
        'leaveNoticePeriodInDays': json['leaveNoticePeriodInDays'],
        'leaveAllowanceCanBeExceeded': json['leaveAllowanceCanBeExceeded'],
        'excludePublicHolidaysFromWorkingTimeLost': json['excludePublicHolidaysFromWorkingTimeLost'],
        'leaveTypeIds': json['leaveTypeIds'],
    };
}

export function CreateLeavePolicyDataToJSON(value?: CreateLeavePolicyData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'defaultLeaveTypeId': value['defaultLeaveTypeId'],
        'name': value['name'],
        'startDateType': value['startDateType'],
        'startDay': value['startDay'],
        'startMonth': value['startMonth'],
        'leaveNoticePeriodInDays': value['leaveNoticePeriodInDays'],
        'leaveAllowanceCanBeExceeded': value['leaveAllowanceCanBeExceeded'],
        'excludePublicHolidaysFromWorkingTimeLost': value['excludePublicHolidaysFromWorkingTimeLost'],
        'leaveTypeIds': value['leaveTypeIds'],
    };
}

