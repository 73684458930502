import { StorageEnum } from '@/lib/enum/StorageEnum';

export const validateLocalStorageAccess = () => {
  try {
    const testKey = 'test';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch {
    return false;
  }
};

export const setLocalStorageItem = (
  key: StorageEnum | string,
  value: string,
): void => {
  if (validateLocalStorageAccess()) {
    localStorage.setItem(key, value);
  }
};

export const getLocalStorageItem = (
  key: StorageEnum | string,
): string | undefined => {
  if (validateLocalStorageAccess()) {
    return localStorage.getItem(key);
  }
  return undefined;
};

export const removeLocalStorageItem = (key: StorageEnum | string): void => {
  if (validateLocalStorageAccess()) {
    localStorage.removeItem(key);
  }
};
