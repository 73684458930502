/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OauthToken } from './OauthToken';
import {
    OauthTokenFromJSON,
    OauthTokenFromJSONTyped,
    OauthTokenToJSON,
} from './OauthToken';

/**
 * 
 * @export
 * @interface GetOauthTokenResponse
 */
export interface GetOauthTokenResponse {
    /**
     * 
     * @type {OauthToken}
     * @memberof GetOauthTokenResponse
     */
    data: OauthToken;
}

/**
 * Check if a given object implements the GetOauthTokenResponse interface.
 */
export function instanceOfGetOauthTokenResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function GetOauthTokenResponseFromJSON(json: any): GetOauthTokenResponse {
    return GetOauthTokenResponseFromJSONTyped(json, false);
}

export function GetOauthTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOauthTokenResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': OauthTokenFromJSON(json['data']),
    };
}

export function GetOauthTokenResponseToJSON(value?: GetOauthTokenResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': OauthTokenToJSON(value['data']),
    };
}

