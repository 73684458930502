/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleTemplate
 */
export interface ScheduleTemplate {
    /**
     * 
     * @type {number}
     * @memberof ScheduleTemplate
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleTemplate
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleTemplate
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleTemplate
     */
    scheduleId: number;
    /**
     * ID of the employee who created this
     * @type {number}
     * @memberof ScheduleTemplate
     */
    creatorId: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduleTemplate
     */
    name: string;
    /**
     * The start of the period that the shifts were originally taken from
     * @type {Date}
     * @memberof ScheduleTemplate
     */
    startsAt: Date;
    /**
     * The end of the period that the shifts were originally taken from (exclusive)
     * @type {Date}
     * @memberof ScheduleTemplate
     */
    endsAt: Date;
    /**
     * The total number of calendar days covered by the template
     * @type {number}
     * @memberof ScheduleTemplate
     */
    numDays: number;
    /**
     * How many employees were assigned to shifts during this period
     * @type {number}
     * @memberof ScheduleTemplate
     */
    numEmployees: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleTemplate
     */
    numShifts: number;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleTemplate
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleTemplate
     */
    updatedAt: Date | null;
}

/**
 * Check if a given object implements the ScheduleTemplate interface.
 */
export function instanceOfScheduleTemplate(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('locationId' in value)) return false;
    if (!('scheduleId' in value)) return false;
    if (!('creatorId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('startsAt' in value)) return false;
    if (!('endsAt' in value)) return false;
    if (!('numDays' in value)) return false;
    if (!('numEmployees' in value)) return false;
    if (!('numShifts' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function ScheduleTemplateFromJSON(json: any): ScheduleTemplate {
    return ScheduleTemplateFromJSONTyped(json, false);
}

export function ScheduleTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'locationId': json['locationId'],
        'scheduleId': json['scheduleId'],
        'creatorId': json['creatorId'],
        'name': json['name'],
        'startsAt': (new Date(json['startsAt'])),
        'endsAt': (new Date(json['endsAt'])),
        'numDays': json['numDays'],
        'numEmployees': json['numEmployees'],
        'numShifts': json['numShifts'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

export function ScheduleTemplateToJSON(value?: ScheduleTemplate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'locationId': value['locationId'],
        'scheduleId': value['scheduleId'],
        'creatorId': value['creatorId'],
        'name': value['name'],
        'startsAt': ((value['startsAt']).toISOString()),
        'endsAt': ((value['endsAt']).toISOString()),
        'numDays': value['numDays'],
        'numEmployees': value['numEmployees'],
        'numShifts': value['numShifts'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

