/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeePersonalDetails } from './EmployeePersonalDetails';
import {
    EmployeePersonalDetailsFromJSON,
    EmployeePersonalDetailsFromJSONTyped,
    EmployeePersonalDetailsToJSON,
} from './EmployeePersonalDetails';

/**
 * 
 * @export
 * @interface ShowEmployeePersonalDetailResponse
 */
export interface ShowEmployeePersonalDetailResponse {
    /**
     * 
     * @type {EmployeePersonalDetails}
     * @memberof ShowEmployeePersonalDetailResponse
     */
    data?: EmployeePersonalDetails;
}

/**
 * Check if a given object implements the ShowEmployeePersonalDetailResponse interface.
 */
export function instanceOfShowEmployeePersonalDetailResponse(value: object): boolean {
    return true;
}

export function ShowEmployeePersonalDetailResponseFromJSON(json: any): ShowEmployeePersonalDetailResponse {
    return ShowEmployeePersonalDetailResponseFromJSONTyped(json, false);
}

export function ShowEmployeePersonalDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeePersonalDetailResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : EmployeePersonalDetailsFromJSON(json['data']),
    };
}

export function ShowEmployeePersonalDetailResponseToJSON(value?: ShowEmployeePersonalDetailResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeePersonalDetailsToJSON(value['data']),
    };
}

