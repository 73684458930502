/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SubscriptionQuote } from './SubscriptionQuote';
import {
    SubscriptionQuoteFromJSON,
    SubscriptionQuoteFromJSONTyped,
    SubscriptionQuoteToJSON,
} from './SubscriptionQuote';

/**
 * 
 * @export
 * @interface CreateSubscriptionQuoteResponse
 */
export interface CreateSubscriptionQuoteResponse {
    /**
     * 
     * @type {SubscriptionQuote}
     * @memberof CreateSubscriptionQuoteResponse
     */
    data: SubscriptionQuote;
}

/**
 * Check if a given object implements the CreateSubscriptionQuoteResponse interface.
 */
export function instanceOfCreateSubscriptionQuoteResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateSubscriptionQuoteResponseFromJSON(json: any): CreateSubscriptionQuoteResponse {
    return CreateSubscriptionQuoteResponseFromJSONTyped(json, false);
}

export function CreateSubscriptionQuoteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSubscriptionQuoteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': SubscriptionQuoteFromJSON(json['data']),
    };
}

export function CreateSubscriptionQuoteResponseToJSON(value?: CreateSubscriptionQuoteResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': SubscriptionQuoteToJSON(value['data']),
    };
}

