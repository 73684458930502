<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="37"
    viewBox="0 0 36 37"
  >
    <defs>
      <filter
        id="a"
        x="0"
        y="0"
        width="36"
        height="37"
        filterUnits="userSpaceOnUse"
      >
        <feOffset
          dy="1"
          input="SourceAlpha"
        />
        <feGaussianBlur result="b" />
        <feFlood flood-opacity="0.2" />
        <feComposite
          operator="in"
          in2="b"
        />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="translate(-15 -10)">
      <path
        d="M0,0H28V28H0Z"
        transform="translate(18 13)"
        fill="#ca4692"
      />
      <g
        transform="matrix(1, 0, 0, 1, 15, 10)"
        filter="url(#a)"
      >
        <path
          d="M203.8,196.1H177.811a5.005,5.005,0,0,0-5.005,5.005v25.989a5.006,5.006,0,0,0,5.005,5.006H203.8a5.006,5.006,
          0,0,0,5.006-5.006V201.1A5.005,5.005,0,0,0,203.8,196.1m-5.378,27.913a3.95,3.95,0,0,1-3.282,1.748h-8.6a3.962,
          3.962,0,0,1-3.628-5.541,15.956,15.956,0,0,1,2.366-3.808,1.718,1.718,0,0,1,2.646,2.192,12.51,12.51,0,0,0-1.855,
          2.975.532.532,0,0,0,.041.517.493.493,0,0,0,.429.23h8.6a.5.5,0,0,0,.43-.229.519.519,0,0,0,
          .05-.5c-1.41-3.458-5.252-5.9-5.655-6.145l-.033-.019c-.211-.125-5.2-3.106-7.122-7.827a3.961,3.961,0,0,1,
          3.662-5.463h8.6a3.962,3.962,0,0,1,3.31,1.78,3.9,3.9,0,0,1,.349,3.685,15.639,15.639,0,0,1-1.924,3.284,1.718,
          1.718,0,1,1-2.741-2.072,12.236,12.236,0,0,0,1.5-2.551.46.46,0,0,0-.047-.447.533.533,0,0,0-.447-.242h-8.6a.5.5,
          0,0,0-.43.229.518.518,0,0,0-.049.5c1.409,3.458,5.251,5.9,5.654,6.145l.033.019c.211.124,5.2,3.106,7.124,
          7.828a3.965,3.965,0,0,1-.381,3.713"
          transform="translate(-172.81 -196.1)"
          fill="#faf9fe"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PinkShiftieCutOutSquareSvg',
};
</script>
