/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface VerifyEmailResponse
 */
export interface VerifyEmailResponse {
    /**
     * 
     * @type {User}
     * @memberof VerifyEmailResponse
     */
    data?: User;
}

/**
 * Check if a given object implements the VerifyEmailResponse interface.
 */
export function instanceOfVerifyEmailResponse(value: object): boolean {
    return true;
}

export function VerifyEmailResponseFromJSON(json: any): VerifyEmailResponse {
    return VerifyEmailResponseFromJSONTyped(json, false);
}

export function VerifyEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyEmailResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : UserFromJSON(json['data']),
    };
}

export function VerifyEmailResponseToJSON(value?: VerifyEmailResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': UserToJSON(value['data']),
    };
}

