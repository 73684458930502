/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateScheduleTemplateData
 */
export interface CreateScheduleTemplateData {
    /**
     * 
     * @type {number}
     * @memberof CreateScheduleTemplateData
     */
    scheduleId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduleTemplateData
     */
    name: string;
    /**
     * The start of the period represented by the template
     * @type {Date}
     * @memberof CreateScheduleTemplateData
     */
    startsAt: Date;
    /**
     * The end of the period represented by the template (exclusive)
     * @type {Date}
     * @memberof CreateScheduleTemplateData
     */
    endsAt: Date;
    /**
     * Which shifts to create the template from. Could be a highly filtered subset of shifts within the stated period.
     * @type {Array<number>}
     * @memberof CreateScheduleTemplateData
     */
    shiftIds: Array<number>;
}

/**
 * Check if a given object implements the CreateScheduleTemplateData interface.
 */
export function instanceOfCreateScheduleTemplateData(value: object): boolean {
    if (!('scheduleId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('startsAt' in value)) return false;
    if (!('endsAt' in value)) return false;
    if (!('shiftIds' in value)) return false;
    return true;
}

export function CreateScheduleTemplateDataFromJSON(json: any): CreateScheduleTemplateData {
    return CreateScheduleTemplateDataFromJSONTyped(json, false);
}

export function CreateScheduleTemplateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScheduleTemplateData {
    if (json == null) {
        return json;
    }
    return {
        
        'scheduleId': json['scheduleId'],
        'name': json['name'],
        'startsAt': (new Date(json['startsAt'])),
        'endsAt': (new Date(json['endsAt'])),
        'shiftIds': json['shiftIds'],
    };
}

export function CreateScheduleTemplateDataToJSON(value?: CreateScheduleTemplateData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'scheduleId': value['scheduleId'],
        'name': value['name'],
        'startsAt': ((value['startsAt']).toISOString()),
        'endsAt': ((value['endsAt']).toISOString()),
        'shiftIds': value['shiftIds'],
    };
}

