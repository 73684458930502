/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingPlan } from './BillingPlan';
import {
    BillingPlanFromJSON,
    BillingPlanFromJSONTyped,
    BillingPlanToJSON,
} from './BillingPlan';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface BillingDowngrade
 */
export interface BillingDowngrade {
    /**
     * 
     * @type {number}
     * @memberof BillingDowngrade
     */
    newPlanId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BillingDowngrade
     */
    downgradeAt: ShiftiePlainDate;
    /**
     * 
     * @type {BillingPlan}
     * @memberof BillingDowngrade
     */
    newPlan?: BillingPlan;
}

/**
 * Check if a given object implements the BillingDowngrade interface.
 */
export function instanceOfBillingDowngrade(value: object): boolean {
    if (!('newPlanId' in value)) return false;
    if (!('downgradeAt' in value)) return false;
    return true;
}

export function BillingDowngradeFromJSON(json: any): BillingDowngrade {
    return BillingDowngradeFromJSONTyped(json, false);
}

export function BillingDowngradeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingDowngrade {
    if (json == null) {
        return json;
    }
    return {
        
        'newPlanId': json['newPlanId'],
        'downgradeAt': ShiftiePlainDateFromJSON(json['downgradeAt']),
        'newPlan': json['newPlan'] == null ? undefined : BillingPlanFromJSON(json['newPlan']),
    };
}

export function BillingDowngradeToJSON(value?: BillingDowngrade | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newPlanId': value['newPlanId'],
        'downgradeAt': ShiftiePlainDateToJSON(value['downgradeAt']),
        'newPlan': BillingPlanToJSON(value['newPlan']),
    };
}

