/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
} from './JobRole';

/**
 * 
 * @export
 * @interface CreateJobRoleResponse
 */
export interface CreateJobRoleResponse {
    /**
     * 
     * @type {JobRole}
     * @memberof CreateJobRoleResponse
     */
    data?: JobRole;
}

/**
 * Check if a given object implements the CreateJobRoleResponse interface.
 */
export function instanceOfCreateJobRoleResponse(value: object): boolean {
    return true;
}

export function CreateJobRoleResponseFromJSON(json: any): CreateJobRoleResponse {
    return CreateJobRoleResponseFromJSONTyped(json, false);
}

export function CreateJobRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateJobRoleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : JobRoleFromJSON(json['data']),
    };
}

export function CreateJobRoleResponseToJSON(value?: CreateJobRoleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': JobRoleToJSON(value['data']),
    };
}

