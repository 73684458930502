/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PendingUpload } from './PendingUpload';
import {
    PendingUploadFromJSON,
    PendingUploadFromJSONTyped,
    PendingUploadToJSON,
} from './PendingUpload';

/**
 * 
 * @export
 * @interface CreateUploadResponse
 */
export interface CreateUploadResponse {
    /**
     * 
     * @type {PendingUpload}
     * @memberof CreateUploadResponse
     */
    data: PendingUpload;
}

/**
 * Check if a given object implements the CreateUploadResponse interface.
 */
export function instanceOfCreateUploadResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateUploadResponseFromJSON(json: any): CreateUploadResponse {
    return CreateUploadResponseFromJSONTyped(json, false);
}

export function CreateUploadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUploadResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': PendingUploadFromJSON(json['data']),
    };
}

export function CreateUploadResponseToJSON(value?: CreateUploadResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': PendingUploadToJSON(value['data']),
    };
}

