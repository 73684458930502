import {
  NestableRouteConfig,
  OurRouteConfigObject,
  routes,
} from '@/router/routes'; // eslint-disable-line no-unused-vars
import { Route } from 'vue-router';

/**
 * Modify the query portion of a Vue route object. Accepts an existing route and an object of new values
 * to add to it. You don't need to specify all the new query params, only those you want to modify. Others will
 * remain untouched.
 * If the value of a query param is null it will be removed.
 *
 * @param {Route} route
 * @param {object} queryReplacements
 *
 * @return {Route}
 */
export const modifyRouteQuery = (
  route: Route,
  queryReplacements: object,
): Route => {
  const query = {
    ...route.query,
    ...queryReplacements,
  };
  // Remove null values.
  Object.keys(query).forEach((key) => {
    if (query[key] === null) {
      delete query[key];
    }
  });

  return {
    ...route,
    query,
  };
};

/**
 * Check if a route is active including child routes.
 * @param {string} currentRouteName
 * @param {string} checkRouteName
 * @param {OurRouteConfigObject} routesObject
 *
 * @return {boolean}
 */
export const isRouteActive = (
  currentRouteName: string,
  checkRouteName: string,
  routesObject: OurRouteConfigObject = routes,
): boolean => {
  if (currentRouteName === checkRouteName) return true;

  const parent = (
    routesObject[currentRouteName] as NestableRouteConfig
  )?.childOf?.();
  if (!parent) return false;

  return parent === checkRouteName;
};
