/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListNotificationsResponse,
  ShowNotificationResponse,
} from '../models/index';
import {
    ListNotificationsResponseFromJSON,
    ListNotificationsResponseToJSON,
    ShowNotificationResponseFromJSON,
    ShowNotificationResponseToJSON,
} from '../models/index';

export interface BulkDeleteNotificationsRequest {
    where?: { [key: string]: object; };
}

export interface DeleteNotificationRequest {
    id: string;
}

export interface ListNotificationsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowNotificationRequest {
    id: string;
    _with?: Array<string>;
}

/**
 * 
 */
export class NotificationApi extends runtime.BaseAPI {

    /**
     * Bulk delete notifications matching the specified criteria
     * Bulk Delete Notifications
     */
    async bulkDeleteNotificationsRaw(requestParameters: BulkDeleteNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.notification.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk delete notifications matching the specified criteria
     * Bulk Delete Notifications
     */
    async bulkDeleteNotifications(requestParameters: BulkDeleteNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkDeleteNotificationsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a specific notification
     * Delete Notification
     */
    async deleteNotificationRaw(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteNotification().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.notification.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific notification
     * Delete Notification
     */
    async deleteNotification(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNotificationRaw(requestParameters, initOverrides);
    }

    /**
     * Show all unread notifications for the current employee
     * List Notifications
     */
    async listNotificationsRaw(requestParameters: ListNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListNotificationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.notification.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListNotificationsResponseFromJSON(jsonValue));
    }

    /**
     * Show all unread notifications for the current employee
     * List Notifications
     */
    async listNotifications(requestParameters: ListNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListNotificationsResponse> {
        const response = await this.listNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Notification
     */
    async showNotificationRaw(requestParameters: ShowNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowNotificationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showNotification().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.notification.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowNotificationResponseFromJSON(jsonValue));
    }

    /**
     * Show Notification
     */
    async showNotification(requestParameters: ShowNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowNotificationResponse> {
        const response = await this.showNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
