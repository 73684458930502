import { PlainDate } from '@/lib/date-time/PlainDate';
import { EntityInterfaceMap } from '@/lib/realtime/realtimeTypes';

type Model = EntityInterfaceMap[keyof EntityInterfaceMap];

type Prop<T extends Model> = keyof T;

type Operator =
  | 'eq'
  | 'neq'
  | 'lt'
  | 'lte'
  | 'gt'
  | 'gte'
  | 'in'
  | 'notIn'
  | 'isNull'
  | 'contains'
  | 'startsWith'
  | 'endsWith';
type Value<T extends Model, P extends Prop<T>> = T[P] | Array<T[P]>;

/**
 * @see https://stackoverflow.com/a/54520829/921476
 * Although modified to return keys where *any* of the types
 * match. E.g. when given 'null', will find all the nullable types.
 */
type KeysMatching2<T, V> = {
  [K in keyof T]-?: V extends T[K] ? K : never;
}[keyof T];

type PropOfType<E extends Model, T> = KeysMatching2<E, T>;

export class ConditionBuilder<T extends Model> {
  #conditions = {} as Record<keyof T, {}>;

  #addCondition(prop: keyof T, operator: Operator, value: any): this {
    // Handle serialisation of common value types
    if (value instanceof Date) {
      value = value.toISOString();
    } else if (value instanceof PlainDate) {
      value = value.toString();
    }
    this.#conditions = {
      ...this.conditions,
      [prop]: {
        ...this.conditions[prop],
        [operator]: value,
      },
    };
    return this;
  }

  where<P extends Prop<T>, V extends Value<T, P>>(
    prop: P,
    operator: Operator,
    value: V,
  ): this {
    return this.#addCondition(prop, operator, value);
  }

  whereIn<P extends Prop<T>, V extends Value<T, P>[]>(prop: P, value: V): this {
    return this.#addCondition(prop, 'in', value);
  }

  whereNotIn<P extends Prop<T>, V extends Value<T, P>[]>(
    prop: P,
    value: V,
  ): this {
    return this.#addCondition(prop, 'notIn', value);
  }

  whereNull(prop: PropOfType<T, null>): this {
    return this.#addCondition(prop, 'isNull', true);
  }

  whereNotNull(prop: PropOfType<T, null>): this {
    return this.#addCondition(prop, 'isNull', false);
  }

  whereDateInPeriod(
    prop: PropOfType<T, PlainDate>,
    start: PlainDate,
    end: PlainDate,
  ): this {
    return this.#addCondition(prop, 'gte', start.toString()).#addCondition(
      prop,
      'lte',
      end.toString(),
    );
  }

  whereDateTimeInPeriod(
    prop: PropOfType<T, Date>,
    start: Date,
    end: Date,
  ): this {
    return this.#addCondition(prop, 'gte', start.toISOString()).#addCondition(
      prop,
      'lt',
      end.toISOString(),
    );
  }

  whereDatesOverlap(
    startProp: PropOfType<T, PlainDate>,
    endProp: PropOfType<T, PlainDate>,
    start: PlainDate,
    end: PlainDate,
  ): this {
    return this.#addCondition(startProp, 'lte', end.toString()).#addCondition(
      endProp,
      'gte',
      start.toString(),
    );
  }

  whereDatesTimesOverlap(
    startProp: PropOfType<T, Date>,
    endProp: PropOfType<T, Date>,
    start: Date,
    end: Date,
  ): this {
    return this.#addCondition(startProp, 'lt', end.toISOString()).#addCondition(
      endProp,
      'gt',
      start.toISOString(),
    );
  }

  get conditions() {
    return this.#conditions;
  }
}
