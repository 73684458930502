
import { PropType } from 'vue';

export default {
  name: 'StagedProgress',
  props: {
    stages: {
      type: Array as PropType<
        {
          label: string;
          complete: boolean;
        }[]
      >,
      default: () => [],
    },
  },
};
