/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrekksoftClientCredential
 */
export interface TrekksoftClientCredential {
    /**
     * 
     * @type {number}
     * @memberof TrekksoftClientCredential
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TrekksoftClientCredential
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof TrekksoftClientCredential
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrekksoftClientCredential
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof TrekksoftClientCredential
     */
    clientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof TrekksoftClientCredential
     */
    status: TrekksoftClientCredentialStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum TrekksoftClientCredentialStatusEnum {
    Active = 'Active',
    Broken = 'Broken'
}


/**
 * Check if a given object implements the TrekksoftClientCredential interface.
 */
export function instanceOfTrekksoftClientCredential(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('clientId' in value)) return false;
    if (!('clientSecret' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function TrekksoftClientCredentialFromJSON(json: any): TrekksoftClientCredential {
    return TrekksoftClientCredentialFromJSONTyped(json, false);
}

export function TrekksoftClientCredentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrekksoftClientCredential {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'clientId': json['clientId'],
        'clientSecret': json['clientSecret'],
        'status': json['status'],
    };
}

export function TrekksoftClientCredentialToJSON(value?: TrekksoftClientCredential | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'clientId': value['clientId'],
        'clientSecret': value['clientSecret'],
        'status': value['status'],
    };
}

