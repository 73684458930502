/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeaveTypeData
 */
export interface UpdateLeaveTypeData {
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveTypeData
     */
    deductionLeaveTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    status?: UpdateLeaveTypeDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    colour?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveTypeData
     */
    paid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    payCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    leaveAllowanceType?: UpdateLeaveTypeDataLeaveAllowanceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    leaveAllowanceUnit?: UpdateLeaveTypeDataLeaveAllowanceUnitEnum;
    /**
     * Leave allowance for this leave type when using the 'Fixed' allowance type.
     * @type {number}
     * @memberof UpdateLeaveTypeData
     */
    leaveAllowance?: number;
    /**
     * What percentage of worked hours an employee accrues for this leave type. A rate of 100 means leave is accrued at the same rate as hours worked.
     * @type {number}
     * @memberof UpdateLeaveTypeData
     */
    accrualRatePerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveTypeData
     */
    accrualLimitDaysPerPeriod?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveTypeData
     */
    accrualAllowedOnOvertimeHours?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UpdateLeaveTypeDataStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}
/**
* @export
* @enum {string}
*/
export enum UpdateLeaveTypeDataLeaveAllowanceTypeEnum {
    Accrued = 'Accrued',
    DeductsFromAnother = 'Deducts From Another',
    Fixed = 'Fixed',
    Unlimited = 'Unlimited'
}
/**
* @export
* @enum {string}
*/
export enum UpdateLeaveTypeDataLeaveAllowanceUnitEnum {
    Days = 'Days',
    Hours = 'Hours'
}


/**
 * Check if a given object implements the UpdateLeaveTypeData interface.
 */
export function instanceOfUpdateLeaveTypeData(value: object): boolean {
    return true;
}

export function UpdateLeaveTypeDataFromJSON(json: any): UpdateLeaveTypeData {
    return UpdateLeaveTypeDataFromJSONTyped(json, false);
}

export function UpdateLeaveTypeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeaveTypeData {
    if (json == null) {
        return json;
    }
    return {
        
        'deductionLeaveTypeId': json['deductionLeaveTypeId'] == null ? undefined : json['deductionLeaveTypeId'],
        'name': json['name'] == null ? undefined : json['name'],
        'status': json['status'] == null ? undefined : json['status'],
        'colour': json['colour'] == null ? undefined : json['colour'],
        'paid': json['paid'] == null ? undefined : json['paid'],
        'payCode': json['payCode'] == null ? undefined : json['payCode'],
        'leaveAllowanceType': json['leaveAllowanceType'] == null ? undefined : json['leaveAllowanceType'],
        'leaveAllowanceUnit': json['leaveAllowanceUnit'] == null ? undefined : json['leaveAllowanceUnit'],
        'leaveAllowance': json['leaveAllowance'] == null ? undefined : json['leaveAllowance'],
        'accrualRatePerHour': json['accrualRatePerHour'] == null ? undefined : json['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': json['accrualLimitDaysPerPeriod'] == null ? undefined : json['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': json['accrualAllowedOnOvertimeHours'] == null ? undefined : json['accrualAllowedOnOvertimeHours'],
    };
}

export function UpdateLeaveTypeDataToJSON(value?: UpdateLeaveTypeData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'deductionLeaveTypeId': value['deductionLeaveTypeId'],
        'name': value['name'],
        'status': value['status'],
        'colour': value['colour'],
        'paid': value['paid'],
        'payCode': value['payCode'],
        'leaveAllowanceType': value['leaveAllowanceType'],
        'leaveAllowanceUnit': value['leaveAllowanceUnit'],
        'leaveAllowance': value['leaveAllowance'],
        'accrualRatePerHour': value['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': value['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': value['accrualAllowedOnOvertimeHours'],
    };
}

