/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';

/**
 * 
 * @export
 * @interface CreateEmployeeResponse
 */
export interface CreateEmployeeResponse {
    /**
     * 
     * @type {Employee}
     * @memberof CreateEmployeeResponse
     */
    data?: Employee;
}

/**
 * Check if a given object implements the CreateEmployeeResponse interface.
 */
export function instanceOfCreateEmployeeResponse(value: object): boolean {
    return true;
}

export function CreateEmployeeResponseFromJSON(json: any): CreateEmployeeResponse {
    return CreateEmployeeResponseFromJSONTyped(json, false);
}

export function CreateEmployeeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : EmployeeFromJSON(json['data']),
    };
}

export function CreateEmployeeResponseToJSON(value?: CreateEmployeeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeToJSON(value['data']),
    };
}

