/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTimesheetEntryDataBreaksInner
 */
export interface UpdateTimesheetEntryDataBreaksInner {
    /**
     * An optional identifier for the break. Supply this to update an existing break.
     * @type {number}
     * @memberof UpdateTimesheetEntryDataBreaksInner
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTimesheetEntryDataBreaksInner
     */
    startedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTimesheetEntryDataBreaksInner
     */
    endedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof UpdateTimesheetEntryDataBreaksInner
     */
    scheduledBreakId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTimesheetEntryDataBreaksInner
     */
    paid: boolean;
}

/**
 * Check if a given object implements the UpdateTimesheetEntryDataBreaksInner interface.
 */
export function instanceOfUpdateTimesheetEntryDataBreaksInner(value: object): boolean {
    if (!('startedAt' in value)) return false;
    if (!('endedAt' in value)) return false;
    if (!('paid' in value)) return false;
    return true;
}

export function UpdateTimesheetEntryDataBreaksInnerFromJSON(json: any): UpdateTimesheetEntryDataBreaksInner {
    return UpdateTimesheetEntryDataBreaksInnerFromJSONTyped(json, false);
}

export function UpdateTimesheetEntryDataBreaksInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTimesheetEntryDataBreaksInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'startedAt': (new Date(json['startedAt'])),
        'endedAt': (new Date(json['endedAt'])),
        'scheduledBreakId': json['scheduledBreakId'] == null ? undefined : json['scheduledBreakId'],
        'paid': json['paid'],
    };
}

export function UpdateTimesheetEntryDataBreaksInnerToJSON(value?: UpdateTimesheetEntryDataBreaksInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'startedAt': ((value['startedAt']).toISOString()),
        'endedAt': ((value['endedAt']).toISOString()),
        'scheduledBreakId': value['scheduledBreakId'],
        'paid': value['paid'],
    };
}

