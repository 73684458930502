import i18n from '@/i18n';

export const presentMinutes = (
  minutes: number,
  shortFormat = true,
  highPrecision: boolean = false,
): string => {
  const isNegative = minutes < 0;
  minutes = Math.abs(minutes);
  const hours = Math.floor(minutes / 60);
  let hoursString = '';

  if (shortFormat && hours) {
    hoursString = `${hours}h`;
  } else if (hours) {
    hoursString = i18n.tc('unitWithAmount.hour', hours).toString();
  }

  // High precision is used for timesheets with hours as 4 decimal places
  const mins = highPrecision
    ? Math.round(minutes % 60)
    : Math.floor(minutes % 60);
  let minsString = '';

  if (shortFormat && mins) {
    minsString = `${mins}m`;
  } else if (mins) {
    minsString = i18n.tc('unitWithAmount.minute', mins).toString();
  }

  let str;

  if (!hours && !mins) {
    str = '-';
  } else {
    str = `${hoursString} ${minsString}`.trim();
  }

  return isNegative ? `-${str}` : str;
};

export const limitStat = (stat: number, limit: number) =>
  stat <= limit ? stat : `${limit}+`;

export const presentTimezoneOffset = (offset: number): string =>
  `GMT${offset >= 0 ? '+' : ''}${offset}`;
