/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeAbsenceSummary } from './EmployeeAbsenceSummary';
import {
    EmployeeAbsenceSummaryFromJSON,
    EmployeeAbsenceSummaryFromJSONTyped,
    EmployeeAbsenceSummaryToJSON,
} from './EmployeeAbsenceSummary';

/**
 * 
 * @export
 * @interface ShowEmployeeAbsenceSummaryResponse
 */
export interface ShowEmployeeAbsenceSummaryResponse {
    /**
     * 
     * @type {EmployeeAbsenceSummary}
     * @memberof ShowEmployeeAbsenceSummaryResponse
     */
    data: EmployeeAbsenceSummary;
}

/**
 * Check if a given object implements the ShowEmployeeAbsenceSummaryResponse interface.
 */
export function instanceOfShowEmployeeAbsenceSummaryResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowEmployeeAbsenceSummaryResponseFromJSON(json: any): ShowEmployeeAbsenceSummaryResponse {
    return ShowEmployeeAbsenceSummaryResponseFromJSONTyped(json, false);
}

export function ShowEmployeeAbsenceSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeAbsenceSummaryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeAbsenceSummaryFromJSON(json['data']),
    };
}

export function ShowEmployeeAbsenceSummaryResponseToJSON(value?: ShowEmployeeAbsenceSummaryResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeAbsenceSummaryToJSON(value['data']),
    };
}

