/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftSwap } from './ShiftSwap';
import {
    ShiftSwapFromJSON,
    ShiftSwapFromJSONTyped,
    ShiftSwapToJSON,
} from './ShiftSwap';

/**
 * 
 * @export
 * @interface CancelShiftSwapResponse
 */
export interface CancelShiftSwapResponse {
    /**
     * 
     * @type {ShiftSwap}
     * @memberof CancelShiftSwapResponse
     */
    data?: ShiftSwap;
}

/**
 * Check if a given object implements the CancelShiftSwapResponse interface.
 */
export function instanceOfCancelShiftSwapResponse(value: object): boolean {
    return true;
}

export function CancelShiftSwapResponseFromJSON(json: any): CancelShiftSwapResponse {
    return CancelShiftSwapResponseFromJSONTyped(json, false);
}

export function CancelShiftSwapResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelShiftSwapResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftSwapFromJSON(json['data']),
    };
}

export function CancelShiftSwapResponseToJSON(value?: CancelShiftSwapResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftSwapToJSON(value['data']),
    };
}

