/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeGroup } from './EmployeeGroup';
import {
    EmployeeGroupFromJSON,
    EmployeeGroupFromJSONTyped,
    EmployeeGroupToJSON,
} from './EmployeeGroup';

/**
 * 
 * @export
 * @interface CreateEmployeeGroupResponse
 */
export interface CreateEmployeeGroupResponse {
    /**
     * 
     * @type {EmployeeGroup}
     * @memberof CreateEmployeeGroupResponse
     */
    data: EmployeeGroup;
}

/**
 * Check if a given object implements the CreateEmployeeGroupResponse interface.
 */
export function instanceOfCreateEmployeeGroupResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateEmployeeGroupResponseFromJSON(json: any): CreateEmployeeGroupResponse {
    return CreateEmployeeGroupResponseFromJSONTyped(json, false);
}

export function CreateEmployeeGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeGroupResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeGroupFromJSON(json['data']),
    };
}

export function CreateEmployeeGroupResponseToJSON(value?: CreateEmployeeGroupResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeGroupToJSON(value['data']),
    };
}

