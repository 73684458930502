/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateScheduledBreakData
 */
export interface CreateScheduledBreakData {
    /**
     * 
     * @type {Date}
     * @memberof CreateScheduledBreakData
     */
    startsAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof CreateScheduledBreakData
     */
    durationInMinutes: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateScheduledBreakData
     */
    paid: boolean;
}

/**
 * Check if a given object implements the CreateScheduledBreakData interface.
 */
export function instanceOfCreateScheduledBreakData(value: object): boolean {
    if (!('startsAt' in value)) return false;
    if (!('durationInMinutes' in value)) return false;
    if (!('paid' in value)) return false;
    return true;
}

export function CreateScheduledBreakDataFromJSON(json: any): CreateScheduledBreakData {
    return CreateScheduledBreakDataFromJSONTyped(json, false);
}

export function CreateScheduledBreakDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScheduledBreakData {
    if (json == null) {
        return json;
    }
    return {
        
        'startsAt': (json['startsAt'] == null ? null : new Date(json['startsAt'])),
        'durationInMinutes': json['durationInMinutes'],
        'paid': json['paid'],
    };
}

export function CreateScheduledBreakDataToJSON(value?: CreateScheduledBreakData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'startsAt': (value['startsAt'] == null ? null : (value['startsAt'] as any).toISOString()),
        'durationInMinutes': value['durationInMinutes'],
        'paid': value['paid'],
    };
}

