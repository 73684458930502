/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OauthToken
 */
export interface OauthToken {
    /**
     * 
     * @type {string}
     * @memberof OauthToken
     */
    accessToken?: string;
}

/**
 * Check if a given object implements the OauthToken interface.
 */
export function instanceOfOauthToken(value: object): boolean {
    return true;
}

export function OauthTokenFromJSON(json: any): OauthToken {
    return OauthTokenFromJSONTyped(json, false);
}

export function OauthTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): OauthToken {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'] == null ? undefined : json['accessToken'],
    };
}

export function OauthTokenToJSON(value?: OauthToken | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accessToken': value['accessToken'],
    };
}

