/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateClockInPortalData
 */
export interface CreateClockInPortalData {
    /**
     * 
     * @type {string}
     * @memberof CreateClockInPortalData
     */
    name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateClockInPortalData
     */
    locations: Array<number>;
}

/**
 * Check if a given object implements the CreateClockInPortalData interface.
 */
export function instanceOfCreateClockInPortalData(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('locations' in value)) return false;
    return true;
}

export function CreateClockInPortalDataFromJSON(json: any): CreateClockInPortalData {
    return CreateClockInPortalDataFromJSONTyped(json, false);
}

export function CreateClockInPortalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateClockInPortalData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'locations': json['locations'],
    };
}

export function CreateClockInPortalDataToJSON(value?: CreateClockInPortalData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'locations': value['locations'],
    };
}

