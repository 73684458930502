/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Update meta data for an employees job role
 * @export
 * @interface UpdateEmployeeJobRoleData
 */
export interface UpdateEmployeeJobRoleData {
    /**
     * Amount the employee should be paid when performing this job role
     * @type {number}
     * @memberof UpdateEmployeeJobRoleData
     */
    rate?: number;
}

/**
 * Check if a given object implements the UpdateEmployeeJobRoleData interface.
 */
export function instanceOfUpdateEmployeeJobRoleData(value: object): boolean {
    return true;
}

export function UpdateEmployeeJobRoleDataFromJSON(json: any): UpdateEmployeeJobRoleData {
    return UpdateEmployeeJobRoleDataFromJSONTyped(json, false);
}

export function UpdateEmployeeJobRoleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeeJobRoleData {
    if (json == null) {
        return json;
    }
    return {
        
        'rate': json['rate'] == null ? undefined : json['rate'],
    };
}

export function UpdateEmployeeJobRoleDataToJSON(value?: UpdateEmployeeJobRoleData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rate': value['rate'],
    };
}

