/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateLeaveTypeData,
  CreateLeaveTypeResponse,
  ListLeaveTypesResponse,
  ShowLeaveTypeResponse,
  UpdateLeaveTypeData,
  UpdateLeaveTypeResponse,
} from '../models/index';
import {
    CreateLeaveTypeDataFromJSON,
    CreateLeaveTypeDataToJSON,
    CreateLeaveTypeResponseFromJSON,
    CreateLeaveTypeResponseToJSON,
    ListLeaveTypesResponseFromJSON,
    ListLeaveTypesResponseToJSON,
    ShowLeaveTypeResponseFromJSON,
    ShowLeaveTypeResponseToJSON,
    UpdateLeaveTypeDataFromJSON,
    UpdateLeaveTypeDataToJSON,
    UpdateLeaveTypeResponseFromJSON,
    UpdateLeaveTypeResponseToJSON,
} from '../models/index';

export interface CreateLeaveTypeRequest {
    createLeaveTypeData?: CreateLeaveTypeData;
}

export interface ListLeaveTypesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowLeaveTypeRequest {
    id: number;
}

export interface UpdateLeaveTypeRequest {
    id: number;
    updateLeaveTypeData?: UpdateLeaveTypeData;
}

/**
 * 
 */
export class LeaveTypeApi extends runtime.BaseAPI {

    /**
     * Create Leave Type
     */
    async createLeaveTypeRaw(requestParameters: CreateLeaveTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLeaveTypeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-type.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeaveTypeDataToJSON(requestParameters['createLeaveTypeData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLeaveTypeResponseFromJSON(jsonValue));
    }

    /**
     * Create Leave Type
     */
    async createLeaveType(requestParameters: CreateLeaveTypeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLeaveTypeResponse> {
        const response = await this.createLeaveTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show all available leave types
     * List Leave Types
     */
    async listLeaveTypesRaw(requestParameters: ListLeaveTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeaveTypesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-type.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeaveTypesResponseFromJSON(jsonValue));
    }

    /**
     * Show all available leave types
     * List Leave Types
     */
    async listLeaveTypes(requestParameters: ListLeaveTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeaveTypesResponse> {
        const response = await this.listLeaveTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details of a leave type
     * Show Leave Type
     */
    async showLeaveTypeRaw(requestParameters: ShowLeaveTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowLeaveTypeResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showLeaveType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-type.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowLeaveTypeResponseFromJSON(jsonValue));
    }

    /**
     * Show details of a leave type
     * Show Leave Type
     */
    async showLeaveType(requestParameters: ShowLeaveTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowLeaveTypeResponse> {
        const response = await this.showLeaveTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Leave Type
     */
    async updateLeaveTypeRaw(requestParameters: UpdateLeaveTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateLeaveTypeResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLeaveType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-type.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeaveTypeDataToJSON(requestParameters['updateLeaveTypeData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateLeaveTypeResponseFromJSON(jsonValue));
    }

    /**
     * Update Leave Type
     */
    async updateLeaveType(requestParameters: UpdateLeaveTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateLeaveTypeResponse> {
        const response = await this.updateLeaveTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
