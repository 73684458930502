/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeOnboarding } from './EmployeeOnboarding';
import {
    EmployeeOnboardingFromJSON,
    EmployeeOnboardingFromJSONTyped,
    EmployeeOnboardingToJSON,
} from './EmployeeOnboarding';

/**
 * 
 * @export
 * @interface UpdateEmployeeOnboardingResponse
 */
export interface UpdateEmployeeOnboardingResponse {
    /**
     * 
     * @type {EmployeeOnboarding}
     * @memberof UpdateEmployeeOnboardingResponse
     */
    data: EmployeeOnboarding;
}

/**
 * Check if a given object implements the UpdateEmployeeOnboardingResponse interface.
 */
export function instanceOfUpdateEmployeeOnboardingResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateEmployeeOnboardingResponseFromJSON(json: any): UpdateEmployeeOnboardingResponse {
    return UpdateEmployeeOnboardingResponseFromJSONTyped(json, false);
}

export function UpdateEmployeeOnboardingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeeOnboardingResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeOnboardingFromJSON(json['data']),
    };
}

export function UpdateEmployeeOnboardingResponseToJSON(value?: UpdateEmployeeOnboardingResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeOnboardingToJSON(value['data']),
    };
}

