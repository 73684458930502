/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';
import type { Upload } from './Upload';
import {
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
} from './Upload';

/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    uploadId: number | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    reference: string;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof Invoice
     */
    date: ShiftiePlainDate | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    totalDue: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    subTotal: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    vatValue: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    status: InvoiceStatusEnum;
    /**
     * 
     * @type {Upload}
     * @memberof Invoice
     */
    upload?: Upload;
}

/**
* @export
* @enum {string}
*/
export enum InvoiceStatusEnum {
    Pending = 'Pending',
    Paid = 'Paid',
    Failed = 'Failed',
    Void = 'Void'
}


/**
 * Check if a given object implements the Invoice interface.
 */
export function instanceOfInvoice(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('uploadId' in value)) return false;
    if (!('reference' in value)) return false;
    if (!('date' in value)) return false;
    if (!('totalDue' in value)) return false;
    if (!('subTotal' in value)) return false;
    if (!('vatValue' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function InvoiceFromJSON(json: any): Invoice {
    return InvoiceFromJSONTyped(json, false);
}

export function InvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invoice {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'uploadId': json['uploadId'],
        'reference': json['reference'],
        'date': ShiftiePlainDateFromJSON(json['date']),
        'totalDue': json['totalDue'],
        'subTotal': json['subTotal'],
        'vatValue': json['vatValue'],
        'status': json['status'],
        'upload': json['upload'] == null ? undefined : UploadFromJSON(json['upload']),
    };
}

export function InvoiceToJSON(value?: Invoice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'uploadId': value['uploadId'],
        'reference': value['reference'],
        'date': ShiftiePlainDateToJSON(value['date']),
        'totalDue': value['totalDue'],
        'subTotal': value['subTotal'],
        'vatValue': value['vatValue'],
        'status': value['status'],
        'upload': UploadToJSON(value['upload']),
    };
}

