export enum EnvironmentEnum {
  Production = 'production',
  Staging = 'staging',
  Development = 'development',
  Local = 'local',
}

export const isLocalEnv = process.env.VUE_APP_ENV === EnvironmentEnum.Local;
export const isDevEnv = process.env.VUE_APP_ENV === EnvironmentEnum.Development;
export const isStagingEnv = process.env.VUE_APP_ENV === EnvironmentEnum.Staging;
