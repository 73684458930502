/**
 * @export
 * @enum {string}
 */
export enum ClockInDialogTypeEnum {
  EarlyClockIn = 'earlyClockIn',
  ClockIn = 'clockIn',
  EarlyStartBreak = 'earlyStartBreak',
  StartBreak = 'startBreak',
  ChooseBreak = 'chooseBreak',
  EndBreak = 'endBreak',
  ClockOut = 'clockOut',
  ChooseLocation = 'chooseLocation',
}
