/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigiticketsScheduleMap } from './DigiticketsScheduleMap';
import {
    DigiticketsScheduleMapFromJSON,
    DigiticketsScheduleMapFromJSONTyped,
    DigiticketsScheduleMapToJSON,
} from './DigiticketsScheduleMap';

/**
 * 
 * @export
 * @interface UpdateDigiticketsScheduleMapResponse
 */
export interface UpdateDigiticketsScheduleMapResponse {
    /**
     * 
     * @type {DigiticketsScheduleMap}
     * @memberof UpdateDigiticketsScheduleMapResponse
     */
    data: DigiticketsScheduleMap;
}

/**
 * Check if a given object implements the UpdateDigiticketsScheduleMapResponse interface.
 */
export function instanceOfUpdateDigiticketsScheduleMapResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateDigiticketsScheduleMapResponseFromJSON(json: any): UpdateDigiticketsScheduleMapResponse {
    return UpdateDigiticketsScheduleMapResponseFromJSONTyped(json, false);
}

export function UpdateDigiticketsScheduleMapResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDigiticketsScheduleMapResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': DigiticketsScheduleMapFromJSON(json['data']),
    };
}

export function UpdateDigiticketsScheduleMapResponseToJSON(value?: UpdateDigiticketsScheduleMapResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': DigiticketsScheduleMapToJSON(value['data']),
    };
}

