/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
} from './Shift';

/**
 * 
 * @export
 * @interface ListShiftsResponse
 */
export interface ListShiftsResponse {
    /**
     * 
     * @type {Array<Shift>}
     * @memberof ListShiftsResponse
     */
    data: Array<Shift>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListShiftsResponse interface.
 */
export function instanceOfListShiftsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListShiftsResponseFromJSON(json: any): ListShiftsResponse {
    return ListShiftsResponseFromJSONTyped(json, false);
}

export function ListShiftsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ShiftFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListShiftsResponseToJSON(value?: ListShiftsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(ShiftToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

