<template>
  <i
    class="h-6 w-6 cursor-pointer rounded-full all:flex align-center justify-center"
    :class="[
      Icon.Xmark,
      {
        'text-white hover:bg-purple-50 hover:text-purple-900': lightMode,
        'text-gray-600 hover:bg-gray-100': !lightMode,
      },
    ]"
    @click="$emit('click')"
  />
</template>

<script>
import { Icon } from '@/lib/enum/Icon';

export default {
  name: 'CloseButton',
  props: {
    lightMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Icon,
    };
  },
};
</script>
