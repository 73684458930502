/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmergencyContact } from './EmergencyContact';
import {
    EmergencyContactFromJSON,
    EmergencyContactFromJSONTyped,
    EmergencyContactToJSON,
} from './EmergencyContact';

/**
 * 
 * @export
 * @interface ShowEmergencyContactResponse
 */
export interface ShowEmergencyContactResponse {
    /**
     * 
     * @type {EmergencyContact}
     * @memberof ShowEmergencyContactResponse
     */
    data?: EmergencyContact;
}

/**
 * Check if a given object implements the ShowEmergencyContactResponse interface.
 */
export function instanceOfShowEmergencyContactResponse(value: object): boolean {
    return true;
}

export function ShowEmergencyContactResponseFromJSON(json: any): ShowEmergencyContactResponse {
    return ShowEmergencyContactResponseFromJSONTyped(json, false);
}

export function ShowEmergencyContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmergencyContactResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : EmergencyContactFromJSON(json['data']),
    };
}

export function ShowEmergencyContactResponseToJSON(value?: ShowEmergencyContactResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmergencyContactToJSON(value['data']),
    };
}

