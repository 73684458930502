import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Ripple from 'vuetify/lib/directives/ripple';

Vue.use(Vuetify, {
  // for Vuetify components which require directives by default (e.g <v-simple-checkbox />)
  // check out https://github.com/vuetifyjs/vuetify/issues/12224
  // path for Ripple component might be changed when upgrading Vutify major version
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        // Override Vuetify's default theme colours
        // These values are copied from tailwind.config.js, and should be kept in sync
        primary: '#04A7F7', // blue.default
        secondary: '#1E1E21', // gray.800
        accent: '#4DC1FA', // blue.light
        error: '#D52855', // red.default
        info: '#04A7F7', // blue.default
        success: '#81BB5B', // green.default
        warning: '#F1B649', // yellow.default
      },
    },
  },
});
