/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeePayPeriodJobRoleRate } from './EmployeePayPeriodJobRoleRate';
import {
    EmployeePayPeriodJobRoleRateFromJSON,
    EmployeePayPeriodJobRoleRateFromJSONTyped,
    EmployeePayPeriodJobRoleRateToJSON,
} from './EmployeePayPeriodJobRoleRate';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface EmployeePayPeriodSummary
 */
export interface EmployeePayPeriodSummary {
    /**
     * A deterministic identifier for the report.
     * @type {string}
     * @memberof EmployeePayPeriodSummary
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayPeriodSummary
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayPeriodSummary
     */
    employeeId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeePayPeriodSummary
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeePayPeriodSummary
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {string}
     * @memberof EmployeePayPeriodSummary
     */
    payrollId: string;
    /**
     * The total number of paid hours, excluding overtime
     * @type {number}
     * @memberof EmployeePayPeriodSummary
     */
    totalBasePaidHours: number;
    /**
     * The total number of overtime paid hours
     * @type {number}
     * @memberof EmployeePayPeriodSummary
     */
    totalOvertimePaidHours: number;
    /**
     * Total number of paid hours, including overtime
     * @type {number}
     * @memberof EmployeePayPeriodSummary
     */
    totalPaidHours: number;
    /**
     * Total number of paid leave hours
     * @type {number}
     * @memberof EmployeePayPeriodSummary
     */
    totalPaidLeaveHours?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayPeriodSummary
     */
    estimatedTotal: number;
    /**
     * 
     * @type {Array<EmployeePayPeriodJobRoleRate>}
     * @memberof EmployeePayPeriodSummary
     */
    hourlyRatesByJobRole: Array<EmployeePayPeriodJobRoleRate>;
}

/**
 * Check if a given object implements the EmployeePayPeriodSummary interface.
 */
export function instanceOfEmployeePayPeriodSummary(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('startDate' in value)) return false;
    if (!('endDate' in value)) return false;
    if (!('payrollId' in value)) return false;
    if (!('totalBasePaidHours' in value)) return false;
    if (!('totalOvertimePaidHours' in value)) return false;
    if (!('totalPaidHours' in value)) return false;
    if (!('estimatedTotal' in value)) return false;
    if (!('hourlyRatesByJobRole' in value)) return false;
    return true;
}

export function EmployeePayPeriodSummaryFromJSON(json: any): EmployeePayPeriodSummary {
    return EmployeePayPeriodSummaryFromJSONTyped(json, false);
}

export function EmployeePayPeriodSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeePayPeriodSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'payrollId': json['payrollId'],
        'totalBasePaidHours': json['totalBasePaidHours'],
        'totalOvertimePaidHours': json['totalOvertimePaidHours'],
        'totalPaidHours': json['totalPaidHours'],
        'totalPaidLeaveHours': json['totalPaidLeaveHours'] == null ? undefined : json['totalPaidLeaveHours'],
        'estimatedTotal': json['estimatedTotal'],
        'hourlyRatesByJobRole': ((json['hourlyRatesByJobRole'] as Array<any>).map(EmployeePayPeriodJobRoleRateFromJSON)),
    };
}

export function EmployeePayPeriodSummaryToJSON(value?: EmployeePayPeriodSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'payrollId': value['payrollId'],
        'totalBasePaidHours': value['totalBasePaidHours'],
        'totalOvertimePaidHours': value['totalOvertimePaidHours'],
        'totalPaidHours': value['totalPaidHours'],
        'totalPaidLeaveHours': value['totalPaidLeaveHours'],
        'estimatedTotal': value['estimatedTotal'],
        'hourlyRatesByJobRole': ((value['hourlyRatesByJobRole'] as Array<any>).map(EmployeePayPeriodJobRoleRateToJSON)),
    };
}

