/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkUpdateTimesheetEntriesData
 */
export interface BulkUpdateTimesheetEntriesData {
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateTimesheetEntriesData
     */
    confirmed?: boolean;
}

/**
 * Check if a given object implements the BulkUpdateTimesheetEntriesData interface.
 */
export function instanceOfBulkUpdateTimesheetEntriesData(value: object): boolean {
    return true;
}

export function BulkUpdateTimesheetEntriesDataFromJSON(json: any): BulkUpdateTimesheetEntriesData {
    return BulkUpdateTimesheetEntriesDataFromJSONTyped(json, false);
}

export function BulkUpdateTimesheetEntriesDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateTimesheetEntriesData {
    if (json == null) {
        return json;
    }
    return {
        
        'confirmed': json['confirmed'] == null ? undefined : json['confirmed'],
    };
}

export function BulkUpdateTimesheetEntriesDataToJSON(value?: BulkUpdateTimesheetEntriesData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'confirmed': value['confirmed'],
    };
}

