/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface ReportCostsByDay
 */
export interface ReportCostsByDay {
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof ReportCostsByDay
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByDay
     */
    employees: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportCostsByDay
     */
    employeeIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByDay
     */
    shifts: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByDay
     */
    hoursScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByDay
     */
    hoursWorked: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByDay
     */
    costsScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByDay
     */
    costsWorked: number | null;
}

/**
 * Check if a given object implements the ReportCostsByDay interface.
 */
export function instanceOfReportCostsByDay(value: object): boolean {
    if (!('date' in value)) return false;
    if (!('employees' in value)) return false;
    if (!('employeeIds' in value)) return false;
    if (!('shifts' in value)) return false;
    if (!('hoursScheduled' in value)) return false;
    if (!('hoursWorked' in value)) return false;
    if (!('costsScheduled' in value)) return false;
    if (!('costsWorked' in value)) return false;
    return true;
}

export function ReportCostsByDayFromJSON(json: any): ReportCostsByDay {
    return ReportCostsByDayFromJSONTyped(json, false);
}

export function ReportCostsByDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCostsByDay {
    if (json == null) {
        return json;
    }
    return {
        
        'date': ShiftiePlainDateFromJSON(json['date']),
        'employees': json['employees'],
        'employeeIds': json['employeeIds'],
        'shifts': json['shifts'],
        'hoursScheduled': json['hoursScheduled'],
        'hoursWorked': json['hoursWorked'],
        'costsScheduled': json['costsScheduled'],
        'costsWorked': json['costsWorked'],
    };
}

export function ReportCostsByDayToJSON(value?: ReportCostsByDay | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': ShiftiePlainDateToJSON(value['date']),
        'employees': value['employees'],
        'employeeIds': value['employeeIds'],
        'shifts': value['shifts'],
        'hoursScheduled': value['hoursScheduled'],
        'hoursWorked': value['hoursWorked'],
        'costsScheduled': value['costsScheduled'],
        'costsWorked': value['costsWorked'],
    };
}

