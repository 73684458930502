/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClockInPinLoginRequest
 */
export interface ClockInPinLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof ClockInPinLoginRequest
     */
    clockInPin: string;
}

/**
 * Check if a given object implements the ClockInPinLoginRequest interface.
 */
export function instanceOfClockInPinLoginRequest(value: object): boolean {
    if (!('clockInPin' in value)) return false;
    return true;
}

export function ClockInPinLoginRequestFromJSON(json: any): ClockInPinLoginRequest {
    return ClockInPinLoginRequestFromJSONTyped(json, false);
}

export function ClockInPinLoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockInPinLoginRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'clockInPin': json['clockInPin'],
    };
}

export function ClockInPinLoginRequestToJSON(value?: ClockInPinLoginRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clockInPin': value['clockInPin'],
    };
}

