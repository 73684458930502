/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Unavailability } from './Unavailability';
import {
    UnavailabilityFromJSON,
    UnavailabilityFromJSONTyped,
    UnavailabilityToJSON,
} from './Unavailability';

/**
 * 
 * @export
 * @interface ShowUnavailabilityResponse
 */
export interface ShowUnavailabilityResponse {
    /**
     * 
     * @type {Unavailability}
     * @memberof ShowUnavailabilityResponse
     */
    data?: Unavailability;
}

/**
 * Check if a given object implements the ShowUnavailabilityResponse interface.
 */
export function instanceOfShowUnavailabilityResponse(value: object): boolean {
    return true;
}

export function ShowUnavailabilityResponseFromJSON(json: any): ShowUnavailabilityResponse {
    return ShowUnavailabilityResponseFromJSONTyped(json, false);
}

export function ShowUnavailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowUnavailabilityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : UnavailabilityFromJSON(json['data']),
    };
}

export function ShowUnavailabilityResponseToJSON(value?: ShowUnavailabilityResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': UnavailabilityToJSON(value['data']),
    };
}

