/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShiftTemplateAreaSession
 */
export interface ShiftTemplateAreaSession {
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSession
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSession
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSession
     */
    shiftTemplateId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSession
     */
    shiftAreaId: number;
    /**
     * 
     * @type {Date}
     * @memberof ShiftTemplateAreaSession
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShiftTemplateAreaSession
     */
    endTime: Date;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSession
     */
    duration: number;
}

/**
 * Check if a given object implements the ShiftTemplateAreaSession interface.
 */
export function instanceOfShiftTemplateAreaSession(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('shiftTemplateId' in value)) return false;
    if (!('shiftAreaId' in value)) return false;
    if (!('startTime' in value)) return false;
    if (!('endTime' in value)) return false;
    if (!('duration' in value)) return false;
    return true;
}

export function ShiftTemplateAreaSessionFromJSON(json: any): ShiftTemplateAreaSession {
    return ShiftTemplateAreaSessionFromJSONTyped(json, false);
}

export function ShiftTemplateAreaSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftTemplateAreaSession {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'companyId': json['companyId'],
        'shiftTemplateId': json['shiftTemplateId'],
        'shiftAreaId': json['shiftAreaId'],
        'startTime': (new Date(json['startTime'])),
        'endTime': (new Date(json['endTime'])),
        'duration': json['duration'],
    };
}

export function ShiftTemplateAreaSessionToJSON(value?: ShiftTemplateAreaSession | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'shiftTemplateId': value['shiftTemplateId'],
        'shiftAreaId': value['shiftAreaId'],
        'startTime': ((value['startTime']).toISOString()),
        'endTime': ((value['endTime']).toISOString()),
        'duration': value['duration'],
    };
}

