import Vue from 'vue';
// eslint-disable-next-line import/no-unresolved
import { GlobalTypes } from '@/types/globals';

export const registerGlobals = (globals: GlobalTypes): void => {
  const reactiveGlobals = Vue.observable(globals);

  // Register each of the observable global variables on the Vue prototype.
  // Each registered property has a getter and setter that operates on the reactive
  // observable object created above. This is what allows the instances properties to
  // be used reactively, e.g.
  //    watch() {
  //      $companyId() {
  //        console.log('$companyId has changed.');
  //      }
  //    }
  //
  Object.keys(reactiveGlobals).forEach((k: string) => {
    Object.defineProperty(Vue.prototype, k, {
      get() {
        return reactiveGlobals[k];
      },
      set(v): void {
        reactiveGlobals[k] = v;
      },
    });
  });
};
