/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateDocumentData,
  CreateDocumentReadData,
  CreateDocumentReadResponse,
  CreateDocumentResponse,
  ListDocumentAssignmentsResponse,
  ListDocumentReadsResponse,
  ListDocumentsResponse,
  ShowDocumentResponse,
  UpdateDocumentData,
  UpdateDocumentResponse,
} from '../models/index';
import {
    CreateDocumentDataFromJSON,
    CreateDocumentDataToJSON,
    CreateDocumentReadDataFromJSON,
    CreateDocumentReadDataToJSON,
    CreateDocumentReadResponseFromJSON,
    CreateDocumentReadResponseToJSON,
    CreateDocumentResponseFromJSON,
    CreateDocumentResponseToJSON,
    ListDocumentAssignmentsResponseFromJSON,
    ListDocumentAssignmentsResponseToJSON,
    ListDocumentReadsResponseFromJSON,
    ListDocumentReadsResponseToJSON,
    ListDocumentsResponseFromJSON,
    ListDocumentsResponseToJSON,
    ShowDocumentResponseFromJSON,
    ShowDocumentResponseToJSON,
    UpdateDocumentDataFromJSON,
    UpdateDocumentDataToJSON,
    UpdateDocumentResponseFromJSON,
    UpdateDocumentResponseToJSON,
} from '../models/index';

export interface CreateDocumentRequest {
    _with?: Array<string>;
    createDocumentData?: CreateDocumentData;
}

export interface CreateDocumentReadRequest {
    id: number;
    createDocumentReadData?: CreateDocumentReadData;
}

export interface DeleteDocumentRequest {
    id: number;
}

export interface DeleteDocumentReadRequest {
    id: number;
}

export interface ListDocumentAssignmentsRequest {
    id: number;
    limit?: number;
    page?: number;
    perPage?: number;
}

export interface ListDocumentReadsRequest {
    limit?: number;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

export interface ListDocumentsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
    read?: boolean;
}

export interface ShowDocumentRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateDocumentRequest {
    id: number;
    _with?: Array<string>;
    updateDocumentData?: UpdateDocumentData;
}

/**
 * 
 */
export class DocumentApi extends runtime.BaseAPI {

    /**
     * Create a new Document
     * Create Document
     */
    async createDocumentRaw(requestParameters: CreateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDocumentResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.document.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDocumentDataToJSON(requestParameters['createDocumentData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDocumentResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Document
     * Create Document
     */
    async createDocument(requestParameters: CreateDocumentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDocumentResponse> {
        const response = await this.createDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Indicate that a document has been read by the current employee
     * Create Document Read
     */
    async createDocumentReadRaw(requestParameters: CreateDocumentReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDocumentReadResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createDocumentRead().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.document-read.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents/{id}/read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDocumentReadDataToJSON(requestParameters['createDocumentReadData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDocumentReadResponseFromJSON(jsonValue));
    }

    /**
     * Indicate that a document has been read by the current employee
     * Create Document Read
     */
    async createDocumentRead(requestParameters: CreateDocumentReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDocumentReadResponse> {
        const response = await this.createDocumentReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a single Document
     * Delete Document
     */
    async deleteDocumentRaw(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDocument().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.document.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single Document
     * Delete Document
     */
    async deleteDocument(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Indicate that a document hasn\'t been read by the current employee
     * Delete Document Read
     */
    async deleteDocumentReadRaw(requestParameters: DeleteDocumentReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDocumentRead().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.document-read.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents/{id}/read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Indicate that a document hasn\'t been read by the current employee
     * Delete Document Read
     */
    async deleteDocumentRead(requestParameters: DeleteDocumentReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentReadRaw(requestParameters, initOverrides);
    }

    /**
     * List the audience for a document, showing which ones have read it
     * List Document Assignments
     */
    async listDocumentAssignmentsRaw(requestParameters: ListDocumentAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDocumentAssignmentsResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling listDocumentAssignments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.document.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents/{id}/assignments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDocumentAssignmentsResponseFromJSON(jsonValue));
    }

    /**
     * List the audience for a document, showing which ones have read it
     * List Document Assignments
     */
    async listDocumentAssignments(requestParameters: ListDocumentAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDocumentAssignmentsResponse> {
        const response = await this.listDocumentAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all the instances of employees reading documents
     * List Documents Reads
     */
    async listDocumentReadsRaw(requestParameters: ListDocumentReadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDocumentReadsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.document-read.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents/reads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDocumentReadsResponseFromJSON(jsonValue));
    }

    /**
     * List all the instances of employees reading documents
     * List Documents Reads
     */
    async listDocumentReads(requestParameters: ListDocumentReadsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDocumentReadsResponse> {
        const response = await this.listDocumentReadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all Documents
     * List Documents
     */
    async listDocumentsRaw(requestParameters: ListDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDocumentsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['read'] != null) {
            queryParameters['read'] = requestParameters['read'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.document.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDocumentsResponseFromJSON(jsonValue));
    }

    /**
     * List all Documents
     * List Documents
     */
    async listDocuments(requestParameters: ListDocumentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDocumentsResponse> {
        const response = await this.listDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single Document
     * Show Document
     */
    async showDocumentRaw(requestParameters: ShowDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowDocumentResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showDocument().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.document.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowDocumentResponseFromJSON(jsonValue));
    }

    /**
     * Show a single Document
     * Show Document
     */
    async showDocument(requestParameters: ShowDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowDocumentResponse> {
        const response = await this.showDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a single Document
     * Update Document
     */
    async updateDocumentRaw(requestParameters: UpdateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDocumentResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateDocument().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.document.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentDataToJSON(requestParameters['updateDocumentData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateDocumentResponseFromJSON(jsonValue));
    }

    /**
     * Update a single Document
     * Update Document
     */
    async updateDocument(requestParameters: UpdateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDocumentResponse> {
        const response = await this.updateDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
