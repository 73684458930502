
import PinkShiftieCutOutSquareSvg from '@/assets/PinkShiftieCutOutSquareSvg.vue';
import LockedFeatureDialog from '@/components/dialog/LockedFeatureDialog.vue';
import EmployeeAvatar from '@/components/employee/EmployeeAvatar.vue';
import DesktopMoreMenu from '@/components/menu/DesktopMoreMenu.vue';
import SupportMenu from '@/components/menu/SupportMenu.vue';
import StatBadge from '@/components/stats/StatBadge.vue';
import SidebarItem from '@/layouts/components/SidebarItem.vue';
import UserMenu from '@/layouts/components/UserMenu.vue';
import {
  isSidebarItemAdditionallyHighlighted,
  isVisibleRoute,
  NavItem,
  navItems,
} from '@/lib/navigation';
import { companyCanAccess } from '@/lib/permission/companyAccessFeatures';
import { isFeatureFlagRoute, redirectDebounced } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import { windowSize } from '@/util/domFunctions';
import { limitStat } from '@/util/presenters';
import { BillingPlanFeaturesEnum } from '../../../api/v1';

const Menu = {
  Support: 'support',
  User: 'user',
  More: 'more',
} as const;
type MenuValue = typeof Menu[keyof typeof Menu];

// Shiftie logo (56px + 16px) + nav section gap (32px) + user menu toggle (72px)
const SIDEBAR_HEIGHT = 56 + 16 + 32 + 72;
// Item height (40px) + margin (8px)
const NAV_ITEM_HEIGHT = 40 + 8;

export default {
  components: {
    StatBadge,
    SidebarItem,
    DesktopMoreMenu,
    LockedFeatureDialog,
    SupportMenu,
    UserMenu,
    EmployeeAvatar,
    PinkShiftieCutOutSquareSvg,
  },

  data() {
    return {
      Menu,
      routes,

      showMenu: null as MenuValue,
      lockedFeatureDialog: null as BillingPlanFeaturesEnum,
    };
  },

  computed: {
    viewHeight: () => windowSize.height,
    loggedInEmployee: () => store.getters.loggedInEmployee,
    currentCompany: () => store.getters.currentCompany,
    totalRequests: () => store.getters['requests/totalRequests'],
    authenticatedUser: () => store.getters.user,

    notificationItems(): NavItem[] {
      return [navItems.requests(this.totalRequests)];
    },

    notificationStat(): string {
      return `${limitStat(this.totalRequests, 9)}`;
    },

    generalItems(): NavItem[] {
      return (
        [
          navItems.dashboardV2(),
          navItems.scheduleV2(),
          navItems.timeClock(),
          navItems.timesheets(),
          navItems.team(),
          navItems.reports(),
        ] as NavItem[]
      ).filter(isVisibleRoute(false, this.currentCompany));
    },

    toolItems(): NavItem[] {
      return [navItems.generalSettings(), ...this.notificationItems].filter(
        isVisibleRoute(false, this.currentCompany),
      );
    },

    staticItems(): NavItem[] {
      return [navItems.supportMenu(() => this.toggleMenu(Menu.Support))].filter(
        isVisibleRoute(false, this.currentCompany),
      );
    },

    moreMenuItem(): NavItem {
      return navItems.desktopMoreMenu(() => this.toggleMenu(Menu.More));
    },

    minHeight(): number {
      return (
        SIDEBAR_HEIGHT +
        NAV_ITEM_HEIGHT * (this.generalItems.length + this.staticItems.length)
      );
    },

    visibleGeneralItems(): NavItem[] {
      if (!this.loggedInEmployee) return [];

      return this.viewHeight < this.minHeight
        ? this.generalItems.slice(
            0,
            Math.max(
              -Math.ceil((this.minHeight - this.viewHeight) / NAV_ITEM_HEIGHT) -
                1,
              2 - this.generalItems.length, // Keep at least 2 items
            ),
          )
        : this.generalItems;
    },

    visibleToolItems(): NavItem[] {
      if (!this.loggedInEmployee) return [];

      return this.viewHeight <
        this.minHeight + NAV_ITEM_HEIGHT * this.toolItems.length
        ? []
        : this.toolItems;
    },

    moreItems(): NavItem[] {
      if (!this.loggedInEmployee) return [];

      const visibleGeneralItemsIds = this.visibleGeneralItems.map((i) => i.id);

      const visibleToolItemsIds = this.visibleToolItems.map((i) => i.id);
      const notificationItemsIds = this.notificationItems.map((i) => i.id);

      return [
        ...this.generalItems.filter(
          (i) => !visibleGeneralItemsIds.includes(i.id),
        ),
        ...this.toolItems.filter(
          (i) =>
            !notificationItemsIds.includes(i.id) &&
            !visibleToolItemsIds.includes(i.id),
        ),
      ];
    },

    isMoreMenuHighlighted(): boolean {
      return this.moreItems.some((moreMenuItem) => {
        const isMoreItemActive = moreMenuItem.to?.name === this.$route.name;
        const isMoreItemAnyInnerPageActive =
          isSidebarItemAdditionallyHighlighted(moreMenuItem, this.$route);
        return isMoreItemActive || isMoreItemAnyInnerPageActive;
      });
    },
  },

  methods: {
    isFeatureFlagRoute,
    redirectDebounced,
    companyCanAccess,

    openLockedFeatureDialog(feature: BillingPlanFeaturesEnum) {
      this.lockedFeatureDialog = feature;
    },

    toggleMenu(menu: MenuValue | null = null) {
      if (this.showMenu === menu || !menu) {
        this.showMenu = null;
        return;
      }
      this.showMenu = menu;
    },

    navClick(item: NavItem) {
      if (item.action) {
        return item.action();
      }

      this.toggleMenu();
      if (item.feature && !companyCanAccess(item.feature)) {
        return this.openLockedFeatureDialog(item.feature);
      }
      return redirectDebounced(item.to, this.$route);
    },
  },
};
