/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The ability for a user to carry out a particular action or access an area of the product
 * @export
 * @interface AccessRolesPermission
 */
export interface AccessRolesPermission {
    /**
     * 
     * @type {number}
     * @memberof AccessRolesPermission
     */
    id: number;
    /**
     * If null, the role is managed by Shiftie
     * @type {number}
     * @memberof AccessRolesPermission
     */
    companyId: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRolesPermission
     */
    accessRoleId: number;
    /**
     * 
     * @type {string}
     * @memberof AccessRolesPermission
     */
    permission: AccessRolesPermissionPermissionEnum;
}

/**
* @export
* @enum {string}
*/
export enum AccessRolesPermissionPermissionEnum {
    ManageBilling = 'ManageBilling',
    ManageClockInPortals = 'ManageClockInPortals',
    ManageCompanyOnboarding = 'ManageCompanyOnboarding',
    ManageDocuments = 'ManageDocuments',
    ManageEmployeeClocking = 'ManageEmployeeClocking',
    ManageIntegrations = 'ManageIntegrations',
    ManageJobRoles = 'ManageJobRoles',
    ManageLeave = 'ManageLeave',
    ManageLocations = 'ManageLocations',
    ManagePayPeriods = 'ManagePayPeriods',
    ManageProtectedSettings = 'ManageProtectedSettings',
    ManageSchedules = 'ManageSchedules',
    ManageSettings = 'ManageSettings',
    ManageShifts = 'ManageShifts',
    ManageTeam = 'ManageTeam',
    ManageTeamPermissions = 'ManageTeamPermissions',
    ManageTimesheets = 'ManageTimesheets',
    SendEmployeeMessage = 'SendEmployeeMessage',
    SetupClockInPortals = 'SetupClockInPortals',
    ViewShiftCosts = 'ViewShiftCosts'
}


/**
 * Check if a given object implements the AccessRolesPermission interface.
 */
export function instanceOfAccessRolesPermission(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('accessRoleId' in value)) return false;
    if (!('permission' in value)) return false;
    return true;
}

export function AccessRolesPermissionFromJSON(json: any): AccessRolesPermission {
    return AccessRolesPermissionFromJSONTyped(json, false);
}

export function AccessRolesPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRolesPermission {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'accessRoleId': json['accessRoleId'],
        'permission': json['permission'],
    };
}

export function AccessRolesPermissionToJSON(value?: AccessRolesPermission | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'accessRoleId': value['accessRoleId'],
        'permission': value['permission'],
    };
}

