/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEmployeeImportData
 */
export interface CreateEmployeeImportData {
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeImportData
     */
    uploadId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeImportData
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeImportData
     */
    employeeCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeImportData
     */
    sendInviteEmails: boolean;
}

/**
 * Check if a given object implements the CreateEmployeeImportData interface.
 */
export function instanceOfCreateEmployeeImportData(value: object): boolean {
    if (!('uploadId' in value)) return false;
    if (!('locationId' in value)) return false;
    if (!('employeeCount' in value)) return false;
    if (!('sendInviteEmails' in value)) return false;
    return true;
}

export function CreateEmployeeImportDataFromJSON(json: any): CreateEmployeeImportData {
    return CreateEmployeeImportDataFromJSONTyped(json, false);
}

export function CreateEmployeeImportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeImportData {
    if (json == null) {
        return json;
    }
    return {
        
        'uploadId': json['uploadId'],
        'locationId': json['locationId'],
        'employeeCount': json['employeeCount'],
        'sendInviteEmails': json['sendInviteEmails'],
    };
}

export function CreateEmployeeImportDataToJSON(value?: CreateEmployeeImportData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uploadId': value['uploadId'],
        'locationId': value['locationId'],
        'employeeCount': value['employeeCount'],
        'sendInviteEmails': value['sendInviteEmails'],
    };
}

