/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeavePolicy } from './LeavePolicy';
import {
    LeavePolicyFromJSON,
    LeavePolicyFromJSONTyped,
    LeavePolicyToJSON,
} from './LeavePolicy';

/**
 * 
 * @export
 * @interface CreateLeavePolicyResponse
 */
export interface CreateLeavePolicyResponse {
    /**
     * 
     * @type {LeavePolicy}
     * @memberof CreateLeavePolicyResponse
     */
    data: LeavePolicy;
}

/**
 * Check if a given object implements the CreateLeavePolicyResponse interface.
 */
export function instanceOfCreateLeavePolicyResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateLeavePolicyResponseFromJSON(json: any): CreateLeavePolicyResponse {
    return CreateLeavePolicyResponseFromJSONTyped(json, false);
}

export function CreateLeavePolicyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeavePolicyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeavePolicyFromJSON(json['data']),
    };
}

export function CreateLeavePolicyResponseToJSON(value?: CreateLeavePolicyResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeavePolicyToJSON(value['data']),
    };
}

