
import InputGroup from '@/components/form/InputGroup';
import StripeElement from '@/components/inputs/StripeElement';
import { Stripe } from '@/plugins/Stripe';
import { PropType } from 'vue';

export default {
  name: 'PaymentForm',
  components: {
    StripeElement,
    InputGroup,
  },
  props: {
    stripeInstance: {
      type: Object as PropType<Stripe>,
      required: true,
    },
    input: {
      type: Function as PropType<(field, element) => void>,
      required: true,
    },
    useMasonryLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      elementsValid: {
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false,
        postalCode: false,
      },
    };
  },
  methods: {
    cardElementComplete(element, stripeElement) {
      this.input(element, stripeElement);
    },

    handleError(error) {
      this.elementsValid[error.id] = !error.message && error.event.complete;
      this.$emit(
        'validate',
        Object.values(this.elementsValid).every((v) => v),
      );
    },
  },
};
