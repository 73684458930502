/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClockInPortal } from './ClockInPortal';
import {
    ClockInPortalFromJSON,
    ClockInPortalFromJSONTyped,
    ClockInPortalToJSON,
} from './ClockInPortal';

/**
 * 
 * @export
 * @interface UpdateClockInPortalResponse
 */
export interface UpdateClockInPortalResponse {
    /**
     * 
     * @type {ClockInPortal}
     * @memberof UpdateClockInPortalResponse
     */
    data: ClockInPortal;
}

/**
 * Check if a given object implements the UpdateClockInPortalResponse interface.
 */
export function instanceOfUpdateClockInPortalResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateClockInPortalResponseFromJSON(json: any): UpdateClockInPortalResponse {
    return UpdateClockInPortalResponseFromJSONTyped(json, false);
}

export function UpdateClockInPortalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClockInPortalResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ClockInPortalFromJSON(json['data']),
    };
}

export function UpdateClockInPortalResponseToJSON(value?: UpdateClockInPortalResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ClockInPortalToJSON(value['data']),
    };
}

