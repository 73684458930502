import i18n from '@/i18n';
import { links } from '@/lang/urlLinks';
import { EventsEnum } from '@/lib/enum/events';
import { Icon } from '@/lib/enum/Icon';
import {
  companyCanAccess,
  companyHasFeatureFlag,
  Feature,
} from '@/lib/permission/companyAccessFeatures';
import { userCan } from '@/lib/permission/userCan';
import { openFreshdeskWidget } from '@/plugins/freshdesk';
import { goToLink, isFeatureFlagRoute } from '@/router/router';
import { routes } from '@/router/routes';
import { Permission, userHasPermission } from '@/util/permissionFunctions';
import { limitStat } from '@/util/presenters';
import { viewport } from '@/util/windowFunctions';
import VueI18n from 'vue-i18n';
import { Location, Route } from 'vue-router';
import { BillingPlanFeaturesEnum, Company, Employee } from '../../api/v1';
import store from '../store';

export interface NavItem {
  id: string;
  label: VueI18n.TranslateResult | string;
  secondaryText?: VueI18n.TranslateResult | string;
  icon: string;
  to?: Location;
  // Whether the user should be able to see this item (intersection of permissions + plan features)
  enabled: boolean;
  // An alternative to a simple link. Required if 'to' is not provided.
  action?: Function;
  feature?: BillingPlanFeaturesEnum;
  stat?: number | string;
}

type NavItemsObject = { [name: string]: (...args: any[]) => NavItem };
/**
 * @see https://stackoverflow.com/a/52157355/921476
 */
const toTypedMap = <T extends NavItemsObject>(o: T): T => o;

// Define each navItem
export const navItems = toTypedMap({
  dashboardV2: () => ({
    id: 'dashboardV2',
    label: viewport.lg ? i18n.t('nav.dashboard') : i18n.t('nav.home'),
    icon: viewport.lg ? Icon.Gauge : Icon.House,
    to: routes.dashboardV2.route(),
    enabled: true,
  }),
  employmentDetails: (employeeId: number) => ({
    id: 'employmentDetails',
    label: i18n.t('nav.profile.employmentDetails'),
    icon: Icon.Briefcase,
    to: routes.employmentDetails.route(employeeId),
    enabled: userCan.manageEmployee(employeeId),
  }),
  scheduleV2: () => ({
    id: 'scheduleV2',
    label: i18n.t('nav.schedule'),
    icon: Icon.CalendarWeek,
    to: routes.schedule.route(),
    enabled: companyCanAccess(Feature.Scheduler),
  }),
  timeClock: () => ({
    id: 'timeClock',
    label: i18n.t('nav.timeClock'),
    icon: viewport.lg ? Icon.StopWatch : Icon.Clock,
    to: routes.timeClock.route(),
    // Show for those without timeclock as this will show the feature dialog instead
    // Show for employees who can see other employees working
    enabled:
      !companyCanAccess(Feature.ClockInOut) || userCan.seeWhoElseIsWorking(),
    feature: Feature.ClockInOut,
  }),
  requests: (total: number) => ({
    id: 'requests',
    label: i18n.t('nav.requests'),
    icon: Icon.Inbox,
    to: routes.requests.route(),
    enabled: userCan.viewRequests(),
    stat: limitStat(total, 9),
  }),
  timesheets: () => ({
    id: 'timesheets',
    label: i18n.t('nav.timesheets'),
    icon: Icon.ClipboardListCheck,
    to: routes.timesheets.route(),
    enabled: true,
    feature: Feature.Timesheets,
  }),
  team: () => ({
    id: 'team',
    label: i18n.t('nav.team'),
    icon: Icon.Users,
    to: routes.team.route(),
    enabled: true,
  }),
  myProfile: (employeeId: number) => ({
    id: 'profile',
    label: i18n.t('route.title.profileSummary'),
    to: routes.profileSummary.route(employeeId),
    icon: Icon.IdCard,
    enabled: true,
  }),
  myDocuments: (employeeId: number) => ({
    id: 'documents',
    label: i18n.t('nav.myDocuments'),
    icon: Icon.File,
    to: routes.myDocuments.route(employeeId),
    enabled: companyCanAccess(Feature.HrManagement),
  }),
  personalDetails: (employeeId: number) => ({
    id: 'personalDetails',
    label: i18n.t('nav.personalDetails'),
    icon: Icon.IdCard,
    to: routes.personalDetails.route(employeeId),
    enabled: true,
  }),
  updatePassword: () => ({
    id: 'updatePassword',
    label: i18n.t('nav.updatePassword'),
    icon: Icon.Key,
    to: routes.updatePassword.route(),
    enabled: true,
  }),
  developerSettings: () => ({
    id: 'developerSettings',
    label: i18n.t('route.title.developerSettings'),
    icon: Icon.Code,
    to: routes.developerSettings.route(),
    enabled: true,
  }),
  wageAndSalary: (employeeId: number) => ({
    id: 'wageAndSalary',
    label: i18n.t('route.title.wageAndSalary'),
    icon: Icon.Wallet,
    to: routes.wageAndSalary.route(employeeId),
    enabled: userCan.managePay(),
  }),
  emergencyDetails: (employeeId: number) => ({
    id: 'emergencyDetails',
    label: i18n.tc('route.title.emergencyDetails'),
    icon: Icon.LifeRing,
    to: routes.emergencyDetails.route(employeeId),
    enabled: true,
  }),
  whereAndWhen: (employeeId: number) => ({
    id: 'whereAndWhen',
    label: i18n.t('route.title.whereAndWhen'),
    icon: Icon.LocationDot,
    to: routes.whereAndWhen.route(employeeId),
    enabled: userCan.manageEmployee(employeeId),
  }),
  settings: () => ({
    id: 'settings',
    label: i18n.t('nav.settings.companySettings'),
    icon: Icon.Gear,
    to: routes.settingsMenu.route(),
    enabled: userCan.viewSettings(),
  }),
  generalSettings: () => ({
    id: 'generalSettings',
    label: i18n.t('nav.settings.companySettings'),
    icon: Icon.Gear,
    to: routes.generalSettings.route(),
    enabled: userCan.viewSettings(),
  }),
  accountDetails: () => ({
    id: 'accountDetails',
    label: i18n.t('subHeading.accountDetails'),
    icon: Icon.User,
    to: routes.updateAccountDetails.route(),
    enabled: true,
  }),
  notificationPreferences: () => ({
    id: 'notificationPreferences',
    label: i18n.t('subHeading.notificationPreferences'),
    icon: Icon.Bell,
    to: routes.notificationPreferences.route(),
    enabled: true,
  }),
  clockingProfile: (
    employee: Employee,
    employeeHasLocationsWithClockingViaPortal: boolean,
  ) => ({
    id: 'clockingProfile',
    label: i18n.tc('subHeading.clockingIn'),
    icon: Icon.Mobile,
    to: routes.clockingProfile.route(),
    feature: Feature.ClockInOut,
    enabled:
      userCan.viewEmployeeClockingProfile(employee) &&
      employeeHasLocationsWithClockingViaPortal,
  }),
  billing: () => ({
    id: 'billing',
    label: i18n.t('nav.settings.billing'),
    icon: Icon.CreditCard,
    to: routes.accountSettings.route(),
    enabled: userHasPermission(Permission.ManageBilling),
  }),
  choosePlan: (isTrialVersion, root) => ({
    id: 'choosePlan',
    label: i18n.t('button.choosePlan'),
    icon: Icon.Star,
    action: () => root.$emit(EventsEnum.TogglePackageDialog),
    enabled: userCan.manageBilling() && isTrialVersion,
  }),
  referAFriend: () => ({
    id: 'referAFriend',
    label: i18n.t('subHeading.referAFriend'),
    secondaryText: i18n.t('bodyText.referAFriendOffer'),
    icon: Icon.Heart,
    to: routes.referAFriend.route(),
    enabled: store.getters['billing/allowsReferrals'],
  }),
  integrations: () => ({
    id: 'integrations',
    label: i18n.t('nav.integrations'),
    icon: Icon.PuzzlePiece,
    to: routes.digiticketsSettings.route(),
    enabled: userCan.manageIntegrationSettings(),
  }),
  help: (callback = null) => ({
    id: 'help',
    label: i18n.t('nav.helpPortal'),
    icon: Icon.CircleQuestion,
    enabled: true,
    action: () => {
      openFreshdeskWidget(
        store.getters.loggedInEmployee,
        store.getters.currentCompany,
      )();
      if (callback) callback();
    },
  }),
  supportMenu: (action) => ({
    id: 'support',
    label: i18n.t('nav.helpSupport'),
    icon: Icon.CircleQuestion,
    enabled: true,
    action,
  }),
  desktopMoreMenu: (action) => ({
    id: 'desktopMoreMenu',
    label: i18n.t('nav.more'),
    icon: Icon.Ellipsis,
    enabled: true,
    action,
  }),
  mobileMoreMenu: (stat: number) => ({
    id: 'mobileMoreMenu',
    label: i18n.t('nav.more'),
    to: routes.mobileMoreMenu.route(),
    icon: Icon.Bars,
    stat: limitStat(stat, 9),
    enabled: true,
  }),
  systemStatus: (callback = null) => ({
    id: 'systemStatus',
    label: i18n.t('nav.systemStatus'),
    icon: Icon.MonitorWaveform,
    enabled: true,
    action: () => {
      goToLink(links.systemStatus);
      if (callback) callback();
    },
  }),
  whatsNew: (callback = null) => ({
    id: 'whatsNew',
    label: i18n.t('nav.whatsNew'),
    icon: Icon.Gift,
    enabled: true,
    action: () => {
      goToLink(links.whatsNew);
      if (callback) callback();
    },
  }),
  featuresSuggestions: (callback = null) => ({
    id: 'featureSuggestions',
    label: i18n.t('nav.featuresSuggestions'),
    icon: Icon.LightBulbOn,
    enabled: true,
    action: () => {
      goToLink(links.supportTicket);
      if (callback) callback();
    },
  }),
  sendMessage: (action: Function) => ({
    id: 'sendMessage',
    label: i18n.t('nav.sendMessage'),
    icon: Icon.Envelope,
    enabled: userCan.sendMessage(),
    feature: Feature.TeamMessaging,
    action,
  }),
  logout: (callback = null) => ({
    id: 'logout',
    label: i18n.t('nav.logout'),
    icon: `${Icon.PowerOff} text-red`,
    enabled: true,
    action: () => {
      if (callback) callback();
    },
  }),
  unavailability: (employeeId: number) => ({
    id: 'unavailability',
    label: i18n.t('nav.unavailability'),
    to: routes.unavailability.route(employeeId),
    icon: Icon.CalendarXmark,
    enabled: userCan.viewUnavailability(employeeId),
  }),
  leave: (employeeId: number) => ({
    id: 'leave',
    label: i18n.t('nav.leave'),
    to: routes.leave.route(employeeId),
    icon: Icon.Plane,
    enabled: userCan.viewLeave(employeeId),
  }),
  absence: (employeeId: number) => ({
    id: 'absence',
    label: i18n.t('nav.absence'),
    to: routes.absence.route(employeeId),
    icon: Icon.TruckMedical,
    enabled: userCan.viewAbsence(),
  }),
  reports: () => ({
    id: 'reports',
    label: i18n.tc('nav.reports.reports'),
    to: routes.shiftDailyTotals.route(),
    icon: Icon.ChartSimple,
    // show nav item if company is unable to access reports feature to show locked feature dialog on click
    enabled: !companyCanAccess(Feature.Reports) || userCan.viewReports(),
    feature: Feature.Reports,
  }),
  reportsMenu: () => ({
    id: 'reportsMenu',
    label: i18n.tc('nav.reports.reports'),
    icon: Icon.ChartPie,
    to: routes.reportsMenu.route(),
    enabled: userCan.viewReports(),
  }),
});

export const isVisibleRoute =
  (checkFeature: boolean, company: Company) => (item: NavItem) =>
    item.enabled &&
    (!checkFeature || !item.feature || companyCanAccess(item.feature)) &&
    (!isFeatureFlagRoute(item.id) ||
      companyHasFeatureFlag(company, routes[item.id]?.featureFlag));

export const isSidebarItemAdditionallyHighlighted = (
  navItem: NavItem,
  route: Route,
) => {
  const teamPageName = routes.team.name;
  const settingsPageName = routes.generalSettings.name;
  const reportsPageName = routes.shiftDailyTotals.name;
  const sidebarNavItemName = navItem.to?.name;

  if (
    [teamPageName, settingsPageName, reportsPageName].includes(
      sidebarNavItemName,
    )
  ) {
    const firstMatchedRouteName = route.matched?.[0]?.name;
    const additionalHighlightConditions = {
      // team page sidebar item is active if any profile page is active
      [teamPageName]: firstMatchedRouteName === routes.profile.name,
      // settings page sidebar item is active if any settings page is active
      [settingsPageName]: firstMatchedRouteName === routes.settings.name,
      // reports page sidebar item is active if any reports page is active
      [reportsPageName]: firstMatchedRouteName === routes.reports.name,
    };
    return additionalHighlightConditions[sidebarNavItemName];
  }
  return false;
};
