/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkClockInDataClockInDataInner
 */
export interface BulkClockInDataClockInDataInner {
    /**
     * 
     * @type {number}
     * @memberof BulkClockInDataClockInDataInner
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof BulkClockInDataClockInDataInner
     */
    shiftId: number;
}

/**
 * Check if a given object implements the BulkClockInDataClockInDataInner interface.
 */
export function instanceOfBulkClockInDataClockInDataInner(value: object): boolean {
    if (!('employeeId' in value)) return false;
    if (!('shiftId' in value)) return false;
    return true;
}

export function BulkClockInDataClockInDataInnerFromJSON(json: any): BulkClockInDataClockInDataInner {
    return BulkClockInDataClockInDataInnerFromJSONTyped(json, false);
}

export function BulkClockInDataClockInDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkClockInDataClockInDataInner {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'shiftId': json['shiftId'],
    };
}

export function BulkClockInDataClockInDataInnerToJSON(value?: BulkClockInDataClockInDataInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employeeId': value['employeeId'],
        'shiftId': value['shiftId'],
    };
}

