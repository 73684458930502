/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeImport } from './EmployeeImport';
import {
    EmployeeImportFromJSON,
    EmployeeImportFromJSONTyped,
    EmployeeImportToJSON,
} from './EmployeeImport';

/**
 * 
 * @export
 * @interface CreateEmployeeImportResponse
 */
export interface CreateEmployeeImportResponse {
    /**
     * 
     * @type {EmployeeImport}
     * @memberof CreateEmployeeImportResponse
     */
    data: EmployeeImport;
}

/**
 * Check if a given object implements the CreateEmployeeImportResponse interface.
 */
export function instanceOfCreateEmployeeImportResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateEmployeeImportResponseFromJSON(json: any): CreateEmployeeImportResponse {
    return CreateEmployeeImportResponseFromJSONTyped(json, false);
}

export function CreateEmployeeImportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeImportResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeImportFromJSON(json['data']),
    };
}

export function CreateEmployeeImportResponseToJSON(value?: CreateEmployeeImportResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeImportToJSON(value['data']),
    };
}

