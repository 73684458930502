/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeAttribute } from './EmployeeAttribute';
import {
    EmployeeAttributeFromJSON,
    EmployeeAttributeFromJSONTyped,
    EmployeeAttributeToJSON,
} from './EmployeeAttribute';

/**
 * 
 * @export
 * @interface CreateEmployeeAttributeResponse
 */
export interface CreateEmployeeAttributeResponse {
    /**
     * 
     * @type {EmployeeAttribute}
     * @memberof CreateEmployeeAttributeResponse
     */
    data?: EmployeeAttribute;
}

/**
 * Check if a given object implements the CreateEmployeeAttributeResponse interface.
 */
export function instanceOfCreateEmployeeAttributeResponse(value: object): boolean {
    return true;
}

export function CreateEmployeeAttributeResponseFromJSON(json: any): CreateEmployeeAttributeResponse {
    return CreateEmployeeAttributeResponseFromJSONTyped(json, false);
}

export function CreateEmployeeAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeAttributeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : EmployeeAttributeFromJSON(json['data']),
    };
}

export function CreateEmployeeAttributeResponseToJSON(value?: CreateEmployeeAttributeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeAttributeToJSON(value['data']),
    };
}

