<template>
  <div>
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Group_2147"
        data-name="Group 2147"
        transform="translate(-18 -14.999)"
      >
        <path
          id="Path_959"
          data-name="Path 959"
          transform="translate(-154.806 -181.1)"
          fill="#fff"
          d="M207.244,196.1H178.367a5.561,5.561,0,0,0-5.561,5.561v28.877a5.562,
          5.562,0,0,0,5.561,5.562h28.877a5.562,5.562,0,0,0,5.562-5.562V201.66a5.562,
          5.562,0,0,0-5.562-5.561m-5.976,31.014a4.389,4.389,0,0,1-3.646,1.942h-9.553a4.4,
          4.4,0,0,1-4.031-6.157,17.729,17.729,0,0,1,2.629-4.231,1.909,1.909,0,0,1,2.94,
          2.435,13.9,13.9,0,0,0-2.061,3.305.591.591,0,0,0,.046.574.547.547,0,0,0,
          .477.255h9.553a.554.554,0,0,0,.477-.254.576.576,0,0,0,
          .056-.554c-1.567-3.843-5.836-6.553-6.283-6.828l-.037-.021c-.235-.139-5.775-3.451-7.914-8.7a4.4,
          4.4,0,0,1,4.069-6.07h9.554a4.4,4.4,0,0,1,3.678,1.978,4.335,4.335,0,0,1,.388,4.095,
          17.379,17.379,0,0,1-2.137,3.649,1.909,1.909,0,1,1-3.046-2.3,13.6,13.6,0,0,0,
          1.666-2.835.511.511,0,0,0-.052-.5.592.592,0,0,0-.5-.269H187.99a.557.557,0,0,
          0-.478.255.575.575,0,0,0-.054.554c1.566,3.843,5.835,6.553,6.283,6.828l.036.021c.235.138,
          5.776,3.451,7.915,8.7a4.406,4.406,0,0,1-.423,4.126"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ShiftieCutOutSquareSvg',
};
</script>
