/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleEvent } from './ScheduleEvent';
import {
    ScheduleEventFromJSON,
    ScheduleEventFromJSONTyped,
    ScheduleEventToJSON,
} from './ScheduleEvent';

/**
 * 
 * @export
 * @interface UpdateScheduleEventResponse
 */
export interface UpdateScheduleEventResponse {
    /**
     * 
     * @type {ScheduleEvent}
     * @memberof UpdateScheduleEventResponse
     */
    data?: ScheduleEvent;
}

/**
 * Check if a given object implements the UpdateScheduleEventResponse interface.
 */
export function instanceOfUpdateScheduleEventResponse(value: object): boolean {
    return true;
}

export function UpdateScheduleEventResponseFromJSON(json: any): UpdateScheduleEventResponse {
    return UpdateScheduleEventResponseFromJSONTyped(json, false);
}

export function UpdateScheduleEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateScheduleEventResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ScheduleEventFromJSON(json['data']),
    };
}

export function UpdateScheduleEventResponseToJSON(value?: UpdateScheduleEventResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ScheduleEventToJSON(value['data']),
    };
}

