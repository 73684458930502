/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Shows that a document has been made available to an employee, and whether they've read it
 * @export
 * @interface DocumentAssignment
 */
export interface DocumentAssignment {
    /**
     * 
     * @type {number}
     * @memberof DocumentAssignment
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentAssignment
     */
    documentId: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentAssignment
     */
    employeeId: number;
    /**
     * Indicates when the document was read, if it has
     * @type {Date}
     * @memberof DocumentAssignment
     */
    readAt: Date | null;
}

/**
 * Check if a given object implements the DocumentAssignment interface.
 */
export function instanceOfDocumentAssignment(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('documentId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('readAt' in value)) return false;
    return true;
}

export function DocumentAssignmentFromJSON(json: any): DocumentAssignment {
    return DocumentAssignmentFromJSONTyped(json, false);
}

export function DocumentAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentAssignment {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'documentId': json['documentId'],
        'employeeId': json['employeeId'],
        'readAt': (json['readAt'] == null ? null : new Date(json['readAt'])),
    };
}

export function DocumentAssignmentToJSON(value?: DocumentAssignment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
        'documentId': value['documentId'],
        'employeeId': value['employeeId'],
        'readAt': (value['readAt'] == null ? null : (value['readAt'] as any).toISOString()),
    };
}

