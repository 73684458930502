/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SentEmail
 */
export interface SentEmail {
    /**
     * 
     * @type {string}
     * @memberof SentEmail
     */
    status: SentEmailStatusEnum;
    /**
     * Comma separated recipient email addresses.
     * @type {string}
     * @memberof SentEmail
     */
    recipients: string;
}

/**
* @export
* @enum {string}
*/
export enum SentEmailStatusEnum {
    Bounced = 'Bounced',
    Complained = 'Complained',
    Delivered = 'Delivered',
    Rejected = 'Rejected',
    Sent = 'Sent'
}


/**
 * Check if a given object implements the SentEmail interface.
 */
export function instanceOfSentEmail(value: object): boolean {
    if (!('status' in value)) return false;
    if (!('recipients' in value)) return false;
    return true;
}

export function SentEmailFromJSON(json: any): SentEmail {
    return SentEmailFromJSONTyped(json, false);
}

export function SentEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SentEmail {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'recipients': json['recipients'],
    };
}

export function SentEmailToJSON(value?: SentEmail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'recipients': value['recipients'],
    };
}

