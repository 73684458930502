import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import { validateLocalStorageAccess } from '@/util/storageFunctions';
import { LogoutReason } from '../auth/logoutReason';

class Controller {
  controller!: AbortController;

  constructor() {
    this.init();
  }

  init() {
    this.controller = new AbortController();
    this.controller.signal.onabort = () => this.init();
  }

  get signal() {
    return this.controller.signal;
  }

  abort() {
    this.controller.abort();
  }
}

export const controller = new Controller();

export const fetchAbort = (
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> | null => {
  if (init) {
    const { signal } = controller;
    init.signal = signal;
  }

  return fetch(input, init);
};

export const abortAndLogout = () => {
  // Abort any in-flight / pending requests as they'll mostly likely result in more 401 responses.
  controller.abort();

  // Redirect to the logout screen with a generic 'session expired' message and set a redirect
  // to the current page if one isn't set already.
  setTimeout(() => {
    const url = new URL(window.location.href);

    redirect(
      routes.logout.route({
        reason: validateLocalStorageAccess()
          ? LogoutReason.NotAuthenticated
          : LogoutReason.NoCookieAccess,
        redirect: url.searchParams.get('redirect') || url.pathname + url.search,
      }),
    ).then();
  }, 300);
};
