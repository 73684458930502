
import StatBadge from '@/components/stats/StatBadge.vue';
import { NavItem } from '@/lib/navigation';
import { companyCanAccess } from '@/lib/permission/companyAccessFeatures';
import { isFeatureFlagRoute } from '@/router/router';
import { routes } from '@/router/routes';
import { PropType } from 'vue';

export default {
  name: 'MenuItem',
  components: { StatBadge },
  props: {
    item: {
      type: Object as PropType<NavItem>,
      required: true,
    },
    navClick: {
      type: Function as PropType<(item: NavItem) => void>,
      required: true,
    },
    disableActive: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    active(): boolean {
      return this.item.to && this.activeRoute(this.item.to.name);
    },
    disabled(): boolean {
      return this.item.feature && !companyCanAccess(this.item.feature);
    },
  },
  methods: {
    isFeatureFlagRoute,
    companyCanAccess,

    activeRoute(routeName: string) {
      // Direct match
      if (routeName === this.$route.name) {
        return true;
      }
      const firstMatchedRouteName = this.$route.matched?.[0]?.name;
      // Match settings routes
      if (firstMatchedRouteName === routes.settings.name) {
        return routeName === routes.generalSettings.name;
      }
      // If the route is part of the profile then we want the Team sidebar item to look active
      if (firstMatchedRouteName === routes.profile.name) {
        return routeName === routes.team.name;
      }
      // Match reports routes
      if (firstMatchedRouteName === routes.reports.name) {
        return routeName === routes.shiftDailyTotals.name;
      }
      return false;
    },
  },
};
