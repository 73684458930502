
import { ButtonSize, buttonSizes } from '@/lib/enum/ButtonSizes';
import { PropType } from 'vue';

export default {
  name: 'Button',

  props: {
    /** @todo Remove this and just use default slot */
    buttonText: {
      type: String,
      default: '',
    },

    color: {
      type: String,
      required: false,
      default: 'blue',
    },

    border: {
      type: Boolean,
      default: true,
    },

    keepActiveOnLoading: {
      type: Boolean,
      default: false,
    },

    iconLeft: {
      type: String,
      default: null,
    },

    iconRight: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadingText: {
      type: String,
      required: false,
      default: '',
    },

    size: {
      type: String as PropType<ButtonSize>,
      default: 'md',
      validator: (p) => buttonSizes.includes(p),
    },

    shadow: {
      type: Boolean,
      required: false,
    },

    value: {
      type: [String, Boolean, Number],
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    circular: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      const classes = ['btn all:inline-block font-medium', this.size];

      classes.push(`btn-${this.color}`);

      if (this.shadow) {
        classes.push('shadow');
      }

      if (this.circular) {
        classes.push('circular');
      }

      if (this.border) {
        classes.push('btn-border');
      }

      if (this.loading) {
        classes.push('loading');
      }

      if (this.keepActiveOnLoading) {
        classes.push('keepActiveOnLoading');
      }

      if (
        !this.buttonText &&
        !this.$slots.default &&
        (this.iconLeft || this.iconRight) &&
        !(this.iconLeft && this.iconRight)
      ) {
        classes.push('square');
      }

      return classes;
    },

    colour() {
      return 'white';
    },

    loadingSize() {
      return {
        xs: 10,
        sm: 12,
        md: 14,
        lg: 14,
        xl: 14,
        xxl: 16,
      }[this.size];
    },
  },
};
