/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateScheduleEventData,
  CreateScheduleEventResponse,
  ListScheduleEventsResponse,
  ShowScheduleEventResponse,
  UpdateScheduleEventData,
  UpdateScheduleEventResponse,
} from '../models/index';
import {
    CreateScheduleEventDataFromJSON,
    CreateScheduleEventDataToJSON,
    CreateScheduleEventResponseFromJSON,
    CreateScheduleEventResponseToJSON,
    ListScheduleEventsResponseFromJSON,
    ListScheduleEventsResponseToJSON,
    ShowScheduleEventResponseFromJSON,
    ShowScheduleEventResponseToJSON,
    UpdateScheduleEventDataFromJSON,
    UpdateScheduleEventDataToJSON,
    UpdateScheduleEventResponseFromJSON,
    UpdateScheduleEventResponseToJSON,
} from '../models/index';

export interface CreateScheduleEventRequest {
    _with?: Array<string>;
    createScheduleEventData?: CreateScheduleEventData;
}

export interface DeleteScheduleEventRequest {
    id: number;
}

export interface ListScheduleEventsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowScheduleEventRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateScheduleEventRequest {
    id: number;
    _with?: Array<string>;
    updateScheduleEventData?: UpdateScheduleEventData;
}

/**
 * 
 */
export class ScheduleEventApi extends runtime.BaseAPI {

    /**
     * Create a new schedule event
     * Create Schedule Event
     */
    async createScheduleEventRaw(requestParameters: CreateScheduleEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateScheduleEventResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-event.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-events`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateScheduleEventDataToJSON(requestParameters['createScheduleEventData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateScheduleEventResponseFromJSON(jsonValue));
    }

    /**
     * Create a new schedule event
     * Create Schedule Event
     */
    async createScheduleEvent(requestParameters: CreateScheduleEventRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateScheduleEventResponse> {
        const response = await this.createScheduleEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an existing schedule event
     * Delete Schedule Event
     */
    async deleteScheduleEventRaw(requestParameters: DeleteScheduleEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteScheduleEvent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-event.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing schedule event
     * Delete Schedule Event
     */
    async deleteScheduleEvent(requestParameters: DeleteScheduleEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteScheduleEventRaw(requestParameters, initOverrides);
    }

    /**
     * List or filter all available schedule events
     * List Schedule Events
     */
    async listScheduleEventsRaw(requestParameters: ListScheduleEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListScheduleEventsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-event.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListScheduleEventsResponseFromJSON(jsonValue));
    }

    /**
     * List or filter all available schedule events
     * List Schedule Events
     */
    async listScheduleEvents(requestParameters: ListScheduleEventsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListScheduleEventsResponse> {
        const response = await this.listScheduleEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single schedule event
     * Show Schedule Event
     */
    async showScheduleEventRaw(requestParameters: ShowScheduleEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowScheduleEventResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showScheduleEvent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-event.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowScheduleEventResponseFromJSON(jsonValue));
    }

    /**
     * Get a single schedule event
     * Show Schedule Event
     */
    async showScheduleEvent(requestParameters: ShowScheduleEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowScheduleEventResponse> {
        const response = await this.showScheduleEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing schedule event
     * Update Schedule Event
     */
    async updateScheduleEventRaw(requestParameters: UpdateScheduleEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateScheduleEventResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateScheduleEvent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-event.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedule-events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateScheduleEventDataToJSON(requestParameters['updateScheduleEventData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateScheduleEventResponseFromJSON(jsonValue));
    }

    /**
     * Update an existing schedule event
     * Update Schedule Event
     */
    async updateScheduleEvent(requestParameters: UpdateScheduleEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateScheduleEventResponse> {
        const response = await this.updateScheduleEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
