import { PlainDate } from '@/lib/date-time/PlainDate';
import { OrderBy } from '@/lib/realtime/weak/realtimeTypes';

// It could be any timezone but should be the same for both PlainDate instances
const DEFAULT_TIMEZONE = 'Europe/London';

const compare = (a: any, b: any, isReverse: boolean) => {
  let result = 0;
  if (typeof a === 'string' && typeof b === 'string') {
    result = a.localeCompare(b);
  } else if (typeof a === 'number' && typeof b === 'number') {
    result = a - b;
  } else if (a instanceof Date && b instanceof Date) {
    result = a.getTime() - b.getTime();
  } else if (a instanceof PlainDate && b instanceof PlainDate) {
    result =
      a.toDate(DEFAULT_TIMEZONE).getTime() -
      b.toDate(DEFAULT_TIMEZONE).getTime();
  } else {
    throw new Error('Unsupported type for sorting');
  }
  return isReverse ? -result : result;
};

export const compareEntities = <T extends object>(
  a: T,
  b: T,
  orderBy: OrderBy<T>,
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop of orderBy) {
    const isReverse = prop.startsWith('-');
    const key = (isReverse ? prop.slice(1) : prop) as keyof T;
    const result = compare(a[key], b[key], isReverse);
    if (result !== 0) {
      return result;
    }
  }
  return 0;
};
