import { StorageEnum } from '@/lib/enum/StorageEnum';
import { isLocalEnv } from '@/lib/environment';
import { EnrichedError } from '@/lib/errors/enriched';
import { isTruthy } from '@/lib/helpers/booleans';
import router from '@/router/router';
import store from '@/store';
import { getGlobal } from '@/util/globalFunctions';
import {
  getLocalStorageItem,
  validateLocalStorageAccess,
} from '@/util/storageFunctions';
import {
  browserTracingIntegration,
  init,
  replayIntegration,
  setTags,
  setUser,
} from '@sentry/vue';
import Vue from 'vue';
import { ResponseError as AuthResponseError } from '../../api/auth';
import {
  Company,
  Employee,
  ResponseError as V1ResponseError,
  User,
} from '../../api/v1';

/** List of patterns to match against error messages that Sentry should ignore. */
const ignoredErrorMessagePatterns: Array<string | RegExp> = [
  'The request failed and the interceptors did not return an alternative response',
  'Fetch is aborted',
];

export const initialiseSentry = () => {
  init({
    Vue,
    dsn: 'https://50246c4704ce4a95af76d646b640fc83@o454135.ingest.sentry.io/5504124',
    enabled: isTruthy(process.env.VUE_APP_SENTRY_ENABLED),
    logErrors: isLocalEnv,
    environment: process.env.VUE_APP_ENV,
    ignoreErrors: ignoredErrorMessagePatterns,

    replaysSessionSampleRate: Number(
      process.env.VUE_APP_SENTRY_RP_SESSION_RATE,
    ),
    replaysOnErrorSampleRate: Number(
      process.env.VUE_APP_SENTRY_RP_SESSION_ERROR_RATE,
    ),

    tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE),

    integrations: [
      browserTracingIntegration({ router }),
      replayIntegration({}),
    ],

    async beforeSend(event, hint) {
      const exception = hint.originalException;

      /**
       * Catch any SDK response errors and replace the Sentry event with a more enriched version, which
       * includes a lot of additional data about the response object, e.g. headers and error messages.
       *
       * We also analyse the response status code and optionally exclude the event being sent to Sentry
       * if it's not something worth reporting on, e.g. 429 errors.
       */
      if (
        exception instanceof V1ResponseError ||
        exception instanceof AuthResponseError
      ) {
        const body = await exception.response.json();
        const { status } = exception.response;

        const isServerError = status >= 500 && status < 600;
        const ignoredStatusCodes = [
          422, // Unprocessable Entity - typically "expected" validation failures
        ];

        if (isServerError || ignoredStatusCodes.includes(status)) {
          return null;
        }

        // Capture and override the original exception message to something more useful.
        const { value: originalValue } = event.exception.values[0];
        event.exception.values[0].value = body.message;

        event.extra = {
          'request.status': exception.response.status,
          'request.url': exception.response.url,
          'response.headers': exception.response.headers,
          'request.message': body.message,
          'request.errors': body.errors || {},
          'exception.original': originalValue,
          'cookies.enabled': validateLocalStorageAccess(),
          'company.global': getGlobal('$companyId'),
          'company.store': store.getters.currentCompany?.id,
          'company.localStore': getLocalStorageItem(StorageEnum.CompanyId),
        };

        event.fingerprint = [exception.response.status, body.message];
      }

      // Enriched exceptions allow us to send additional context, which may be useful
      // for developers when debugging.
      if (exception instanceof EnrichedError) {
        event.extra = exception.extra;
      }

      return event;
    },
  });
};

export const setSentryUser = (u: User) => {
  setUser({
    id: String(u.id),
    email: u.email,
  });
};

export const setSentryCompanyContext = (c: Company, e: Employee) => {
  setTags({
    'company.id': c.id,
    'company.name': c.name,
    'employee.id': e.id,
  });
};

/**
 * Attach any additional tags to Sentry which may be useful when
 * investigating errors. This doesn't include user-specific data,
 * as Sentry handles users slightly differently to generic tags.
 */
export const setAdditionalSentryTags = (tags: {
  [key: string]: string | number | boolean | null;
}) => {
  setTags(tags);
};
