/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * 
 * @export
 * @interface ShowTagResponse
 */
export interface ShowTagResponse {
    /**
     * 
     * @type {Tag}
     * @memberof ShowTagResponse
     */
    data?: Tag;
}

/**
 * Check if a given object implements the ShowTagResponse interface.
 */
export function instanceOfShowTagResponse(value: object): boolean {
    return true;
}

export function ShowTagResponseFromJSON(json: any): ShowTagResponse {
    return ShowTagResponseFromJSONTyped(json, false);
}

export function ShowTagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowTagResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : TagFromJSON(json['data']),
    };
}

export function ShowTagResponseToJSON(value?: ShowTagResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': TagToJSON(value['data']),
    };
}

