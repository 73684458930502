/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { History } from './History';
import {
    HistoryFromJSON,
    HistoryFromJSONTyped,
    HistoryToJSON,
} from './History';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface SearchHistoryResponse
 */
export interface SearchHistoryResponse {
    /**
     * 
     * @type {Array<History>}
     * @memberof SearchHistoryResponse
     */
    data: Array<History>;
    /**
     * 
     * @type {Pagination}
     * @memberof SearchHistoryResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the SearchHistoryResponse interface.
 */
export function instanceOfSearchHistoryResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function SearchHistoryResponseFromJSON(json: any): SearchHistoryResponse {
    return SearchHistoryResponseFromJSONTyped(json, false);
}

export function SearchHistoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchHistoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(HistoryFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function SearchHistoryResponseToJSON(value?: SearchHistoryResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(HistoryToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

