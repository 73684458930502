var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"flex bg-white",class:_vm.horizontal
      ? 'flex-row flex-nowrap mr-not-first horizontal'
      : 'flex-column mb-not-last vertical'},[(_vm.legend)?_c('legend',{staticClass:"pb-4"},[_vm._v(" "+_vm._s(_vm.legend)+" ")]):_vm._e(),_c('div',{staticClass:"flex-inner-wrapper"},_vm._l((_vm.list),function(input){return _c('label',{key:input[_vm.inputValue],staticClass:"text-overflow-ellipsis",class:[
        {
          maxWidth: _vm.maxWidth,
          checked: input[_vm.inputValue] === _vm.value,
          disabled: input.disabled,
        },
        { 'label-left': _vm.labelLeft },
      ],style:({ maxWidth: _vm.viewport.md ? _vm.maxWidth : 'none' })},[_c('input',{attrs:{"type":"radio","name":_vm.radioGroup,"disabled":_vm.disabled || input.disabled},domProps:{"value":input[_vm.inputValue],"checked":input[_vm.inputValue] === _vm.value && !input.disabled},on:{"input":function($event){!input.disabled ? _vm.checked(input) : null}}}),_vm._t("default",function(){return [_vm._v(" "+_vm._s(input.name)+" ")]},{"input":input})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }