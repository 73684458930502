import { i18nDateTimeFormats } from '@/lang/dateTimeFormats';
import { messages } from '@/lang/messages';
import { i18nNumberFormats } from '@/lang/numberFormats';
import Vue from 'vue';
import VueI18n, {
  DateTimeFormatOptions,
  WarnHtmlInMessageLevel,
} from 'vue-i18n';

Vue.use(VueI18n);

// Add default timezone of UTC to avoid having to set it specifically in our unit tests
Object.keys(i18nDateTimeFormats).forEach((locale) => {
  Object.keys(i18nDateTimeFormats[locale]).forEach((format) => {
    i18nDateTimeFormats[locale][format].timeZone = 'UTC';
  });
});

/**
 * Construct a new translator instance (an instance of Vue I18n)
 */
export const createTranslator = ({
  locale,
  ...otherOptions
}: VueI18n.I18nOptions = {}) => {
  const defaultOptions = {
    dateTimeFormats: i18nDateTimeFormats,
    numberFormats: i18nNumberFormats,
    locale: 'en-gb',
    fallbackLocale: 'en-gb', // Use English as fallback language
    messages,
    warnHtmlInMessage: 'off' as WarnHtmlInMessageLevel, // We want to be able to allow HTML like <br> in language strings
  } as VueI18n.I18nOptions;
  return new VueI18n({
    ...defaultOptions,
    ...(locale && {
      locale: locale.toLowerCase(),
    }),
    ...otherOptions,
  });
};

const i18n = createTranslator();

export default i18n;

// Make sure that all locales added here are represented in dateTimeFormats.i18nDateTimeFormats
export const supportedLocales = [
  { id: 'en-gb', name: 'English (UK)' },
  { id: 'en-us', name: 'English (US)' },
  { id: 'fr', name: 'Français' },
];

export const initDateTimeAndLocale = (
  locale: string,
  timeFormat: '12' | '24',
  timeZone: string,
) => {
  const localeLower = locale.toLowerCase();
  /**
   * Depending on whether the company wants to use a 12-hour clock, or a 24-hour one, we need to modify the default
   * format of times. *Don't* use `hour12` as this doesn't work consistently across Chrome and Firefox. Instead, use
   * `hourCycle`, which seems to have more standardised behaviour.
   */
  i18n.locale = localeLower;
  Object.values(i18nDateTimeFormats[localeLower]).forEach(
    (format: DateTimeFormatOptions) => {
      /**
       * We use h23 in order to show midnight as 00:00 rather than 24:00. It's possible that the latter is used in
       * some locales, but as we're currently focussed on UK and US, we won't worry about this for now. If 24:00 is
       * needed in the future, we'll have to apply these conditionally upon the locale.
       */
      format.hourCycle = timeFormat === '12' ? 'h12' : 'h23';
    },
  );

  // Set the formatting to use the companies timezone
  Object.keys(i18nDateTimeFormats[localeLower]).forEach((format) => {
    i18nDateTimeFormats[localeLower][format].timeZone = timeZone;
  });
  i18n.setDateTimeFormat(localeLower, i18nDateTimeFormats[localeLower]);
};
