/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface EmployeeLeaveSummary
 */
export interface EmployeeLeaveSummary {
    /**
     * A deterministic identifier for the report.
     * @type {string}
     * @memberof EmployeeLeaveSummary
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveSummary
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveSummary
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveSummary
     */
    leavePeriodId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveSummary
     */
    leaveTypeId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeeLeaveSummary
     */
    leavePeriodStartDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeeLeaveSummary
     */
    leavePeriodEndDate: ShiftiePlainDate;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveSummary
     */
    leaveEntitlementUnit: EmployeeLeaveSummaryLeaveEntitlementUnitEnum;
    /**
     * The employee's basic leave allowance (in days) for the current year. When the leave type is 'Unlimited', this will be null.
     * @type {number}
     * @memberof EmployeeLeaveSummary
     */
    allowance: number | null;
    /**
     * Number of days carried over from the previous holiday Year
     * @type {number}
     * @memberof EmployeeLeaveSummary
     */
    carriedOver: number;
    /**
     * Number of days (+ or -) their leave has been adjusted by in the current year
     * @type {number}
     * @memberof EmployeeLeaveSummary
     */
    adjustment: number;
    /**
     * How many days leave have been booked (including those awaiting approval) in the current year
     * @type {number}
     * @memberof EmployeeLeaveSummary
     */
    booked: number;
    /**
     * How many days can still be booked in the current year
     * @type {number}
     * @memberof EmployeeLeaveSummary
     */
    remaining: number;
}

/**
* @export
* @enum {string}
*/
export enum EmployeeLeaveSummaryLeaveEntitlementUnitEnum {
    Days = 'Days',
    Hours = 'Hours'
}


/**
 * Check if a given object implements the EmployeeLeaveSummary interface.
 */
export function instanceOfEmployeeLeaveSummary(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('leavePeriodId' in value)) return false;
    if (!('leaveTypeId' in value)) return false;
    if (!('leavePeriodStartDate' in value)) return false;
    if (!('leavePeriodEndDate' in value)) return false;
    if (!('leaveEntitlementUnit' in value)) return false;
    if (!('allowance' in value)) return false;
    if (!('carriedOver' in value)) return false;
    if (!('adjustment' in value)) return false;
    if (!('booked' in value)) return false;
    if (!('remaining' in value)) return false;
    return true;
}

export function EmployeeLeaveSummaryFromJSON(json: any): EmployeeLeaveSummary {
    return EmployeeLeaveSummaryFromJSONTyped(json, false);
}

export function EmployeeLeaveSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeLeaveSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'leavePeriodId': json['leavePeriodId'],
        'leaveTypeId': json['leaveTypeId'],
        'leavePeriodStartDate': ShiftiePlainDateFromJSON(json['leavePeriodStartDate']),
        'leavePeriodEndDate': ShiftiePlainDateFromJSON(json['leavePeriodEndDate']),
        'leaveEntitlementUnit': json['leaveEntitlementUnit'],
        'allowance': json['allowance'],
        'carriedOver': json['carriedOver'],
        'adjustment': json['adjustment'],
        'booked': json['booked'],
        'remaining': json['remaining'],
    };
}

export function EmployeeLeaveSummaryToJSON(value?: EmployeeLeaveSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'leavePeriodId': value['leavePeriodId'],
        'leaveTypeId': value['leaveTypeId'],
        'leavePeriodStartDate': ShiftiePlainDateToJSON(value['leavePeriodStartDate']),
        'leavePeriodEndDate': ShiftiePlainDateToJSON(value['leavePeriodEndDate']),
        'leaveEntitlementUnit': value['leaveEntitlementUnit'],
        'allowance': value['allowance'],
        'carriedOver': value['carriedOver'],
        'adjustment': value['adjustment'],
        'booked': value['booked'],
        'remaining': value['remaining'],
    };
}

