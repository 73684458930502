import { setLocalStorageItem } from '@/util/storageFunctions';
import Vue from 'vue';
// eslint-disable-next-line import/no-unresolved
import { StorageEnum } from '@/lib/enum/StorageEnum';
import { GlobalTypes } from '@/types/globals';

export const getGlobal = <T extends keyof GlobalTypes>(g: T): GlobalTypes[T] =>
  Vue.prototype[g];

// https://github.com/typescript-eslint/typescript-eslint/issues/14
// eslint-disable-next-line
export const setGlobal = <T extends keyof GlobalTypes>(
  k: T,
  v: GlobalTypes[T],
): void => {
  Vue.prototype[k] = v;
};

// Custom setters / getters are defined below. Each should state why they are needed
// over the generic setter and getter defined above.

// This override is needed as we're required to store companyId in the localStore.
export const setCompanyId = (companyId: number) => {
  setGlobal('$companyId', companyId);
  setLocalStorageItem(StorageEnum.CompanyId, String(companyId));
};
