/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingSubscriptionCancellation
 */
export interface BillingSubscriptionCancellation {
    /**
     * 
     * @type {Date}
     * @memberof BillingSubscriptionCancellation
     */
    cancelAt: Date;
}

/**
 * Check if a given object implements the BillingSubscriptionCancellation interface.
 */
export function instanceOfBillingSubscriptionCancellation(value: object): boolean {
    if (!('cancelAt' in value)) return false;
    return true;
}

export function BillingSubscriptionCancellationFromJSON(json: any): BillingSubscriptionCancellation {
    return BillingSubscriptionCancellationFromJSONTyped(json, false);
}

export function BillingSubscriptionCancellationFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingSubscriptionCancellation {
    if (json == null) {
        return json;
    }
    return {
        
        'cancelAt': (new Date(json['cancelAt'])),
    };
}

export function BillingSubscriptionCancellationToJSON(value?: BillingSubscriptionCancellation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cancelAt': ((value['cancelAt']).toISOString()),
    };
}

