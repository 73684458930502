/**
 * @export
 * @enum {string}
 */
export enum AppWarningEnum {
  Unverified = 'Unverified',
  Refresh = 'Refresh',
  WebsocketDisconnected = 'WebsocketDisconnected',
  WebsocketReconnecting = 'WebsocketReconnecting',
}
