/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeSimplified } from './EmployeeSimplified';
import {
    EmployeeSimplifiedFromJSON,
    EmployeeSimplifiedFromJSONTyped,
    EmployeeSimplifiedToJSON,
} from './EmployeeSimplified';

/**
 * Informs the user of some event or action that needs to be taken
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    companyId: number;
    /**
     * ID of the Employee this is for
     * @type {number}
     * @memberof Notification
     */
    recipientId: number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    type: NotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    line2: string;
    /**
     * Name of the primary entity type associated with this Notification
     * @type {string}
     * @memberof Notification
     */
    entityType: NotificationEntityTypeEnum;
    /**
     * ID of the primary entity instance associated with this Notification
     * @type {number}
     * @memberof Notification
     */
    entityId: number;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    createdAt: Date;
    /**
     * 
     * @type {EmployeeSimplified}
     * @memberof Notification
     */
    associatedEmployee?: EmployeeSimplified;
}

/**
* @export
* @enum {string}
*/
export enum NotificationTypeEnum {
    ClockInLate = 'Clock In Late',
    ClockInMissed = 'Clock In Missed',
    ClockInReminder = 'Clock In Reminder',
    ClockInPinAssigned = 'Clock In Pin Assigned',
    CompanyEnabledClockInPortals = 'Company Enabled Clock In Portals',
    ClockInPinUpdated = 'Clock In Pin Updated',
    ClockOutReminder = 'Clock Out Reminder',
    CreditsRenewed = 'Credits Renewed',
    DocumentAssigned = 'Document Assigned',
    InvoicePaid = 'Invoice Paid',
    LeaveApproved = 'Leave Approved',
    LeaveApprovedManager = 'Leave Approved Manager',
    LeaveCancellationApproved = 'Leave Cancellation Approved',
    LeaveCancellationDenied = 'Leave Cancellation Denied',
    LeaveCancellationRequested = 'Leave Cancellation Requested',
    LeaveDenied = 'Leave Denied',
    LeaveRequested = 'Leave Requested',
    MultipleShiftsAssigned = 'Multiple Shifts Assigned',
    OpenShiftAutoAssigned = 'Open Shift Auto-Assigned',
    OpenShiftAvailable = 'Open Shift Available',
    OpenShiftResponseReceived = 'Open Shift Response Received',
    OpenShiftResponseRejected = 'Open Shift Response Rejected',
    OvertimeWarning = 'Overtime Warning',
    PayCyclePending = 'Pay Cycle Pending',
    ScheduledHoursWillCauseOvertimeWarning = 'Scheduled Hours Will Cause Overtime Warning',
    ShiftAssigned = 'Shift Assigned',
    ShiftAssigneeUnavailableAssignee = 'Shift Assignee Unavailable Assignee',
    ShiftAssigneeUnavailableManager = 'Shift Assignee Unavailable Manager',
    ShiftNotAcknowledged = 'Shift Not Acknowledged',
    ShiftStarting = 'Shift Starting',
    ShiftSwapAccepted = 'Shift Swap Accepted',
    ShiftSwapApproved = 'Shift Swap Approved',
    ShiftSwapCancelled = 'Shift Swap Cancelled',
    ShiftSwapDeclined = 'Shift Swap Declined',
    ShiftSwapRejected = 'Shift Swap Rejected',
    ShiftSwapRequest = 'Shift Swap Request',
    ShiftSwapReviewRequired = 'Shift Swap Review Required',
    ShiftSwapShiftChanged = 'Shift Swap Shift Changed',
    ShiftUnassigned = 'Shift Unassigned',
    ShiftUpdated = 'Shift Updated',
    AbsenceDeleted = 'Absence Deleted',
    AbsenceReported = 'Absence Reported',
    AbsenceUpdated = 'Absence Updated',
    SubscriptionUpgraded = 'Subscription Upgraded'
}
/**
* @export
* @enum {string}
*/
export enum NotificationEntityTypeEnum {
    Absence = 'Absence',
    Document = 'Document',
    Employee = 'Employee',
    LeaveRequest = 'LeaveRequest',
    PayCycle = 'PayCycle',
    Shift = 'Shift',
    ShiftSwap = 'ShiftSwap',
    TimesheetEntry = 'TimesheetEntry'
}


/**
 * Check if a given object implements the Notification interface.
 */
export function instanceOfNotification(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('recipientId' in value)) return false;
    if (!('type' in value)) return false;
    if (!('line1' in value)) return false;
    if (!('line2' in value)) return false;
    if (!('entityType' in value)) return false;
    if (!('entityId' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'recipientId': json['recipientId'],
        'type': json['type'],
        'line1': json['line1'],
        'line2': json['line2'],
        'entityType': json['entityType'],
        'entityId': json['entityId'],
        'createdAt': (new Date(json['createdAt'])),
        'associatedEmployee': json['associatedEmployee'] == null ? undefined : EmployeeSimplifiedFromJSON(json['associatedEmployee']),
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'recipientId': value['recipientId'],
        'type': value['type'],
        'line1': value['line1'],
        'line2': value['line2'],
        'entityType': value['entityType'],
        'entityId': value['entityId'],
        'createdAt': ((value['createdAt']).toISOString()),
        'associatedEmployee': EmployeeSimplifiedToJSON(value['associatedEmployee']),
    };
}

