/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Shows the state of onboarding for a company
 * @export
 * @interface CompanyOnboarding
 */
export interface CompanyOnboarding {
    /**
     * 
     * @type {number}
     * @memberof CompanyOnboarding
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyOnboarding
     */
    hasAddedJobRoles: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyOnboarding
     */
    hasAddedTeam: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyOnboarding
     */
    hasInvitedTeam: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyOnboarding
     */
    hasAddedShifts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyOnboarding
     */
    hasPublishedShifts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyOnboarding
     */
    hasConfiguredClockingSettings: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyOnboarding
     */
    hasConfiguredLeaveSettings: boolean;
}

/**
 * Check if a given object implements the CompanyOnboarding interface.
 */
export function instanceOfCompanyOnboarding(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('hasAddedJobRoles' in value)) return false;
    if (!('hasAddedTeam' in value)) return false;
    if (!('hasInvitedTeam' in value)) return false;
    if (!('hasAddedShifts' in value)) return false;
    if (!('hasPublishedShifts' in value)) return false;
    if (!('hasConfiguredClockingSettings' in value)) return false;
    if (!('hasConfiguredLeaveSettings' in value)) return false;
    return true;
}

export function CompanyOnboardingFromJSON(json: any): CompanyOnboarding {
    return CompanyOnboardingFromJSONTyped(json, false);
}

export function CompanyOnboardingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyOnboarding {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'hasAddedJobRoles': json['hasAddedJobRoles'],
        'hasAddedTeam': json['hasAddedTeam'],
        'hasInvitedTeam': json['hasInvitedTeam'],
        'hasAddedShifts': json['hasAddedShifts'],
        'hasPublishedShifts': json['hasPublishedShifts'],
        'hasConfiguredClockingSettings': json['hasConfiguredClockingSettings'],
        'hasConfiguredLeaveSettings': json['hasConfiguredLeaveSettings'],
    };
}

export function CompanyOnboardingToJSON(value?: CompanyOnboarding | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'hasAddedJobRoles': value['hasAddedJobRoles'],
        'hasAddedTeam': value['hasAddedTeam'],
        'hasInvitedTeam': value['hasInvitedTeam'],
        'hasAddedShifts': value['hasAddedShifts'],
        'hasPublishedShifts': value['hasPublishedShifts'],
        'hasConfiguredClockingSettings': value['hasConfiguredClockingSettings'],
        'hasConfiguredLeaveSettings': value['hasConfiguredLeaveSettings'],
    };
}

