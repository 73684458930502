/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApproveLeaveRequestCancellationResponse,
  ApproveLeaveRequestResponse,
  CancelLeaveRequestData,
  CancelLeaveRequestResponse,
  CreateLeaveRequestData,
  CreateLeaveRequestResponse,
  CreateLeaveRequestUsageCalculationData,
  CreateLeaveRequestUsageCalculationResponse,
  DenyLeaveRequestCancellationResponse,
  DenyLeaveRequestResponse,
  ListLeaveRequestsResponse,
  ReviewLeaveRequestData,
  ShowLeaveRequestResponse,
  UpdateLeaveRequestData,
  UpdateLeaveRequestResponse,
} from '../models/index';
import {
    ApproveLeaveRequestCancellationResponseFromJSON,
    ApproveLeaveRequestCancellationResponseToJSON,
    ApproveLeaveRequestResponseFromJSON,
    ApproveLeaveRequestResponseToJSON,
    CancelLeaveRequestDataFromJSON,
    CancelLeaveRequestDataToJSON,
    CancelLeaveRequestResponseFromJSON,
    CancelLeaveRequestResponseToJSON,
    CreateLeaveRequestDataFromJSON,
    CreateLeaveRequestDataToJSON,
    CreateLeaveRequestResponseFromJSON,
    CreateLeaveRequestResponseToJSON,
    CreateLeaveRequestUsageCalculationDataFromJSON,
    CreateLeaveRequestUsageCalculationDataToJSON,
    CreateLeaveRequestUsageCalculationResponseFromJSON,
    CreateLeaveRequestUsageCalculationResponseToJSON,
    DenyLeaveRequestCancellationResponseFromJSON,
    DenyLeaveRequestCancellationResponseToJSON,
    DenyLeaveRequestResponseFromJSON,
    DenyLeaveRequestResponseToJSON,
    ListLeaveRequestsResponseFromJSON,
    ListLeaveRequestsResponseToJSON,
    ReviewLeaveRequestDataFromJSON,
    ReviewLeaveRequestDataToJSON,
    ShowLeaveRequestResponseFromJSON,
    ShowLeaveRequestResponseToJSON,
    UpdateLeaveRequestDataFromJSON,
    UpdateLeaveRequestDataToJSON,
    UpdateLeaveRequestResponseFromJSON,
    UpdateLeaveRequestResponseToJSON,
} from '../models/index';

export interface ApproveLeaveRequestRequest {
    id: number;
    _with?: Array<string>;
    reviewLeaveRequestData?: ReviewLeaveRequestData;
}

export interface ApproveLeaveRequestCancellationRequest {
    id: number;
    _with?: Array<string>;
    reviewLeaveRequestData?: ReviewLeaveRequestData;
}

export interface CancelLeaveRequestRequest {
    id: number;
    _with?: Array<string>;
    cancelLeaveRequestData?: CancelLeaveRequestData;
}

export interface CreateLeaveRequestRequest {
    _with?: Array<string>;
    createLeaveRequestData?: CreateLeaveRequestData;
}

export interface CreateLeaveRequestUsageCalculationRequest {
    createLeaveRequestUsageCalculationData?: CreateLeaveRequestUsageCalculationData;
}

export interface DenyLeaveRequestRequest {
    id: number;
    _with?: Array<string>;
    reviewLeaveRequestData?: ReviewLeaveRequestData;
}

export interface DenyLeaveRequestCancellationRequest {
    id: number;
    _with?: Array<string>;
    reviewLeaveRequestData?: ReviewLeaveRequestData;
}

export interface ListLeaveRequestsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowLeaveRequestRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateLeaveRequestRequest {
    id: number;
    _with?: Array<string>;
    updateLeaveRequestData?: UpdateLeaveRequestData;
}

/**
 * 
 */
export class LeaveRequestApi extends runtime.BaseAPI {

    /**
     * Approve a pending leave request
     * Approve Leave Request
     */
    async approveLeaveRequestRaw(requestParameters: ApproveLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApproveLeaveRequestResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling approveLeaveRequest().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewLeaveRequestDataToJSON(requestParameters['reviewLeaveRequestData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApproveLeaveRequestResponseFromJSON(jsonValue));
    }

    /**
     * Approve a pending leave request
     * Approve Leave Request
     */
    async approveLeaveRequest(requestParameters: ApproveLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApproveLeaveRequestResponse> {
        const response = await this.approveLeaveRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approve the cancellation of a leave request
     * Approve Leave Request Cancellation
     */
    async approveLeaveRequestCancellationRaw(requestParameters: ApproveLeaveRequestCancellationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApproveLeaveRequestCancellationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling approveLeaveRequestCancellation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests/{id}/approve-cancellation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewLeaveRequestDataToJSON(requestParameters['reviewLeaveRequestData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApproveLeaveRequestCancellationResponseFromJSON(jsonValue));
    }

    /**
     * Approve the cancellation of a leave request
     * Approve Leave Request Cancellation
     */
    async approveLeaveRequestCancellation(requestParameters: ApproveLeaveRequestCancellationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApproveLeaveRequestCancellationResponse> {
        const response = await this.approveLeaveRequestCancellationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel a leave request
     * Cancel Leave Request
     */
    async cancelLeaveRequestRaw(requestParameters: CancelLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CancelLeaveRequestResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling cancelLeaveRequest().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelLeaveRequestDataToJSON(requestParameters['cancelLeaveRequestData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CancelLeaveRequestResponseFromJSON(jsonValue));
    }

    /**
     * Cancel a leave request
     * Cancel Leave Request
     */
    async cancelLeaveRequest(requestParameters: CancelLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CancelLeaveRequestResponse> {
        const response = await this.cancelLeaveRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit a new leave request. By default it will go into a pending state awaiting approval, but under certain circumstances it may be automatically approved.
     * Create Leave Request
     */
    async createLeaveRequestRaw(requestParameters: CreateLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLeaveRequestResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeaveRequestDataToJSON(requestParameters['createLeaveRequestData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLeaveRequestResponseFromJSON(jsonValue));
    }

    /**
     * Submit a new leave request. By default it will go into a pending state awaiting approval, but under certain circumstances it may be automatically approved.
     * Create Leave Request
     */
    async createLeaveRequest(requestParameters: CreateLeaveRequestRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLeaveRequestResponse> {
        const response = await this.createLeaveRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find out how how many days leave a period of time off would use
     * Create Leave Request Usage Calculation
     */
    async createLeaveRequestUsageCalculationRaw(requestParameters: CreateLeaveRequestUsageCalculationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLeaveRequestUsageCalculationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests/usage-calculations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeaveRequestUsageCalculationDataToJSON(requestParameters['createLeaveRequestUsageCalculationData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLeaveRequestUsageCalculationResponseFromJSON(jsonValue));
    }

    /**
     * Find out how how many days leave a period of time off would use
     * Create Leave Request Usage Calculation
     */
    async createLeaveRequestUsageCalculation(requestParameters: CreateLeaveRequestUsageCalculationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLeaveRequestUsageCalculationResponse> {
        const response = await this.createLeaveRequestUsageCalculationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approve a pending leave request
     * Deny Leave Request
     */
    async denyLeaveRequestRaw(requestParameters: DenyLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DenyLeaveRequestResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling denyLeaveRequest().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests/{id}/deny`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewLeaveRequestDataToJSON(requestParameters['reviewLeaveRequestData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DenyLeaveRequestResponseFromJSON(jsonValue));
    }

    /**
     * Approve a pending leave request
     * Deny Leave Request
     */
    async denyLeaveRequest(requestParameters: DenyLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DenyLeaveRequestResponse> {
        const response = await this.denyLeaveRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deny the cancellation of a leave request
     * Deny Leave Request Cancellation
     */
    async denyLeaveRequestCancellationRaw(requestParameters: DenyLeaveRequestCancellationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DenyLeaveRequestCancellationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling denyLeaveRequestCancellation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests/{id}/deny-cancellation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewLeaveRequestDataToJSON(requestParameters['reviewLeaveRequestData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DenyLeaveRequestCancellationResponseFromJSON(jsonValue));
    }

    /**
     * Deny the cancellation of a leave request
     * Deny Leave Request Cancellation
     */
    async denyLeaveRequestCancellation(requestParameters: DenyLeaveRequestCancellationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DenyLeaveRequestCancellationResponse> {
        const response = await this.denyLeaveRequestCancellationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show or filter all leave requests
     * List Leave Requests
     */
    async listLeaveRequestsRaw(requestParameters: ListLeaveRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeaveRequestsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeaveRequestsResponseFromJSON(jsonValue));
    }

    /**
     * Show or filter all leave requests
     * List Leave Requests
     */
    async listLeaveRequests(requestParameters: ListLeaveRequestsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeaveRequestsResponse> {
        const response = await this.listLeaveRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details of an existing leave request
     * Show Leave Request
     */
    async showLeaveRequestRaw(requestParameters: ShowLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowLeaveRequestResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showLeaveRequest().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowLeaveRequestResponseFromJSON(jsonValue));
    }

    /**
     * Show details of an existing leave request
     * Show Leave Request
     */
    async showLeaveRequest(requestParameters: ShowLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowLeaveRequestResponse> {
        const response = await this.showLeaveRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing (unapproved) leave request
     * Update Leave Request
     */
    async updateLeaveRequestRaw(requestParameters: UpdateLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateLeaveRequestResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLeaveRequest().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-request.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeaveRequestDataToJSON(requestParameters['updateLeaveRequestData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateLeaveRequestResponseFromJSON(jsonValue));
    }

    /**
     * Update an existing (unapproved) leave request
     * Update Leave Request
     */
    async updateLeaveRequest(requestParameters: UpdateLeaveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateLeaveRequestResponse> {
        const response = await this.updateLeaveRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
