/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';

/**
 * 
 * @export
 * @interface ConfirmEmployeeResponse
 */
export interface ConfirmEmployeeResponse {
    /**
     * 
     * @type {Employee}
     * @memberof ConfirmEmployeeResponse
     */
    data: Employee;
}

/**
 * Check if a given object implements the ConfirmEmployeeResponse interface.
 */
export function instanceOfConfirmEmployeeResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ConfirmEmployeeResponseFromJSON(json: any): ConfirmEmployeeResponse {
    return ConfirmEmployeeResponseFromJSONTyped(json, false);
}

export function ConfirmEmployeeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmEmployeeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeFromJSON(json['data']),
    };
}

export function ConfirmEmployeeResponseToJSON(value?: ConfirmEmployeeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeToJSON(value['data']),
    };
}

