/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkPattern } from './WorkPattern';
import {
    WorkPatternFromJSON,
    WorkPatternFromJSONTyped,
    WorkPatternToJSON,
} from './WorkPattern';

/**
 * 
 * @export
 * @interface ShowWorkPatternResponse
 */
export interface ShowWorkPatternResponse {
    /**
     * 
     * @type {WorkPattern}
     * @memberof ShowWorkPatternResponse
     */
    data?: WorkPattern;
}

/**
 * Check if a given object implements the ShowWorkPatternResponse interface.
 */
export function instanceOfShowWorkPatternResponse(value: object): boolean {
    return true;
}

export function ShowWorkPatternResponseFromJSON(json: any): ShowWorkPatternResponse {
    return ShowWorkPatternResponseFromJSONTyped(json, false);
}

export function ShowWorkPatternResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowWorkPatternResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : WorkPatternFromJSON(json['data']),
    };
}

export function ShowWorkPatternResponseToJSON(value?: ShowWorkPatternResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': WorkPatternToJSON(value['data']),
    };
}

