export const isTruthy = (v: any): boolean => {
  if (typeof v === 'boolean') {
    return v;
  }

  if (typeof v === 'number') {
    return v !== 0;
  }

  if (typeof v === 'string') {
    v = v.toLowerCase().trim();
    if (!['true', 'false', 'yes', 'no', '1', '0'].includes(v)) {
      throw new Error('String value could not be evaluated as truthy or falsy');
    }

    return ['true', '1', 'yes'].includes(v);
  }

  if (v === null || v === undefined) {
    return false;
  }

  throw new Error('Could not cast value to a boolean');
};
