/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * A public holiday for a particular geographic region
 * @export
 * @interface PublicHoliday
 */
export interface PublicHoliday {
    /**
     * 
     * @type {number}
     * @memberof PublicHoliday
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PublicHoliday
     */
    holidayRegionId: number;
    /**
     * 
     * @type {string}
     * @memberof PublicHoliday
     */
    name: string;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof PublicHoliday
     */
    date: ShiftiePlainDate;
}

/**
 * Check if a given object implements the PublicHoliday interface.
 */
export function instanceOfPublicHoliday(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('holidayRegionId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('date' in value)) return false;
    return true;
}

export function PublicHolidayFromJSON(json: any): PublicHoliday {
    return PublicHolidayFromJSONTyped(json, false);
}

export function PublicHolidayFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicHoliday {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'holidayRegionId': json['holidayRegionId'],
        'name': json['name'],
        'date': ShiftiePlainDateFromJSON(json['date']),
    };
}

export function PublicHolidayToJSON(value?: PublicHoliday | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'holidayRegionId': value['holidayRegionId'],
        'name': value['name'],
        'date': ShiftiePlainDateToJSON(value['date']),
    };
}

