var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.initialising && _vm.userCan.clockInSelf() && !_vm.hide && _vm.viewport.lg)?_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"left":"","max-width":"450","min-width":"450","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"quick-clock-in",class:{
          'on-break':
            _vm.activeTimesheet?.status === _vm.TimesheetEntryStatusEnum.Break,
          late:
            !_vm.activeTimesheet &&
            _vm.activeShift &&
            _vm.activeShift.startsAt < _vm.now.time,
          disabled:
            (!_vm.activeTimesheet ||
              _vm.activeTimesheet.status === _vm.TimesheetEntryStatusEnum.Break) &&
            _vm.activeShift &&
            !_vm.isWithinClockInThreshold,
        }},on),[_c('div',{staticClass:"all:flex align-center"},[_c('i',{staticClass:"clock-icon mr-3 text-16",class:_vm.activeTimesheet?.status === _vm.TimesheetEntryStatusEnum.Break
                ? _vm.Icon.Mug
                : _vm.Icon.StopWatch}),_c('ClockInTimer',{attrs:{"scheduled-break":_vm.activeShift?.scheduledBreaks[0] ?? null,"timesheet-entry":_vm.activeTimesheet,"time-only":""}})],1),_c('i',{staticClass:"text-gray-600 text-12",class:_vm.Icon.Expand})])]}}],null,false,4210779164),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c('ClockInWidget',{on:{"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"header-append",fn:function(){return [_c('div',{staticClass:"ml-auto flex gap-4 text-gray-400 text-18 items-center"},[(_vm.userCan.seeWhoElseIsWorking())?_c('i',{staticClass:"cursor-pointer",class:_vm.Icon.WindowMaximise,on:{"click":_vm.maximize}}):_vm._e(),_c('i',{staticClass:"cursor-pointer",class:_vm.Icon.Xmark,on:{"click":_vm.closeMenu}})])]},proxy:true}],null,false,2057115534)})],1)],1):(_vm.initialising)?_c('div',{staticClass:"all:flex items-center ml-4"},[_c('LoadingDot',{attrs:{"iteration":3}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }