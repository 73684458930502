var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-12 rounded-full text-overflow-ellipsis inline-block",class:[
    { 'cursor-pointer': _vm.clickable },
    `text-${_vm.textColour} bg-${_vm.colour}`,
    _vm.borderColour ? `border-1 border-${_vm.borderColour}` : '',
  ],on:{"click":function($event){_vm.clickable ? _vm.$emit('click', $event) : null}}},[_c('span',{staticClass:"w-full h-full px-2 all:py-1 all:flex gap-2 align-center leading-tight",class:{
      'hover:backdrop-brightness-95': _vm.clickable && _vm.hoverEffect === 'darken',
      'hover:backdrop-brightness-125': _vm.clickable && _vm.hoverEffect === 'lighten',
    }},[(_vm.prependIcon)?_c('i',{staticClass:"text-12 font-bold",class:_vm.prependIcon}):_vm._e(),(_vm.text)?_c('span',{staticClass:"text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.icon)?_c('i',{staticClass:"text-12",class:[_vm.icon, { 'order-first': _vm.iconPosition === 'left' }]}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }