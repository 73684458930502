/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeWeeklyShiftsTotal } from './EmployeeWeeklyShiftsTotal';
import {
    EmployeeWeeklyShiftsTotalFromJSON,
    EmployeeWeeklyShiftsTotalFromJSONTyped,
    EmployeeWeeklyShiftsTotalToJSON,
} from './EmployeeWeeklyShiftsTotal';

/**
 * 
 * @export
 * @interface ShowEmployeeWeeklyShiftsTotalResponse
 */
export interface ShowEmployeeWeeklyShiftsTotalResponse {
    /**
     * 
     * @type {EmployeeWeeklyShiftsTotal}
     * @memberof ShowEmployeeWeeklyShiftsTotalResponse
     */
    data: EmployeeWeeklyShiftsTotal;
}

/**
 * Check if a given object implements the ShowEmployeeWeeklyShiftsTotalResponse interface.
 */
export function instanceOfShowEmployeeWeeklyShiftsTotalResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowEmployeeWeeklyShiftsTotalResponseFromJSON(json: any): ShowEmployeeWeeklyShiftsTotalResponse {
    return ShowEmployeeWeeklyShiftsTotalResponseFromJSONTyped(json, false);
}

export function ShowEmployeeWeeklyShiftsTotalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeWeeklyShiftsTotalResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeWeeklyShiftsTotalFromJSON(json['data']),
    };
}

export function ShowEmployeeWeeklyShiftsTotalResponseToJSON(value?: ShowEmployeeWeeklyShiftsTotalResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeWeeklyShiftsTotalToJSON(value['data']),
    };
}

