<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 334 112"
  >
    <g transform="translate(-3295 -2109)">
      <g transform="translate(3258 2050)">
        <path
          d="M18.061,112a14,14,0,1,1,0-28H48.836a6,6,0,0,0-.005-12H231.164a6,6,0,0,0,0,12v0H320a14,14,0,1,1,0,28ZM307.483,72a8,8,0,1,1,0-16h16.486a8,8,0,0,1,0,16ZM27.289,72a8,8,0,0,1,0-16H91.474a6,6,0,0,0-.005-12H243.347a6,6,0,0,0,0,12v0h40.016a8,8,0,0,1,0,16ZM48.608,44a8,8,0,1,1,0-16H96.55a6,6,0,0,0-.005-12h173.2a6,6,0,0,0,0,12v0h42.047a8,8,0,0,1,0,16ZM8,44A8,8,0,1,1,8,28H25.5a8,8,0,1,1,0,16ZM42.517,16a8,8,0,1,1,0-16H285.392a8,8,0,0,1,0,16Z"
          transform="translate(37 59)"
          fill="#e8e7ee"
        />
        <g transform="translate(-31 65.688)">
          <g transform="translate(-171 -1163)">
            <g transform="translate(364 1169.313)">
              <path
                d="M28.13,39.5H16.694A6.7,6.7,0,0,0,10,46.194v64.015a6.7,6.7,0,0,0,6.694,6.694H67.571a6.7,6.7,0,0,0,6.694-6.694V46.194A6.7,6.7,0,0,0,67.571,39.5Z"
                transform="translate(-8.326 -32.889)"
                fill="#f8dfb1"
              />
              <path
                d="M58,87.5h48.2v61.337H58Z"
                transform="translate(-48.293 -72.856)"
                fill="#fff"
              />
              <path
                d="M142,14.937A6.7,6.7,0,0,0,135.536,10h-.294a6.7,6.7,0,0,0-6.46,4.937h-3.895A2.887,2.887,0,0,0,122,17.824V22.97h26.777V17.824a2.887,2.887,0,0,0-2.887-2.887Z"
                transform="translate(-101.582 -8.326)"
                fill="#faf9fe"
              />
              <path
                d="M67.613,24.6V13.3a8.377,8.377,0,0,0-8.368-8.368H41.586A8.387,8.387,0,0,0,33.954,0h-.294a8.387,8.387,0,0,0-7.632,4.937H8.368A8.377,8.377,0,0,0,0,13.3V77.32a8.377,8.377,0,0,0,8.368,8.368H59.245a8.377,8.377,0,0,0,8.368-8.368V24.6ZM64.266,13.3V24.88a18.1,18.1,0,0,0-4.686,1.507V14.644a1.674,1.674,0,0,0-1.674-1.674H48.869V9.5A4.535,4.535,0,0,0,48.7,8.284H59.245A5.026,5.026,0,0,1,64.266,13.3ZM27.2,8.284a1.674,1.674,0,0,0,1.616-1.236,5.028,5.028,0,0,1,4.844-3.7h.294a5.029,5.029,0,0,1,4.844,3.7,1.673,1.673,0,0,0,1.615,1.236h3.9A1.215,1.215,0,0,1,45.521,9.5V12.97H22.091V9.5A1.215,1.215,0,0,1,23.3,8.284ZM59.245,82.34H8.368A5.026,5.026,0,0,1,3.347,77.32V13.3A5.026,5.026,0,0,1,8.368,8.284H18.909A4.535,4.535,0,0,0,18.744,9.5V12.97H9.707a1.674,1.674,0,0,0-1.674,1.674V75.981a1.674,1.674,0,0,0,1.674,1.674H27.112a1.674,1.674,0,0,0,0-3.347H11.38V16.317H56.232v5.6s-.354,17.89,0,20.866c.279,2.332-.256,14.244,0,14.444V74.307H40.5a1.674,1.674,0,0,0,0,3.347H57.906a1.674,1.674,0,0,0,1.674-1.674V59.3a18.1,18.1,0,0,0,4.686,1.507V77.32A5.026,5.026,0,0,1,59.245,82.34Z"
                fill="#2c1841"
              />
              <path
                d="M193.674,42.1a1.674,1.674,0,1,0-1.183-.49A1.686,1.686,0,0,0,193.674,42.1Z"
                transform="translate(-159.867 -32.265)"
                fill="#2c1841"
              />
              <path
                d="M193.674,444a1.674,1.674,0,1,0,1.183.49A1.686,1.686,0,0,0,193.674,444Z"
                transform="translate(-159.867 -369.693)"
                fill="#2c1841"
              />
              <g transform="translate(-1532.417 -1293.157)">
                <path
                  d="M19.164,27.239H2a2,2,0,0,1-2-2V15.861a2,2,0,0,1,2-2H9.521V10.316a2,2,0,0,1,2-2h7.642V2a2,2,0,0,1,2-2H29.7a2,2,0,0,1,2,2V25.239a2,2,0,0,1-2,2Z"
                  transform="translate(1549 1336)"
                  fill="#2c1841"
                />
                <g transform="translate(1552.17 1338.785)">
                  <rect
                    width="6.793"
                    height="7.699"
                    transform="translate(0 14.039)"
                    fill="#e9bcd6"
                  />
                  <rect
                    width="6.34"
                    height="13.133"
                    transform="translate(9.51 8.604)"
                    fill="#b5d1f2"
                  />
                  <rect
                    width="6.34"
                    height="21.737"
                    transform="translate(19.02 0)"
                    fill="#e9bdd5"
                  />
                </g>
              </g>
              <path
                d="M194.65,347.8h25.311a2.65,2.65,0,0,0,0-5.3H194.65a2.65,2.65,0,1,0,0,5.3Z"
                transform="translate(-175.173 -319.543)"
                fill="#2c1841"
              />
              <path
                d="M194.65,387.8h11.993a2.65,2.65,0,1,0,0-5.3H194.65a2.65,2.65,0,1,0,0,5.3Z"
                transform="translate(-175.173 -348.941)"
                fill="#2c1841"
              />
            </g>
            <g transform="translate(312 1169.313)">
              <circle
                cx="19"
                cy="19"
                r="19"
                transform="translate(98 23.688)"
                fill="#2c1841"
              />
              <path
                d="M336,173a16,16,0,1,1-16-16A16,16,0,0,1,336,173Z"
                transform="translate(-203 -130.313)"
                fill="#e1f5ff"
              />
              <path
                d="M348.371,292.552a16.5,16.5,0,0,0,10.98-4.162,11.38,11.38,0,0,0-21.96,0A16.5,16.5,0,0,0,348.371,292.552Z"
                transform="translate(-230.926 -233.141)"
                fill="#ddf0cf"
                stroke="#2c1841"
                stroke-width="3"
              />
              <path
                d="M382.342,215.337v1.3a5.2,5.2,0,0,1-10.393,0v-1.3a5.2,5.2,0,0,1,10.393,0Z"
                transform="translate(-259.7 -174.973)"
                fill="#fce4ea"
                stroke="#2c1841"
                stroke-width="3"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ClipboardWithAvatar',
};
</script>
