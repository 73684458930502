/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveRequest } from './LeaveRequest';
import {
    LeaveRequestFromJSON,
    LeaveRequestFromJSONTyped,
    LeaveRequestToJSON,
} from './LeaveRequest';

/**
 * 
 * @export
 * @interface DenyLeaveRequestResponse
 */
export interface DenyLeaveRequestResponse {
    /**
     * 
     * @type {LeaveRequest}
     * @memberof DenyLeaveRequestResponse
     */
    data?: LeaveRequest;
}

/**
 * Check if a given object implements the DenyLeaveRequestResponse interface.
 */
export function instanceOfDenyLeaveRequestResponse(value: object): boolean {
    return true;
}

export function DenyLeaveRequestResponseFromJSON(json: any): DenyLeaveRequestResponse {
    return DenyLeaveRequestResponseFromJSONTyped(json, false);
}

export function DenyLeaveRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DenyLeaveRequestResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : LeaveRequestFromJSON(json['data']),
    };
}

export function DenyLeaveRequestResponseToJSON(value?: DenyLeaveRequestResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeaveRequestToJSON(value['data']),
    };
}

