/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Cancel a leave request
 * @export
 * @interface CancelLeaveRequestData
 */
export interface CancelLeaveRequestData {
    /**
     * Optional message about why this leave request is being cancelled
     * @type {string}
     * @memberof CancelLeaveRequestData
     */
    notes?: string;
}

/**
 * Check if a given object implements the CancelLeaveRequestData interface.
 */
export function instanceOfCancelLeaveRequestData(value: object): boolean {
    return true;
}

export function CancelLeaveRequestDataFromJSON(json: any): CancelLeaveRequestData {
    return CancelLeaveRequestDataFromJSONTyped(json, false);
}

export function CancelLeaveRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelLeaveRequestData {
    if (json == null) {
        return json;
    }
    return {
        
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

export function CancelLeaveRequestDataToJSON(value?: CancelLeaveRequestData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notes': value['notes'],
    };
}

