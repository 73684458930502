/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListShiftCostReportsByDayResponse,
  ListShiftCostReportsByEmployeeResponse,
  ListShiftCostReportsByEmploymentTypeResponse,
  ListShiftCostReportsByGroupResponse,
  ListShiftCostReportsByJobRoleResponse,
  ListShiftCostReportsByLocationResponse,
} from '../models/index';
import {
    ListShiftCostReportsByDayResponseFromJSON,
    ListShiftCostReportsByDayResponseToJSON,
    ListShiftCostReportsByEmployeeResponseFromJSON,
    ListShiftCostReportsByEmployeeResponseToJSON,
    ListShiftCostReportsByEmploymentTypeResponseFromJSON,
    ListShiftCostReportsByEmploymentTypeResponseToJSON,
    ListShiftCostReportsByGroupResponseFromJSON,
    ListShiftCostReportsByGroupResponseToJSON,
    ListShiftCostReportsByJobRoleResponseFromJSON,
    ListShiftCostReportsByJobRoleResponseToJSON,
    ListShiftCostReportsByLocationResponseFromJSON,
    ListShiftCostReportsByLocationResponseToJSON,
} from '../models/index';

export interface ListShiftCostReportsByDayRequest {
    page?: number;
    perPage?: number;
    locationIds?: Array<number>;
    jobRoleIds?: Array<number>;
    employeeIds?: Array<number>;
    employeeGroupIds?: Array<number>;
    publishedShifts?: boolean;
    archived?: boolean;
    startsAt?: Date;
    endsAt?: Date;
}

export interface ListShiftCostReportsByEmployeeRequest {
    page?: number;
    perPage?: number;
    locationIds?: Array<number>;
    jobRoleIds?: Array<number>;
    employeeIds?: Array<number>;
    employeeGroupIds?: Array<number>;
    publishedShifts?: boolean;
    archived?: boolean;
    startsAt?: Date;
    endsAt?: Date;
}

export interface ListShiftCostReportsByEmploymentTypeRequest {
    page?: number;
    perPage?: number;
    locationIds?: Array<number>;
    jobRoleIds?: Array<number>;
    employeeIds?: Array<number>;
    employeeGroupIds?: Array<number>;
    publishedShifts?: boolean;
    archived?: boolean;
    startsAt?: Date;
    endsAt?: Date;
}

export interface ListShiftCostReportsByGroupRequest {
    page?: number;
    perPage?: number;
    locationIds?: Array<number>;
    jobRoleIds?: Array<number>;
    employeeIds?: Array<number>;
    employeeGroupIds?: Array<number>;
    publishedShifts?: boolean;
    archived?: boolean;
    startsAt?: Date;
    endsAt?: Date;
}

export interface ListShiftCostReportsByJobRoleRequest {
    page?: number;
    perPage?: number;
    locationIds?: Array<number>;
    jobRoleIds?: Array<number>;
    employeeIds?: Array<number>;
    employeeGroupIds?: Array<number>;
    publishedShifts?: boolean;
    archived?: boolean;
    startsAt?: Date;
    endsAt?: Date;
}

export interface ListShiftCostReportsByLocationRequest {
    page?: number;
    perPage?: number;
    locationIds?: Array<number>;
    jobRoleIds?: Array<number>;
    employeeIds?: Array<number>;
    employeeGroupIds?: Array<number>;
    publishedShifts?: boolean;
    archived?: boolean;
    startsAt?: Date;
    endsAt?: Date;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     * Get a shift costs report broken down by Days.
     * Shift Costs By Days
     */
    async listShiftCostReportsByDayRaw(requestParameters: ListShiftCostReportsByDayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftCostReportsByDayResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['locationIds'] != null) {
            queryParameters['locationIds'] = requestParameters['locationIds'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['employeeIds'] != null) {
            queryParameters['employeeIds'] = requestParameters['employeeIds'];
        }

        if (requestParameters['employeeGroupIds'] != null) {
            queryParameters['employeeGroupIds'] = requestParameters['employeeGroupIds'];
        }

        if (requestParameters['publishedShifts'] != null) {
            queryParameters['publishedShifts'] = requestParameters['publishedShifts'];
        }

        if (requestParameters['archived'] != null) {
            queryParameters['archived'] = requestParameters['archived'];
        }

        if (requestParameters['startsAt'] != null) {
            queryParameters['startsAt'] = (requestParameters['startsAt'] as any).toISOString();
        }

        if (requestParameters['endsAt'] != null) {
            queryParameters['endsAt'] = (requestParameters['endsAt'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-cost-report.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/costs/daily`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftCostReportsByDayResponseFromJSON(jsonValue));
    }

    /**
     * Get a shift costs report broken down by Days.
     * Shift Costs By Days
     */
    async listShiftCostReportsByDay(requestParameters: ListShiftCostReportsByDayRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftCostReportsByDayResponse> {
        const response = await this.listShiftCostReportsByDayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a shift costs report broken down by Employment Type.
     * Shift Costs By Employee
     */
    async listShiftCostReportsByEmployeeRaw(requestParameters: ListShiftCostReportsByEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftCostReportsByEmployeeResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['locationIds'] != null) {
            queryParameters['locationIds'] = requestParameters['locationIds'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['employeeIds'] != null) {
            queryParameters['employeeIds'] = requestParameters['employeeIds'];
        }

        if (requestParameters['employeeGroupIds'] != null) {
            queryParameters['employeeGroupIds'] = requestParameters['employeeGroupIds'];
        }

        if (requestParameters['publishedShifts'] != null) {
            queryParameters['publishedShifts'] = requestParameters['publishedShifts'];
        }

        if (requestParameters['archived'] != null) {
            queryParameters['archived'] = requestParameters['archived'];
        }

        if (requestParameters['startsAt'] != null) {
            queryParameters['startsAt'] = (requestParameters['startsAt'] as any).toISOString();
        }

        if (requestParameters['endsAt'] != null) {
            queryParameters['endsAt'] = (requestParameters['endsAt'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-cost-report.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/costs/by-employee`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftCostReportsByEmployeeResponseFromJSON(jsonValue));
    }

    /**
     * Get a shift costs report broken down by Employment Type.
     * Shift Costs By Employee
     */
    async listShiftCostReportsByEmployee(requestParameters: ListShiftCostReportsByEmployeeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftCostReportsByEmployeeResponse> {
        const response = await this.listShiftCostReportsByEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a shift costs report broken down by Employment Type.
     * Shift Costs By Employment Type
     */
    async listShiftCostReportsByEmploymentTypeRaw(requestParameters: ListShiftCostReportsByEmploymentTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftCostReportsByEmploymentTypeResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['locationIds'] != null) {
            queryParameters['locationIds'] = requestParameters['locationIds'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['employeeIds'] != null) {
            queryParameters['employeeIds'] = requestParameters['employeeIds'];
        }

        if (requestParameters['employeeGroupIds'] != null) {
            queryParameters['employeeGroupIds'] = requestParameters['employeeGroupIds'];
        }

        if (requestParameters['publishedShifts'] != null) {
            queryParameters['publishedShifts'] = requestParameters['publishedShifts'];
        }

        if (requestParameters['archived'] != null) {
            queryParameters['archived'] = requestParameters['archived'];
        }

        if (requestParameters['startsAt'] != null) {
            queryParameters['startsAt'] = (requestParameters['startsAt'] as any).toISOString();
        }

        if (requestParameters['endsAt'] != null) {
            queryParameters['endsAt'] = (requestParameters['endsAt'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-cost-report.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/costs/by-employment-type`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftCostReportsByEmploymentTypeResponseFromJSON(jsonValue));
    }

    /**
     * Get a shift costs report broken down by Employment Type.
     * Shift Costs By Employment Type
     */
    async listShiftCostReportsByEmploymentType(requestParameters: ListShiftCostReportsByEmploymentTypeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftCostReportsByEmploymentTypeResponse> {
        const response = await this.listShiftCostReportsByEmploymentTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a shift costs report broken down by Group.
     * Shift Costs By Group
     */
    async listShiftCostReportsByGroupRaw(requestParameters: ListShiftCostReportsByGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftCostReportsByGroupResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['locationIds'] != null) {
            queryParameters['locationIds'] = requestParameters['locationIds'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['employeeIds'] != null) {
            queryParameters['employeeIds'] = requestParameters['employeeIds'];
        }

        if (requestParameters['employeeGroupIds'] != null) {
            queryParameters['employeeGroupIds'] = requestParameters['employeeGroupIds'];
        }

        if (requestParameters['publishedShifts'] != null) {
            queryParameters['publishedShifts'] = requestParameters['publishedShifts'];
        }

        if (requestParameters['archived'] != null) {
            queryParameters['archived'] = requestParameters['archived'];
        }

        if (requestParameters['startsAt'] != null) {
            queryParameters['startsAt'] = (requestParameters['startsAt'] as any).toISOString();
        }

        if (requestParameters['endsAt'] != null) {
            queryParameters['endsAt'] = (requestParameters['endsAt'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-cost-report.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/costs/by-group`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftCostReportsByGroupResponseFromJSON(jsonValue));
    }

    /**
     * Get a shift costs report broken down by Group.
     * Shift Costs By Group
     */
    async listShiftCostReportsByGroup(requestParameters: ListShiftCostReportsByGroupRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftCostReportsByGroupResponse> {
        const response = await this.listShiftCostReportsByGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a shift costs report broken down by Job Role.
     * Shift Costs By Job Role
     */
    async listShiftCostReportsByJobRoleRaw(requestParameters: ListShiftCostReportsByJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftCostReportsByJobRoleResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['locationIds'] != null) {
            queryParameters['locationIds'] = requestParameters['locationIds'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['employeeIds'] != null) {
            queryParameters['employeeIds'] = requestParameters['employeeIds'];
        }

        if (requestParameters['employeeGroupIds'] != null) {
            queryParameters['employeeGroupIds'] = requestParameters['employeeGroupIds'];
        }

        if (requestParameters['publishedShifts'] != null) {
            queryParameters['publishedShifts'] = requestParameters['publishedShifts'];
        }

        if (requestParameters['archived'] != null) {
            queryParameters['archived'] = requestParameters['archived'];
        }

        if (requestParameters['startsAt'] != null) {
            queryParameters['startsAt'] = (requestParameters['startsAt'] as any).toISOString();
        }

        if (requestParameters['endsAt'] != null) {
            queryParameters['endsAt'] = (requestParameters['endsAt'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-cost-report.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/costs/by-job-role`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftCostReportsByJobRoleResponseFromJSON(jsonValue));
    }

    /**
     * Get a shift costs report broken down by Job Role.
     * Shift Costs By Job Role
     */
    async listShiftCostReportsByJobRole(requestParameters: ListShiftCostReportsByJobRoleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftCostReportsByJobRoleResponse> {
        const response = await this.listShiftCostReportsByJobRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a shift costs report broken down by Location.
     * Shift Costs By Location
     */
    async listShiftCostReportsByLocationRaw(requestParameters: ListShiftCostReportsByLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftCostReportsByLocationResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['locationIds'] != null) {
            queryParameters['locationIds'] = requestParameters['locationIds'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['employeeIds'] != null) {
            queryParameters['employeeIds'] = requestParameters['employeeIds'];
        }

        if (requestParameters['employeeGroupIds'] != null) {
            queryParameters['employeeGroupIds'] = requestParameters['employeeGroupIds'];
        }

        if (requestParameters['publishedShifts'] != null) {
            queryParameters['publishedShifts'] = requestParameters['publishedShifts'];
        }

        if (requestParameters['archived'] != null) {
            queryParameters['archived'] = requestParameters['archived'];
        }

        if (requestParameters['startsAt'] != null) {
            queryParameters['startsAt'] = (requestParameters['startsAt'] as any).toISOString();
        }

        if (requestParameters['endsAt'] != null) {
            queryParameters['endsAt'] = (requestParameters['endsAt'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-cost-report.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reports/costs/by-location`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftCostReportsByLocationResponseFromJSON(jsonValue));
    }

    /**
     * Get a shift costs report broken down by Location.
     * Shift Costs By Location
     */
    async listShiftCostReportsByLocation(requestParameters: ListShiftCostReportsByLocationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftCostReportsByLocationResponse> {
        const response = await this.listShiftCostReportsByLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
