/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateEmployeeAttributeData,
  CreateEmployeeAttributeResponse,
  ListEmployeeAttributesResponse,
  ShowEmployeeAttributeResponse,
  UpdateEmployeeAttributeData,
  UpdateEmployeeAttributeResponse,
} from '../models/index';
import {
    CreateEmployeeAttributeDataFromJSON,
    CreateEmployeeAttributeDataToJSON,
    CreateEmployeeAttributeResponseFromJSON,
    CreateEmployeeAttributeResponseToJSON,
    ListEmployeeAttributesResponseFromJSON,
    ListEmployeeAttributesResponseToJSON,
    ShowEmployeeAttributeResponseFromJSON,
    ShowEmployeeAttributeResponseToJSON,
    UpdateEmployeeAttributeDataFromJSON,
    UpdateEmployeeAttributeDataToJSON,
    UpdateEmployeeAttributeResponseFromJSON,
    UpdateEmployeeAttributeResponseToJSON,
} from '../models/index';

export interface CreateEmployeeAttributeRequest {
    createEmployeeAttributeData?: CreateEmployeeAttributeData;
}

export interface ListEmployeeAttributesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowEmployeeAttributeRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateEmployeeAttributeRequest {
    id: number;
    _with?: Array<string>;
    updateEmployeeAttributeData?: UpdateEmployeeAttributeData;
}

/**
 * 
 */
export class EmployeeAttributeApi extends runtime.BaseAPI {

    /**
     * Create a new EmployeeAttribute
     * Create Employee Attribute
     */
    async createEmployeeAttributeRaw(requestParameters: CreateEmployeeAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEmployeeAttributeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-attribute.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-attributes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmployeeAttributeDataToJSON(requestParameters['createEmployeeAttributeData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEmployeeAttributeResponseFromJSON(jsonValue));
    }

    /**
     * Create a new EmployeeAttribute
     * Create Employee Attribute
     */
    async createEmployeeAttribute(requestParameters: CreateEmployeeAttributeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEmployeeAttributeResponse> {
        const response = await this.createEmployeeAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all Employee Attributes
     * List Employee Attributes
     */
    async listEmployeeAttributesRaw(requestParameters: ListEmployeeAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeAttributesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-attribute.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeAttributesResponseFromJSON(jsonValue));
    }

    /**
     * List all Employee Attributes
     * List Employee Attributes
     */
    async listEmployeeAttributes(requestParameters: ListEmployeeAttributesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeAttributesResponse> {
        const response = await this.listEmployeeAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single Employee Attribute
     * Show Employee Attribute
     */
    async showEmployeeAttributeRaw(requestParameters: ShowEmployeeAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeeAttributeResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmployeeAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-attribute.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-attributes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeeAttributeResponseFromJSON(jsonValue));
    }

    /**
     * Show a single Employee Attribute
     * Show Employee Attribute
     */
    async showEmployeeAttribute(requestParameters: ShowEmployeeAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeeAttributeResponse> {
        const response = await this.showEmployeeAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a single Employee Attribute
     * Update Employee Attribute
     */
    async updateEmployeeAttributeRaw(requestParameters: UpdateEmployeeAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEmployeeAttributeResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEmployeeAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-attribute.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-attributes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeeAttributeDataToJSON(requestParameters['updateEmployeeAttributeData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEmployeeAttributeResponseFromJSON(jsonValue));
    }

    /**
     * Update a single Employee Attribute
     * Update Employee Attribute
     */
    async updateEmployeeAttribute(requestParameters: UpdateEmployeeAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEmployeeAttributeResponse> {
        const response = await this.updateEmployeeAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
