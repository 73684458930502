/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublishShiftsData
 */
export interface PublishShiftsData {
    /**
     * 
     * @type {Array<number>}
     * @memberof PublishShiftsData
     */
    shiftIds: Array<number>;
}

/**
 * Check if a given object implements the PublishShiftsData interface.
 */
export function instanceOfPublishShiftsData(value: object): boolean {
    if (!('shiftIds' in value)) return false;
    return true;
}

export function PublishShiftsDataFromJSON(json: any): PublishShiftsData {
    return PublishShiftsDataFromJSONTyped(json, false);
}

export function PublishShiftsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishShiftsData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftIds': json['shiftIds'],
    };
}

export function PublishShiftsDataToJSON(value?: PublishShiftsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shiftIds': value['shiftIds'],
    };
}

