import { AnyGetter, getFromObject } from '@/util/objectFunctions';
import spacetime from 'spacetime';

export const minutesRemaining = (
  timezone: string,
  entity: object,
  startProp: string = 'startAt',
  endProp: string = 'endsAt',
): number => {
  const entityStart = spacetime(entity[startProp], timezone);
  const now = spacetime.now(timezone);
  const start = now.isAfter(entityStart) ? now : entityStart;
  return start.diff(spacetime(entity[endProp], timezone), 'minute');
};

export function getEntity<T>(
  id: unknown,
  entities: ReadonlyArray<T>,
  key: keyof T,
): T | undefined;
export function getEntity<T extends { id: unknown }>(
  id: T['id'],
  entities: ReadonlyArray<T>,
  key?: keyof T,
): T | undefined;
export function getEntity<T>(
  id: unknown,
  entities: ReadonlyArray<T>,
  key: keyof T = 'id' as keyof T,
): T | undefined {
  return entities.find((e) => e[key] === id);
}

export function popEntity<T>(
  id: unknown,
  entities: T[],
  key: keyof T,
): T | undefined;
export function popEntity<T extends { id: unknown }>(
  id: T['id'],
  entities: T[],
  key?: keyof T,
): T | undefined;
export function popEntity<T>(
  id: unknown,
  entities: T[],
  key: keyof T = 'id' as keyof T,
): T | undefined {
  const index = entities.findIndex((e) => e[key] === id);
  if (index === -1) return;
  return entities.splice(index, 1)[0];
}

export const getEntityName = <T extends { id: number; name: string }>(
  id: number | null,
  entities: T[],
  fallback: string = '',
) => (id && getEntity(id, entities)?.name) || fallback;

export const getEntityNames = <T extends { id: number; name: string }>(
  ids: number[],
  entities: T[],
  fallback: string = '',
) =>
  (ids.length &&
    entities
      .filter((e) => ids.includes(e.id))
      .map((e) => e.name)
      .join(', ')) ||
  fallback;

export const hasMatchingId = (
  { id }: { id: number; [key: string]: any },
  targetId: number,
): boolean => id === targetId;

export function getMapFromUniqueEntities<R, T extends object>(
  entities: ReadonlyArray<T>,
  field: AnyGetter<T>,
): Map<R, T>;
export function getMapFromUniqueEntities<R, T extends { id: unknown }>(
  entities: ReadonlyArray<T>,
  field?: AnyGetter<T>,
): Map<R, T>;
export function getMapFromUniqueEntities<R, T extends object>(
  entities: ReadonlyArray<T>,
  field: AnyGetter<T> = 'id',
): Map<R, T> {
  return new Map(
    entities.map((item) => [getFromObject(item, field) as R, item]),
  );
}
