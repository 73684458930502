/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthTokenMetaUser } from './AuthTokenMetaUser';
import {
    AuthTokenMetaUserFromJSON,
    AuthTokenMetaUserFromJSONTyped,
    AuthTokenMetaUserToJSON,
} from './AuthTokenMetaUser';

/**
 * 
 * @export
 * @interface AuthTokenMeta
 */
export interface AuthTokenMeta {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenMeta
     */
    state: AuthTokenMetaStateEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthTokenMeta
     */
    type: AuthTokenMetaTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof AuthTokenMeta
     */
    expiresAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthTokenMeta
     */
    usedAt: Date | null;
    /**
     * 
     * @type {AuthTokenMetaUser}
     * @memberof AuthTokenMeta
     */
    user: AuthTokenMetaUser;
}

/**
* @export
* @enum {string}
*/
export enum AuthTokenMetaStateEnum {
    Expired = 'Expired',
    NonExistent = 'Non-existent',
    Used = 'Used',
    Valid = 'Valid'
}
/**
* @export
* @enum {string}
*/
export enum AuthTokenMetaTypeEnum {
    EmailRevert = 'Email Revert',
    EmailVerification = 'Email Verification',
    EmployeeConfirmation = 'Employee Confirmation',
    PhoneVerification = 'Phone Verification',
    SetPassword = 'Set Password'
}


/**
 * Check if a given object implements the AuthTokenMeta interface.
 */
export function instanceOfAuthTokenMeta(value: object): boolean {
    if (!('state' in value)) return false;
    if (!('type' in value)) return false;
    if (!('expiresAt' in value)) return false;
    if (!('usedAt' in value)) return false;
    if (!('user' in value)) return false;
    return true;
}

export function AuthTokenMetaFromJSON(json: any): AuthTokenMeta {
    return AuthTokenMetaFromJSONTyped(json, false);
}

export function AuthTokenMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthTokenMeta {
    if (json == null) {
        return json;
    }
    return {
        
        'state': json['state'],
        'type': json['type'],
        'expiresAt': (json['expiresAt'] == null ? null : new Date(json['expiresAt'])),
        'usedAt': (json['usedAt'] == null ? null : new Date(json['usedAt'])),
        'user': AuthTokenMetaUserFromJSON(json['user']),
    };
}

export function AuthTokenMetaToJSON(value?: AuthTokenMeta | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'state': value['state'],
        'type': value['type'],
        'expiresAt': (value['expiresAt'] == null ? null : (value['expiresAt'] as any).toISOString()),
        'usedAt': (value['usedAt'] == null ? null : (value['usedAt'] as any).toISOString()),
        'user': AuthTokenMetaUserToJSON(value['user']),
    };
}

