/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BudgetSummary } from './BudgetSummary';
import {
    BudgetSummaryFromJSON,
    BudgetSummaryFromJSONTyped,
    BudgetSummaryToJSON,
} from './BudgetSummary';

/**
 * 
 * @export
 * @interface ShowBudgetSummaryResponse
 */
export interface ShowBudgetSummaryResponse {
    /**
     * 
     * @type {BudgetSummary}
     * @memberof ShowBudgetSummaryResponse
     */
    data: BudgetSummary;
}

/**
 * Check if a given object implements the ShowBudgetSummaryResponse interface.
 */
export function instanceOfShowBudgetSummaryResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowBudgetSummaryResponseFromJSON(json: any): ShowBudgetSummaryResponse {
    return ShowBudgetSummaryResponseFromJSONTyped(json, false);
}

export function ShowBudgetSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowBudgetSummaryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BudgetSummaryFromJSON(json['data']),
    };
}

export function ShowBudgetSummaryResponseToJSON(value?: ShowBudgetSummaryResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': BudgetSummaryToJSON(value['data']),
    };
}

