import { StorageEnum } from '@/lib/enum/StorageEnum';
import { isLocalEnv } from '@/lib/environment';
import { initialiseWebsocket } from '@/lib/helpers/websocketFunctions';
import { registerGlobals } from '@/plugins/globals';
import { initialiseSentry } from '@/plugins/sentry';
import { websocketService } from '@/plugins/socket-service/SocketService';
import { applyGlobalHooks } from '@/router/globalHooks';
import { applyRouteHooks } from '@/router/routeHooks';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/util/storageFunctions';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import Vue from 'vue';
import device from 'vue-device-detector-js';
import VueDragDrop from 'vue-drag-drop';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import { registerServiceWorker } from './registerServiceWorker';
import router, { configureRouter } from './router/router';
import store from './store';
import './styles/main.scss';

Vue.config.productionTip = false;

// Register the service worker
registerServiceWorker();

// Configure the router's routes & hooks outside of router.ts. This
// is done to avoid the inevitable import cycle of importing anything
// that uses exports from api.ts, e.g. the store / store based functions.
configureRouter(applyGlobalHooks, applyRouteHooks);

window.addEventListener('error', (e) => {
  // If we encounter a chunk loading error, then force a client refresh, but only if once hasn't
  // happened in the last 5 minutes (300s). This is to prevent infinite loop errors when a chunk
  // loading error is consistently being thrown by the client's browser.
  if (/Loading (CSS )?chunk \d+ failed/.test(e.message)) {
    const lastReload = getLocalStorageItem(StorageEnum.LastChunkReload);

    if (Number(lastReload || 0) < Date.now() - 300 * 1000) {
      setLocalStorageItem(StorageEnum.LastChunkReload, Date.now().toString());
      window.location.reload();
    }
  }

  // https://blog.elantha.com/resizeobserver-loop-limit-exceeded/
  // Chrome and Firefox don't always log all error messages but Sentry will still catch them
  // If we're in a local environment we want to output all errors to console
  if (isLocalEnv) throw e;
});

// @ts-ignore
Vue.use(Vuelidate);
Vue.use(device);
Vue.use(VueDragDrop);

// Register initial values for global vue instance variables.
registerGlobals({
  $companyId: null,
  $loggedInEmployeeId: null,
});

// Initialise Sentry
initialiseSentry();

/**
 * Initialise the global WebSocket connection. This is done before the Vue instance
 * is mounted to the DOM to ensure the connection is available for any user land
 * code that needs it.
 */
initialiseWebsocket(store, websocketService);

const VueInstance = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
});

VueInstance.$mount('#app');

window.addEventListener('beforeunload', () => {
  VueInstance.$destroy();
});
