/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTagData
 */
export interface CreateTagData {
    /**
     * 
     * @type {string}
     * @memberof CreateTagData
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTagData
     */
    visibleToEmployees: boolean;
}

/**
 * Check if a given object implements the CreateTagData interface.
 */
export function instanceOfCreateTagData(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('visibleToEmployees' in value)) return false;
    return true;
}

export function CreateTagDataFromJSON(json: any): CreateTagData {
    return CreateTagDataFromJSONTyped(json, false);
}

export function CreateTagDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTagData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'visibleToEmployees': json['visibleToEmployees'],
    };
}

export function CreateTagDataToJSON(value?: CreateTagData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'visibleToEmployees': value['visibleToEmployees'],
    };
}

