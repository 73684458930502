
import { companyApi, employeeApi } from '@/api';
import Button from '@/components/buttons/Button.vue';
import ConfirmActionDialog from '@/components/dialog/ConfirmActionDialog.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import OnboardingBottomSheet from '@/components/widgets/onboarding-widget/components/OnboardingBottomSheet.vue';
import OnboardingDialog from '@/components/widgets/onboarding-widget/components/OnboardingDialog.vue';
import { Icon } from '@/lib/enum/Icon';
import {
  Flag,
  getCompanyOnboardingFlags,
  getEmployeeOnboardingFlags,
  getOnboardingClosed,
  setOnboardingClosed,
} from '@/lib/onboarding/onboardingFunctions';
import { userCan } from '@/lib/permission/userCan';
import { Entity } from '@/lib/store/realtimeEntities';
import store from '@/store';
import { fetchEntity } from '@/stores/entities';
import { Permission } from '@/util/permissionFunctions';
import { viewport } from '@/util/windowFunctions';

export default {
  name: 'OnboardingWidget',
  components: {
    OnboardingBottomSheet,
    ConfirmActionDialog,
    OnboardingDialog,
    BubbleIcon,
    Button,
  },
  data() {
    return {
      Icon,
      userCan,
      viewport,

      show:
        getOnboardingClosed(
          store.getters.currentCompany.id,
          store.getters.loggedInEmployee.id,
        ) !== 'true' && !store.state.isImpersonationSession,
      showConfirmation: false,
      actingFlag: null as Flag | null,

      companyOnboarding: fetchEntity(Entity.CompanyOnboarding),
      employeeOnboarding: fetchEntity(Entity.EmployeeOnboarding),
      smsEnabled: false,
    };
  },
  computed: {
    permissions: () => store.state.permissions,
    loggedInEmployee: () => store.getters.loggedInEmployee,

    isEmployee() {
      return (
        this.permissions.length === 0 ||
        (this.permissions.length === 1 &&
          this.permissions[0] === Permission.SendEmployeeMessage)
      );
    },

    flags(): Flag[] {
      if (this.isEmployee) {
        return this.employeeOnboarding.data
          ? getEmployeeOnboardingFlags(
              this.employeeOnboarding.data,
              this.loggedInEmployee.id,
              this.smsEnabled,
            )
          : [];
      }
      return this.companyOnboarding.data
        ? getCompanyOnboardingFlags(this.companyOnboarding.data)
        : [];
    },
    completedFlags(): Flag[] {
      return this.flags.filter((flag) => flag.completed);
    },

    visible() {
      return this.flags.length > this.completedFlags.length;
    },
  },
  async mounted() {
    const query = fetchEntity(Entity.FeaturesEnabled);
    await query.promise;
    this.smsEnabled = query.data?.smsEnabled ?? false;
  },
  methods: {
    hide() {
      this.show = false;
      setOnboardingClosed(
        store.getters.currentCompany.id,
        store.getters.loggedInEmployee.id,
        true,
      );
    },
    toggle() {
      if (this.show) this.hide();
      else this.show = true;
    },
    selectItem(item: Flag) {
      if (item.completed) return;
      this.actingFlag = item;
    },
    async dismissChecklist() {
      const dismissOnboarding = () =>
        this.isEmployee
          ? employeeApi.updateEmployeeOnboarding({
              updateEmployeeOnboardingData: {
                hasAddedPersonalDetails: true,
                hasVerifiedPhoneNumber: true,
                hasAddedUnavailability: true,
              },
            })
          : companyApi.updateCompanyOnboarding({
              updateCompanyOnboardingData: {
                hasAddedJobRoles: true,
                hasAddedTeam: true,
                hasAddedShifts: true,
                hasInvitedTeam: true,
                hasConfiguredClockingSettings: true,
                hasPublishedShifts: true,
                hasConfiguredLeaveSettings: true,
              },
            });
      dismissOnboarding().then(this.hide);
    },
  },
};
