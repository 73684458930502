/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatePasswordResetData,
  RevertEmailData,
  SetPasswordData,
  SetPasswordResponse,
  ShowAuthenticatedUserResponse,
  UpdateAuthenticatedUserData,
  UpdateAuthenticatedUserResponse,
  UpdatePasswordRequestData,
  VerifyEmailData,
  VerifyEmailResponse,
} from '../models/index';
import {
    CreatePasswordResetDataFromJSON,
    CreatePasswordResetDataToJSON,
    RevertEmailDataFromJSON,
    RevertEmailDataToJSON,
    SetPasswordDataFromJSON,
    SetPasswordDataToJSON,
    SetPasswordResponseFromJSON,
    SetPasswordResponseToJSON,
    ShowAuthenticatedUserResponseFromJSON,
    ShowAuthenticatedUserResponseToJSON,
    UpdateAuthenticatedUserDataFromJSON,
    UpdateAuthenticatedUserDataToJSON,
    UpdateAuthenticatedUserResponseFromJSON,
    UpdateAuthenticatedUserResponseToJSON,
    UpdatePasswordRequestDataFromJSON,
    UpdatePasswordRequestDataToJSON,
    VerifyEmailDataFromJSON,
    VerifyEmailDataToJSON,
    VerifyEmailResponseFromJSON,
    VerifyEmailResponseToJSON,
} from '../models/index';

export interface CreatePasswordResetRequest {
    createPasswordResetData?: CreatePasswordResetData;
}

export interface RevertEmailRequest {
    revertEmailData?: RevertEmailData;
}

export interface SetPasswordRequest {
    setPasswordData?: SetPasswordData;
}

export interface ShowAuthenticatedUserRequest {
    _with?: Array<string>;
}

export interface UpdateAuthenticatedUserRequest {
    _with?: Array<string>;
    propagateChanges?: boolean;
    updateAuthenticatedUserData?: UpdateAuthenticatedUserData;
}

export interface UpdateUserPasswordRequest {
    updatePasswordRequestData?: UpdatePasswordRequestData;
}

export interface VerifyEmailRequest {
    verifyEmailData?: VerifyEmailData;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Request a password reset link.
     * Create Password Reset
     */
    async createPasswordResetRaw(requestParameters: CreatePasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/password-reset/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePasswordResetDataToJSON(requestParameters['createPasswordResetData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request a password reset link.
     * Create Password Reset
     */
    async createPasswordReset(requestParameters: CreatePasswordResetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPasswordResetRaw(requestParameters, initOverrides);
    }

    /**
     * Request a new confirmation email.
     * Resend Confirmation Email
     */
    async resendConfirmationEmailRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/resend-confirmation-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request a new confirmation email.
     * Resend Confirmation Email
     */
    async resendConfirmationEmail(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendConfirmationEmailRaw(initOverrides);
    }

    /**
     * Revert a users email to the previously held value.
     * Revert Email
     */
    async revertEmailRaw(requestParameters: RevertEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/revert-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RevertEmailDataToJSON(requestParameters['revertEmailData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revert a users email to the previously held value.
     * Revert Email
     */
    async revertEmail(requestParameters: RevertEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revertEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Set the password for a newly created user. This cannot be used when a user already has already set their password.
     * Set Password
     */
    async setPasswordRaw(requestParameters: SetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetPasswordResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/set-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPasswordDataToJSON(requestParameters['setPasswordData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetPasswordResponseFromJSON(jsonValue));
    }

    /**
     * Set the password for a newly created user. This cannot be used when a user already has already set their password.
     * Set Password
     */
    async setPassword(requestParameters: SetPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetPasswordResponse> {
        const response = await this.setPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the currently authenticated user.
     * Show Authenticated User
     */
    async showAuthenticatedUserRaw(requestParameters: ShowAuthenticatedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowAuthenticatedUserResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.auth.user.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowAuthenticatedUserResponseFromJSON(jsonValue));
    }

    /**
     * Show the currently authenticated user.
     * Show Authenticated User
     */
    async showAuthenticatedUser(requestParameters: ShowAuthenticatedUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowAuthenticatedUserResponse> {
        const response = await this.showAuthenticatedUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the currently authenticated user.
     * Update Current User
     */
    async updateAuthenticatedUserRaw(requestParameters: UpdateAuthenticatedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateAuthenticatedUserResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['propagateChanges'] != null) {
            queryParameters['propagateChanges'] = requestParameters['propagateChanges'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.auth.user.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAuthenticatedUserDataToJSON(requestParameters['updateAuthenticatedUserData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateAuthenticatedUserResponseFromJSON(jsonValue));
    }

    /**
     * Update the currently authenticated user.
     * Update Current User
     */
    async updateAuthenticatedUser(requestParameters: UpdateAuthenticatedUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateAuthenticatedUserResponse> {
        const response = await this.updateAuthenticatedUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update your password.
     * Update User Password
     */
    async updateUserPasswordRaw(requestParameters: UpdateUserPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.auth.user.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/update-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePasswordRequestDataToJSON(requestParameters['updatePasswordRequestData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update your password.
     * Update User Password
     */
    async updateUserPassword(requestParameters: UpdateUserPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Verify a user\'s email address using the token supplied in an email sent to them.
     * Verify Email
     */
    async verifyEmailRaw(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VerifyEmailResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/verify-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyEmailDataToJSON(requestParameters['verifyEmailData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyEmailResponseFromJSON(jsonValue));
    }

    /**
     * Verify a user\'s email address using the token supplied in an email sent to them.
     * Verify Email
     */
    async verifyEmail(requestParameters: VerifyEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VerifyEmailResponse> {
        const response = await this.verifyEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
