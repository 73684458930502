var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.initialising)?_c('div',{staticClass:"all:flex justify-center all:p-4"},[_c(_setup.LoadingDot,{attrs:{"iteration":3}})],1):_c('ul',{staticClass:"pl-0 pb-2 text-gray-700",class:{
    'border-b-1': _setup.userCan.manageShifts(),
  }},_vm._l((_setup.summaryRows),function({ infoType, iconClass, info },idx){return _c('li',{key:infoType,staticClass:"flex items-center gap-x-4"},[_c('i',{staticClass:"w-4 h-4",class:[
        iconClass,
        { 'mt-2.5 self-start': infoType === _setup.SummaryRowInfoTypeEnum.Breaks },
      ]}),_c('div',{staticClass:"grow py-2",class:{
        'border-b-1': idx !== _setup.summaryRows.length - 1,
      }},[(infoType === _setup.SummaryRowInfoTypeEnum.Breaks)?_c('div',{staticClass:"flex flex-col"},[(_vm.shift.scheduledBreaks.length)?_vm._l((_vm.shift.scheduledBreaks),function(b){return _c('span',{key:b.id},[_vm._v(" "+_vm._s(_setup.generateBreakRowInfo(b))+" ")])}):_c('span',[_vm._v(_vm._s(_vm.$tc('info.noBreaksScheduled')))])],2):_vm._e(),(infoType === _setup.SummaryRowInfoTypeEnum.Tags)?_c('div',{staticClass:"flex flex-wrap gap-2"},_vm._l((_setup.tagNames),function(tagName,tagIdx){return _c(_setup.Tag,{key:tagName + tagIdx,attrs:{"tag-name":tagName}})}),1):_c('span',[_vm._v(_vm._s(info))])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }