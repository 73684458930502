/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FeaturesEnabled } from './FeaturesEnabled';
import {
    FeaturesEnabledFromJSON,
    FeaturesEnabledFromJSONTyped,
    FeaturesEnabledToJSON,
} from './FeaturesEnabled';

/**
 * 
 * @export
 * @interface ShowEnabledFeaturesResponse
 */
export interface ShowEnabledFeaturesResponse {
    /**
     * 
     * @type {FeaturesEnabled}
     * @memberof ShowEnabledFeaturesResponse
     */
    data: FeaturesEnabled;
}

/**
 * Check if a given object implements the ShowEnabledFeaturesResponse interface.
 */
export function instanceOfShowEnabledFeaturesResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowEnabledFeaturesResponseFromJSON(json: any): ShowEnabledFeaturesResponse {
    return ShowEnabledFeaturesResponseFromJSONTyped(json, false);
}

export function ShowEnabledFeaturesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEnabledFeaturesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': FeaturesEnabledFromJSON(json['data']),
    };
}

export function ShowEnabledFeaturesResponseToJSON(value?: ShowEnabledFeaturesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': FeaturesEnabledToJSON(value['data']),
    };
}

