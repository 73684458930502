/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface CreateLocationResponse
 */
export interface CreateLocationResponse {
    /**
     * 
     * @type {Location}
     * @memberof CreateLocationResponse
     */
    data?: Location;
}

/**
 * Check if a given object implements the CreateLocationResponse interface.
 */
export function instanceOfCreateLocationResponse(value: object): boolean {
    return true;
}

export function CreateLocationResponseFromJSON(json: any): CreateLocationResponse {
    return CreateLocationResponseFromJSONTyped(json, false);
}

export function CreateLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLocationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : LocationFromJSON(json['data']),
    };
}

export function CreateLocationResponseToJSON(value?: CreateLocationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LocationToJSON(value['data']),
    };
}

