/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { PayPeriod } from './PayPeriod';
import {
    PayPeriodFromJSON,
    PayPeriodFromJSONTyped,
    PayPeriodToJSON,
} from './PayPeriod';

/**
 * 
 * @export
 * @interface ListPayPeriodsResponse
 */
export interface ListPayPeriodsResponse {
    /**
     * 
     * @type {Array<PayPeriod>}
     * @memberof ListPayPeriodsResponse
     */
    data: Array<PayPeriod>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListPayPeriodsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListPayPeriodsResponse interface.
 */
export function instanceOfListPayPeriodsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListPayPeriodsResponseFromJSON(json: any): ListPayPeriodsResponse {
    return ListPayPeriodsResponseFromJSONTyped(json, false);
}

export function ListPayPeriodsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPayPeriodsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(PayPeriodFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListPayPeriodsResponseToJSON(value?: ListPayPeriodsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(PayPeriodToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

