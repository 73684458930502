
import { Icon } from '@/lib/enum/Icon';
import ActionDialog from './ActionDialog.vue';

export default {
  name: 'UnsavedChangesDialog',

  components: {
    ActionDialog,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Icon,
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
