import { render, staticRenderFns } from "./EmployeeStatusDot.vue?vue&type=template&id=1f9e2c76&scoped=true&"
import script from "./EmployeeStatusDot.vue?vue&type=script&lang=ts&"
export * from "./EmployeeStatusDot.vue?vue&type=script&lang=ts&"
import style0 from "./EmployeeStatusDot.vue?vue&type=style&index=0&id=1f9e2c76&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f9e2c76",
  null
  
)

export default component.exports