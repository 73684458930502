/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { Unavailability } from './Unavailability';
import {
    UnavailabilityFromJSON,
    UnavailabilityFromJSONTyped,
    UnavailabilityToJSON,
} from './Unavailability';

/**
 * 
 * @export
 * @interface ListUnavailabilityResponse
 */
export interface ListUnavailabilityResponse {
    /**
     * 
     * @type {Array<Unavailability>}
     * @memberof ListUnavailabilityResponse
     */
    data: Array<Unavailability>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListUnavailabilityResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListUnavailabilityResponse interface.
 */
export function instanceOfListUnavailabilityResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListUnavailabilityResponseFromJSON(json: any): ListUnavailabilityResponse {
    return ListUnavailabilityResponseFromJSONTyped(json, false);
}

export function ListUnavailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListUnavailabilityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UnavailabilityFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListUnavailabilityResponseToJSON(value?: ListUnavailabilityResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(UnavailabilityToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

