import { urlB64ToUint8Array } from '@/util/stringFunctions';
import { PushSubscription as InternalPushSubscription } from '../../api/v1';

class Push {
  private pushManager: PushManager | null;

  init(pushManager: PushManager) {
    this.pushManager = pushManager;
  }

  async isAvailable(): Promise<Boolean> {
    if (!('PushManager' in window) || !this.pushManager) {
      return false;
    }

    return ['granted', 'prompt'].includes(
      await this.pushManager.permissionState({ userVisibleOnly: true }),
    );
  }

  async subscribe(vapidKey: string): Promise<PushSubscription> {
    return this.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(vapidKey),
    });
  }

  async getBrowserSubscription(): Promise<PushSubscription | null> {
    if (!(await this.isAvailable())) {
      return null;
    }

    return this.pushManager.getSubscription();
  }

  async isSubscribed(
    subscriptions: InternalPushSubscription[],
  ): Promise<Boolean> {
    if (!(await this.isAvailable())) {
      return false;
    }

    const deviceSubscription = await this.getBrowserSubscription();
    return (
      deviceSubscription &&
      subscriptions.map((s) => s.endpoint).includes(deviceSubscription.endpoint)
    );
  }
}

export default new Push();
