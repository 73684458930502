/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateTagData,
  CreateTagResponse,
  ListTagsResponse,
  ShowTagResponse,
  UpdateTagData,
  UpdateTagResponse,
} from '../models/index';
import {
    CreateTagDataFromJSON,
    CreateTagDataToJSON,
    CreateTagResponseFromJSON,
    CreateTagResponseToJSON,
    ListTagsResponseFromJSON,
    ListTagsResponseToJSON,
    ShowTagResponseFromJSON,
    ShowTagResponseToJSON,
    UpdateTagDataFromJSON,
    UpdateTagDataToJSON,
    UpdateTagResponseFromJSON,
    UpdateTagResponseToJSON,
} from '../models/index';

export interface CreateTagRequest {
    createTagData?: CreateTagData;
}

export interface ListTagsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowTagRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateTagRequest {
    id: number;
    _with?: Array<string>;
    updateTagData?: UpdateTagData;
}

/**
 * 
 */
export class TagsApi extends runtime.BaseAPI {

    /**
     * Create a new tag
     * Create Tag
     */
    async createTagRaw(requestParameters: CreateTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTagResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.tag.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTagDataToJSON(requestParameters['createTagData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTagResponseFromJSON(jsonValue));
    }

    /**
     * Create a new tag
     * Create Tag
     */
    async createTag(requestParameters: CreateTagRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTagResponse> {
        const response = await this.createTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all tags
     * List Tags
     */
    async listTagsRaw(requestParameters: ListTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTagsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.tag.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTagsResponseFromJSON(jsonValue));
    }

    /**
     * List all tags
     * List Tags
     */
    async listTags(requestParameters: ListTagsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTagsResponse> {
        const response = await this.listTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a tag
     * Show Tag
     */
    async showTagRaw(requestParameters: ShowTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowTagResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showTag().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.tag.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowTagResponseFromJSON(jsonValue));
    }

    /**
     * Show a tag
     * Show Tag
     */
    async showTag(requestParameters: ShowTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowTagResponse> {
        const response = await this.showTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a tag
     * Update Tag
     */
    async updateTagRaw(requestParameters: UpdateTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateTagResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTag().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.tag.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTagDataToJSON(requestParameters['updateTagData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateTagResponseFromJSON(jsonValue));
    }

    /**
     * Update a tag
     * Update Tag
     */
    async updateTag(requestParameters: UpdateTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateTagResponse> {
        const response = await this.updateTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
