/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchJobLog
 */
export interface BatchJobLog {
    /**
     * 
     * @type {string}
     * @memberof BatchJobLog
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof BatchJobLog
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof BatchJobLog
     */
    type: BatchJobLogTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof BatchJobLog
     */
    extra: object;
    /**
     * 
     * @type {Date}
     * @memberof BatchJobLog
     */
    finishedAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum BatchJobLogTypeEnum {
    ApplyScheduleTemplate = 'ApplyScheduleTemplate',
    BulkClockIn = 'BulkClockIn',
    BulkClockOut = 'BulkClockOut',
    CopyShifts = 'CopyShifts',
    EmployeeImport = 'EmployeeImport',
    Generic = 'Generic',
    PublishShifts = 'PublishShifts',
    RecalculateWorkingTimeLost = 'RecalculateWorkingTimeLost'
}


/**
 * Check if a given object implements the BatchJobLog interface.
 */
export function instanceOfBatchJobLog(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('type' in value)) return false;
    if (!('extra' in value)) return false;
    if (!('finishedAt' in value)) return false;
    return true;
}

export function BatchJobLogFromJSON(json: any): BatchJobLog {
    return BatchJobLogFromJSONTyped(json, false);
}

export function BatchJobLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchJobLog {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'type': json['type'],
        'extra': json['extra'],
        'finishedAt': (new Date(json['finishedAt'])),
    };
}

export function BatchJobLogToJSON(value?: BatchJobLog | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'type': value['type'],
        'extra': value['extra'],
        'finishedAt': ((value['finishedAt']).toISOString()),
    };
}

