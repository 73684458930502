
import InputGroup from '@/components/form/InputGroup.vue';
import { ActionButton, SimpleBubbleIconType } from '@/types/propTypes';
import { PropType } from 'vue';
import Button from '../buttons/Button.vue';
import BubbleIcon from '../interface/BubbleIcon.vue';
import Dialog from './Dialog.vue';

export default {
  name: 'ActionDialog',

  components: {
    InputGroup,
    Button,
    BubbleIcon,
    Dialog,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    bubbleIcon: {
      type: Object as PropType<SimpleBubbleIconType | null>,
      default: null,
    },
    header: {
      type: String,
      required: true,
    },
    bodyText: {
      type: String,
      default: '',
    },
    bodyExtra: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: Number,
      default: 450,
    },
    primaryButton: {
      type: Object as PropType<ActionButton>,
      default: null,
    },
    secondaryAction: {
      type: Object as PropType<ActionButton> | null,
      default: null,
    },
    footer: {
      type: String,
      default: null,
    },
  },
};
