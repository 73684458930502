/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ShowAuthenticatedUserResponse
 */
export interface ShowAuthenticatedUserResponse {
    /**
     * 
     * @type {User}
     * @memberof ShowAuthenticatedUserResponse
     */
    data: User;
}

/**
 * Check if a given object implements the ShowAuthenticatedUserResponse interface.
 */
export function instanceOfShowAuthenticatedUserResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowAuthenticatedUserResponseFromJSON(json: any): ShowAuthenticatedUserResponse {
    return ShowAuthenticatedUserResponseFromJSONTyped(json, false);
}

export function ShowAuthenticatedUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowAuthenticatedUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': UserFromJSON(json['data']),
    };
}

export function ShowAuthenticatedUserResponseToJSON(value?: ShowAuthenticatedUserResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': UserToJSON(value['data']),
    };
}

