export interface BaseEventMap {
  [key: string]: typeof key extends 'type' ? never : Record<string, unknown>;
}

type CustomEventListener<
  T extends BaseEventMap,
  K extends string = keyof T & string,
> = (event: T[K] & { type: K }) => void;

type CustomEventListenerRecord<
  T extends BaseEventMap,
  K extends string = keyof T & string,
> = Map<K, Set<CustomEventListener<T, K>>>;

export type Unsubscribe = () => void;

export class CustomEventEmitter<
  EventMap extends BaseEventMap,
  // Sometimes we need to almost the same types
  // to handle dispatching and listeners
  // because they can't be used the same way for both operations
  ListenersMap extends BaseEventMap = EventMap,
  EventType extends string = keyof EventMap & string,
> {
  #listeners: CustomEventListenerRecord<
    ListenersMap,
    keyof ListenersMap & string
  > = new Map();

  addEventListener<Type extends keyof ListenersMap & string>(
    type: Type,
    listener: CustomEventListener<ListenersMap, Type>,
  ): Unsubscribe {
    const genericListener = listener as CustomEventListener<
      ListenersMap,
      keyof ListenersMap & string
    >;
    if (!this.#listeners.has(type)) this.#listeners.set(type, new Set());
    this.#listeners.get(type).add(genericListener);
    return () => this.removeEventListener(type, genericListener);
  }

  removeEventListener<Type extends keyof ListenersMap & string>(
    type: Type,
    listener: CustomEventListener<ListenersMap, Type>,
  ) {
    if (!this.#listeners.has(type)) return;
    this.#listeners
      .get(type)
      .delete(
        listener as CustomEventListener<
          ListenersMap,
          keyof ListenersMap & string
        >,
      );
  }

  protected _dispatchEvent<
    Type extends EventType,
    Event extends EventMap[Type] = EventMap[Type],
  >(type: Type, event: Event) {
    if (!this.#listeners.has(type)) return;
    this.#listeners.get(type).forEach((listener) =>
      (listener as unknown as CustomEventListener<EventMap, Type>)({
        ...event,
        type,
      }),
    );
  }
}
