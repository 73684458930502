/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanySetting } from './CompanySetting';
import {
    CompanySettingFromJSON,
    CompanySettingFromJSONTyped,
    CompanySettingToJSON,
} from './CompanySetting';

/**
 * 
 * @export
 * @interface ShowCompanySettingsResponse
 */
export interface ShowCompanySettingsResponse {
    /**
     * 
     * @type {CompanySetting}
     * @memberof ShowCompanySettingsResponse
     */
    data: CompanySetting;
}

/**
 * Check if a given object implements the ShowCompanySettingsResponse interface.
 */
export function instanceOfShowCompanySettingsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowCompanySettingsResponseFromJSON(json: any): ShowCompanySettingsResponse {
    return ShowCompanySettingsResponseFromJSONTyped(json, false);
}

export function ShowCompanySettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowCompanySettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanySettingFromJSON(json['data']),
    };
}

export function ShowCompanySettingsResponseToJSON(value?: ShowCompanySettingsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': CompanySettingToJSON(value['data']),
    };
}

