/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveRequest } from './LeaveRequest';
import {
    LeaveRequestFromJSON,
    LeaveRequestFromJSONTyped,
    LeaveRequestToJSON,
} from './LeaveRequest';

/**
 * 
 * @export
 * @interface ApproveLeaveRequestCancellationResponse
 */
export interface ApproveLeaveRequestCancellationResponse {
    /**
     * 
     * @type {LeaveRequest}
     * @memberof ApproveLeaveRequestCancellationResponse
     */
    data?: LeaveRequest;
}

/**
 * Check if a given object implements the ApproveLeaveRequestCancellationResponse interface.
 */
export function instanceOfApproveLeaveRequestCancellationResponse(value: object): boolean {
    return true;
}

export function ApproveLeaveRequestCancellationResponseFromJSON(json: any): ApproveLeaveRequestCancellationResponse {
    return ApproveLeaveRequestCancellationResponseFromJSONTyped(json, false);
}

export function ApproveLeaveRequestCancellationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveLeaveRequestCancellationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : LeaveRequestFromJSON(json['data']),
    };
}

export function ApproveLeaveRequestCancellationResponseToJSON(value?: ApproveLeaveRequestCancellationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeaveRequestToJSON(value['data']),
    };
}

