export interface CancellablePromise<T> extends Promise<T> {
  cancel: () => void;
}

/**
 * Make an existing Promise cancellable
 *
 * Inspiration taken from:
 * @see https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 * @see https://rajeshnaroth.medium.com/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f
 */
export const makeCancellable = <T extends unknown>(
  promise: Promise<T>,
): CancellablePromise<T> => {
  let cancelled = false;

  const wrappedPromise: Promise<T> = new Promise((resolve, reject) => {
    promise
      // We're making a decision here that we won't let calling code know about cancelled promises,
      // i.e. we won't be able to add an explicit catch() in the calling code to handle occasions when a promise
      // is cancelled by some other code. It's fairly unlikely that we would need to do this, but if a case does pop up,
      // then we can change this to: val => cancelled ? reject({ cancelled }) : resolve(val)
      // ...however, that would mean we'd need to add catch() to *all* places where we're dealing with cancellable
      // promises, otherwise an error will be logged. Hence, the reason for not doing this in the first place!
      .then((val) => !cancelled && resolve(val))
      // We're making a decision here that we won't bother letting calling code know about errors that occur in
      // cancelled promises. It's probably going to be the case that we don't want to know about these, but we can
      // always change this if we find a good case for it.
      .catch((error) => !cancelled && reject(error));
  });

  const cancel = () => {
    cancelled = true;
  };

  // Add a cancel method to the promise
  return Object.assign(wrappedPromise, { cancel });
};
