/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveType } from './LeaveType';
import {
    LeaveTypeFromJSON,
    LeaveTypeFromJSONTyped,
    LeaveTypeToJSON,
} from './LeaveType';

/**
 * 
 * @export
 * @interface ShowLeaveTypeResponse
 */
export interface ShowLeaveTypeResponse {
    /**
     * 
     * @type {LeaveType}
     * @memberof ShowLeaveTypeResponse
     */
    data?: LeaveType;
}

/**
 * Check if a given object implements the ShowLeaveTypeResponse interface.
 */
export function instanceOfShowLeaveTypeResponse(value: object): boolean {
    return true;
}

export function ShowLeaveTypeResponseFromJSON(json: any): ShowLeaveTypeResponse {
    return ShowLeaveTypeResponseFromJSONTyped(json, false);
}

export function ShowLeaveTypeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowLeaveTypeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : LeaveTypeFromJSON(json['data']),
    };
}

export function ShowLeaveTypeResponseToJSON(value?: ShowLeaveTypeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': LeaveTypeToJSON(value['data']),
    };
}

