/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAuthenticatedUserData
 */
export interface UpdateAuthenticatedUserData {
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthenticatedUserData
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthenticatedUserData
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthenticatedUserData
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAuthenticatedUserData
     */
    cookiesAccepted?: boolean;
}

/**
 * Check if a given object implements the UpdateAuthenticatedUserData interface.
 */
export function instanceOfUpdateAuthenticatedUserData(value: object): boolean {
    return true;
}

export function UpdateAuthenticatedUserDataFromJSON(json: any): UpdateAuthenticatedUserData {
    return UpdateAuthenticatedUserDataFromJSONTyped(json, false);
}

export function UpdateAuthenticatedUserDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAuthenticatedUserData {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'cookiesAccepted': json['cookiesAccepted'] == null ? undefined : json['cookiesAccepted'],
    };
}

export function UpdateAuthenticatedUserDataToJSON(value?: UpdateAuthenticatedUserData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'cookiesAccepted': value['cookiesAccepted'],
    };
}

