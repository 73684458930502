/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
} from './Notification';

/**
 * 
 * @export
 * @interface ShowNotificationResponse
 */
export interface ShowNotificationResponse {
    /**
     * 
     * @type {Notification}
     * @memberof ShowNotificationResponse
     */
    data?: Notification;
}

/**
 * Check if a given object implements the ShowNotificationResponse interface.
 */
export function instanceOfShowNotificationResponse(value: object): boolean {
    return true;
}

export function ShowNotificationResponseFromJSON(json: any): ShowNotificationResponse {
    return ShowNotificationResponseFromJSONTyped(json, false);
}

export function ShowNotificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowNotificationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : NotificationFromJSON(json['data']),
    };
}

export function ShowNotificationResponseToJSON(value?: ShowNotificationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': NotificationToJSON(value['data']),
    };
}

