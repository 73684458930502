/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Credit
 */
export interface Credit {
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    type: CreditTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Credit
     */
    currentValue: number;
    /**
     * 
     * @type {number}
     * @memberof Credit
     */
    renewalValue: number;
    /**
     * 
     * @type {boolean}
     * @memberof Credit
     */
    renewalsEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    status: CreditStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Credit
     */
    lowBalanceWarningThreshold: number;
    /**
     * 
     * @type {number}
     * @memberof Credit
     */
    triggerRenewalThreshold: number;
}

/**
* @export
* @enum {string}
*/
export enum CreditTypeEnum {
    Referral = 'Referral',
    Sms = 'SMS'
}
/**
* @export
* @enum {string}
*/
export enum CreditStatusEnum {
    Ok = 'OK',
    Arrears = 'Arrears'
}


/**
 * Check if a given object implements the Credit interface.
 */
export function instanceOfCredit(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('currentValue' in value)) return false;
    if (!('renewalValue' in value)) return false;
    if (!('renewalsEnabled' in value)) return false;
    if (!('status' in value)) return false;
    if (!('lowBalanceWarningThreshold' in value)) return false;
    if (!('triggerRenewalThreshold' in value)) return false;
    return true;
}

export function CreditFromJSON(json: any): Credit {
    return CreditFromJSONTyped(json, false);
}

export function CreditFromJSONTyped(json: any, ignoreDiscriminator: boolean): Credit {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'currentValue': json['currentValue'],
        'renewalValue': json['renewalValue'],
        'renewalsEnabled': json['renewalsEnabled'],
        'status': json['status'],
        'lowBalanceWarningThreshold': json['lowBalanceWarningThreshold'],
        'triggerRenewalThreshold': json['triggerRenewalThreshold'],
    };
}

export function CreditToJSON(value?: Credit | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'currentValue': value['currentValue'],
        'renewalValue': value['renewalValue'],
        'renewalsEnabled': value['renewalsEnabled'],
        'status': value['status'],
        'lowBalanceWarningThreshold': value['lowBalanceWarningThreshold'],
        'triggerRenewalThreshold': value['triggerRenewalThreshold'],
    };
}

