/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeAttribute
 */
export interface EmployeeAttribute {
    /**
     * 
     * @type {number}
     * @memberof EmployeeAttribute
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeAttribute
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeAttribute
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeAttribute
     */
    status: EmployeeAttributeStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof EmployeeAttribute
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmployeeAttribute
     */
    updatedAt: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum EmployeeAttributeStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the EmployeeAttribute interface.
 */
export function instanceOfEmployeeAttribute(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('status' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function EmployeeAttributeFromJSON(json: any): EmployeeAttribute {
    return EmployeeAttributeFromJSONTyped(json, false);
}

export function EmployeeAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeAttribute {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

export function EmployeeAttributeToJSON(value?: EmployeeAttribute | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'status': value['status'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

