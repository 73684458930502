/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessToken } from './AccessToken';
import {
    AccessTokenFromJSON,
    AccessTokenFromJSONTyped,
    AccessTokenToJSON,
} from './AccessToken';

/**
 * 
 * @export
 * @interface ClockInPinLoginResponse
 */
export interface ClockInPinLoginResponse {
    /**
     * 
     * @type {AccessToken}
     * @memberof ClockInPinLoginResponse
     */
    data: AccessToken;
}

/**
 * Check if a given object implements the ClockInPinLoginResponse interface.
 */
export function instanceOfClockInPinLoginResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ClockInPinLoginResponseFromJSON(json: any): ClockInPinLoginResponse {
    return ClockInPinLoginResponseFromJSONTyped(json, false);
}

export function ClockInPinLoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockInPinLoginResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': AccessTokenFromJSON(json['data']),
    };
}

export function ClockInPinLoginResponseToJSON(value?: ClockInPinLoginResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': AccessTokenToJSON(value['data']),
    };
}

