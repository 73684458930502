/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';

/**
 * Represents an adjustment made to an employees leave allowance for a given period.
 * @export
 * @interface LeaveAdjustment
 */
export interface LeaveAdjustment {
    /**
     * 
     * @type {number}
     * @memberof LeaveAdjustment
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveAdjustment
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveAdjustment
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveAdjustment
     */
    leavePeriodId: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveAdjustment
     */
    leaveTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveAdjustment
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveAdjustment
     */
    adjusterId: number;
    /**
     * 
     * @type {string}
     * @memberof LeaveAdjustment
     */
    reason: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LeaveAdjustment
     */
    createdAt: Date;
    /**
     * 
     * @type {Employee}
     * @memberof LeaveAdjustment
     */
    employee?: Employee;
    /**
     * 
     * @type {Employee}
     * @memberof LeaveAdjustment
     */
    adjuster?: Employee;
}

/**
 * Check if a given object implements the LeaveAdjustment interface.
 */
export function instanceOfLeaveAdjustment(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('leavePeriodId' in value)) return false;
    if (!('leaveTypeId' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('adjusterId' in value)) return false;
    if (!('reason' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function LeaveAdjustmentFromJSON(json: any): LeaveAdjustment {
    return LeaveAdjustmentFromJSONTyped(json, false);
}

export function LeaveAdjustmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveAdjustment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'leavePeriodId': json['leavePeriodId'],
        'leaveTypeId': json['leaveTypeId'],
        'amount': json['amount'],
        'adjusterId': json['adjusterId'],
        'reason': json['reason'],
        'createdAt': (new Date(json['createdAt'])),
        'employee': json['employee'] == null ? undefined : EmployeeFromJSON(json['employee']),
        'adjuster': json['adjuster'] == null ? undefined : EmployeeFromJSON(json['adjuster']),
    };
}

export function LeaveAdjustmentToJSON(value?: LeaveAdjustment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'leavePeriodId': value['leavePeriodId'],
        'leaveTypeId': value['leaveTypeId'],
        'amount': value['amount'],
        'adjusterId': value['adjusterId'],
        'reason': value['reason'],
        'createdAt': ((value['createdAt']).toISOString()),
        'employee': EmployeeToJSON(value['employee']),
        'adjuster': EmployeeToJSON(value['adjuster']),
    };
}

