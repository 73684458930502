/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Creates an emergency contact using the data provided.
 * @export
 * @interface CreateEmergencyContactData
 */
export interface CreateEmergencyContactData {
    /**
     * 
     * @type {number}
     * @memberof CreateEmergencyContactData
     */
    employeeId: number;
    /**
     * The emergency contact's name.
     * @type {string}
     * @memberof CreateEmergencyContactData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmergencyContactData
     */
    relationship?: CreateEmergencyContactDataRelationshipEnum;
    /**
     * The contact telephone number of the emergency contact.
     * @type {string}
     * @memberof CreateEmergencyContactData
     */
    telephoneNumber: string | null;
    /**
     * The contact mobile number of the emergency contact.
     * @type {string}
     * @memberof CreateEmergencyContactData
     */
    mobileNumber: string | null;
    /**
     * A flag to designate the contact as the default emergency contact.
     * @type {boolean}
     * @memberof CreateEmergencyContactData
     */
    isDefault?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CreateEmergencyContactDataRelationshipEnum {
    Spouse = 'Spouse',
    Partner = 'Partner',
    Relative = 'Relative',
    Parent = 'Parent',
    Sibling = 'Sibling',
    Other = 'Other'
}


/**
 * Check if a given object implements the CreateEmergencyContactData interface.
 */
export function instanceOfCreateEmergencyContactData(value: object): boolean {
    if (!('employeeId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('telephoneNumber' in value)) return false;
    if (!('mobileNumber' in value)) return false;
    return true;
}

export function CreateEmergencyContactDataFromJSON(json: any): CreateEmergencyContactData {
    return CreateEmergencyContactDataFromJSONTyped(json, false);
}

export function CreateEmergencyContactDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmergencyContactData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'name': json['name'],
        'relationship': json['relationship'] == null ? undefined : json['relationship'],
        'telephoneNumber': json['telephoneNumber'],
        'mobileNumber': json['mobileNumber'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
    };
}

export function CreateEmergencyContactDataToJSON(value?: CreateEmergencyContactData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employeeId': value['employeeId'],
        'name': value['name'],
        'relationship': value['relationship'],
        'telephoneNumber': value['telephoneNumber'],
        'mobileNumber': value['mobileNumber'],
        'isDefault': value['isDefault'],
    };
}

