import { isTruthy } from '@/lib/helpers/booleans';

declare global {
  interface Window {
    clarity: any;
  }
}

export const clarityInitialised = () => typeof window.clarity !== 'undefined';

/**
 * This is an extracted version of the script Clarity provides to enable tracking
 * for projects. The original script looks like this:
 *
 *    <script type="text/javascript">
 *        (function(c,l,a,r,i,t,y){
 *            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
 *            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
 *            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
 *        })(window, document, "clarity", "script", "${clarityProjectId}");
 *    </script>
 *
 * Our version allows setting a flag to disable Clarity before any JS is ever loaded,
 * which is particularly useful for local / development environments.
 */
export const initialiseClarity = () => {
  if (!isTruthy(process.env.VUE_APP_CLARITY_ENABLED) || clarityInitialised()) {
    return;
  }

  const cScript = document.createElement('script');
  cScript.src = `https://www.clarity.ms/tag/${process.env.VUE_APP_CLARITY_PROJECT_ID}`;
  cScript.type = 'text/javascript';
  cScript.async = true;
  document.head.appendChild(cScript);

  const clarityFn = (...args) => {
    (window.clarity.q = window.clarity.q || []).push(args);
  };

  window.clarity = window.clarity || clarityFn;
};
