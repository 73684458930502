/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateScheduleData,
  CreateScheduleResponse,
  ListSchedulesResponse,
  ShowScheduleResponse,
  UpdateScheduleData,
  UpdateScheduleResponse,
} from '../models/index';
import {
    CreateScheduleDataFromJSON,
    CreateScheduleDataToJSON,
    CreateScheduleResponseFromJSON,
    CreateScheduleResponseToJSON,
    ListSchedulesResponseFromJSON,
    ListSchedulesResponseToJSON,
    ShowScheduleResponseFromJSON,
    ShowScheduleResponseToJSON,
    UpdateScheduleDataFromJSON,
    UpdateScheduleDataToJSON,
    UpdateScheduleResponseFromJSON,
    UpdateScheduleResponseToJSON,
} from '../models/index';

export interface CreateScheduleRequest {
    _with?: Array<string>;
    createScheduleData?: CreateScheduleData;
}

export interface ListSchedulesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowScheduleRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateScheduleRequest {
    id: number;
    _with?: Array<string>;
    updateScheduleData?: UpdateScheduleData;
}

/**
 * 
 */
export class ScheduleApi extends runtime.BaseAPI {

    /**
     * Create a new schedule
     * Create Schedule
     */
    async createScheduleRaw(requestParameters: CreateScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateScheduleResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateScheduleDataToJSON(requestParameters['createScheduleData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateScheduleResponseFromJSON(jsonValue));
    }

    /**
     * Create a new schedule
     * Create Schedule
     */
    async createSchedule(requestParameters: CreateScheduleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateScheduleResponse> {
        const response = await this.createScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List or filter all schedules
     * List Schedules
     */
    async listSchedulesRaw(requestParameters: ListSchedulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListSchedulesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListSchedulesResponseFromJSON(jsonValue));
    }

    /**
     * List or filter all schedules
     * List Schedules
     */
    async listSchedules(requestParameters: ListSchedulesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListSchedulesResponse> {
        const response = await this.listSchedulesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details of a specific schedule
     * Show Schedule
     */
    async showScheduleRaw(requestParameters: ShowScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowScheduleResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showSchedule().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowScheduleResponseFromJSON(jsonValue));
    }

    /**
     * Show details of a specific schedule
     * Show Schedule
     */
    async showSchedule(requestParameters: ShowScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowScheduleResponse> {
        const response = await this.showScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the details of an existing schedule
     * Update Schedule
     */
    async updateScheduleRaw(requestParameters: UpdateScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateScheduleResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateSchedule().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateScheduleDataToJSON(requestParameters['updateScheduleData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateScheduleResponseFromJSON(jsonValue));
    }

    /**
     * Update the details of an existing schedule
     * Update Schedule
     */
    async updateSchedule(requestParameters: UpdateScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateScheduleResponse> {
        const response = await this.updateScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
