/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTagData
 */
export interface UpdateTagData {
    /**
     * 
     * @type {string}
     * @memberof UpdateTagData
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTagData
     */
    visibleToEmployees?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagData
     */
    status?: UpdateTagDataStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateTagDataStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the UpdateTagData interface.
 */
export function instanceOfUpdateTagData(value: object): boolean {
    return true;
}

export function UpdateTagDataFromJSON(json: any): UpdateTagData {
    return UpdateTagDataFromJSONTyped(json, false);
}

export function UpdateTagDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTagData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'visibleToEmployees': json['visibleToEmployees'] == null ? undefined : json['visibleToEmployees'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function UpdateTagDataToJSON(value?: UpdateTagData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'visibleToEmployees': value['visibleToEmployees'],
        'status': value['status'],
    };
}

