/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
} from './Shift';

/**
 * 
 * @export
 * @interface UpdateShiftResponse
 */
export interface UpdateShiftResponse {
    /**
     * 
     * @type {Shift}
     * @memberof UpdateShiftResponse
     */
    data?: Shift;
}

/**
 * Check if a given object implements the UpdateShiftResponse interface.
 */
export function instanceOfUpdateShiftResponse(value: object): boolean {
    return true;
}

export function UpdateShiftResponseFromJSON(json: any): UpdateShiftResponse {
    return UpdateShiftResponseFromJSONTyped(json, false);
}

export function UpdateShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShiftResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftFromJSON(json['data']),
    };
}

export function UpdateShiftResponseToJSON(value?: UpdateShiftResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftToJSON(value['data']),
    };
}

