// Element as a css selector, e.g. '#mobile-nav'
export const hideFixedElement = (element: string) => {
  const html = document.querySelector(element);
  if (html) {
    html.classList.add('d-none');
  }
};

// Element as a css selector, e.g. '#mobile-nav'
export const showFixedElement = (element: string) => {
  const html = document.querySelector(element);
  if (html) {
    html.classList.remove('d-none');
  }
};
