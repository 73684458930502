
import InputGroup from '@/components/form/InputGroup.vue';
import IconMessageBlock from '@/components/info/IconMessageBlock.vue';
import InlineDialog from '@/components/info/InlineDialog.vue';
import MapPlaceholder from '@/components/map/MapPlaceholder.vue';
import { InlineDialogTheme } from '@/lib/enum/Colour';
import { Icon } from '@/lib/enum/Icon';
import {
  addMarker,
  addSearch,
  boundLatitude,
  boundLongitude,
  Coordinates,
  createGeoJSONCircle,
  distanceInMetersToLatitudeDegrees,
  KM_PER_DEGREE,
  mapInit,
} from '@/plugins/mapbox';
import mapboxgl, { AnySourceData, Map, Marker } from 'mapbox-gl';
import { PropType } from 'vue';

type LocationMarkerDragEndEvent = Event & {
  target: {
    _lngLat: {
      lng: number;
      lat: number;
    };
  };
};

export default {
  name: 'Map',

  components: { IconMessageBlock, InlineDialog, InputGroup, MapPlaceholder },

  props: {
    containerId: {
      type: String,
      required: true,
    },

    addressRequired: {
      type: Boolean,
      default: false,
    },

    addressLabel: {
      type: String,
      default: '',
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    marker: {
      type: Object as PropType<{
        coordinates?: Coordinates;
        options?: {
          draggable?: boolean;
          color?: string;
          usePin?: boolean;
        };
      } | null>,
      default: () => ({}),
    },

    userCoordinates: {
      type: Array as PropType<Coordinates | null>,
      default: null,
    },

    radiusInMeters: {
      type: Number,
      default: 0,
    },

    addressUpdated: {
      type: Function as PropType<(string) => void>,
      default: null,
    },

    locationUpdated: {
      type: Function as PropType<(Coordinates?) => void>,
      default: null,
    },
  },

  data() {
    return {
      InlineDialogTheme,
      initialised: false,
      searchInitialised: false,
      loading: true,
      locationMarker: null as Marker,
      map: null as Map,
      addSearchTimeout: null as Number,
      Icon,
    };
  },

  computed: {
    metersBetweenMarkerAndUser() {
      if (!this.userCoordinates || !this.marker.coordinates) {
        return;
      }
      // eslint-disable-next-line global-require
      const haversine = require('haversine');

      return Math.round(
        haversine(
          {
            longitude: this.marker.coordinates[0],
            latitude: this.marker.coordinates[1],
          },
          {
            latitude: this.userCoordinates[1],
            longitude: this.userCoordinates[0],
          },
          { unit: 'meter' },
        ),
      );
    },
  },

  watch: {
    radiusInMeters(newRadius: number) {
      if (this.map && this.initialised && this.map.loaded()) {
        this.updateMapRadius(newRadius, this.marker.coordinates);
        if (newRadius) {
          this.fitBoundingBox(true);
        }
      }
    },
    marker: {
      deep: true,
      handler() {
        if (this.map && this.initialised && this.map.loaded()) {
          this.updateMapRadius(this.radiusInMeters, this.marker.coordinates);
          if (this.marker.coordinates && this.userCoordinates) {
            this.fitBoundingBox();
          }
        }
      },
    },
    userCoordinates: {
      deep: true,
      handler() {
        if (this.map && this.initialised && this.map.loaded()) {
          this.addUserPosition();
          if (this.marker.coordinates && this.userCoordinates) {
            this.fitBoundingBox();
          }
        }
      },
    },
  },

  mounted() {
    this.$nextTick(this.initMap);
  },

  beforeDestroy() {
    clearTimeout(this.addSearchTimeout);
    this.map.remove();
  },

  methods: {
    initMap() {
      const map = document.getElementById(this.containerId);
      if (!map) {
        setTimeout(this.initMap, 1500);
        return;
      }

      // If the map already exists, clear it and reinitialise using the new options
      this.map?.remove();
      this.map = mapInit(
        this.containerId,
        this.marker.coordinates ?? [-5, 55],
        3,
      );

      this.map.on('load', () => {
        if (this.marker.coordinates) {
          this.addLocationMarker();
        }

        if (this.userCoordinates) {
          this.addUserPosition();
        }

        if (this.marker.coordinates) {
          if (this.userCoordinates) {
            this.fitBoundingBox();
          } else if (!this.userCoordinates && this.radiusInMeters) {
            this.fitBoundingBox(true);
          }
        }

        if (this.searchable) {
          this.addSearchBar();
        }

        const mapPlaceholder = document.getElementById(
          `${this.containerId}Loading`,
        );
        if (mapPlaceholder) mapPlaceholder.style.display = 'none';
      });

      this.$nextTick(() => {
        this.initialised = true;
      });
    },

    addLocationMarker() {
      let markerPin;
      if (this.marker.options?.usePin) {
        markerPin = document.createElement('div');
        // eslint-disable-next-line global-require
        markerPin.style.backgroundImage = `url(${require('@/assets/shift-location-pin.png')})`;
        markerPin.style.backgroundSize = 'contain';
        markerPin.style.backgroundPosition = 'center';
        markerPin.style.width = '18px';
        markerPin.style.height = '30px';
      }
      this.locationMarker = addMarker(this.map, this.marker.coordinates, {
        ...(this.marker.options && this.marker.options),
        ...(markerPin && { anchor: 'bottom', element: markerPin }),
      });
      // Zoom in on the marker
      this.map.setZoom(15);

      if (this.marker.options?.draggable) {
        this.locationMarker.on('dragend', this.dragEndHandler);
      }

      if (this.radiusInMeters) {
        this.addMarkerRadius();
      }
    },

    addMarkerRadius(coords: Coordinates) {
      const coordinates = coords ?? this.marker.coordinates;
      if (!coordinates || !this.radiusInMeters) {
        throw Error('No marker or radius provided');
      }
      this.map.addSource(
        'radius',
        createGeoJSONCircle(coordinates, this.radiusInMeters) as AnySourceData,
      );
      this.map.addLayer({
        id: 'radius',
        type: 'fill',
        source: 'radius',
        paint: {
          'fill-opacity': 0.3,
          'fill-color': '#04a7f7',
        },
      });
      this.map.addLayer({
        id: 'radius-outline',
        type: 'line',
        source: 'radius',
        paint: {
          'line-color': '#04a7f7',
          'line-width': 3,
        },
      });
    },

    addUserPosition() {
      if (this.map.hasImage('user') && this.map.getSource('user-icon')) {
        this.map.getSource('user-icon').setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: this.userCoordinates,
              },
            },
          ],
        });
        return;
      }
      this.map.loadImage(
        // eslint-disable-next-line global-require
        require('@/assets/user-marker.png'),
        (error, image) => {
          if (error) throw error;
          this.map.addImage('user', image);
          this.map.addSource('user-icon', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: this.userCoordinates,
                  },
                },
              ],
            },
          } as AnySourceData);
          this.map.addLayer({
            id: 'user-icon',
            type: 'symbol',
            source: 'user-icon',
            layout: {
              'icon-image': 'user',
              'icon-size': 0.1,
              'icon-anchor': 'bottom',
            },
          });
        },
      );
    },

    updateMapRadius(radiusInMeters: number, center: Coordinates) {
      if (!radiusInMeters || !center?.length) {
        if (this.map.getLayer('radius')) {
          this.removeRadius();
        }
        return;
      }
      if (!this.map.getSource('radius')) {
        // prevent race condition when marker.coordinates had not been set into state while we already got geocoding result
        return this.addMarkerRadius(center);
      }
      this.map
        .getSource('radius')
        .setData(createGeoJSONCircle(center, radiusInMeters).data);
    },

    fitBoundingBox(fitRadius: boolean = false) {
      if (fitRadius) {
        const [lng, lat] = this.marker.coordinates;
        const radiusInLatitudeDegrees = distanceInMetersToLatitudeDegrees(
          this.radiusInMeters,
        );
        // we only care about North and South extremums because maps are usually wide enough
        this.map.fitBounds([
          [lng, lat - radiusInLatitudeDegrees], // southernmost point of radius
          [lng, lat + radiusInLatitudeDegrees], // northernmost point of radius
        ]);
        return;
      }
      if (!this.marker.coordinates || !this.userCoordinates) {
        return;
      }
      const distanceInDegrees =
        (this.radiusInMeters + this.metersBetweenMarkerAndUser / 3) /
        1000 /
        KM_PER_DEGREE;

      const minimumLongitude = Math.min(
        this.marker.coordinates[0],
        this.userCoordinates[0],
      );
      const maximumLongitude = Math.max(
        this.marker.coordinates[0],
        this.userCoordinates[0],
      );
      const east = boundLongitude(
        minimumLongitude -
          distanceInDegrees / Math.cos(minimumLongitude * (Math.PI / 180)),
      );
      const west = boundLongitude(
        maximumLongitude +
          distanceInDegrees / Math.cos(minimumLongitude * (Math.PI / 180)),
      );
      const south = boundLatitude(
        Math.min(this.marker.coordinates[1], this.userCoordinates[1]) -
          distanceInDegrees,
      );
      const north = boundLatitude(
        Math.max(this.marker.coordinates[1], this.userCoordinates[1]) +
          distanceInDegrees,
      );

      this.map.fitBounds([
        [west, south],
        [east, north],
      ]);
    },

    async addSearchBar() {
      this.addSearchTimeout = setTimeout(() => {
        const geocoder = addSearch(
          'search',
          this.map,
          this.$t('placeholder.locationAddress'),
        );

        geocoder.on('clear', () => {
          if (this.locationMarker) this.locationMarker.remove();
          if (this.map.getLayer('radius')) {
            this.removeRadius();
          }
          this.locationUpdatedHandler(undefined);
        });

        geocoder.on('result', (ev) => {
          // Remove current marker
          if (this.locationMarker) {
            this.locationMarker.remove();
          }

          this.locationMarker = new mapboxgl.Marker(this.marker.options ?? {})
            .setLngLat(ev.result.center)
            .addTo(this.map);

          if (this.addressUpdated) {
            this.addressUpdated(ev.result.place_name);
          }
          this.locationUpdatedHandler(ev.result.geometry.coordinates);
          this.updateMapRadius(
            this.radiusInMeters,
            ev.result.geometry.coordinates,
          );
          if (this.marker.options?.draggable) {
            this.locationMarker.on('dragend', this.dragEndHandler);
          }
        });

        this.searchInitialised = true;
      }, 500);
    },

    dragEndHandler(e: LocationMarkerDragEndEvent) {
      this.locationUpdatedHandler([e.target._lngLat.lng, e.target._lngLat.lat]);
    },

    locationUpdatedHandler(location?: Coordinates) {
      if (this.locationUpdated) {
        this.locationUpdated(location);
      }
    },

    // use when you sure that 'radius' layer exists
    removeRadius() {
      this.map.removeLayer('radius');
      this.map.removeLayer('radius-outline');
      if (this.map.getSource('radius')) {
        this.map.removeSource('radius');
      }
    },
  },
};
