/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListAdditionalScopesResponse
 */
export interface ListAdditionalScopesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAdditionalScopesResponse
     */
    data: Array<ListAdditionalScopesResponseDataEnum>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListAdditionalScopesResponse
     */
    pagination: Pagination;
}

/**
* @export
* @enum {string}
*/
export enum ListAdditionalScopesResponseDataEnum {
    ApiAuthAdditionalNotConfigured = 'api.auth.additional.not-configured'
}


/**
 * Check if a given object implements the ListAdditionalScopesResponse interface.
 */
export function instanceOfListAdditionalScopesResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListAdditionalScopesResponseFromJSON(json: any): ListAdditionalScopesResponse {
    return ListAdditionalScopesResponseFromJSONTyped(json, false);
}

export function ListAdditionalScopesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAdditionalScopesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListAdditionalScopesResponseToJSON(value?: ListAdditionalScopesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'pagination': PaginationToJSON(value['pagination']),
    };
}

