/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateJobRoleData
 */
export interface UpdateJobRoleData {
    /**
     * 
     * @type {string}
     * @memberof UpdateJobRoleData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateJobRoleData
     */
    status?: UpdateJobRoleDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateJobRoleData
     */
    colour?: string;
    /**
     * A list of location IDs where this job role is relevant. An empty array means that it applies to all locations.
     * @type {Array<number>}
     * @memberof UpdateJobRoleData
     */
    locations?: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum UpdateJobRoleDataStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the UpdateJobRoleData interface.
 */
export function instanceOfUpdateJobRoleData(value: object): boolean {
    return true;
}

export function UpdateJobRoleDataFromJSON(json: any): UpdateJobRoleData {
    return UpdateJobRoleDataFromJSONTyped(json, false);
}

export function UpdateJobRoleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateJobRoleData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'status': json['status'] == null ? undefined : json['status'],
        'colour': json['colour'] == null ? undefined : json['colour'],
        'locations': json['locations'] == null ? undefined : json['locations'],
    };
}

export function UpdateJobRoleDataToJSON(value?: UpdateJobRoleData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'status': value['status'],
        'colour': value['colour'],
        'locations': value['locations'],
    };
}

