/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListHolidayRegionsResponse,
  ListPublicHolidaysResponse,
} from '../models/index';
import {
    ListHolidayRegionsResponseFromJSON,
    ListHolidayRegionsResponseToJSON,
    ListPublicHolidaysResponseFromJSON,
    ListPublicHolidaysResponseToJSON,
} from '../models/index';

export interface ListHolidayRegionsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

export interface ListPublicHolidaysRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

/**
 * 
 */
export class PublicHolidayApi extends runtime.BaseAPI {

    /**
     * Show all available holiday regions for which we have national/public holiday data
     * List Holiday Regions
     */
    async listHolidayRegionsRaw(requestParameters: ListHolidayRegionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListHolidayRegionsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.holiday-region.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/holiday-regions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListHolidayRegionsResponseFromJSON(jsonValue));
    }

    /**
     * Show all available holiday regions for which we have national/public holiday data
     * List Holiday Regions
     */
    async listHolidayRegions(requestParameters: ListHolidayRegionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListHolidayRegionsResponse> {
        const response = await this.listHolidayRegionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show all national/public holidays for a given region
     * List Public Holidays
     */
    async listPublicHolidaysRaw(requestParameters: ListPublicHolidaysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPublicHolidaysResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.public-holiday.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public-holidays`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPublicHolidaysResponseFromJSON(jsonValue));
    }

    /**
     * Show all national/public holidays for a given region
     * List Public Holidays
     */
    async listPublicHolidays(requestParameters: ListPublicHolidaysRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPublicHolidaysResponse> {
        const response = await this.listPublicHolidaysRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
