/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Relationships
 */
export interface Relationships {
    /**
     * 
     * @type {Array<number>}
     * @memberof Relationships
     */
    relationIds: Array<number>;
    /**
     * Determines how provided values are handled in relation to the entity's existing values.
     * @type {string}
     * @memberof Relationships
     */
    method: RelationshipsMethodEnum;
}

/**
* @export
* @enum {string}
*/
export enum RelationshipsMethodEnum {
    Add = 'add',
    Remove = 'remove',
    Overwrite = 'overwrite'
}


/**
 * Check if a given object implements the Relationships interface.
 */
export function instanceOfRelationships(value: object): boolean {
    if (!('relationIds' in value)) return false;
    if (!('method' in value)) return false;
    return true;
}

export function RelationshipsFromJSON(json: any): Relationships {
    return RelationshipsFromJSONTyped(json, false);
}

export function RelationshipsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Relationships {
    if (json == null) {
        return json;
    }
    return {
        
        'relationIds': json['relationIds'],
        'method': json['method'],
    };
}

export function RelationshipsToJSON(value?: Relationships | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'relationIds': value['relationIds'],
        'method': value['method'],
    };
}

