
import ToolTipWrapper from '@/components/info/ToolTipWrapper.vue';
import { dateTimeFormats } from '@/lang/dateTimeFormats';
import { Icon } from '@/lib/enum/Icon';
import { Now } from '@/lib/helpers/now';
import { userCan } from '@/lib/permission/userCan';
import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import { getTimezoneOffset, normaliseTimezoneName } from '@/util/dateFunctions';
import { presentTimezoneOffset } from '@/util/presenters';
import spacetime from 'spacetime';

export default {
  name: 'CompanyTime',
  components: { ToolTipWrapper },
  data() {
    return {
      Icon,
      routes,
      userCan,
      now: new Now(),
    };
  },
  computed: {
    companyTimezone: (): string =>
      normaliseTimezoneName(store.getters.timezone),
    localTimezone: (): string =>
      normaliseTimezoneName(Intl.DateTimeFormat().resolvedOptions().timeZone),
    companyTimezoneOffset(): number {
      return getTimezoneOffset(this.now.time, this.companyTimezone);
    },
    companyTime(): string {
      return this.$d(
        spacetime(this.now.time, this.companyTimezone).toNativeDate(),
        dateTimeFormats.hourMinute,
      );
    },
  },
  mounted() {
    this.now.init();
  },
  beforeDestroy() {
    this.now.destroy();
  },
  methods: {
    redirect,
    getTimezoneOffset,
    presentTimezoneOffset,
  },
};
