/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PendingEmail } from './PendingEmail';
import {
    PendingEmailFromJSON,
    PendingEmailFromJSONTyped,
    PendingEmailToJSON,
} from './PendingEmail';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    emailVerifiedAt: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    cookiesAccepted: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    cookiesReviewedAt: Date | null;
    /**
     * A flag to control whether the user has access to the developer settings. This is purely used for UI purposes.
     * @type {boolean}
     * @memberof User
     */
    developerSettings: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {PendingEmail}
     * @memberof User
     */
    pendingEmail: PendingEmail;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('lastName' in value)) return false;
    if (!('email' in value)) return false;
    if (!('emailVerifiedAt' in value)) return false;
    if (!('cookiesAccepted' in value)) return false;
    if (!('cookiesReviewedAt' in value)) return false;
    if (!('developerSettings' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('pendingEmail' in value)) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'emailVerifiedAt': (json['emailVerifiedAt'] == null ? null : new Date(json['emailVerifiedAt'])),
        'cookiesAccepted': json['cookiesAccepted'],
        'cookiesReviewedAt': (json['cookiesReviewedAt'] == null ? null : new Date(json['cookiesReviewedAt'])),
        'developerSettings': json['developerSettings'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'pendingEmail': PendingEmailFromJSON(json['pendingEmail']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'emailVerifiedAt': (value['emailVerifiedAt'] == null ? null : (value['emailVerifiedAt'] as any).toISOString()),
        'cookiesAccepted': value['cookiesAccepted'],
        'cookiesReviewedAt': (value['cookiesReviewedAt'] == null ? null : (value['cookiesReviewedAt'] as any).toISOString()),
        'developerSettings': value['developerSettings'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'pendingEmail': PendingEmailToJSON(value['pendingEmail']),
    };
}

