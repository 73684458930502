import {
  GetSingleEntityParameterType,
  realtimeEntities,
  SingleEntityInterfaceMap,
  SingleEntityName,
} from '@/lib/store/realtimeEntities';
import { Commit } from 'vuex';

// This keeps track of which entities have already been loaded. Once a name is in here, then it means it should never be
// fetched from the API again - only ever updated via websocket messages.
let promises = {} as Record<
  SingleEntityName | `${SingleEntityName}-${string}`,
  Promise<any>
>;

let commit: Commit = null;

export const setCommit = (c: Commit) => {
  commit = c;
};

const loadEntity = async <T extends SingleEntityName>(
  entityName: T,
  params?: GetSingleEntityParameterType<T>,
): Promise<SingleEntityInterfaceMap[T]> => {
  const entityNameWithParams = params
    ? `${entityName}-${JSON.stringify(params)}`
    : entityName;

  if (!promises[entityNameWithParams]) {
    promises[entityNameWithParams] =
      realtimeEntities[entityName].fetcher(params);
    const results = await promises[entityNameWithParams];
    // This relies on external code having set a reference to the store's commit function inside this module
    // as Vuex doesn't provide any easy access to the commit function from outside a component or store.
    // Yes, it's not ideal, but it seems to be the only way to get Vuex dev tools showing the correct values
    // presumably because it watches for mutations making the changes to the state.
    commit('entities/SET_COLLECTION', {
      entityName: entityNameWithParams,
      entities: [results],
    });
  }

  return promises[entityNameWithParams] as Promise<SingleEntityInterfaceMap[T]>;
};

/**
 * This is a store organised in a generic fashion that can hold multiple entities. The idea is that each entity in this
 * store works on real-time updates via websocket messages, so data is only fetched from the API when it is first
 * required; thereafter, all updates come via websocket messages.
 */
const store = {
  namespaced: true,

  // Set the initial state for each entity as an empty array
  state: Object.fromEntries(
    Object.keys(realtimeEntities).map((key) => [key, []]),
  ),

  mutations: {
    RESET(state) {
      /** @todo Should probably cancel any outstanding promises? */
      // Make sure all entities think they need to be reloaded
      promises = {} as Record<SingleEntityName, Promise<any>>;
      Object.keys(state).forEach((key) => state[key].splice(0));
    },

    REMOVE(state, { entityName, id }) {
      const collection = state[entityName];
      const index = collection.findIndex((wp) => wp.id === id);
      if (index !== -1) {
        collection.splice(index, 1);
      }
    },

    SET_COLLECTION(state, { entityName, entities }) {
      // We use splice & push to modify the array directly - that way any vue components watching it will get updated
      // For whatever reason, this seems to work better than Vue.set
      state[entityName].splice(0);
      state[entityName].push(...entities);
    },

    UPSERT(state, { entityName, entity }) {
      const collection = state[entityName];
      const existingEntity = collection.find((e) => e && e.id === entity.id);
      if (existingEntity !== undefined) {
        Object.assign(existingEntity, entity);
      } else {
        collection.push(entity);
      }
    },
  },
};

/**
 * Show a single instance of a given entity. Only operates on entities derived from the
 * API that don't require an id for the endpoint, e.g. ShowCompanyOnboarding.
 */
export const fetchEntity = <T extends SingleEntityName>(
  entityName: T,
  params?: GetSingleEntityParameterType<T>,
): {
  isLoading: boolean;
  data: SingleEntityInterfaceMap[T] | null; // data will be null while the entity is being fetched
  promise: Promise<SingleEntityInterfaceMap[T]>;
} => {
  const firstItemFromStore = () => {
    const [item] = store.state[entityName];
    return item ?? null;
  };
  const response = {
    isLoading: true,
    data: firstItemFromStore(),
    promise: undefined,
  };
  response.promise = loadEntity(entityName, params).then(() => {
    response.isLoading = false;
    response.data = firstItemFromStore();
    return response.data;
  });

  return response;
};

export default store;
