/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateJobRoleData
 */
export interface CreateJobRoleData {
    /**
     * 
     * @type {string}
     * @memberof CreateJobRoleData
     */
    name: string;
    /**
     * A list of location IDs where this job role is relevant. An empty array means that it applies to all locations.
     * @type {Array<number>}
     * @memberof CreateJobRoleData
     */
    locations?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateJobRoleData
     */
    colour: string;
}

/**
 * Check if a given object implements the CreateJobRoleData interface.
 */
export function instanceOfCreateJobRoleData(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('colour' in value)) return false;
    return true;
}

export function CreateJobRoleDataFromJSON(json: any): CreateJobRoleData {
    return CreateJobRoleDataFromJSONTyped(json, false);
}

export function CreateJobRoleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateJobRoleData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'locations': json['locations'] == null ? undefined : json['locations'],
        'colour': json['colour'],
    };
}

export function CreateJobRoleDataToJSON(value?: CreateJobRoleData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'locations': value['locations'],
        'colour': value['colour'],
    };
}

