/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeavePolicy
 */
export interface LeavePolicy {
    /**
     * 
     * @type {number}
     * @memberof LeavePolicy
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LeavePolicy
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof LeavePolicy
     */
    defaultLeaveTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof LeavePolicy
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeavePolicy
     */
    startDateType: LeavePolicyStartDateTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LeavePolicy
     */
    startDay: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeavePolicy
     */
    startMonth: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeavePolicy
     */
    interval: LeavePolicyIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof LeavePolicy
     */
    maxFuturePeriods: number;
    /**
     * 
     * @type {number}
     * @memberof LeavePolicy
     */
    leaveNoticePeriodInDays: number;
    /**
     * 
     * @type {boolean}
     * @memberof LeavePolicy
     */
    leaveAllowanceCanBeExceeded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeavePolicy
     */
    excludePublicHolidaysFromWorkingTimeLost: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof LeavePolicy
     */
    leaveTypeIds: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum LeavePolicyStartDateTypeEnum {
    SpecificDate = 'Specific Date',
    EmployeeJoinDate = 'Employee Join Date'
}
/**
* @export
* @enum {string}
*/
export enum LeavePolicyIntervalEnum {
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Yearly = 'Yearly'
}


/**
 * Check if a given object implements the LeavePolicy interface.
 */
export function instanceOfLeavePolicy(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('defaultLeaveTypeId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('startDateType' in value)) return false;
    if (!('startDay' in value)) return false;
    if (!('startMonth' in value)) return false;
    if (!('interval' in value)) return false;
    if (!('maxFuturePeriods' in value)) return false;
    if (!('leaveNoticePeriodInDays' in value)) return false;
    if (!('leaveAllowanceCanBeExceeded' in value)) return false;
    if (!('excludePublicHolidaysFromWorkingTimeLost' in value)) return false;
    if (!('leaveTypeIds' in value)) return false;
    return true;
}

export function LeavePolicyFromJSON(json: any): LeavePolicy {
    return LeavePolicyFromJSONTyped(json, false);
}

export function LeavePolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeavePolicy {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'defaultLeaveTypeId': json['defaultLeaveTypeId'],
        'name': json['name'],
        'startDateType': json['startDateType'],
        'startDay': json['startDay'],
        'startMonth': json['startMonth'],
        'interval': json['interval'],
        'maxFuturePeriods': json['maxFuturePeriods'],
        'leaveNoticePeriodInDays': json['leaveNoticePeriodInDays'],
        'leaveAllowanceCanBeExceeded': json['leaveAllowanceCanBeExceeded'],
        'excludePublicHolidaysFromWorkingTimeLost': json['excludePublicHolidaysFromWorkingTimeLost'],
        'leaveTypeIds': json['leaveTypeIds'],
    };
}

export function LeavePolicyToJSON(value?: LeavePolicy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'defaultLeaveTypeId': value['defaultLeaveTypeId'],
        'name': value['name'],
        'startDateType': value['startDateType'],
        'startDay': value['startDay'],
        'startMonth': value['startMonth'],
        'interval': value['interval'],
        'maxFuturePeriods': value['maxFuturePeriods'],
        'leaveNoticePeriodInDays': value['leaveNoticePeriodInDays'],
        'leaveAllowanceCanBeExceeded': value['leaveAllowanceCanBeExceeded'],
        'excludePublicHolidaysFromWorkingTimeLost': value['excludePublicHolidaysFromWorkingTimeLost'],
        'leaveTypeIds': value['leaveTypeIds'],
    };
}

