/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDigiticketsConnectionData
 */
export interface CreateDigiticketsConnectionData {
    /**
     * 
     * @type {string}
     * @memberof CreateDigiticketsConnectionData
     */
    apiKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDigiticketsConnectionData
     */
    name: string;
    /**
     * The DigiTickets userID returned from a successful login request
     * @type {number}
     * @memberof CreateDigiticketsConnectionData
     */
    userId: number;
}

/**
 * Check if a given object implements the CreateDigiticketsConnectionData interface.
 */
export function instanceOfCreateDigiticketsConnectionData(value: object): boolean {
    if (!('apiKey' in value)) return false;
    if (!('name' in value)) return false;
    if (!('userId' in value)) return false;
    return true;
}

export function CreateDigiticketsConnectionDataFromJSON(json: any): CreateDigiticketsConnectionData {
    return CreateDigiticketsConnectionDataFromJSONTyped(json, false);
}

export function CreateDigiticketsConnectionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDigiticketsConnectionData {
    if (json == null) {
        return json;
    }
    return {
        
        'apiKey': json['apiKey'],
        'name': json['name'],
        'userId': json['userId'],
    };
}

export function CreateDigiticketsConnectionDataToJSON(value?: CreateDigiticketsConnectionData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'apiKey': value['apiKey'],
        'name': value['name'],
        'userId': value['userId'],
    };
}

