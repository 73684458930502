import { findEntity } from '@/lib/realtime/realtimeFunctions';
import { Entity } from '@/lib/store/realtimeEntities';
import {
  BillingPlanFeaturesEnum,
  Company,
  CompanyFeatureFlagsEnum,
  CompanyStatusEnum,
} from '../../../api/v1';
import store from '../../store';

export const Feature = BillingPlanFeaturesEnum;

/**
 * Check if the current company has access to a feature
 */
export const companyCanAccess = (feature: BillingPlanFeaturesEnum) =>
  store.getters['billing/getCompanyFeatures'].includes(feature);

// As "leave" relies on both a compatible plan + the setting turned on, we roll this up into an easy-to-use constant
export const isLeaveEnabled = () =>
  companyCanAccess(Feature.Leave) &&
  store.state.settings.leaveSettings.enableLeaveRequests;

export const companyIsLocked = async (id: number) => {
  const company = await findEntity(Entity.Company, id);

  return company.status === CompanyStatusEnum.Locked;
};

export const companyHasFeatureFlag = (
  company: Company,
  flag: CompanyFeatureFlagsEnum,
): boolean => company.featureFlags.includes(flag);
