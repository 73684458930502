/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeatherReportWeather
 */
export interface WeatherReportWeather {
    /**
     * 
     * @type {string}
     * @memberof WeatherReportWeather
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherReportWeather
     */
    main?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherReportWeather
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherReportWeather
     */
    icon?: string;
}

/**
 * Check if a given object implements the WeatherReportWeather interface.
 */
export function instanceOfWeatherReportWeather(value: object): boolean {
    return true;
}

export function WeatherReportWeatherFromJSON(json: any): WeatherReportWeather {
    return WeatherReportWeatherFromJSONTyped(json, false);
}

export function WeatherReportWeatherFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeatherReportWeather {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'main': json['main'] == null ? undefined : json['main'],
        'description': json['description'] == null ? undefined : json['description'],
        'icon': json['icon'] == null ? undefined : json['icon'],
    };
}

export function WeatherReportWeatherToJSON(value?: WeatherReportWeather | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'main': value['main'],
        'description': value['description'],
        'icon': value['icon'],
    };
}

