/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateEmergencyContactData,
  CreateEmergencyContactResponse,
  ListEmergencyContactsResponse,
  ShowEmergencyContactResponse,
  UpdateEmergencyContactData,
  UpdateEmergencyContactResponse,
} from '../models/index';
import {
    CreateEmergencyContactDataFromJSON,
    CreateEmergencyContactDataToJSON,
    CreateEmergencyContactResponseFromJSON,
    CreateEmergencyContactResponseToJSON,
    ListEmergencyContactsResponseFromJSON,
    ListEmergencyContactsResponseToJSON,
    ShowEmergencyContactResponseFromJSON,
    ShowEmergencyContactResponseToJSON,
    UpdateEmergencyContactDataFromJSON,
    UpdateEmergencyContactDataToJSON,
    UpdateEmergencyContactResponseFromJSON,
    UpdateEmergencyContactResponseToJSON,
} from '../models/index';

export interface CreateEmergencyContactRequest {
    createEmergencyContactData?: CreateEmergencyContactData;
}

export interface DeleteEmergencyContactRequest {
    id: number;
}

export interface ListEmergencyContactsRequest {
    limit?: number;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    orderBy?: Array<string>;
}

export interface ShowEmergencyContactRequest {
    id: number;
}

export interface UpdateEmergencyContactRequest {
    id: number;
    updateEmergencyContactData?: UpdateEmergencyContactData;
}

/**
 * 
 */
export class EmergencyContactApi extends runtime.BaseAPI {

    /**
     * Create a new Emergency Contact
     * Create Emergency Contact
     */
    async createEmergencyContactRaw(requestParameters: CreateEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEmergencyContactResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.emergency-contact.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emergency-contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmergencyContactDataToJSON(requestParameters['createEmergencyContactData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEmergencyContactResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Emergency Contact
     * Create Emergency Contact
     */
    async createEmergencyContact(requestParameters: CreateEmergencyContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEmergencyContactResponse> {
        const response = await this.createEmergencyContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an Employee\'s emergency Contact
     * Delete Emergency Contact
     */
    async deleteEmergencyContactRaw(requestParameters: DeleteEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEmergencyContact().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.emergency-contact.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emergency-contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an Employee\'s emergency Contact
     * Delete Emergency Contact
     */
    async deleteEmergencyContact(requestParameters: DeleteEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmergencyContactRaw(requestParameters, initOverrides);
    }

    /**
     * Lists All Employee\'s emergency Contact information for the active company
     * List Emergency Contacts
     */
    async listEmergencyContactsRaw(requestParameters: ListEmergencyContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmergencyContactsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.emergency-contact.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emergency-contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmergencyContactsResponseFromJSON(jsonValue));
    }

    /**
     * Lists All Employee\'s emergency Contact information for the active company
     * List Emergency Contacts
     */
    async listEmergencyContacts(requestParameters: ListEmergencyContactsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmergencyContactsResponse> {
        const response = await this.listEmergencyContactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show an Employee\'s emergency Contact
     * Show Employee Emergency Contact
     */
    async showEmergencyContactRaw(requestParameters: ShowEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmergencyContactResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmergencyContact().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.emergency-contact.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emergency-contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmergencyContactResponseFromJSON(jsonValue));
    }

    /**
     * Show an Employee\'s emergency Contact
     * Show Employee Emergency Contact
     */
    async showEmergencyContact(requestParameters: ShowEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmergencyContactResponse> {
        const response = await this.showEmergencyContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an Employee\'s emergency Contact
     * Update Emergency Contact
     */
    async updateEmergencyContactRaw(requestParameters: UpdateEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEmergencyContactResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEmergencyContact().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.emergency-contact.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emergency-contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmergencyContactDataToJSON(requestParameters['updateEmergencyContactData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEmergencyContactResponseFromJSON(jsonValue));
    }

    /**
     * Updates an Employee\'s emergency Contact
     * Update Emergency Contact
     */
    async updateEmergencyContact(requestParameters: UpdateEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEmergencyContactResponse> {
        const response = await this.updateEmergencyContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
