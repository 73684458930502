var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.size},[_c('v-menu',_vm._b({attrs:{"content-class":[
        'tooltip-click',
        _vm.top ? 'pos-top' : '',
        _vm.right ? 'pos-right' : '',
        _vm.bottom ? 'pos-bottom' : '',
        _vm.left ? 'pos-left' : '',
      ].join(' '),"max-width":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('i',_vm._g({staticClass:"activator",class:_vm.icon,style:({ color: _vm.iconColour })},on))]}}])},'v-menu',_vm.$props,false),[_c('v-list',{staticClass:"message-wrapper relative"},[_c('div',{staticClass:"all:flex justify-space-between align-center mb-4"},[_c('h4',{staticClass:"text-14 text-white"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('i',{staticClass:"text-green ml-4",class:_vm.Icon.LightBulbOn})]),_vm._t("message",function(){return [_c('p',{staticClass:"text-14 text-white mb-0"},[_vm._v(" "+_vm._s(_vm.message)+" ")])]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }