/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListLeavePeriodsResponse,
  ShowLeavePeriodResponse,
  UpdateLeavePeriodData,
  UpdateLeavePeriodResponse,
} from '../models/index';
import {
    ListLeavePeriodsResponseFromJSON,
    ListLeavePeriodsResponseToJSON,
    ShowLeavePeriodResponseFromJSON,
    ShowLeavePeriodResponseToJSON,
    UpdateLeavePeriodDataFromJSON,
    UpdateLeavePeriodDataToJSON,
    UpdateLeavePeriodResponseFromJSON,
    UpdateLeavePeriodResponseToJSON,
} from '../models/index';

export interface ListLeavePeriodsRequest {
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    limit?: number;
    orderBy?: Array<string>;
}

export interface ShowLeavePeriodRequest {
    id: number;
}

export interface UpdateLeavePeriodRequest {
    id: number;
    updateLeavePeriodData?: UpdateLeavePeriodData;
}

/**
 * 
 */
export class LeavePeriodApi extends runtime.BaseAPI {

    /**
     * List Leave Periods
     */
    async listLeavePeriodsRaw(requestParameters: ListLeavePeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeavePeriodsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-period.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-periods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeavePeriodsResponseFromJSON(jsonValue));
    }

    /**
     * List Leave Periods
     */
    async listLeavePeriods(requestParameters: ListLeavePeriodsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeavePeriodsResponse> {
        const response = await this.listLeavePeriodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Show Leave Period
     */
    async showLeavePeriodRaw(requestParameters: ShowLeavePeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowLeavePeriodResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showLeavePeriod().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-period.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-periods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowLeavePeriodResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Show Leave Period
     */
    async showLeavePeriod(requestParameters: ShowLeavePeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowLeavePeriodResponse> {
        const response = await this.showLeavePeriodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Leave Period
     */
    async updateLeavePeriodRaw(requestParameters: UpdateLeavePeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateLeavePeriodResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLeavePeriod().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-period.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/leave-periods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeavePeriodDataToJSON(requestParameters['updateLeavePeriodData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateLeavePeriodResponseFromJSON(jsonValue));
    }

    /**
     * Update Leave Period
     */
    async updateLeavePeriod(requestParameters: UpdateLeavePeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateLeavePeriodResponse> {
        const response = await this.updateLeavePeriodRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
