/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateLocationData,
  CreateLocationResponse,
  ListLocationsResponse,
  ShowLocationResponse,
  UpdateLocationData,
  UpdateLocationResponse,
} from '../models/index';
import {
    CreateLocationDataFromJSON,
    CreateLocationDataToJSON,
    CreateLocationResponseFromJSON,
    CreateLocationResponseToJSON,
    ListLocationsResponseFromJSON,
    ListLocationsResponseToJSON,
    ShowLocationResponseFromJSON,
    ShowLocationResponseToJSON,
    UpdateLocationDataFromJSON,
    UpdateLocationDataToJSON,
    UpdateLocationResponseFromJSON,
    UpdateLocationResponseToJSON,
} from '../models/index';

export interface CreateLocationRequest {
    _with?: Array<string>;
    createLocationData?: CreateLocationData;
}

export interface ListLocationsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowLocationRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateLocationRequest {
    id: number;
    _with?: Array<string>;
    updateLocationData?: UpdateLocationData;
}

/**
 * 
 */
export class LocationApi extends runtime.BaseAPI {

    /**
     * Create a new location for a company
     * Create Location
     */
    async createLocationRaw(requestParameters: CreateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLocationResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.location.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLocationDataToJSON(requestParameters['createLocationData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLocationResponseFromJSON(jsonValue));
    }

    /**
     * Create a new location for a company
     * Create Location
     */
    async createLocation(requestParameters: CreateLocationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLocationResponse> {
        const response = await this.createLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all locations that a user has access to
     * List Locations
     */
    async listLocationsRaw(requestParameters: ListLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLocationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.location.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLocationsResponseFromJSON(jsonValue));
    }

    /**
     * List all locations that a user has access to
     * List Locations
     */
    async listLocations(requestParameters: ListLocationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLocationsResponse> {
        const response = await this.listLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details of a specific location
     * Show Location
     */
    async showLocationRaw(requestParameters: ShowLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowLocationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showLocation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.location.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowLocationResponseFromJSON(jsonValue));
    }

    /**
     * Show details of a specific location
     * Show Location
     */
    async showLocation(requestParameters: ShowLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowLocationResponse> {
        const response = await this.showLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the details of an existing location
     * Update Location
     */
    async updateLocationRaw(requestParameters: UpdateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateLocationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLocation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.location.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLocationDataToJSON(requestParameters['updateLocationData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateLocationResponseFromJSON(jsonValue));
    }

    /**
     * Update the details of an existing location
     * Update Location
     */
    async updateLocation(requestParameters: UpdateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateLocationResponse> {
        const response = await this.updateLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
