
import Dialog from '@/components/dialog/Dialog.vue';
import OnboardingDialogContent from '@/components/widgets/onboarding-widget/components/OnboardingDialogContent.vue';
import { Flag } from '@/lib/onboarding/onboardingFunctions';
import { goToLink, redirect } from '@/router/router';
import { PropType } from 'vue';

export default {
  name: 'OnboardingDialog',
  components: {
    OnboardingDialogContent,
    Dialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    flag: {
      type: Object as PropType<Flag>,
      required: true,
    },
  },
  emits: ['input', 'actionClicked'],
  methods: {
    goToLink,

    actionClick() {
      redirect(this.flag.actionLink);
      this.$emit('input', false);
      this.$emit('actionClicked');
    },
  },
};
