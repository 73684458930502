/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleTemplate } from './ScheduleTemplate';
import {
    ScheduleTemplateFromJSON,
    ScheduleTemplateFromJSONTyped,
    ScheduleTemplateToJSON,
} from './ScheduleTemplate';

/**
 * 
 * @export
 * @interface CreateScheduleTemplateResponse
 */
export interface CreateScheduleTemplateResponse {
    /**
     * 
     * @type {ScheduleTemplate}
     * @memberof CreateScheduleTemplateResponse
     */
    data?: ScheduleTemplate;
}

/**
 * Check if a given object implements the CreateScheduleTemplateResponse interface.
 */
export function instanceOfCreateScheduleTemplateResponse(value: object): boolean {
    return true;
}

export function CreateScheduleTemplateResponseFromJSON(json: any): CreateScheduleTemplateResponse {
    return CreateScheduleTemplateResponseFromJSONTyped(json, false);
}

export function CreateScheduleTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScheduleTemplateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ScheduleTemplateFromJSON(json['data']),
    };
}

export function CreateScheduleTemplateResponseToJSON(value?: CreateScheduleTemplateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ScheduleTemplateToJSON(value['data']),
    };
}

