import {
  ComputedGetter,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  Ref,
  ref,
} from 'vue';
import { NavigationGuard } from 'vue-router';

// TODO: Remove it after Vue Router 4 migration
// https://github.com/vuejs/vue-router/issues/3760#issuecomment-1178468293
export const useRoute = () => {
  const { proxy } = getCurrentInstance();
  return proxy.$route;
};

// TODO: Remove it after Vue Router 4 migration
// https://github.com/vuejs/vue-router/issues/3760#issuecomment-1178468293
export const useRouter = () => {
  const { proxy } = getCurrentInstance();
  return proxy.$router;
};

// TODO: Remove it after Vue Router 4 migration
// https://github.com/vuejs/vue-router/issues/3760#issuecomment-1178468293
export const onBeforeRouteLeave = (guard: NavigationGuard) => {
  const router = useRouter();

  const unsubscribe = ref(null);
  onMounted(() => {
    unsubscribe.value = router.beforeEach(guard);
  });
  onUnmounted(() => {
    unsubscribe.value();
  });
};

export const useSafeRedirect = (
  isSafe: ComputedGetter<boolean>,
  onPrevented?: NavigationGuard,
  onBeforeRedirect?: NavigationGuard,
) => {
  onBeforeRouteLeave((to, from, next) => {
    if (isSafe()) {
      onBeforeRedirect?.(to, from, next);
      next();
    } else {
      onPrevented?.(to, from, next);
    }
  });
};

type WarningState = {
  show: boolean;
  onConfirm: () => any;
  onCancel: () => any;
};
export const useSafeRedirectWarningState = (
  isSafe: ComputedGetter<boolean>,
  onConfirm?: () => any,
  onCancel?: () => any,
): Ref<WarningState> => {
  const warningState = ref<WarningState>({
    show: false,
    onConfirm: () => {
      onConfirm?.();
      warningState.value.show = false;
    },
    onCancel: () => {
      onCancel?.();
      warningState.value.show = false;
    },
  });
  useSafeRedirect(isSafe, (to, from, next) => {
    warningState.value = {
      ...warningState.value,
      show: true,
      onConfirm: () => {
        next();
        warningState.value.show = false;
      },
    };
  });

  return warningState;
};
