/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface PayCycle
 */
export interface PayCycle {
    /**
     * 
     * @type {number}
     * @memberof PayCycle
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PayCycle
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof PayCycle
     */
    frequency: PayCycleFrequencyEnum;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof PayCycle
     */
    startsOn: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof PayCycle
     */
    endsOn: ShiftiePlainDate | null;
}

/**
* @export
* @enum {string}
*/
export enum PayCycleFrequencyEnum {
    Weekly = 'Weekly',
    TwoWeekly = 'TwoWeekly',
    FourWeekly = 'FourWeekly',
    Monthly = 'Monthly'
}


/**
 * Check if a given object implements the PayCycle interface.
 */
export function instanceOfPayCycle(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('frequency' in value)) return false;
    if (!('startsOn' in value)) return false;
    if (!('endsOn' in value)) return false;
    return true;
}

export function PayCycleFromJSON(json: any): PayCycle {
    return PayCycleFromJSONTyped(json, false);
}

export function PayCycleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayCycle {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'frequency': json['frequency'],
        'startsOn': ShiftiePlainDateFromJSON(json['startsOn']),
        'endsOn': ShiftiePlainDateFromJSON(json['endsOn']),
    };
}

export function PayCycleToJSON(value?: PayCycle | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'frequency': value['frequency'],
        'startsOn': ShiftiePlainDateToJSON(value['startsOn']),
        'endsOn': ShiftiePlainDateToJSON(value['endsOn']),
    };
}

