/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OAuthClient } from './OAuthClient';
import {
    OAuthClientFromJSON,
    OAuthClientFromJSONTyped,
    OAuthClientToJSON,
} from './OAuthClient';

/**
 * 
 * @export
 * @interface UpdateOauthClientResponse
 */
export interface UpdateOauthClientResponse {
    /**
     * 
     * @type {OAuthClient}
     * @memberof UpdateOauthClientResponse
     */
    data: OAuthClient;
}

/**
 * Check if a given object implements the UpdateOauthClientResponse interface.
 */
export function instanceOfUpdateOauthClientResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateOauthClientResponseFromJSON(json: any): UpdateOauthClientResponse {
    return UpdateOauthClientResponseFromJSONTyped(json, false);
}

export function UpdateOauthClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOauthClientResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': OAuthClientFromJSON(json['data']),
    };
}

export function UpdateOauthClientResponseToJSON(value?: UpdateOauthClientResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': OAuthClientToJSON(value['data']),
    };
}

