/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Keeps track of how far through the onboarding process an employee has got, in terms of adding personal details, profile photo, etc.
 * 
 * @export
 * @interface EmployeeOnboarding
 */
export interface EmployeeOnboarding {
    /**
     * 
     * @type {number}
     * @memberof EmployeeOnboarding
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOnboarding
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOnboarding
     */
    employeeId: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOnboarding
     */
    hasAddedPersonalDetails: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOnboarding
     */
    hasVerifiedPhoneNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOnboarding
     */
    hasAddedUnavailability: boolean;
}

/**
 * Check if a given object implements the EmployeeOnboarding interface.
 */
export function instanceOfEmployeeOnboarding(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('hasAddedPersonalDetails' in value)) return false;
    if (!('hasVerifiedPhoneNumber' in value)) return false;
    if (!('hasAddedUnavailability' in value)) return false;
    return true;
}

export function EmployeeOnboardingFromJSON(json: any): EmployeeOnboarding {
    return EmployeeOnboardingFromJSONTyped(json, false);
}

export function EmployeeOnboardingFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeOnboarding {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'hasAddedPersonalDetails': json['hasAddedPersonalDetails'],
        'hasVerifiedPhoneNumber': json['hasVerifiedPhoneNumber'],
        'hasAddedUnavailability': json['hasAddedUnavailability'],
    };
}

export function EmployeeOnboardingToJSON(value?: EmployeeOnboarding | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'hasAddedPersonalDetails': value['hasAddedPersonalDetails'],
        'hasVerifiedPhoneNumber': value['hasVerifiedPhoneNumber'],
        'hasAddedUnavailability': value['hasAddedUnavailability'],
    };
}

