/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimesheetEntry } from './TimesheetEntry';
import {
    TimesheetEntryFromJSON,
    TimesheetEntryFromJSONTyped,
    TimesheetEntryToJSON,
} from './TimesheetEntry';

/**
 * 
 * @export
 * @interface ShowTimesheetEntryResponse
 */
export interface ShowTimesheetEntryResponse {
    /**
     * 
     * @type {TimesheetEntry}
     * @memberof ShowTimesheetEntryResponse
     */
    data: TimesheetEntry;
}

/**
 * Check if a given object implements the ShowTimesheetEntryResponse interface.
 */
export function instanceOfShowTimesheetEntryResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function ShowTimesheetEntryResponseFromJSON(json: any): ShowTimesheetEntryResponse {
    return ShowTimesheetEntryResponseFromJSONTyped(json, false);
}

export function ShowTimesheetEntryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowTimesheetEntryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': TimesheetEntryFromJSON(json['data']),
    };
}

export function ShowTimesheetEntryResponseToJSON(value?: ShowTimesheetEntryResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': TimesheetEntryToJSON(value['data']),
    };
}

