/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IcalFeed } from './IcalFeed';
import {
    IcalFeedFromJSON,
    IcalFeedFromJSONTyped,
    IcalFeedToJSON,
} from './IcalFeed';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListCalendarFeedsResponse
 */
export interface ListCalendarFeedsResponse {
    /**
     * 
     * @type {Array<IcalFeed>}
     * @memberof ListCalendarFeedsResponse
     */
    data: Array<IcalFeed>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListCalendarFeedsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListCalendarFeedsResponse interface.
 */
export function instanceOfListCalendarFeedsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListCalendarFeedsResponseFromJSON(json: any): ListCalendarFeedsResponse {
    return ListCalendarFeedsResponseFromJSONTyped(json, false);
}

export function ListCalendarFeedsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCalendarFeedsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(IcalFeedFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListCalendarFeedsResponseToJSON(value?: ListCalendarFeedsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(IcalFeedToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

