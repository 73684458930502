/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListEmployeeAbsenceSummariesResponse,
  ListEmployeeLeaveSummariesResponse,
  ListEmployeePayPeriodSummariesResponse,
  ListMissingShiftsResponse,
  ListTimesheetSummariesResponse,
  ShiftiePlainDate,
  ShowBudgetSummaryResponse,
  ShowClockedInSummaryResponse,
  ShowDaySummaryResponse,
  ShowEmployeeAbsenceSummaryResponse,
  ShowEmployeeLeaveSummaryResponse,
  ShowEmployeePayPeriodSummaryResponse,
  ShowEmployeeWeeklyShiftsTotalResponse,
  ShowPayPeriodsSummaryResponse,
  ShowScheduleOverviewResponse,
} from '../models/index';
import {
    ListEmployeeAbsenceSummariesResponseFromJSON,
    ListEmployeeAbsenceSummariesResponseToJSON,
    ListEmployeeLeaveSummariesResponseFromJSON,
    ListEmployeeLeaveSummariesResponseToJSON,
    ListEmployeePayPeriodSummariesResponseFromJSON,
    ListEmployeePayPeriodSummariesResponseToJSON,
    ListMissingShiftsResponseFromJSON,
    ListMissingShiftsResponseToJSON,
    ListTimesheetSummariesResponseFromJSON,
    ListTimesheetSummariesResponseToJSON,
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateToJSON,
    ShowBudgetSummaryResponseFromJSON,
    ShowBudgetSummaryResponseToJSON,
    ShowClockedInSummaryResponseFromJSON,
    ShowClockedInSummaryResponseToJSON,
    ShowDaySummaryResponseFromJSON,
    ShowDaySummaryResponseToJSON,
    ShowEmployeeAbsenceSummaryResponseFromJSON,
    ShowEmployeeAbsenceSummaryResponseToJSON,
    ShowEmployeeLeaveSummaryResponseFromJSON,
    ShowEmployeeLeaveSummaryResponseToJSON,
    ShowEmployeePayPeriodSummaryResponseFromJSON,
    ShowEmployeePayPeriodSummaryResponseToJSON,
    ShowEmployeeWeeklyShiftsTotalResponseFromJSON,
    ShowEmployeeWeeklyShiftsTotalResponseToJSON,
    ShowPayPeriodsSummaryResponseFromJSON,
    ShowPayPeriodsSummaryResponseToJSON,
    ShowScheduleOverviewResponseFromJSON,
    ShowScheduleOverviewResponseToJSON,
} from '../models/index';

export interface ListEmployeeAbsenceSummariesRequest {
    startDate: ShiftiePlainDate;
    endDate: ShiftiePlainDate;
    perPage?: number;
    page?: number;
    limit?: number;
}

export interface ListEmployeeLeaveSummariesRequest {
    perPage?: number;
    page?: number;
    limit?: number;
}

export interface ListEmployeePayPeriodSummariesRequest {
    startDate: ShiftiePlainDate;
    endDate: ShiftiePlainDate;
    page?: number;
    perPage?: number;
    jobRoleIds?: Array<number>;
    locationIds?: Array<number>;
    tagIds?: Array<number>;
}

export interface ListMissingShiftsRequest {
    _with?: Array<string>;
    where?: { [key: string]: object; };
    jobRoleIds?: Array<number>;
    scheduleIds?: Array<number>;
}

export interface ListTimesheetSummariesRequest {
    payPeriodId: number;
    page?: number;
    perPage?: number;
    jobRoleIds?: Array<number>;
    locationIds?: Array<number>;
    tagIds?: Array<number>;
}

export interface ShowBudgetSummaryRequest {
    startDate: ShiftiePlainDate;
    format: ShowBudgetSummaryFormatEnum;
}

export interface ShowClockedInSummaryRequest {
    jobRoleIds?: Array<number>;
    tagIds?: Array<number>;
    scheduleIds?: Array<number>;
}

export interface ShowDaySummaryRequest {
    date?: ShiftiePlainDate;
}

export interface ShowEmployeeAbsenceSummaryRequest {
    id: number;
    startDate: ShiftiePlainDate;
    endDate: ShiftiePlainDate;
}

export interface ShowEmployeeLeaveSummaryRequest {
    id: number;
    leavePeriodId: number;
    leaveTypeId: number;
}

export interface ShowEmployeePayPeriodSummaryRequest {
    employeeId: number;
    startDate: ShiftiePlainDate;
    endDate: ShiftiePlainDate;
    jobRoleIds?: Array<number>;
    locationIds?: Array<number>;
    tagIds?: Array<number>;
}

export interface ShowEmployeeWeeklyShiftsTotalRequest {
    id: number;
    startDate: Date;
    endDate: Date;
}

export interface ShowScheduleOverviewRequest {
    startDate: ShiftiePlainDate;
    endDate: ShiftiePlainDate;
    scheduleId?: number;
}

/**
 * 
 */
export class SummariesApi extends runtime.BaseAPI {

    /**
     * Show a summary for all employee\'s absence for the current holiday year
     * List Employee Absence Summaries
     */
    async listEmployeeAbsenceSummariesRaw(requestParameters: ListEmployeeAbsenceSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeAbsenceSummariesResponse>> {
        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling listEmployeeAbsenceSummaries().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling listEmployeeAbsenceSummaries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-absence-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/employee-absence`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeAbsenceSummariesResponseFromJSON(jsonValue));
    }

    /**
     * Show a summary for all employee\'s absence for the current holiday year
     * List Employee Absence Summaries
     */
    async listEmployeeAbsenceSummaries(requestParameters: ListEmployeeAbsenceSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeAbsenceSummariesResponse> {
        const response = await this.listEmployeeAbsenceSummariesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a summary for all employee\'s leave calculations for their current leave period its default leave type.
     * List Employee Leave Summaries
     */
    async listEmployeeLeaveSummariesRaw(requestParameters: ListEmployeeLeaveSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeLeaveSummariesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-leave-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/employee-leave`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeLeaveSummariesResponseFromJSON(jsonValue));
    }

    /**
     * Show a summary for all employee\'s leave calculations for their current leave period its default leave type.
     * List Employee Leave Summaries
     */
    async listEmployeeLeaveSummaries(requestParameters: ListEmployeeLeaveSummariesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeLeaveSummariesResponse> {
        const response = await this.listEmployeeLeaveSummariesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Employee Pay Period Summaries
     */
    async listEmployeePayPeriodSummariesRaw(requestParameters: ListEmployeePayPeriodSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeePayPeriodSummariesResponse>> {
        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling listEmployeePayPeriodSummaries().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling listEmployeePayPeriodSummaries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['locationIds'] != null) {
            queryParameters['locationIds'] = requestParameters['locationIds'];
        }

        if (requestParameters['tagIds'] != null) {
            queryParameters['tagIds'] = requestParameters['tagIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-pay-period-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/employee-pay-period`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeePayPeriodSummariesResponseFromJSON(jsonValue));
    }

    /**
     * List Employee Pay Period Summaries
     */
    async listEmployeePayPeriodSummaries(requestParameters: ListEmployeePayPeriodSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeePayPeriodSummariesResponse> {
        const response = await this.listEmployeePayPeriodSummariesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all shifts for today that are currently in progress or ended without being clocked in for.
     * List Missing Shifts
     */
    async listMissingShiftsRaw(requestParameters: ListMissingShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListMissingShiftsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['scheduleIds'] != null) {
            queryParameters['scheduleIds'] = requestParameters['scheduleIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.missing-shifts-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/missing-shifts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListMissingShiftsResponseFromJSON(jsonValue));
    }

    /**
     * List all shifts for today that are currently in progress or ended without being clocked in for.
     * List Missing Shifts
     */
    async listMissingShifts(requestParameters: ListMissingShiftsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListMissingShiftsResponse> {
        const response = await this.listMissingShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return summaries of all timesheet entries for each employee in the given period.
     * List Timesheet Summaries
     */
    async listTimesheetSummariesRaw(requestParameters: ListTimesheetSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTimesheetSummariesResponse>> {
        if (requestParameters['payPeriodId'] == null) {
            throw new runtime.RequiredError(
                'payPeriodId',
                'Required parameter "payPeriodId" was null or undefined when calling listTimesheetSummaries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['payPeriodId'] != null) {
            queryParameters['payPeriodId'] = requestParameters['payPeriodId'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['locationIds'] != null) {
            queryParameters['locationIds'] = requestParameters['locationIds'];
        }

        if (requestParameters['tagIds'] != null) {
            queryParameters['tagIds'] = requestParameters['tagIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.timesheet-entry-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/timesheets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTimesheetSummariesResponseFromJSON(jsonValue));
    }

    /**
     * Return summaries of all timesheet entries for each employee in the given period.
     * List Timesheet Summaries
     */
    async listTimesheetSummaries(requestParameters: ListTimesheetSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTimesheetSummariesResponse> {
        const response = await this.listTimesheetSummariesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Budget Summary
     */
    async showBudgetSummaryRaw(requestParameters: ShowBudgetSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowBudgetSummaryResponse>> {
        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling showBudgetSummary().'
            );
        }

        if (requestParameters['format'] == null) {
            throw new runtime.RequiredError(
                'format',
                'Required parameter "format" was null or undefined when calling showBudgetSummary().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['format'] != null) {
            queryParameters['format'] = requestParameters['format'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.budget-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/budget`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowBudgetSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Show Budget Summary
     */
    async showBudgetSummary(requestParameters: ShowBudgetSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowBudgetSummaryResponse> {
        const response = await this.showBudgetSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Shows a summary of the current clock-in statuses, showing count of break, late, in, finished and missing employees for the time period specified.
     * Show Clocked In Summary
     */
    async showClockedInSummaryRaw(requestParameters: ShowClockedInSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowClockedInSummaryResponse>> {
        const queryParameters: any = {};

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['tagIds'] != null) {
            queryParameters['tagIds'] = requestParameters['tagIds'];
        }

        if (requestParameters['scheduleIds'] != null) {
            queryParameters['scheduleIds'] = requestParameters['scheduleIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clocked-in-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/clocked-in-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowClockedInSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Shows a summary of the current clock-in statuses, showing count of break, late, in, finished and missing employees for the time period specified.
     * Show Clocked In Summary
     */
    async showClockedInSummary(requestParameters: ShowClockedInSummaryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowClockedInSummaryResponse> {
        const response = await this.showClockedInSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a breakdown of employees for a given day, across different statuses.
     * Show Day Summary
     */
    async showDaySummaryRaw(requestParameters: ShowDaySummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowDaySummaryResponse>> {
        const queryParameters: any = {};

        if (requestParameters['date'] != null) {
            queryParameters['date'] = requestParameters['date'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/day`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowDaySummaryResponseFromJSON(jsonValue));
    }

    /**
     * Show a breakdown of employees for a given day, across different statuses.
     * Show Day Summary
     */
    async showDaySummary(requestParameters: ShowDaySummaryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowDaySummaryResponse> {
        const response = await this.showDaySummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a summary of an employee\'s absence calculations for the current holiday Year
     * Show Employee Absence Summary
     */
    async showEmployeeAbsenceSummaryRaw(requestParameters: ShowEmployeeAbsenceSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeeAbsenceSummaryResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmployeeAbsenceSummary().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling showEmployeeAbsenceSummary().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling showEmployeeAbsenceSummary().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-absence-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/employee-absence/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeeAbsenceSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Show a summary of an employee\'s absence calculations for the current holiday Year
     * Show Employee Absence Summary
     */
    async showEmployeeAbsenceSummary(requestParameters: ShowEmployeeAbsenceSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeeAbsenceSummaryResponse> {
        const response = await this.showEmployeeAbsenceSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a summary of an employee\'s leave calculations for a given leave period and type.
     * Show Employee Leave Summary
     */
    async showEmployeeLeaveSummaryRaw(requestParameters: ShowEmployeeLeaveSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeeLeaveSummaryResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmployeeLeaveSummary().'
            );
        }

        if (requestParameters['leavePeriodId'] == null) {
            throw new runtime.RequiredError(
                'leavePeriodId',
                'Required parameter "leavePeriodId" was null or undefined when calling showEmployeeLeaveSummary().'
            );
        }

        if (requestParameters['leaveTypeId'] == null) {
            throw new runtime.RequiredError(
                'leaveTypeId',
                'Required parameter "leaveTypeId" was null or undefined when calling showEmployeeLeaveSummary().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['leavePeriodId'] != null) {
            queryParameters['leavePeriodId'] = requestParameters['leavePeriodId'];
        }

        if (requestParameters['leaveTypeId'] != null) {
            queryParameters['leaveTypeId'] = requestParameters['leaveTypeId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-leave-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/employee-leave/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeeLeaveSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Show a summary of an employee\'s leave calculations for a given leave period and type.
     * Show Employee Leave Summary
     */
    async showEmployeeLeaveSummary(requestParameters: ShowEmployeeLeaveSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeeLeaveSummaryResponse> {
        const response = await this.showEmployeeLeaveSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Employee Pay Period Summary
     */
    async showEmployeePayPeriodSummaryRaw(requestParameters: ShowEmployeePayPeriodSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeePayPeriodSummaryResponse>> {
        if (requestParameters['employeeId'] == null) {
            throw new runtime.RequiredError(
                'employeeId',
                'Required parameter "employeeId" was null or undefined when calling showEmployeePayPeriodSummary().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling showEmployeePayPeriodSummary().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling showEmployeePayPeriodSummary().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['jobRoleIds'] != null) {
            queryParameters['jobRoleIds'] = requestParameters['jobRoleIds'];
        }

        if (requestParameters['locationIds'] != null) {
            queryParameters['locationIds'] = requestParameters['locationIds'];
        }

        if (requestParameters['tagIds'] != null) {
            queryParameters['tagIds'] = requestParameters['tagIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-pay-period-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/employee-pay-period/{employeeId}`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters['employeeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeePayPeriodSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Show Employee Pay Period Summary
     */
    async showEmployeePayPeriodSummary(requestParameters: ShowEmployeePayPeriodSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeePayPeriodSummaryResponse> {
        const response = await this.showEmployeePayPeriodSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a summarised total of an employee\'s weekly cost and hours worked
     * Show Weekly Shift Totals
     */
    async showEmployeeWeeklyShiftsTotalRaw(requestParameters: ShowEmployeeWeeklyShiftsTotalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeeWeeklyShiftsTotalResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmployeeWeeklyShiftsTotal().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling showEmployeeWeeklyShiftsTotal().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling showEmployeeWeeklyShiftsTotal().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = (requestParameters['startDate'] as any).toISOString();
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = (requestParameters['endDate'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-totals-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/employee-weekly-shifts-total/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeeWeeklyShiftsTotalResponseFromJSON(jsonValue));
    }

    /**
     * Show a summarised total of an employee\'s weekly cost and hours worked
     * Show Weekly Shift Totals
     */
    async showEmployeeWeeklyShiftsTotal(requestParameters: ShowEmployeeWeeklyShiftsTotalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeeWeeklyShiftsTotalResponse> {
        const response = await this.showEmployeeWeeklyShiftsTotalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Pay Periods Summary
     */
    async showPayPeriodsSummaryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowPayPeriodsSummaryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.pay-periods-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/pay-periods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowPayPeriodsSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Show Pay Periods Summary
     */
    async showPayPeriodsSummary(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowPayPeriodsSummaryResponse> {
        const response = await this.showPayPeriodsSummaryRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show a summary of shifts over a given period, optionally for a specified schedule.
     * Show Schedule Overview
     */
    async showScheduleOverviewRaw(requestParameters: ShowScheduleOverviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowScheduleOverviewResponse>> {
        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling showScheduleOverview().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling showScheduleOverview().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['scheduleId'] != null) {
            queryParameters['scheduleId'] = requestParameters['scheduleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-overview-summary.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summaries/schedule-overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowScheduleOverviewResponseFromJSON(jsonValue));
    }

    /**
     * Show a summary of shifts over a given period, optionally for a specified schedule.
     * Show Schedule Overview
     */
    async showScheduleOverview(requestParameters: ShowScheduleOverviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowScheduleOverviewResponse> {
        const response = await this.showScheduleOverviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum ShowBudgetSummaryFormatEnum {
    Daily = 'Daily',
    Weekly = 'Weekly'
}
