
import BackgroundJobs from '@/components/BackgroundJobs.vue';
import Button from '@/components/buttons/Button.vue';
import QuickClockIn from '@/components/clockIn/QuickClockIn.vue';
import LockedFeatureDialog from '@/components/dialog/LockedFeatureDialog.vue';
import ToolTipWrapper from '@/components/info/ToolTipWrapper.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import CompanyTime from '@/components/interface/CompanyTime.vue';
import { checkBillingContact } from '@/lib/billing/billing';
import { EventsEnum } from '@/lib/enum/events';
import { Icon } from '@/lib/enum/Icon';
import {
  companyCanAccess,
  Feature,
} from '@/lib/permission/companyAccessFeatures';
import { userCan } from '@/lib/permission/userCan';
import { routes } from '@/router/routes';
import store from '@/store';
import SendMessageDialog from '@/views/messaging/SendMessageDialog.vue';
import spacetime from 'spacetime';
import { BillingPlanFeaturesEnum } from '../../../api/v1';

export default {
  name: 'Topbar',

  components: {
    CompanyTime,
    LockedFeatureDialog,
    SendMessageDialog,
    Button,
    ToolTipWrapper,
    BubbleIcon,
    QuickClockIn,
    BackgroundJobs,
  },

  data() {
    return {
      EventsEnum,
      routes,
      userCan,
      Icon,
      showSendMessageDialog: false,
      lockedFeatureDialog: null as BillingPlanFeaturesEnum,
    };
  },

  computed: {
    warnings: () => store.state.appWarnings.warning,
    isImpersonationSession: () => store.state.isImpersonationSession,

    currentPlan: () => store.state.billing.billingPlan,
    currentSubscription: () => store.state.billing.currentSubscription,

    currentCompany: () => store.getters.currentCompany,
    loggedInEmployee: () => store.getters.loggedInEmployee,
    isUpgradable: () => store.getters['billing/isUpgradable'],
    isTrialVersion: () => store.getters['billing/isTrialVersion'],
    trialDaysLeft: () => store.getters['billing/trialDaysLeft'],
    billingOverview: () => store.state.billing.billingOverview,

    hasMissingBillingDataForPaidSubscription(): boolean {
      return (
        this.billingOverview &&
        (!this.billingOverview.hasPaymentMethod ||
          !this.billingOverview.hasDetails) &&
        this.currentSubscription &&
        this.currentSubscription.price > 0
      );
    },

    showBillingButton(): boolean {
      if (!userCan.manageBilling()) {
        return false;
      }

      if (this.currentPlan.allowsSwitching && this.isUpgradable) {
        return true;
      }

      return (
        this.isTrialVersion || this.hasMissingBillingDataForPaidSubscription
      );
    },

    billingButtonText(): string {
      if (this.isTrialVersion) {
        return this.$tc('button.choosePlan');
      }

      if (this.hasMissingBillingDataForPaidSubscription) {
        return this.$tc('button.addBilling');
      }

      return this.$tc('button.upgradeNow');
    },

    billingButtonPretextNumber(): number {
      if (this.isTrialVersion) {
        return this.trialDaysLeft;
      }

      return spacetime
        .now(this.currentCompany.timezone)
        .diff(
          spacetime(
            this.currentSubscription.nextRenewalAt.toDate(
              this.currentCompany.timezone,
            ),
          ),
          'days',
        );
    },
  },

  async mounted() {
    await checkBillingContact();
  },

  methods: {
    async upgrade() {
      if (this.isTrialVersion || this.isUpgradable) {
        this.$root.$emit(EventsEnum.TogglePackageDialog);

        return;
      }

      this.$root.$emit(EventsEnum.ToggleBillingDialog);
    },

    openMessageDialog() {
      if (companyCanAccess(Feature.TeamMessaging)) {
        this.showSendMessageDialog = true;
        return;
      }
      this.lockedFeatureDialog = Feature.TeamMessaging;
    },
  },
};
