/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCostsByGroupRollup
 */
export interface ReportCostsByGroupRollup {
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroupRollup
     */
    employees: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroupRollup
     */
    shifts: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroupRollup
     */
    hoursScheduled: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroupRollup
     */
    hoursWorked: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroupRollup
     */
    costsScheduled: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroupRollup
     */
    costsWorked: number;
}

/**
 * Check if a given object implements the ReportCostsByGroupRollup interface.
 */
export function instanceOfReportCostsByGroupRollup(value: object): boolean {
    if (!('employees' in value)) return false;
    if (!('shifts' in value)) return false;
    if (!('hoursScheduled' in value)) return false;
    if (!('hoursWorked' in value)) return false;
    if (!('costsScheduled' in value)) return false;
    if (!('costsWorked' in value)) return false;
    return true;
}

export function ReportCostsByGroupRollupFromJSON(json: any): ReportCostsByGroupRollup {
    return ReportCostsByGroupRollupFromJSONTyped(json, false);
}

export function ReportCostsByGroupRollupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCostsByGroupRollup {
    if (json == null) {
        return json;
    }
    return {
        
        'employees': json['employees'],
        'shifts': json['shifts'],
        'hoursScheduled': json['hoursScheduled'],
        'hoursWorked': json['hoursWorked'],
        'costsScheduled': json['costsScheduled'],
        'costsWorked': json['costsWorked'],
    };
}

export function ReportCostsByGroupRollupToJSON(value?: ReportCostsByGroupRollup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employees': value['employees'],
        'shifts': value['shifts'],
        'hoursScheduled': value['hoursScheduled'],
        'hoursWorked': value['hoursWorked'],
        'costsScheduled': value['costsScheduled'],
        'costsWorked': value['costsWorked'],
    };
}

