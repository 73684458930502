/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDigiticketsScheduleMapData
 */
export interface CreateDigiticketsScheduleMapData {
    /**
     * 
     * @type {number}
     * @memberof CreateDigiticketsScheduleMapData
     */
    digiticketsConnectionId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDigiticketsScheduleMapData
     */
    scheduleId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDigiticketsScheduleMapData
     */
    digiticketsBranchId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateDigiticketsScheduleMapData
     */
    digiticketsEventIds: Array<number>;
}

/**
 * Check if a given object implements the CreateDigiticketsScheduleMapData interface.
 */
export function instanceOfCreateDigiticketsScheduleMapData(value: object): boolean {
    if (!('digiticketsConnectionId' in value)) return false;
    if (!('scheduleId' in value)) return false;
    if (!('digiticketsBranchId' in value)) return false;
    if (!('digiticketsEventIds' in value)) return false;
    return true;
}

export function CreateDigiticketsScheduleMapDataFromJSON(json: any): CreateDigiticketsScheduleMapData {
    return CreateDigiticketsScheduleMapDataFromJSONTyped(json, false);
}

export function CreateDigiticketsScheduleMapDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDigiticketsScheduleMapData {
    if (json == null) {
        return json;
    }
    return {
        
        'digiticketsConnectionId': json['digiticketsConnectionId'],
        'scheduleId': json['scheduleId'],
        'digiticketsBranchId': json['digiticketsBranchId'],
        'digiticketsEventIds': json['digiticketsEventIds'],
    };
}

export function CreateDigiticketsScheduleMapDataToJSON(value?: CreateDigiticketsScheduleMapData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'digiticketsConnectionId': value['digiticketsConnectionId'],
        'scheduleId': value['scheduleId'],
        'digiticketsBranchId': value['digiticketsBranchId'],
        'digiticketsEventIds': value['digiticketsEventIds'],
    };
}

