/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Credit } from './Credit';
import {
    CreditFromJSON,
    CreditFromJSONTyped,
    CreditToJSON,
} from './Credit';

/**
 * 
 * @export
 * @interface EnableCreditRenewalsResponse
 */
export interface EnableCreditRenewalsResponse {
    /**
     * 
     * @type {Credit}
     * @memberof EnableCreditRenewalsResponse
     */
    data: Credit;
}

/**
 * Check if a given object implements the EnableCreditRenewalsResponse interface.
 */
export function instanceOfEnableCreditRenewalsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function EnableCreditRenewalsResponseFromJSON(json: any): EnableCreditRenewalsResponse {
    return EnableCreditRenewalsResponseFromJSONTyped(json, false);
}

export function EnableCreditRenewalsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnableCreditRenewalsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CreditFromJSON(json['data']),
    };
}

export function EnableCreditRenewalsResponseToJSON(value?: EnableCreditRenewalsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': CreditToJSON(value['data']),
    };
}

