<template>
  <div class="text-center text-gray-500 text-12 all:flex flex-col">
    <span>
      <strong>{{ $t('label.version') }}</strong
      >: {{ releaseVersion() }}
    </span>

    <span v-if="apiVersion()">
      <strong>{{ $t('label.API') }}</strong
      >: {{ apiVersion() }}
    </span>
  </div>
</template>

<script>
import { StorageEnum } from '@/lib/enum/StorageEnum';
import { getLocalStorageItem } from '@/util/storageFunctions';
import spacetime from 'spacetime';

export default {
  name: 'VersionInformation',

  methods: {
    releaseVersion() {
      return (
        process.env.VUE_APP_RELEASE_VERSION ||
        spacetime.now().format('{year}.{iso-month}.{date-pad}.1-dev')
      );
    },

    apiVersion() {
      return getLocalStorageItem(StorageEnum.ApiVersion);
    },
  },
};
</script>
