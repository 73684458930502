/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetPaymentMethodData
 */
export interface SetPaymentMethodData {
    /**
     * 
     * @type {string}
     * @memberof SetPaymentMethodData
     */
    stripePaymentMethodId: string;
}

/**
 * Check if a given object implements the SetPaymentMethodData interface.
 */
export function instanceOfSetPaymentMethodData(value: object): boolean {
    if (!('stripePaymentMethodId' in value)) return false;
    return true;
}

export function SetPaymentMethodDataFromJSON(json: any): SetPaymentMethodData {
    return SetPaymentMethodDataFromJSONTyped(json, false);
}

export function SetPaymentMethodDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPaymentMethodData {
    if (json == null) {
        return json;
    }
    return {
        
        'stripePaymentMethodId': json['stripePaymentMethodId'],
    };
}

export function SetPaymentMethodDataToJSON(value?: SetPaymentMethodData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stripePaymentMethodId': value['stripePaymentMethodId'],
    };
}

