/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthenticationData } from './AuthenticationData';
import {
    AuthenticationDataFromJSON,
    AuthenticationDataFromJSONTyped,
    AuthenticationDataToJSON,
} from './AuthenticationData';

/**
 * 
 * @export
 * @interface VerifyOBOTokenResponse
 */
export interface VerifyOBOTokenResponse {
    /**
     * 
     * @type {AuthenticationData}
     * @memberof VerifyOBOTokenResponse
     */
    data: AuthenticationData;
}

/**
 * Check if a given object implements the VerifyOBOTokenResponse interface.
 */
export function instanceOfVerifyOBOTokenResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function VerifyOBOTokenResponseFromJSON(json: any): VerifyOBOTokenResponse {
    return VerifyOBOTokenResponseFromJSONTyped(json, false);
}

export function VerifyOBOTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyOBOTokenResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': AuthenticationDataFromJSON(json['data']),
    };
}

export function VerifyOBOTokenResponseToJSON(value?: VerifyOBOTokenResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': AuthenticationDataToJSON(value['data']),
    };
}

