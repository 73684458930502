/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';
import type { RecurrenceRule } from './RecurrenceRule';
import {
    RecurrenceRuleFromJSON,
    RecurrenceRuleFromJSONTyped,
    RecurrenceRuleToJSON,
} from './RecurrenceRule';

/**
 * Defines a period of time where an employee has made a specific statement regarding their working preference.
 * @export
 * @interface Unavailability
 */
export interface Unavailability {
    /**
     * 
     * @type {number}
     * @memberof Unavailability
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Unavailability
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof Unavailability
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof Unavailability
     */
    recurrenceRuleId: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Unavailability
     */
    startsAt: Date;
    /**
     * Treated as UTC, and exclusive, eg. 00:00 for midnight rather than 23:59
     * @type {Date}
     * @memberof Unavailability
     */
    endsAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Unavailability
     */
    notes: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Unavailability
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Unavailability
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {Employee}
     * @memberof Unavailability
     */
    employee?: Employee;
    /**
     * 
     * @type {RecurrenceRule}
     * @memberof Unavailability
     */
    recurrenceRule: RecurrenceRule;
}

/**
 * Check if a given object implements the Unavailability interface.
 */
export function instanceOfUnavailability(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('recurrenceRuleId' in value)) return false;
    if (!('startsAt' in value)) return false;
    if (!('endsAt' in value)) return false;
    if (!('notes' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('recurrenceRule' in value)) return false;
    return true;
}

export function UnavailabilityFromJSON(json: any): Unavailability {
    return UnavailabilityFromJSONTyped(json, false);
}

export function UnavailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Unavailability {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'recurrenceRuleId': json['recurrenceRuleId'],
        'startsAt': (new Date(json['startsAt'])),
        'endsAt': (json['endsAt'] == null ? null : new Date(json['endsAt'])),
        'notes': json['notes'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'employee': json['employee'] == null ? undefined : EmployeeFromJSON(json['employee']),
        'recurrenceRule': RecurrenceRuleFromJSON(json['recurrenceRule']),
    };
}

export function UnavailabilityToJSON(value?: Unavailability | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'recurrenceRuleId': value['recurrenceRuleId'],
        'startsAt': ((value['startsAt']).toISOString()),
        'endsAt': (value['endsAt'] == null ? null : (value['endsAt'] as any).toISOString()),
        'notes': value['notes'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'employee': EmployeeToJSON(value['employee']),
        'recurrenceRule': RecurrenceRuleToJSON(value['recurrenceRule']),
    };
}

