/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSetupIntentResponse
 */
export interface CreateSetupIntentResponse {
    /**
     * The client secret for the setup intent
     * @type {string}
     * @memberof CreateSetupIntentResponse
     */
    data: string;
}

/**
 * Check if a given object implements the CreateSetupIntentResponse interface.
 */
export function instanceOfCreateSetupIntentResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateSetupIntentResponseFromJSON(json: any): CreateSetupIntentResponse {
    return CreateSetupIntentResponseFromJSONTyped(json, false);
}

export function CreateSetupIntentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSetupIntentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
    };
}

export function CreateSetupIntentResponseToJSON(value?: CreateSetupIntentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
    };
}

