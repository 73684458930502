/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { ScheduleEvent } from './ScheduleEvent';
import {
    ScheduleEventFromJSON,
    ScheduleEventFromJSONTyped,
    ScheduleEventToJSON,
} from './ScheduleEvent';

/**
 * 
 * @export
 * @interface ListScheduleEventsResponse
 */
export interface ListScheduleEventsResponse {
    /**
     * 
     * @type {Array<ScheduleEvent>}
     * @memberof ListScheduleEventsResponse
     */
    data: Array<ScheduleEvent>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListScheduleEventsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListScheduleEventsResponse interface.
 */
export function instanceOfListScheduleEventsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListScheduleEventsResponseFromJSON(json: any): ListScheduleEventsResponse {
    return ListScheduleEventsResponseFromJSONTyped(json, false);
}

export function ListScheduleEventsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListScheduleEventsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ScheduleEventFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListScheduleEventsResponseToJSON(value?: ListScheduleEventsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(ScheduleEventToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

