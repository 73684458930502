<template>
  <SidebarLayout>
    <template #menu>
      <SettingsMenu />
    </template>
    <template #body>
      <slot />
    </template>
  </SidebarLayout>
</template>

<script>
import SidebarLayout from '@/layouts/SidebarLayout';
import SettingsMenu from '@/views/settings/SettingsMenu';

export default {
  name: 'SettingsLayout',

  components: {
    SidebarLayout,
    SettingsMenu,
  },
};
</script>
