/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimesheetEntry } from './TimesheetEntry';
import {
    TimesheetEntryFromJSON,
    TimesheetEntryFromJSONTyped,
    TimesheetEntryToJSON,
} from './TimesheetEntry';

/**
 * 
 * @export
 * @interface RecordClockOutResponse
 */
export interface RecordClockOutResponse {
    /**
     * 
     * @type {TimesheetEntry}
     * @memberof RecordClockOutResponse
     */
    data: TimesheetEntry;
}

/**
 * Check if a given object implements the RecordClockOutResponse interface.
 */
export function instanceOfRecordClockOutResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function RecordClockOutResponseFromJSON(json: any): RecordClockOutResponse {
    return RecordClockOutResponseFromJSONTyped(json, false);
}

export function RecordClockOutResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordClockOutResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': TimesheetEntryFromJSON(json['data']),
    };
}

export function RecordClockOutResponseToJSON(value?: RecordClockOutResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': TimesheetEntryToJSON(value['data']),
    };
}

