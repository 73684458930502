/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DigiticketsEventSessionsReport
 */
export interface DigiticketsEventSessionsReport {
    /**
     * 
     * @type {string}
     * @memberof DigiticketsEventSessionsReport
     */
    eventName: string;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsEventSessionsReport
     */
    totalSpacesSold: number;
}

/**
 * Check if a given object implements the DigiticketsEventSessionsReport interface.
 */
export function instanceOfDigiticketsEventSessionsReport(value: object): boolean {
    if (!('eventName' in value)) return false;
    if (!('totalSpacesSold' in value)) return false;
    return true;
}

export function DigiticketsEventSessionsReportFromJSON(json: any): DigiticketsEventSessionsReport {
    return DigiticketsEventSessionsReportFromJSONTyped(json, false);
}

export function DigiticketsEventSessionsReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigiticketsEventSessionsReport {
    if (json == null) {
        return json;
    }
    return {
        
        'eventName': json['eventName'],
        'totalSpacesSold': json['totalSpacesSold'],
    };
}

export function DigiticketsEventSessionsReportToJSON(value?: DigiticketsEventSessionsReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'eventName': value['eventName'],
        'totalSpacesSold': value['totalSpacesSold'],
    };
}

