/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCostsByGroup
 */
export interface ReportCostsByGroup {
    /**
     * 
     * @type {string}
     * @memberof ReportCostsByGroup
     */
    groupName: string;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroup
     */
    groupId: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroup
     */
    employees: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportCostsByGroup
     */
    employeeIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroup
     */
    shifts: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroup
     */
    hoursScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroup
     */
    hoursWorked: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroup
     */
    costsScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByGroup
     */
    costsWorked: number | null;
}

/**
 * Check if a given object implements the ReportCostsByGroup interface.
 */
export function instanceOfReportCostsByGroup(value: object): boolean {
    if (!('groupName' in value)) return false;
    if (!('groupId' in value)) return false;
    if (!('employees' in value)) return false;
    if (!('employeeIds' in value)) return false;
    if (!('shifts' in value)) return false;
    if (!('hoursScheduled' in value)) return false;
    if (!('hoursWorked' in value)) return false;
    if (!('costsScheduled' in value)) return false;
    if (!('costsWorked' in value)) return false;
    return true;
}

export function ReportCostsByGroupFromJSON(json: any): ReportCostsByGroup {
    return ReportCostsByGroupFromJSONTyped(json, false);
}

export function ReportCostsByGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCostsByGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'groupName': json['groupName'],
        'groupId': json['groupId'],
        'employees': json['employees'],
        'employeeIds': json['employeeIds'],
        'shifts': json['shifts'],
        'hoursScheduled': json['hoursScheduled'],
        'hoursWorked': json['hoursWorked'],
        'costsScheduled': json['costsScheduled'],
        'costsWorked': json['costsWorked'],
    };
}

export function ReportCostsByGroupToJSON(value?: ReportCostsByGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'groupName': value['groupName'],
        'groupId': value['groupId'],
        'employees': value['employees'],
        'employeeIds': value['employeeIds'],
        'shifts': value['shifts'],
        'hoursScheduled': value['hoursScheduled'],
        'hoursWorked': value['hoursWorked'],
        'costsScheduled': value['costsScheduled'],
        'costsWorked': value['costsWorked'],
    };
}

