import { StorageEnum } from '@/lib/enum/StorageEnum';
import { getLocalStorageItem } from '@/util/storageFunctions';
import { viewport } from '@/util/windowFunctions';
import spacetime from 'spacetime';
import { Company, Employee } from '../../api/v1';

declare global {
  interface Window {
    fcWidget: { [key: string]: any };
  }
}

const ID = 'Freshchat-js-sdk';

const initFreshChat = (employee: Employee, company: Company) => {
  window.fcWidget.init({
    token: '7d1a3c8a-0877-4fc1-97a1-ec3cafd95cd2',
    host: 'https://digitickets.freshchat.com',
    widgetUuid: '7d3e59af-a32d-4634-8176-7ad52b61a863',
    config: {
      fullscreen: !viewport.md,
      headerProperty: {
        hideChatButton: true,
      },
    },
    firstName: employee.firstName,
    lastName: employee.lastName,
    email: employee.contactEmail || 'No Email Address',
  });

  // Additional information
  const releaseVersion =
    process.env.VUE_APP_RELEASE_VERSION ||
    spacetime.now().format('{year}.{iso-month}.{date-pad}.1-dev');
  const apiVersion = getLocalStorageItem(StorageEnum.ApiVersion);

  window.fcWidget?.user.setProperties({
    companyName: company.name,
    version: `FE:${releaseVersion}/API:${apiVersion}`,
  });
};
export const initialiseFreshdeskChat = (
  employee: Employee,
  company: Company,
) => {
  // Can't use CustomElements as Safari doesn't support them
  const fScript = document.createElement('script');
  fScript.src = 'https://digitickets.freshchat.com/js/widget.js';
  fScript.type = 'text/javascript';
  fScript.id = ID;
  // eslint-disable-next-line no-use-before-define
  fScript.onload = openFreshdeskWidget(employee, company);
  document.head.appendChild(fScript);
};

export const freshdeskLoaded = (): boolean =>
  typeof window.fcWidget === 'object';

export const openFreshdeskWidget =
  (employee: Employee, company: Company) => () => {
    if (!freshdeskLoaded() && !document.getElementById(ID)) {
      return initialiseFreshdeskChat(employee, company);
    }

    if (window.fcWidget?.isOpen()) {
      window.fcWidget.close();
    }

    initFreshChat(employee, company);
    window.fcWidget.open({
      name: 'Support',
    });
  };
