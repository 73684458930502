/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface UpdateAuthenticatedUserResponse
 */
export interface UpdateAuthenticatedUserResponse {
    /**
     * 
     * @type {User}
     * @memberof UpdateAuthenticatedUserResponse
     */
    data?: User;
}

/**
 * Check if a given object implements the UpdateAuthenticatedUserResponse interface.
 */
export function instanceOfUpdateAuthenticatedUserResponse(value: object): boolean {
    return true;
}

export function UpdateAuthenticatedUserResponseFromJSON(json: any): UpdateAuthenticatedUserResponse {
    return UpdateAuthenticatedUserResponseFromJSONTyped(json, false);
}

export function UpdateAuthenticatedUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAuthenticatedUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : UserFromJSON(json['data']),
    };
}

export function UpdateAuthenticatedUserResponseToJSON(value?: UpdateAuthenticatedUserResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': UserToJSON(value['data']),
    };
}

