/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeAttribute } from './EmployeeAttribute';
import {
    EmployeeAttributeFromJSON,
    EmployeeAttributeFromJSONTyped,
    EmployeeAttributeToJSON,
} from './EmployeeAttribute';

/**
 * 
 * @export
 * @interface ShowEmployeeAttributeResponse
 */
export interface ShowEmployeeAttributeResponse {
    /**
     * 
     * @type {EmployeeAttribute}
     * @memberof ShowEmployeeAttributeResponse
     */
    data?: EmployeeAttribute;
}

/**
 * Check if a given object implements the ShowEmployeeAttributeResponse interface.
 */
export function instanceOfShowEmployeeAttributeResponse(value: object): boolean {
    return true;
}

export function ShowEmployeeAttributeResponseFromJSON(json: any): ShowEmployeeAttributeResponse {
    return ShowEmployeeAttributeResponseFromJSONTyped(json, false);
}

export function ShowEmployeeAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeAttributeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : EmployeeAttributeFromJSON(json['data']),
    };
}

export function ShowEmployeeAttributeResponseToJSON(value?: ShowEmployeeAttributeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeAttributeToJSON(value['data']),
    };
}

