/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateDigiticketsConnectionData,
  CreateDigiticketsConnectionResponse,
  CreateDigiticketsScheduleMapData,
  CreateDigiticketsScheduleMapResponse,
  CreateTrekksoftAuthenticationData,
  CreateTrekksoftClientCredentialsResponse,
  ListDigiticketsBranchesResponse,
  ListDigiticketsConnectionsResponse,
  ListDigiticketsEventsResponse,
  ListDigiticketsPrebookedVisitorsSummariesResponse,
  ListDigiticketsScheduleMapsResponse,
  ListDigiticketsSessionsOverviewsResponse,
  ListDigiticketsSessionsResponse,
  ListTrekksoftClientCredentialsResponse,
  ListTrekksoftPrebookedVisitorsSummariesResponse,
  ListWeatherForAreaResponse,
  ShiftiePlainDate,
  ShowDigiticketsConnectionResponse,
  ShowTrekksoftClientCredentialsResponse,
  UpdateDigiticketsConnectionData,
  UpdateDigiticketsConnectionResponse,
  UpdateDigiticketsScheduleMapData,
  UpdateDigiticketsScheduleMapResponse,
} from '../models/index';
import {
    CreateDigiticketsConnectionDataFromJSON,
    CreateDigiticketsConnectionDataToJSON,
    CreateDigiticketsConnectionResponseFromJSON,
    CreateDigiticketsConnectionResponseToJSON,
    CreateDigiticketsScheduleMapDataFromJSON,
    CreateDigiticketsScheduleMapDataToJSON,
    CreateDigiticketsScheduleMapResponseFromJSON,
    CreateDigiticketsScheduleMapResponseToJSON,
    CreateTrekksoftAuthenticationDataFromJSON,
    CreateTrekksoftAuthenticationDataToJSON,
    CreateTrekksoftClientCredentialsResponseFromJSON,
    CreateTrekksoftClientCredentialsResponseToJSON,
    ListDigiticketsBranchesResponseFromJSON,
    ListDigiticketsBranchesResponseToJSON,
    ListDigiticketsConnectionsResponseFromJSON,
    ListDigiticketsConnectionsResponseToJSON,
    ListDigiticketsEventsResponseFromJSON,
    ListDigiticketsEventsResponseToJSON,
    ListDigiticketsPrebookedVisitorsSummariesResponseFromJSON,
    ListDigiticketsPrebookedVisitorsSummariesResponseToJSON,
    ListDigiticketsScheduleMapsResponseFromJSON,
    ListDigiticketsScheduleMapsResponseToJSON,
    ListDigiticketsSessionsOverviewsResponseFromJSON,
    ListDigiticketsSessionsOverviewsResponseToJSON,
    ListDigiticketsSessionsResponseFromJSON,
    ListDigiticketsSessionsResponseToJSON,
    ListTrekksoftClientCredentialsResponseFromJSON,
    ListTrekksoftClientCredentialsResponseToJSON,
    ListTrekksoftPrebookedVisitorsSummariesResponseFromJSON,
    ListTrekksoftPrebookedVisitorsSummariesResponseToJSON,
    ListWeatherForAreaResponseFromJSON,
    ListWeatherForAreaResponseToJSON,
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateToJSON,
    ShowDigiticketsConnectionResponseFromJSON,
    ShowDigiticketsConnectionResponseToJSON,
    ShowTrekksoftClientCredentialsResponseFromJSON,
    ShowTrekksoftClientCredentialsResponseToJSON,
    UpdateDigiticketsConnectionDataFromJSON,
    UpdateDigiticketsConnectionDataToJSON,
    UpdateDigiticketsConnectionResponseFromJSON,
    UpdateDigiticketsConnectionResponseToJSON,
    UpdateDigiticketsScheduleMapDataFromJSON,
    UpdateDigiticketsScheduleMapDataToJSON,
    UpdateDigiticketsScheduleMapResponseFromJSON,
    UpdateDigiticketsScheduleMapResponseToJSON,
} from '../models/index';

export interface CreateDigiticketsConnectionRequest {
    createDigiticketsConnectionData?: CreateDigiticketsConnectionData;
}

export interface CreateDigiticketsScheduleMapRequest {
    createDigiticketsScheduleMapData?: CreateDigiticketsScheduleMapData;
}

export interface CreateTrekksoftClientCredentialsRequest {
    createTrekksoftAuthenticationData?: CreateTrekksoftAuthenticationData;
}

export interface DeleteDigiticketsConnectionRequest {
    id: string;
}

export interface DeleteDigiticketsScheduleMapRequest {
    id: string;
}

export interface DeleteTrekksoftClientCredentialsRequest {
    id: number;
}

export interface ListDigiticketsBranchesRequest {
    connectionId: number;
}

export interface ListDigiticketsEventsRequest {
    connectionId: number;
    branchIds?: Array<number>;
    includeEmpty?: boolean;
}

export interface ListDigiticketsPrebookedVisitorsSummariesRequest {
    branchId: number;
    connectionId: number;
    startDate: ShiftiePlainDate;
    endDate: ShiftiePlainDate;
}

export interface ListDigiticketsSessionsRequest {
    connectionId: number;
    branchId: number;
    eventIds: Array<number>;
    date: ShiftiePlainDate;
    page?: number;
    perPage?: number;
}

export interface ListDigiticketsSessionsOverviewsRequest {
    connectionId: number;
    branchId: number;
    eventIds: Array<number>;
    startDate: ShiftiePlainDate;
    endDate: ShiftiePlainDate;
}

export interface ListTrekksoftPrebookedVisitorsSummariesRequest {
    startDate: ShiftiePlainDate;
    endDate: ShiftiePlainDate;
    credentialsId: number;
}

export interface ListWeatherForAreaRequest {
    dt: number;
    lat: number;
    lon: number;
}

export interface ShowDigiticketsConnectionRequest {
    id: string;
}

export interface ShowTrekksoftClientCredentialsRequest {
    id: number;
}

export interface UpdateDigiticketsConnectionRequest {
    id: string;
    updateDigiticketsConnectionData?: UpdateDigiticketsConnectionData;
}

export interface UpdateDigiticketsScheduleMapRequest {
    id: string;
    updateDigiticketsScheduleMapData?: UpdateDigiticketsScheduleMapData;
}

/**
 * 
 */
export class IntegrationApi extends runtime.BaseAPI {

    /**
     * Create Digitickets Connection
     */
    async createDigiticketsConnectionRaw(requestParameters: CreateDigiticketsConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDigiticketsConnectionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-connection.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/connections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDigiticketsConnectionDataToJSON(requestParameters['createDigiticketsConnectionData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDigiticketsConnectionResponseFromJSON(jsonValue));
    }

    /**
     * Create Digitickets Connection
     */
    async createDigiticketsConnection(requestParameters: CreateDigiticketsConnectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDigiticketsConnectionResponse> {
        const response = await this.createDigiticketsConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Digitickets Schedule Map
     */
    async createDigiticketsScheduleMapRaw(requestParameters: CreateDigiticketsScheduleMapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDigiticketsScheduleMapResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-schedule-map.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/schedule-maps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDigiticketsScheduleMapDataToJSON(requestParameters['createDigiticketsScheduleMapData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDigiticketsScheduleMapResponseFromJSON(jsonValue));
    }

    /**
     * Create Digitickets Schedule Map
     */
    async createDigiticketsScheduleMap(requestParameters: CreateDigiticketsScheduleMapRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDigiticketsScheduleMapResponse> {
        const response = await this.createDigiticketsScheduleMapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Trekksoft Client Credential
     */
    async createTrekksoftClientCredentialsRaw(requestParameters: CreateTrekksoftClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTrekksoftClientCredentialsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.trekksoft-credentials.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/trekksoft/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTrekksoftAuthenticationDataToJSON(requestParameters['createTrekksoftAuthenticationData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTrekksoftClientCredentialsResponseFromJSON(jsonValue));
    }

    /**
     * Create Trekksoft Client Credential
     */
    async createTrekksoftClientCredentials(requestParameters: CreateTrekksoftClientCredentialsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTrekksoftClientCredentialsResponse> {
        const response = await this.createTrekksoftClientCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Digitickets Connection
     */
    async deleteDigiticketsConnectionRaw(requestParameters: DeleteDigiticketsConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDigiticketsConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-connection.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/connections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Digitickets Connection
     */
    async deleteDigiticketsConnection(requestParameters: DeleteDigiticketsConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDigiticketsConnectionRaw(requestParameters, initOverrides);
    }

    /**
     * 
     * Delete Digitickets Schedule Map
     */
    async deleteDigiticketsScheduleMapRaw(requestParameters: DeleteDigiticketsScheduleMapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDigiticketsScheduleMap().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-schedule-map.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/schedule-maps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     * Delete Digitickets Schedule Map
     */
    async deleteDigiticketsScheduleMap(requestParameters: DeleteDigiticketsScheduleMapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDigiticketsScheduleMapRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Trekksoft Client Credentials
     */
    async deleteTrekksoftClientCredentialsRaw(requestParameters: DeleteTrekksoftClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteTrekksoftClientCredentials().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.trekksoft-credentials.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/trekksoft/auth/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Trekksoft Client Credentials
     */
    async deleteTrekksoftClientCredentials(requestParameters: DeleteTrekksoftClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTrekksoftClientCredentialsRaw(requestParameters, initOverrides);
    }

    /**
     * List Branches
     */
    async listDigiticketsBranchesRaw(requestParameters: ListDigiticketsBranchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDigiticketsBranchesResponse>> {
        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling listDigiticketsBranches().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['connectionId'] != null) {
            queryParameters['connectionId'] = requestParameters['connectionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-branch.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/branches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDigiticketsBranchesResponseFromJSON(jsonValue));
    }

    /**
     * List Branches
     */
    async listDigiticketsBranches(requestParameters: ListDigiticketsBranchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDigiticketsBranchesResponse> {
        const response = await this.listDigiticketsBranchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Digitickets Connections
     */
    async listDigiticketsConnectionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDigiticketsConnectionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-connection.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/connections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDigiticketsConnectionsResponseFromJSON(jsonValue));
    }

    /**
     * List Digitickets Connections
     */
    async listDigiticketsConnections(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDigiticketsConnectionsResponse> {
        const response = await this.listDigiticketsConnectionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List Digitickets Events
     */
    async listDigiticketsEventsRaw(requestParameters: ListDigiticketsEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDigiticketsEventsResponse>> {
        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling listDigiticketsEvents().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['connectionId'] != null) {
            queryParameters['connectionId'] = requestParameters['connectionId'];
        }

        if (requestParameters['branchIds'] != null) {
            queryParameters['branchIds'] = requestParameters['branchIds'];
        }

        if (requestParameters['includeEmpty'] != null) {
            queryParameters['includeEmpty'] = requestParameters['includeEmpty'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-event.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDigiticketsEventsResponseFromJSON(jsonValue));
    }

    /**
     * List Digitickets Events
     */
    async listDigiticketsEvents(requestParameters: ListDigiticketsEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDigiticketsEventsResponse> {
        const response = await this.listDigiticketsEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Produce a daily list of pre-booked visitors between 2 dates for a given branch.
     * List Prebooked Visitors Summaries
     */
    async listDigiticketsPrebookedVisitorsSummariesRaw(requestParameters: ListDigiticketsPrebookedVisitorsSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDigiticketsPrebookedVisitorsSummariesResponse>> {
        if (requestParameters['branchId'] == null) {
            throw new runtime.RequiredError(
                'branchId',
                'Required parameter "branchId" was null or undefined when calling listDigiticketsPrebookedVisitorsSummaries().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling listDigiticketsPrebookedVisitorsSummaries().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling listDigiticketsPrebookedVisitorsSummaries().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling listDigiticketsPrebookedVisitorsSummaries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['branchId'] != null) {
            queryParameters['branchId'] = requestParameters['branchId'];
        }

        if (requestParameters['connectionId'] != null) {
            queryParameters['connectionId'] = requestParameters['connectionId'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-prebooked-visitor.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/pre-booked-visitors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDigiticketsPrebookedVisitorsSummariesResponseFromJSON(jsonValue));
    }

    /**
     * Produce a daily list of pre-booked visitors between 2 dates for a given branch.
     * List Prebooked Visitors Summaries
     */
    async listDigiticketsPrebookedVisitorsSummaries(requestParameters: ListDigiticketsPrebookedVisitorsSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDigiticketsPrebookedVisitorsSummariesResponse> {
        const response = await this.listDigiticketsPrebookedVisitorsSummariesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Digitickets Schedule Maps
     */
    async listDigiticketsScheduleMapsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDigiticketsScheduleMapsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-schedule-map.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/schedule-maps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDigiticketsScheduleMapsResponseFromJSON(jsonValue));
    }

    /**
     * List Digitickets Schedule Maps
     */
    async listDigiticketsScheduleMaps(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDigiticketsScheduleMapsResponse> {
        const response = await this.listDigiticketsScheduleMapsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all sessions for a given date across a number of given events.
     * List Digitickets Sessions
     */
    async listDigiticketsSessionsRaw(requestParameters: ListDigiticketsSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDigiticketsSessionsResponse>> {
        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling listDigiticketsSessions().'
            );
        }

        if (requestParameters['branchId'] == null) {
            throw new runtime.RequiredError(
                'branchId',
                'Required parameter "branchId" was null or undefined when calling listDigiticketsSessions().'
            );
        }

        if (requestParameters['eventIds'] == null) {
            throw new runtime.RequiredError(
                'eventIds',
                'Required parameter "eventIds" was null or undefined when calling listDigiticketsSessions().'
            );
        }

        if (requestParameters['date'] == null) {
            throw new runtime.RequiredError(
                'date',
                'Required parameter "date" was null or undefined when calling listDigiticketsSessions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['connectionId'] != null) {
            queryParameters['connectionId'] = requestParameters['connectionId'];
        }

        if (requestParameters['branchId'] != null) {
            queryParameters['branchId'] = requestParameters['branchId'];
        }

        if (requestParameters['eventIds'] != null) {
            queryParameters['eventIds'] = requestParameters['eventIds'];
        }

        if (requestParameters['date'] != null) {
            queryParameters['date'] = requestParameters['date'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-session.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDigiticketsSessionsResponseFromJSON(jsonValue));
    }

    /**
     * List all sessions for a given date across a number of given events.
     * List Digitickets Sessions
     */
    async listDigiticketsSessions(requestParameters: ListDigiticketsSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDigiticketsSessionsResponse> {
        const response = await this.listDigiticketsSessionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a per-day list of reports for a given date-range, showing spaces sold for chosen events across all sessions.
     * List Digitickets Sessions Overviews
     */
    async listDigiticketsSessionsOverviewsRaw(requestParameters: ListDigiticketsSessionsOverviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDigiticketsSessionsOverviewsResponse>> {
        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling listDigiticketsSessionsOverviews().'
            );
        }

        if (requestParameters['branchId'] == null) {
            throw new runtime.RequiredError(
                'branchId',
                'Required parameter "branchId" was null or undefined when calling listDigiticketsSessionsOverviews().'
            );
        }

        if (requestParameters['eventIds'] == null) {
            throw new runtime.RequiredError(
                'eventIds',
                'Required parameter "eventIds" was null or undefined when calling listDigiticketsSessionsOverviews().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling listDigiticketsSessionsOverviews().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling listDigiticketsSessionsOverviews().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['connectionId'] != null) {
            queryParameters['connectionId'] = requestParameters['connectionId'];
        }

        if (requestParameters['branchId'] != null) {
            queryParameters['branchId'] = requestParameters['branchId'];
        }

        if (requestParameters['eventIds'] != null) {
            queryParameters['eventIds'] = requestParameters['eventIds'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-session.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/reports/sessions-overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDigiticketsSessionsOverviewsResponseFromJSON(jsonValue));
    }

    /**
     * Get a per-day list of reports for a given date-range, showing spaces sold for chosen events across all sessions.
     * List Digitickets Sessions Overviews
     */
    async listDigiticketsSessionsOverviews(requestParameters: ListDigiticketsSessionsOverviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDigiticketsSessionsOverviewsResponse> {
        const response = await this.listDigiticketsSessionsOverviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * List Trekksoft Client Credentials
     */
    async listTrekksoftClientCredentialsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTrekksoftClientCredentialsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.trekksoft-credentials.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/trekksoft/auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTrekksoftClientCredentialsResponseFromJSON(jsonValue));
    }

    /**
     * 
     * List Trekksoft Client Credentials
     */
    async listTrekksoftClientCredentials(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTrekksoftClientCredentialsResponse> {
        const response = await this.listTrekksoftClientCredentialsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Produce a daily list of pre-booked visitors between 2 dates.
     * List Prebooked Visitors Summaries
     */
    async listTrekksoftPrebookedVisitorsSummariesRaw(requestParameters: ListTrekksoftPrebookedVisitorsSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTrekksoftPrebookedVisitorsSummariesResponse>> {
        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling listTrekksoftPrebookedVisitorsSummaries().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling listTrekksoftPrebookedVisitorsSummaries().'
            );
        }

        if (requestParameters['credentialsId'] == null) {
            throw new runtime.RequiredError(
                'credentialsId',
                'Required parameter "credentialsId" was null or undefined when calling listTrekksoftPrebookedVisitorsSummaries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['credentialsId'] != null) {
            queryParameters['credentialsId'] = requestParameters['credentialsId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.trekksoft-prebooked-visitors.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/trekksoft/pre-booked-visitors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTrekksoftPrebookedVisitorsSummariesResponseFromJSON(jsonValue));
    }

    /**
     * Produce a daily list of pre-booked visitors between 2 dates.
     * List Prebooked Visitors Summaries
     */
    async listTrekksoftPrebookedVisitorsSummaries(requestParameters: ListTrekksoftPrebookedVisitorsSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTrekksoftPrebookedVisitorsSummariesResponse> {
        const response = await this.listTrekksoftPrebookedVisitorsSummariesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Obtain weather information about a specified area during a specified time period
     * Retrieve weather information
     */
    async listWeatherForAreaRaw(requestParameters: ListWeatherForAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListWeatherForAreaResponse>> {
        if (requestParameters['dt'] == null) {
            throw new runtime.RequiredError(
                'dt',
                'Required parameter "dt" was null or undefined when calling listWeatherForArea().'
            );
        }

        if (requestParameters['lat'] == null) {
            throw new runtime.RequiredError(
                'lat',
                'Required parameter "lat" was null or undefined when calling listWeatherForArea().'
            );
        }

        if (requestParameters['lon'] == null) {
            throw new runtime.RequiredError(
                'lon',
                'Required parameter "lon" was null or undefined when calling listWeatherForArea().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['dt'] != null) {
            queryParameters['dt'] = requestParameters['dt'];
        }

        if (requestParameters['lat'] != null) {
            queryParameters['lat'] = requestParameters['lat'];
        }

        if (requestParameters['lon'] != null) {
            queryParameters['lon'] = requestParameters['lon'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.weather.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/weather`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListWeatherForAreaResponseFromJSON(jsonValue));
    }

    /**
     * Obtain weather information about a specified area during a specified time period
     * Retrieve weather information
     */
    async listWeatherForArea(requestParameters: ListWeatherForAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListWeatherForAreaResponse> {
        const response = await this.listWeatherForAreaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Digitickets Connection
     */
    async showDigiticketsConnectionRaw(requestParameters: ShowDigiticketsConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowDigiticketsConnectionResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showDigiticketsConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-connection.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/connections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowDigiticketsConnectionResponseFromJSON(jsonValue));
    }

    /**
     * Show Digitickets Connection
     */
    async showDigiticketsConnection(requestParameters: ShowDigiticketsConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowDigiticketsConnectionResponse> {
        const response = await this.showDigiticketsConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Show Trekksoft Client Credentials
     */
    async showTrekksoftClientCredentialsRaw(requestParameters: ShowTrekksoftClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowTrekksoftClientCredentialsResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showTrekksoftClientCredentials().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.trekksoft-credentials.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/trekksoft/auth/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowTrekksoftClientCredentialsResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Show Trekksoft Client Credentials
     */
    async showTrekksoftClientCredentials(requestParameters: ShowTrekksoftClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowTrekksoftClientCredentialsResponse> {
        const response = await this.showTrekksoftClientCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Update Digitickets Connection
     */
    async updateDigiticketsConnectionRaw(requestParameters: UpdateDigiticketsConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDigiticketsConnectionResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateDigiticketsConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-connection.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/connections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDigiticketsConnectionDataToJSON(requestParameters['updateDigiticketsConnectionData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateDigiticketsConnectionResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Update Digitickets Connection
     */
    async updateDigiticketsConnection(requestParameters: UpdateDigiticketsConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDigiticketsConnectionResponse> {
        const response = await this.updateDigiticketsConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Digitickets Schedule Map
     */
    async updateDigiticketsScheduleMapRaw(requestParameters: UpdateDigiticketsScheduleMapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDigiticketsScheduleMapResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateDigiticketsScheduleMap().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.digitickets-schedule-map.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/digitickets/schedule-maps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDigiticketsScheduleMapDataToJSON(requestParameters['updateDigiticketsScheduleMapData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateDigiticketsScheduleMapResponseFromJSON(jsonValue));
    }

    /**
     * Update Digitickets Schedule Map
     */
    async updateDigiticketsScheduleMap(requestParameters: UpdateDigiticketsScheduleMapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDigiticketsScheduleMapResponse> {
        const response = await this.updateDigiticketsScheduleMapRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
