
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

/**
 * See here for details on the stripe documentation for how to use stripe elements:
 * https://stripe.com/docs/js/elements_object/create_element?type=cardNumber
 */

export default {
  name: 'StripeElement',

  props: {
    labelText: {
      type: [String, TranslateResult],
      required: true,
    },

    stripe: {
      type: Object,
      required: true,
    },

    placeholder: {
      type: String,
      default: '',
    },

    element: {
      type: String,
      required: true,
    },

    options: {
      type: Object,
      default: null,
    },

    styles: {
      type: Object,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
    },

    handleErrors: {
      type: String,
      default: 'ignore',
      validator: (prop) => ['show', 'emit', 'ignore'].includes(prop),
    },
  },

  data() {
    return {
      mounted: false,
      error: { id: this.element, message: null, event: null },
    };
  },

  computed: {
    elements() {
      return this.stripe.stripeElements;
    },
  },

  mounted() {
    const element = this.elements.create(this.element, {
      classes: {
        base: 'card-details',
      },
      placeholder: this.placeholder
        ? this.$t(`placeholder.${this.placeholder}`)
        : this.labelText,
      style: {
        base: {
          '::placeholder': { color: '#E8E9EC' },
          fontWeight: 'normal',
          ...this.styles,
        },
      },
      ...this.options,
    });
    element.mount(this.$refs[this.element]);
    this.$emit('mounted', this.element);

    // On input emit an event when the form element is completed and valid
    element.on('change', (event) => {
      this.error.event = event;
      if (event.complete) {
        this.$emit('complete', this.elements.getElement(this.element));
        this.$emit('value', event.value);
      }

      if (event.empty && this.required) {
        this.error.message = `${this.labelText} is required`;
      } else if (event.error) {
        this.error.message = event.error.message;
      } else {
        this.error.message = null;
      }

      this.$emit('error', this.error);
    });
  },

  methods: {
    destroy(field) {
      this.elements.getElement(field).destroy();
    },
  },
};
