/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCostsByJobRole
 */
export interface ReportCostsByJobRole {
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRole
     */
    jobRoleId: number;
    /**
     * 
     * @type {string}
     * @memberof ReportCostsByJobRole
     */
    jobRole: string;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRole
     */
    employees: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportCostsByJobRole
     */
    employeeIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRole
     */
    shifts: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRole
     */
    hoursScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRole
     */
    hoursWorked: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRole
     */
    costsScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRole
     */
    costsWorked: number | null;
}

/**
 * Check if a given object implements the ReportCostsByJobRole interface.
 */
export function instanceOfReportCostsByJobRole(value: object): boolean {
    if (!('jobRoleId' in value)) return false;
    if (!('jobRole' in value)) return false;
    if (!('employees' in value)) return false;
    if (!('employeeIds' in value)) return false;
    if (!('shifts' in value)) return false;
    if (!('hoursScheduled' in value)) return false;
    if (!('hoursWorked' in value)) return false;
    if (!('costsScheduled' in value)) return false;
    if (!('costsWorked' in value)) return false;
    return true;
}

export function ReportCostsByJobRoleFromJSON(json: any): ReportCostsByJobRole {
    return ReportCostsByJobRoleFromJSONTyped(json, false);
}

export function ReportCostsByJobRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCostsByJobRole {
    if (json == null) {
        return json;
    }
    return {
        
        'jobRoleId': json['jobRoleId'],
        'jobRole': json['jobRole'],
        'employees': json['employees'],
        'employeeIds': json['employeeIds'],
        'shifts': json['shifts'],
        'hoursScheduled': json['hoursScheduled'],
        'hoursWorked': json['hoursWorked'],
        'costsScheduled': json['costsScheduled'],
        'costsWorked': json['costsWorked'],
    };
}

export function ReportCostsByJobRoleToJSON(value?: ReportCostsByJobRole | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'jobRoleId': value['jobRoleId'],
        'jobRole': value['jobRole'],
        'employees': value['employees'],
        'employeeIds': value['employeeIds'],
        'shifts': value['shifts'],
        'hoursScheduled': value['hoursScheduled'],
        'hoursWorked': value['hoursWorked'],
        'costsScheduled': value['costsScheduled'],
        'costsWorked': value['costsWorked'],
    };
}

