/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanySetting } from './CompanySetting';
import {
    CompanySettingFromJSON,
    CompanySettingFromJSONTyped,
    CompanySettingToJSON,
} from './CompanySetting';

/**
 * 
 * @export
 * @interface UpdateCompanySettingsResponse
 */
export interface UpdateCompanySettingsResponse {
    /**
     * 
     * @type {CompanySetting}
     * @memberof UpdateCompanySettingsResponse
     */
    data?: CompanySetting;
}

/**
 * Check if a given object implements the UpdateCompanySettingsResponse interface.
 */
export function instanceOfUpdateCompanySettingsResponse(value: object): boolean {
    return true;
}

export function UpdateCompanySettingsResponseFromJSON(json: any): UpdateCompanySettingsResponse {
    return UpdateCompanySettingsResponseFromJSONTyped(json, false);
}

export function UpdateCompanySettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanySettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : CompanySettingFromJSON(json['data']),
    };
}

export function UpdateCompanySettingsResponseToJSON(value?: UpdateCompanySettingsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': CompanySettingToJSON(value['data']),
    };
}

