var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading && _vm.employee)?_c('div',{staticClass:"rounded-full relative inline-block",class:[
    {
      'border-2 p-[2px]': _vm.highlight,
      'cursor-pointer': _vm.interaction,
      highlight: _vm.highlight,
    },
    `border-${_vm.highlightColour}`,
  ],style:(`padding: ${_vm.highlight ? 2 : 0}px;`),on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('div',{staticClass:"avatar",class:[
      {
        'bg-purple-800': !_vm.photo || _vm.imageLoadFailed,
        'avatar--photo': _vm.photo && !_vm.imageLoadFailed,
        'is-highlighted': _vm.highlight,
        interaction: _vm.interaction,
      },
      `avatar--${_vm.size}`,
    ]},[(_vm.photo && !_vm.imageLoadFailed)?_c('img',{staticClass:"w-full h-full",attrs:{"alt":`Profile photo for ${_vm.name}`,"src":_vm.src},on:{"error":function($event){_vm.imageLoadFailed = true}}}):_c('span',{staticClass:"avatar__initials text-white"},[_vm._v(" "+_vm._s(_vm.initials)+" ")]),_vm._t("dot",function(){return [(_vm.showStatus)?_c('EmployeeStatusDot',{attrs:{"employee":_vm.employee,"absolute":""}}):_vm._e()]})],2)]):_c('AvatarPlaceholder',{attrs:{"iterate":1}})
}
var staticRenderFns = []

export { render, staticRenderFns }