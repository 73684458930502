/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateShiftAreaData
 */
export interface UpdateShiftAreaData {
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftAreaData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftAreaData
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftAreaData
     */
    colour?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftAreaData
     */
    status?: UpdateShiftAreaDataStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateShiftAreaData
     */
    locationIds?: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum UpdateShiftAreaDataStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the UpdateShiftAreaData interface.
 */
export function instanceOfUpdateShiftAreaData(value: object): boolean {
    return true;
}

export function UpdateShiftAreaDataFromJSON(json: any): UpdateShiftAreaData {
    return UpdateShiftAreaDataFromJSONTyped(json, false);
}

export function UpdateShiftAreaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShiftAreaData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'code': json['code'] == null ? undefined : json['code'],
        'colour': json['colour'] == null ? undefined : json['colour'],
        'status': json['status'] == null ? undefined : json['status'],
        'locationIds': json['locationIds'] == null ? undefined : json['locationIds'],
    };
}

export function UpdateShiftAreaDataToJSON(value?: UpdateShiftAreaData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'code': value['code'],
        'colour': value['colour'],
        'status': value['status'],
        'locationIds': value['locationIds'],
    };
}

