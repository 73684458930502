/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Credit } from './Credit';
import {
    CreditFromJSON,
    CreditFromJSONTyped,
    CreditToJSON,
} from './Credit';

/**
 * 
 * @export
 * @interface TriggerCreditRenewalResponse
 */
export interface TriggerCreditRenewalResponse {
    /**
     * 
     * @type {Credit}
     * @memberof TriggerCreditRenewalResponse
     */
    data: Credit;
}

/**
 * Check if a given object implements the TriggerCreditRenewalResponse interface.
 */
export function instanceOfTriggerCreditRenewalResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function TriggerCreditRenewalResponseFromJSON(json: any): TriggerCreditRenewalResponse {
    return TriggerCreditRenewalResponseFromJSONTyped(json, false);
}

export function TriggerCreditRenewalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TriggerCreditRenewalResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CreditFromJSON(json['data']),
    };
}

export function TriggerCreditRenewalResponseToJSON(value?: TriggerCreditRenewalResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': CreditToJSON(value['data']),
    };
}

