/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftTemplateAreaSession } from './ShiftTemplateAreaSession';
import {
    ShiftTemplateAreaSessionFromJSON,
    ShiftTemplateAreaSessionFromJSONTyped,
    ShiftTemplateAreaSessionToJSON,
} from './ShiftTemplateAreaSession';
import type { ShiftTemplateBreak } from './ShiftTemplateBreak';
import {
    ShiftTemplateBreakFromJSON,
    ShiftTemplateBreakFromJSONTyped,
    ShiftTemplateBreakToJSON,
} from './ShiftTemplateBreak';

/**
 * 
 * @export
 * @interface CreateShiftTemplateData
 */
export interface CreateShiftTemplateData {
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTemplateData
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    jobRoleId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    startHour: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    startMinute: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    endHour: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    endMinute: number;
    /**
     * Whether to show the shift end time to non-managerial staff
     * @type {boolean}
     * @memberof CreateShiftTemplateData
     */
    showEndTime?: boolean;
    /**
     * Whether to show the job role to non-managerial staff
     * @type {boolean}
     * @memberof CreateShiftTemplateData
     */
    showJobRole?: boolean;
    /**
     * 
     * @type {Array<ShiftTemplateBreak>}
     * @memberof CreateShiftTemplateData
     */
    shiftTemplateBreaks?: Array<ShiftTemplateBreak>;
    /**
     * 
     * @type {Array<ShiftTemplateAreaSession>}
     * @memberof CreateShiftTemplateData
     */
    shiftTemplateAreaSessions?: Array<ShiftTemplateAreaSession>;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTemplateData
     */
    notes?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateShiftTemplateData
     */
    tags?: Array<number>;
}

/**
 * Check if a given object implements the CreateShiftTemplateData interface.
 */
export function instanceOfCreateShiftTemplateData(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('startHour' in value)) return false;
    if (!('startMinute' in value)) return false;
    if (!('endHour' in value)) return false;
    if (!('endMinute' in value)) return false;
    return true;
}

export function CreateShiftTemplateDataFromJSON(json: any): CreateShiftTemplateData {
    return CreateShiftTemplateDataFromJSONTyped(json, false);
}

export function CreateShiftTemplateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftTemplateData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'jobRoleId': json['jobRoleId'] == null ? undefined : json['jobRoleId'],
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'endHour': json['endHour'],
        'endMinute': json['endMinute'],
        'showEndTime': json['showEndTime'] == null ? undefined : json['showEndTime'],
        'showJobRole': json['showJobRole'] == null ? undefined : json['showJobRole'],
        'shiftTemplateBreaks': json['shiftTemplateBreaks'] == null ? undefined : ((json['shiftTemplateBreaks'] as Array<any>).map(ShiftTemplateBreakFromJSON)),
        'shiftTemplateAreaSessions': json['shiftTemplateAreaSessions'] == null ? undefined : ((json['shiftTemplateAreaSessions'] as Array<any>).map(ShiftTemplateAreaSessionFromJSON)),
        'notes': json['notes'] == null ? undefined : json['notes'],
        'tags': json['tags'] == null ? undefined : json['tags'],
    };
}

export function CreateShiftTemplateDataToJSON(value?: CreateShiftTemplateData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'locationId': value['locationId'],
        'jobRoleId': value['jobRoleId'],
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'endHour': value['endHour'],
        'endMinute': value['endMinute'],
        'showEndTime': value['showEndTime'],
        'showJobRole': value['showJobRole'],
        'shiftTemplateBreaks': value['shiftTemplateBreaks'] == null ? undefined : ((value['shiftTemplateBreaks'] as Array<any>).map(ShiftTemplateBreakToJSON)),
        'shiftTemplateAreaSessions': value['shiftTemplateAreaSessions'] == null ? undefined : ((value['shiftTemplateAreaSessions'] as Array<any>).map(ShiftTemplateAreaSessionToJSON)),
        'notes': value['notes'],
        'tags': value['tags'],
    };
}

