/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginData,
  LoginResponse,
  RefreshAccessTokenResponse,
  RefreshData,
  VerifyOBOTokenResponse,
  VerifyOboTokenData,
} from '../models/index';
import {
    LoginDataFromJSON,
    LoginDataToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    RefreshAccessTokenResponseFromJSON,
    RefreshAccessTokenResponseToJSON,
    RefreshDataFromJSON,
    RefreshDataToJSON,
    VerifyOBOTokenResponseFromJSON,
    VerifyOBOTokenResponseToJSON,
    VerifyOboTokenDataFromJSON,
    VerifyOboTokenDataToJSON,
} from '../models/index';

export interface LoginRequest {
    _with?: Array<string>;
    includeRefreshTokenInResponse?: boolean;
    loginData?: LoginData;
}

export interface LogoutRequest {
    logoutEverywhere?: boolean;
}

export interface RefreshAccessTokenRequest {
    refreshData?: RefreshData;
}

export interface VerifyOBOTokenRequest {
    verifyOboTokenData?: VerifyOboTokenData;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     * Authenticate using a set of credentials.
     * Login
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['includeRefreshTokenInResponse'] != null) {
            queryParameters['includeRefreshTokenInResponse'] = requestParameters['includeRefreshTokenInResponse'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDataToJSON(requestParameters['loginData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Authenticate using a set of credentials.
     * Login
     */
    async login(requestParameters: LoginRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invalidate your current credentials set.
     * Logout
     */
    async logoutRaw(requestParameters: LogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['logoutEverywhere'] != null) {
            queryParameters['logoutEverywhere'] = requestParameters['logoutEverywhere'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Invalidate your current credentials set.
     * Logout
     */
    async logout(requestParameters: LogoutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutRaw(requestParameters, initOverrides);
    }

    /**
     * Obtain a new token using a refresh token. Token can either be obtained by the cookie set when logging in, or by directly passing a token in the request body.  NOTE: Refresh tokens supplied via a cookie are prioritised.
     * Refresh Access Token
     */
    async refreshAccessTokenRaw(requestParameters: RefreshAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshAccessTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshDataToJSON(requestParameters['refreshData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshAccessTokenResponseFromJSON(jsonValue));
    }

    /**
     * Obtain a new token using a refresh token. Token can either be obtained by the cookie set when logging in, or by directly passing a token in the request body.  NOTE: Refresh tokens supplied via a cookie are prioritised.
     * Refresh Access Token
     */
    async refreshAccessToken(requestParameters: RefreshAccessTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshAccessTokenResponse> {
        const response = await this.refreshAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify an \'on behalf of\' token.
     * Verify OBO Token
     */
    async verifyOBOTokenRaw(requestParameters: VerifyOBOTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VerifyOBOTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/obo/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyOboTokenDataToJSON(requestParameters['verifyOboTokenData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyOBOTokenResponseFromJSON(jsonValue));
    }

    /**
     * Verify an \'on behalf of\' token.
     * Verify OBO Token
     */
    async verifyOBOToken(requestParameters: VerifyOBOTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VerifyOBOTokenResponse> {
        const response = await this.verifyOBOTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
