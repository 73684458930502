import { render, staticRenderFns } from "./DateSquareV2.vue?vue&type=template&id=36c3a0ed&scoped=true&"
import script from "./DateSquareV2.vue?vue&type=script&lang=ts&"
export * from "./DateSquareV2.vue?vue&type=script&lang=ts&"
import style0 from "./DateSquareV2.vue?vue&type=style&index=0&id=36c3a0ed&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c3a0ed",
  null
  
)

export default component.exports