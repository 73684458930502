/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Shows that an employee has read a document that was assigned to them
 * @export
 * @interface DocumentRead
 */
export interface DocumentRead {
    /**
     * 
     * @type {number}
     * @memberof DocumentRead
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentRead
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentRead
     */
    documentId: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentRead
     */
    employeeId: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentRead
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the DocumentRead interface.
 */
export function instanceOfDocumentRead(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('documentId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function DocumentReadFromJSON(json: any): DocumentRead {
    return DocumentReadFromJSONTyped(json, false);
}

export function DocumentReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentRead {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'documentId': json['documentId'],
        'employeeId': json['employeeId'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function DocumentReadToJSON(value?: DocumentRead | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'documentId': value['documentId'],
        'employeeId': value['employeeId'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

