/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpenShiftResponse
 */
export interface OpenShiftResponse {
    /**
     * 
     * @type {number}
     * @memberof OpenShiftResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OpenShiftResponse
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof OpenShiftResponse
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof OpenShiftResponse
     */
    shiftId: number;
    /**
     * Identifies all responses from the same employee for identical/matching shifts
     * @type {string}
     * @memberof OpenShiftResponse
     */
    series: string;
    /**
     * 
     * @type {string}
     * @memberof OpenShiftResponse
     */
    status: OpenShiftResponseStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpenShiftResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpenShiftResponse
     */
    updatedAt: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum OpenShiftResponseStatusEnum {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}


/**
 * Check if a given object implements the OpenShiftResponse interface.
 */
export function instanceOfOpenShiftResponse(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('shiftId' in value)) return false;
    if (!('series' in value)) return false;
    if (!('status' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function OpenShiftResponseFromJSON(json: any): OpenShiftResponse {
    return OpenShiftResponseFromJSONTyped(json, false);
}

export function OpenShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenShiftResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'shiftId': json['shiftId'],
        'series': json['series'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

export function OpenShiftResponseToJSON(value?: OpenShiftResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'shiftId': value['shiftId'],
        'series': value['series'],
        'status': value['status'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

