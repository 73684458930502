/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateScheduleData
 */
export interface CreateScheduleData {
    /**
     * 
     * @type {number}
     * @memberof CreateScheduleData
     */
    defaultLocationId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduleData
     */
    name: string;
}

/**
 * Check if a given object implements the CreateScheduleData interface.
 */
export function instanceOfCreateScheduleData(value: object): boolean {
    if (!('defaultLocationId' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function CreateScheduleDataFromJSON(json: any): CreateScheduleData {
    return CreateScheduleDataFromJSONTyped(json, false);
}

export function CreateScheduleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScheduleData {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultLocationId': json['defaultLocationId'],
        'name': json['name'],
    };
}

export function CreateScheduleDataToJSON(value?: CreateScheduleData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'defaultLocationId': value['defaultLocationId'],
        'name': value['name'],
    };
}

