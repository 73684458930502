/**
 * @export
 * @enum {string}
 * This enum is a collection of all browser local storage names
 */
export enum StorageEnum {
  AddShiftieToHome = 'addShiftieToHome',
  ApiVersion = 'apiVersion',
  CompanyId = 'companyId',
  FromRoute = 'fromRoute',
  Info = 'info',
  LastChunkReload = 'lastChunkReload',
  OfferCode = 'offerCode',
  ScheduleFilter = 'shiftieScheduleFilter',
  ScheduleViewOptions = 'scheduleViewOptions',
  ShowTips = 'showTips',
  Version = 'version',
  OnboardingClosed = 'onboardingClosed',
}
