/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
} from './JobRole';

/**
 * 
 * @export
 * @interface UpdateJobRoleResponse
 */
export interface UpdateJobRoleResponse {
    /**
     * 
     * @type {JobRole}
     * @memberof UpdateJobRoleResponse
     */
    data?: JobRole;
}

/**
 * Check if a given object implements the UpdateJobRoleResponse interface.
 */
export function instanceOfUpdateJobRoleResponse(value: object): boolean {
    return true;
}

export function UpdateJobRoleResponseFromJSON(json: any): UpdateJobRoleResponse {
    return UpdateJobRoleResponseFromJSONTyped(json, false);
}

export function UpdateJobRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateJobRoleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : JobRoleFromJSON(json['data']),
    };
}

export function UpdateJobRoleResponseToJSON(value?: UpdateJobRoleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': JobRoleToJSON(value['data']),
    };
}

