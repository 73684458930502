import { AppWarningEnum } from '@/lib/enum/AppWarningEnum';
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    warning: [] as AppWarningEnum[],
  },

  mutations: {
    SET_WARNING(state, warnings: AppWarningEnum[]) {
      Vue.set(state, 'warning', warnings);
    },
  },

  actions: {
    setWarning({ commit, state }, warning: AppWarningEnum) {
      commit('SET_WARNING', [...state.warning, warning]);
    },

    removeWarning({ commit, state }, warning: AppWarningEnum) {
      commit(
        'SET_WARNING',
        state.warning.filter((w) => w !== warning),
      );
    },

    resetWarnings({ commit }) {
      commit('SET_WARNING', []);
    },
  },
};
