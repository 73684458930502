/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OAuthClient } from './OAuthClient';
import {
    OAuthClientFromJSON,
    OAuthClientFromJSONTyped,
    OAuthClientToJSON,
} from './OAuthClient';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListOauthClientsResponse
 */
export interface ListOauthClientsResponse {
    /**
     * 
     * @type {Array<OAuthClient>}
     * @memberof ListOauthClientsResponse
     */
    data: Array<OAuthClient>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListOauthClientsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListOauthClientsResponse interface.
 */
export function instanceOfListOauthClientsResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListOauthClientsResponseFromJSON(json: any): ListOauthClientsResponse {
    return ListOauthClientsResponseFromJSONTyped(json, false);
}

export function ListOauthClientsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOauthClientsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(OAuthClientFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListOauthClientsResponseToJSON(value?: ListOauthClientsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(OAuthClientToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

