/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
} from './JobRole';

/**
 * 
 * @export
 * @interface ShowJobRoleResponse
 */
export interface ShowJobRoleResponse {
    /**
     * 
     * @type {JobRole}
     * @memberof ShowJobRoleResponse
     */
    data?: JobRole;
}

/**
 * Check if a given object implements the ShowJobRoleResponse interface.
 */
export function instanceOfShowJobRoleResponse(value: object): boolean {
    return true;
}

export function ShowJobRoleResponseFromJSON(json: any): ShowJobRoleResponse {
    return ShowJobRoleResponseFromJSONTyped(json, false);
}

export function ShowJobRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowJobRoleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : JobRoleFromJSON(json['data']),
    };
}

export function ShowJobRoleResponseToJSON(value?: ShowJobRoleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': JobRoleToJSON(value['data']),
    };
}

