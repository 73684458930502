
import Button from '@/components/buttons/Button.vue';
import { links } from '@/lang/urlLinks';
import {
  checkPlanChangeInterval,
  checkPlanChangeLevel,
  PlanChangeInterval,
  PlanChangeLevel,
} from '@/lib/billing/billingFunctions';
import {
  PlanChangeIntervalEnum,
  PlanChangeLevelEnum,
} from '@/lib/enum/billing/BillingEnum';
import { PropType } from 'vue';
import { BillingPlan } from '../../../api/v1';
import Dialog from '../dialog/Dialog.vue';

export default {
  name: 'PlanConfirmation',

  components: {
    Dialog,
    Button,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isTrialVersion: {
      type: Boolean,
      default: false,
    },
    billingOnly: {
      type: Boolean,
      default: false,
    },
    newBillingPlan: {
      type: Object as PropType<BillingPlan>,
      required: true,
    },
    oldBillingPlan: {
      type: Object as PropType<BillingPlan>,
      required: true,
    },
  },

  data() {
    return {
      links,
    };
  },

  computed: {
    open: {
      get() {
        return this.value;
      },
      set(v) {
        if (!v) this.$emit('close');
      },
    },

    planChangeLevel(): PlanChangeLevel {
      return checkPlanChangeLevel(
        this.oldBillingPlan.name,
        this.newBillingPlan.name,
      );
    },

    planChangeInterval(): PlanChangeInterval {
      return checkPlanChangeInterval(
        this.oldBillingPlan.interval,
        this.newBillingPlan.interval,
      );
    },

    heading(): string {
      if (this.isTrialVersion) {
        return this.$t('signUp.confirmation.nowTrialingPackage', {
          package: this.newBillingPlan.name,
        });
      }
      if (this.billingOnly) {
        return this.$t('signUp.confirmation.paymentDetailsUpdated');
      }

      if (this.planChangeLevel === PlanChangeLevelEnum.Upgrade) {
        return this.$t('signUp.confirmation.upgrade', {
          plan: this.newBillingPlan.name,
        });
      }
      if (this.planChangeLevel === PlanChangeLevelEnum.Downgrade) {
        return this.$t('signUp.confirmation.downgrade', {
          plan: this.newBillingPlan.name,
        });
      }
      if (
        [
          PlanChangeIntervalEnum.AnnuallyToMonthly,
          PlanChangeIntervalEnum.MonthlyToAnnually,
        ].includes(this.planChangeInterval)
      ) {
        return this.$t('signUp.confirmation.planChange');
      }
      return this.$t('signUp.confirmation.confirmingPlanChoice');
    },
    bodyPath(): string {
      if (this.billingOnly) {
        return '';
      }

      // Billing upgrade
      if (this.planChangeLevel === PlanChangeLevelEnum.Upgrade) {
        if (
          this.planChangeInterval ===
            PlanChangeIntervalEnum.AnnuallyToMonthly &&
          !this.isTrialVersion
        ) {
          // Future upgrade
          return 'signUp.confirmation.upgradeAnnualToMonthly';
        }
        return 'signUp.confirmation.upgradeCoffee';
      }

      // Billing downgrade
      if (this.planChangeLevel === PlanChangeLevelEnum.Downgrade) {
        if (
          this.planChangeInterval ===
            PlanChangeIntervalEnum.MonthlyToAnnually ||
          this.isTrialVersion
        ) {
          // Immediate downgrade
          return 'signUp.confirmation.lossOfFeatures';
        }
        return 'signUp.confirmation.lossOfFeaturesNextCycle';
      }

      // Same monthly plan to annual plan
      if (
        this.planChangeInterval === PlanChangeIntervalEnum.MonthlyToAnnually
      ) {
        return 'signUp.confirmation.monthlyToAnnually';
      }

      // Same annual plan to monthly plan
      if (
        this.planChangeInterval === PlanChangeIntervalEnum.AnnuallyToMonthly
      ) {
        return 'signUp.confirmation.annuallyToMonthly';
      }

      return 'signUp.confirmation.pleasedStickingAround';
    },
  },
};
