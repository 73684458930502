/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigiticketsConnection } from './DigiticketsConnection';
import {
    DigiticketsConnectionFromJSON,
    DigiticketsConnectionFromJSONTyped,
    DigiticketsConnectionToJSON,
} from './DigiticketsConnection';

/**
 * 
 * @export
 * @interface UpdateDigiticketsConnectionResponse
 */
export interface UpdateDigiticketsConnectionResponse {
    /**
     * 
     * @type {DigiticketsConnection}
     * @memberof UpdateDigiticketsConnectionResponse
     */
    data: DigiticketsConnection;
}

/**
 * Check if a given object implements the UpdateDigiticketsConnectionResponse interface.
 */
export function instanceOfUpdateDigiticketsConnectionResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateDigiticketsConnectionResponseFromJSON(json: any): UpdateDigiticketsConnectionResponse {
    return UpdateDigiticketsConnectionResponseFromJSONTyped(json, false);
}

export function UpdateDigiticketsConnectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDigiticketsConnectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': DigiticketsConnectionFromJSON(json['data']),
    };
}

export function UpdateDigiticketsConnectionResponseToJSON(value?: UpdateDigiticketsConnectionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': DigiticketsConnectionToJSON(value['data']),
    };
}

