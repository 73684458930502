/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCompanyOnboardingData
 */
export interface UpdateCompanyOnboardingData {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyOnboardingData
     */
    hasAddedJobRoles?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyOnboardingData
     */
    hasAddedTeam?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyOnboardingData
     */
    hasInvitedTeam?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyOnboardingData
     */
    hasAddedShifts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyOnboardingData
     */
    hasPublishedShifts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyOnboardingData
     */
    hasConfiguredClockingSettings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyOnboardingData
     */
    hasConfiguredLeaveSettings?: boolean;
}

/**
 * Check if a given object implements the UpdateCompanyOnboardingData interface.
 */
export function instanceOfUpdateCompanyOnboardingData(value: object): boolean {
    return true;
}

export function UpdateCompanyOnboardingDataFromJSON(json: any): UpdateCompanyOnboardingData {
    return UpdateCompanyOnboardingDataFromJSONTyped(json, false);
}

export function UpdateCompanyOnboardingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyOnboardingData {
    if (json == null) {
        return json;
    }
    return {
        
        'hasAddedJobRoles': json['hasAddedJobRoles'] == null ? undefined : json['hasAddedJobRoles'],
        'hasAddedTeam': json['hasAddedTeam'] == null ? undefined : json['hasAddedTeam'],
        'hasInvitedTeam': json['hasInvitedTeam'] == null ? undefined : json['hasInvitedTeam'],
        'hasAddedShifts': json['hasAddedShifts'] == null ? undefined : json['hasAddedShifts'],
        'hasPublishedShifts': json['hasPublishedShifts'] == null ? undefined : json['hasPublishedShifts'],
        'hasConfiguredClockingSettings': json['hasConfiguredClockingSettings'] == null ? undefined : json['hasConfiguredClockingSettings'],
        'hasConfiguredLeaveSettings': json['hasConfiguredLeaveSettings'] == null ? undefined : json['hasConfiguredLeaveSettings'],
    };
}

export function UpdateCompanyOnboardingDataToJSON(value?: UpdateCompanyOnboardingData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hasAddedJobRoles': value['hasAddedJobRoles'],
        'hasAddedTeam': value['hasAddedTeam'],
        'hasInvitedTeam': value['hasInvitedTeam'],
        'hasAddedShifts': value['hasAddedShifts'],
        'hasPublishedShifts': value['hasPublishedShifts'],
        'hasConfiguredClockingSettings': value['hasConfiguredClockingSettings'],
        'hasConfiguredLeaveSettings': value['hasConfiguredLeaveSettings'],
    };
}

