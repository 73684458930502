export type SocketOperationKind = 'Created' | 'Deleted' | 'Updated';

export enum WebsocketControlEvent {
  NewDeployment = 'CE_DEPLOYMENT',
  ClientAuthenticationSuccessful = 'CE_AUTH_SUCCESS',
}

export enum SocketServiceEventType {
  ENTITY = 'ENTITY',
  BATCH_ENTITY = 'BATCH_ENTITY',
  CONTROL = 'CONTROL',
}

export type EntityEventType<T extends string> = `ENTITY:${T}`;

export type BatchEntityEventType<T extends string> = `BATCH_ENTITY:${T}`;

export type EntityEvent<T extends string> = {
  entity: T;
} & (
  | {
      kind: 'Deleted';
      data: number | string | unknown;
    }
  | {
      kind: 'Created' | 'Updated';
      data: unknown;
    }
);

export type BatchEntityEvent<T extends string> = {
  entity: T;
  kind: SocketOperationKind;
  data: number[];
};

interface EntityEvents<T extends string> {
  [key: string]: typeof key extends BatchEntityEventType<T>
    ? BatchEntityEvent<T>
    : typeof key extends EntityEventType<T>
    ? EntityEvent<T>
    : never;
}

// Main Event Emitter type
export type SocketServiceEventMap<T extends string> = {
  [SocketServiceEventType.ENTITY]: EntityEvent<T>;
  [SocketServiceEventType.BATCH_ENTITY]: BatchEntityEvent<T>;
  [SocketServiceEventType.CONTROL]: { event: WebsocketControlEvent };
} & { [k in EntityEventType<T>]: EntityEvent<T> } & {
  [k in BatchEntityEventType<T>]: BatchEntityEvent<T>;
} & EntityEvents<T>;

// Event Emitter type for listening
export type SocketServiceEventMapListeners<T extends string> = {
  [SocketServiceEventType.ENTITY]: EntityEvent<T>;
  [SocketServiceEventType.BATCH_ENTITY]: BatchEntityEvent<T>;
  [SocketServiceEventType.CONTROL]: { event: WebsocketControlEvent };
} & EntityEvents<T>;

export const entityEventType = <T extends string>(
  entity: T,
): EntityEventType<T> => `ENTITY:${entity}`;

export const batchEntityEventType = <T extends string>(
  entity: T,
): BatchEntityEventType<T> => `BATCH_ENTITY:${entity}`;
