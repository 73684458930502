
import { BrandIconType, IconType } from '@/lib/enum/Icon';
import { PropType } from 'vue';

enum Type {
  Hint = 'hint',
  Warning = 'warning',
  Danger = 'danger',
  Error = 'error',
}

type TypeValue = typeof Type[keyof typeof Type];

export default {
  name: 'IconMessageBlock',

  props: {
    icon: {
      type: String as PropType<IconType | BrandIconType | null>,
      default: null,
    },

    message: {
      type: String,
      default: '',
    },

    transition: {
      type: String,
      default: 'fade',
    },

    type: {
      type: String as PropType<TypeValue>,
      default: Type.Error,
    },
  },
};
