import { Tag } from '../../api/v1';

export const capitalise = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const capitaliseAll = (string: string) => {
  const parts = string.split(' ');
  return parts.reduce((a, p) => `${a} ${capitalise(p)}`, '');
};

export const initials = (a: string, b: string) =>
  `${a.charAt(0).toUpperCase()}${b.charAt(0).toUpperCase()}`;

export const urlB64ToUint8Array = (string: string) => {
  const padding = '='.repeat(4 - ((string.length % 4) % 4));
  const base64 = (string + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export const concatTags = (tags: Tag[]): string =>
  tags.map((tag) => tag.name).join(', ');

export const splitWordString = (str: string): string[] => {
  let words: string[] = str.split(/\s+/); // Regular case

  if (words.length <= 1) words = str.split('_'); // snake_case
  if (words.length <= 1) words = str.split('-'); // kebab-case
  if (words.length <= 1) {
    // Split by capital letters (camelCase or PascalCase)
    words = str.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
  }

  return words;
};

export const wordsToRegularCase = (
  words: string[],
  capitalised?: boolean,
): string => {
  words = words.map((word) => word.toLowerCase());
  if (capitalised && words[0]) words[0] = capitalise(words[0]);
  return words.join(' ');
};

export const stringToRegularCase = (
  str: string,
  capitalised?: boolean,
): string => wordsToRegularCase(splitWordString(str), capitalised);

/**
 * Compares two strings in their 'base' form, e.g.,
 *    baseStringsAreEqual('Dev Team', 'Dév Team') => true
 *    baseStringsAreEqual('DeV tEaM', 'dev team') => true
 *    baseStringsAreEqual('Dev Team', 'Dev Team 2') => false
 */
export const baseStringsAreEqual = (a: string, b: string): boolean =>
  a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0;

const PLURAL_EXCEPTIONS: Record<string, string> = {};
const SINGULAR_EXCEPTIONS: Record<string, string> = {};

export const toSingular = (plural: string): string =>
  plural in PLURAL_EXCEPTIONS
    ? PLURAL_EXCEPTIONS[plural]
    : plural.replace(/s?$/, '');

export const toPlural = (singular: string): string =>
  singular in SINGULAR_EXCEPTIONS
    ? SINGULAR_EXCEPTIONS[singular]
    : `${singular}s`;

export const generateDuplicateName = (
  originalName: string,
  entities: { name: string; [key: string]: unknown }[],
): string => {
  const regex = new RegExp(
    `^${originalName.replace(
      /[-[\]{}()*+?.,\\^$|#\s]/g,
      '\\$&',
    )}(.?\\([0-9]+\\))?$`,
  );
  const matchingEntities = entities.filter(
    (e) => !!e.name.match(regex)?.length,
  ).length;

  return `${originalName} (${matchingEntities})`;
};

// A descriptive name for a CSS classes string (ex. 'text-red-700 bg-red-50')
export type CSSClass = string;
