var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ActionDialog',{attrs:{"persistent":"","value":_vm.open,"bubble-icon":{
    icon: _vm.Icon.TriangleExclamation,
    colour: 'yellow',
  },"body-text":_vm.$tc('modal.body.unsavedChanges'),"header":_vm.$tc('modal.heading.unsavedChanges'),"primary-button":{
    label: _vm.$tc('button.yesProceed'),
    action: () => _vm.confirm(),
    colour: 'pink',
  },"secondary-action":{
    label: _vm.$t('button.cancel'),
    action: () => _vm.cancel(),
    colour: 'transparent',
  }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._t("default")]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }