import { isFuture, isPast } from '@/util/dateArithmetic';
import spacetime from 'spacetime';
import { Shift, TimesheetEntry } from '../../../api/v1';

export const isAssigned = (shift: Shift): boolean => !!shift.employeeId;

export const isUnassignedAndNotOpen = (shift: Shift): boolean =>
  !shift.employeeId && !shift.open;

export const isOpenAndUnassigned = (shift: Shift): boolean =>
  !shift.employeeId && shift.open;

export const isPublished = (shift: Shift): boolean => !!shift.publishedAt;

export const isLocked = (shift: Shift): boolean => !!shift.lockedAt;

export const withinLateGracePeriod = (
  { startsAt }: Shift,
  gracePeriod: number,
  timezone: string,
): boolean => {
  const latestClockInTime = spacetime(startsAt, timezone)
    .add(gracePeriod, 'minute')
    .toNativeDate();
  return isPast(startsAt) && isFuture(latestClockInTime);
};

export const isMissing = (shift: Shift, timesheet: TimesheetEntry): boolean => {
  return (
    isPublished(shift) &&
    isAssigned(shift) &&
    isPast(shift.startsAt) &&
    !timesheet
  );
};
