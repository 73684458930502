/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeAttribute } from './EmployeeAttribute';
import {
    EmployeeAttributeFromJSON,
    EmployeeAttributeFromJSONTyped,
    EmployeeAttributeToJSON,
} from './EmployeeAttribute';

/**
 * 
 * @export
 * @interface UpdateEmployeeAttributeResponse
 */
export interface UpdateEmployeeAttributeResponse {
    /**
     * 
     * @type {EmployeeAttribute}
     * @memberof UpdateEmployeeAttributeResponse
     */
    data: EmployeeAttribute;
}

/**
 * Check if a given object implements the UpdateEmployeeAttributeResponse interface.
 */
export function instanceOfUpdateEmployeeAttributeResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateEmployeeAttributeResponseFromJSON(json: any): UpdateEmployeeAttributeResponse {
    return UpdateEmployeeAttributeResponseFromJSONTyped(json, false);
}

export function UpdateEmployeeAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeeAttributeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeAttributeFromJSON(json['data']),
    };
}

export function UpdateEmployeeAttributeResponseToJSON(value?: UpdateEmployeeAttributeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeeAttributeToJSON(value['data']),
    };
}

