/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SentEmail } from './SentEmail';
import {
    SentEmailFromJSON,
    SentEmailFromJSONTyped,
    SentEmailToJSON,
} from './SentEmail';

/**
 * 
 * @export
 * @interface EmployeeInvite
 */
export interface EmployeeInvite {
    /**
     * 
     * @type {string}
     * @memberof EmployeeInvite
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeInvite
     */
    companyId: number;
    /**
     * The id of the employee the invite was sent to.
     * @type {number}
     * @memberof EmployeeInvite
     */
    employeeId: number;
    /**
     * The id of the employee who sent the invite.
     * @type {number}
     * @memberof EmployeeInvite
     */
    invitedById: number;
    /**
     * 
     * @type {SentEmail}
     * @memberof EmployeeInvite
     */
    sentEmail: SentEmail;
    /**
     * 
     * @type {Date}
     * @memberof EmployeeInvite
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmployeeInvite
     */
    updatedAt: Date | null;
}

/**
 * Check if a given object implements the EmployeeInvite interface.
 */
export function instanceOfEmployeeInvite(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('invitedById' in value)) return false;
    if (!('sentEmail' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function EmployeeInviteFromJSON(json: any): EmployeeInvite {
    return EmployeeInviteFromJSONTyped(json, false);
}

export function EmployeeInviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeInvite {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'invitedById': json['invitedById'],
        'sentEmail': SentEmailFromJSON(json['sentEmail']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

export function EmployeeInviteToJSON(value?: EmployeeInvite | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'invitedById': value['invitedById'],
        'sentEmail': SentEmailToJSON(value['sentEmail']),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

