/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingSubscriptionCancellation } from './BillingSubscriptionCancellation';
import {
    BillingSubscriptionCancellationFromJSON,
    BillingSubscriptionCancellationFromJSONTyped,
    BillingSubscriptionCancellationToJSON,
} from './BillingSubscriptionCancellation';

/**
 * 
 * @export
 * @interface CreateSubscriptionCancellationResponse
 */
export interface CreateSubscriptionCancellationResponse {
    /**
     * 
     * @type {BillingSubscriptionCancellation}
     * @memberof CreateSubscriptionCancellationResponse
     */
    data: BillingSubscriptionCancellation;
}

/**
 * Check if a given object implements the CreateSubscriptionCancellationResponse interface.
 */
export function instanceOfCreateSubscriptionCancellationResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateSubscriptionCancellationResponseFromJSON(json: any): CreateSubscriptionCancellationResponse {
    return CreateSubscriptionCancellationResponseFromJSONTyped(json, false);
}

export function CreateSubscriptionCancellationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSubscriptionCancellationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingSubscriptionCancellationFromJSON(json['data']),
    };
}

export function CreateSubscriptionCancellationResponseToJSON(value?: CreateSubscriptionCancellationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': BillingSubscriptionCancellationToJSON(value['data']),
    };
}

