/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OAuthClient
 */
export interface OAuthClient {
    /**
     * 
     * @type {number}
     * @memberof OAuthClient
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OAuthClient
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthClient
     */
    clientId: string;
    /**
     * List of scopes available for the client to request. When no scopes are defined, then the client can request any available scope.
     * @type {Array<string>}
     * @memberof OAuthClient
     */
    scopes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OAuthClient
     */
    expiresAt: string | null;
}

/**
 * Check if a given object implements the OAuthClient interface.
 */
export function instanceOfOAuthClient(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('clientId' in value)) return false;
    if (!('scopes' in value)) return false;
    if (!('expiresAt' in value)) return false;
    return true;
}

export function OAuthClientFromJSON(json: any): OAuthClient {
    return OAuthClientFromJSONTyped(json, false);
}

export function OAuthClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): OAuthClient {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'clientId': json['clientId'],
        'scopes': json['scopes'],
        'expiresAt': json['expiresAt'],
    };
}

export function OAuthClientToJSON(value?: OAuthClient | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'clientId': value['clientId'],
        'scopes': value['scopes'],
        'expiresAt': value['expiresAt'],
    };
}

