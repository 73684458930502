import { IconType } from '@/lib/enum/Icon';
import VueI18n from 'vue-i18n';
import { Location } from 'vue-router';

export interface MenuItem {
  name: VueI18n.TranslateResult | string;
  route: Location;
  hide?: boolean;
  disabled?: boolean;
  icon?: IconType;
  tooltip?: VueI18n.TranslateResult | string;
}

export interface MenuSection {
  name: VueI18n.TranslateResult | string;
  icon: IconType;
  open: (routeName: string) => boolean;
  hide: boolean;
  children: MenuItem[];
}

type MenuItemObject = { [name: string]: (...args: any[]) => MenuItem };
type MenuItemSection = { [name: string]: (...args: any[]) => MenuSection };

/**
 * @see https://stackoverflow.com/a/52157355/921476
 */
export const toItemMap = <T extends MenuItemObject>(o: T): T => o;
export const toSectionMap = <T extends MenuItemSection>(o: T): T => o;
